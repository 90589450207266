@media (max-width: 991px) {
  .earning-add-modal {
    padding-top: 10px;
    padding-bottom: 10px; } }

.earning-add-modal .sub-panels .panel-body-table .panel {
  margin-bottom: 10px;
  box-shadow: none; }

.earning-add-modal .sub-panels:last-child .panel-body-table .panel {
  box-shadow: none; }
  @media (max-width: 767px) {
    .earning-add-modal .sub-panels:last-child .panel-body-table .panel .nc-radio-button:last-child {
      margin-bottom: 0; } }

.earning-add-modal .sub-panels:last-child .panel-body-table .inactive-panel {
  padding-bottom: 10px; }
