.multi-menu-section-tab-container {
  font-family: "Source Sans Pro", sans-serif;
  color: #555; }
  .multi-menu-section-tab-container .multi-menu-section-tab-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .multi-menu-section-tab-container .multi-menu-section-tab-content .active {
      width: none; }
    .multi-menu-section-tab-container .multi-menu-section-tab-content .single-tab-menu {
      background-color: #fff;
      padding: 10px;
      text-align: center;
      height: inherit;
      cursor: pointer;
      margin-right: 10px;
      margin-bottom: 20px; }
      .multi-menu-section-tab-container .multi-menu-section-tab-content .single-tab-menu:last-child {
        margin-right: 0; }
    .multi-menu-section-tab-container .multi-menu-section-tab-content .single-tab-menu.disabled {
      opacity: 0.4;
      cursor: not-allowed; }
    .multi-menu-section-tab-container .multi-menu-section-tab-content .single-tab-icon > img {
      height: 30px; }
    .multi-menu-section-tab-container .multi-menu-section-tab-content .single-tab-content .single-tab-label {
      margin-left: 5px;
      font-size: 15px;
      font-weight: 400;
      text-transform: uppercase; }
    .multi-menu-section-tab-container .multi-menu-section-tab-content .single-tab-content > i {
      font-size: 20px; }
    .multi-menu-section-tab-container .multi-menu-section-tab-content .badge-count {
      background-color: #fb6b5b !important;
      font-size: 14px !important;
      font-family: Source Sans Pro, sans-serif !important;
      font-weight: 400; }
    .multi-menu-section-tab-container .multi-menu-section-tab-content .single-tab-menu.active {
      border-top: 5px solid #339966;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      padding-top: 5px !important;
      height: 112px;
      margin-bottom: 0px !important; }
      @media (min-width: 991px) {
        .multi-menu-section-tab-container .multi-menu-section-tab-content .single-tab-menu.active {
          height: 94px !important; } }
  .multi-menu-section-tab-container .multi-menu-section-tab-content-mobile .single-tab-menu {
    background-color: #fff;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 10px; }
  .multi-menu-section-tab-container .multi-menu-section-tab-content-mobile .single-tab-menu.disabled {
    opacity: 0.4;
    cursor: not-allowed; }
  @media (max-width: 767px) {
    .multi-menu-section-tab-container .multi-menu-section-tab-content-mobile .single-tab-content {
      display: flex;
      align-items: center;
      justify-content: center; } }
  .multi-menu-section-tab-container .multi-menu-section-tab-content-mobile .single-tab-content .single-tab-label {
    margin-left: 5px;
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase; }
  .multi-menu-section-tab-container .multi-menu-section-tab-content-mobile .single-tab-content > i {
    font-size: 22px; }
  .multi-menu-section-tab-container .multi-menu-section-tab-content-mobile .single-tab-content .single-tab-icon-mobile {
    margin-right: 5px; }
    .multi-menu-section-tab-container .multi-menu-section-tab-content-mobile .single-tab-content .single-tab-icon-mobile > img {
      height: 30px; }
  .multi-menu-section-tab-container .multi-menu-section-tab-content-mobile .badge-count {
    background-color: #fb6b5b !important;
    font-size: 14px !important;
    font-family: Source Sans Pro, sans-serif !important;
    font-weight: 400; }
  .multi-menu-section-tab-container .multi-menu-section-tab-content-mobile .single-tab-menu.active {
    border-top: 5px solid #339966;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    padding-top: 5px !important;
    margin-bottom: 10px !important; }

@media (min-width: 992px) and (max-width: 1199px) {
  .single-tab-menu {
    width: 24%; }
  .single-tab-menu.active {
    width: 24%; } }

@media (min-width: 768px) and (max-width: 991px) {
  .single-tab-menu {
    width: 23.5%; }
  .single-tab-menu.active {
    width: 23.5%; } }

@media (min-width: 1200px) {
  .single-tab-menu {
    width: 24%; }
  .single-tab-menu.active {
    width: 24%; } }

@media (max-width: 767px) {
  .single-tab-menu.active {
    width: auto !important; } }

@media (max-width: 420px) {
  .multi-menu-section-tab-content-mobile .single-tab-menu {
    display: flex;
    align-content: center;
    justify-content: center; } }
