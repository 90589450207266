.notification-popover {
  padding: 0px; }
  .notification-popover .popover-content {
    padding: 0px; }
    .notification-popover .popover-content .notification-item {
      padding: 15px;
      border-bottom: 1px solid #ccc; }
      .notification-popover .popover-content .notification-item .notification-image {
        float: left;
        width: 50px; }
      .notification-popover .popover-content .notification-item .notification-item-content {
        float: left;
        width: 300px;
        padding-left: 20px; }
      .notification-popover .popover-content .notification-item .notification-time {
        float: left; }
      .notification-popover .popover-content .notification-item:last-child {
        border-bottom: none; }
      .notification-popover .popover-content .notification-item:hover {
        cursor: default;
        background-color: #ebe7e7; }
    .notification-popover .popover-content .popover-footer {
      text-align: center;
      color: "";
      border-top: 1px solid #ccc;
      padding: 10px;
      font-weight: 600; }
