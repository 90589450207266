.utility-container {
  padding-top: 50px;
  font-size: 15px;
  font-weight: 600; }
  .utility-container i {
    margin-right: 15px;
    margin-left: 8px; }
  .utility-container .seperator {
    padding: 10px 8px; }
  .utility-container .item-title {
    color: #009999;
    cursor: pointer; }
  .utility-container .items {
    margin: 0px 20px; }
    .utility-container .items .fa-chevron-circle-right {
      color: #009999; }
  .utility-container .item-description {
    margin: 10px 0px 25px;
    font-weight: 500;
    padding-right: 25px; }
