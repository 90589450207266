.wage-tax-tabs-section {
  display: flex;
  margin-bottom: 10px; }
  .wage-tax-tabs-section .wage-tax-icon-horizontal {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px; }
    .wage-tax-tabs-section .wage-tax-icon-horizontal .wage-tax-icon-name {
      color: #555;
      font-size: 15px;
      font-weight: 400;
      margin-left: 15px; }
    .wage-tax-tabs-section .wage-tax-icon-horizontal .wage-tax-icon-name-bold {
      font-size: 16px !important;
      font-weight: 600 !important; }
  .wage-tax-tabs-section .wage-tax-selected-tab {
    color: #339966 !important; }

.wage-tax-validation-item {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #ccc; }
  @media (min-width: 992px) {
    .wage-tax-validation-item {
      display: flex;
      align-items: center; } }
  @media (max-width: 767px) {
    .wage-tax-validation-item {
      margin-bottom: 20px;
      padding-bottom: 20px; } }
  .wage-tax-validation-item .nc-accordian {
    margin-left: 52px; }
    @media (max-width: 991px) {
      .wage-tax-validation-item .nc-accordian {
        margin-left: 0;
        margin-bottom: 10px; } }
  .wage-tax-validation-item:last-child {
    margin-bottom: 0px;
    padding-bottom: 0; }
  @media (max-width: 767px) {
    .wage-tax-validation-item .current-section {
      margin-bottom: 30px !important;
      padding-right: 0px; } }
  .wage-tax-validation-item .ig-wrapper {
    display: flex;
    padding-right: 20px; }
    .wage-tax-validation-item .ig-wrapper.tax-verification {
      margin-bottom: 10px; }
      .wage-tax-validation-item .ig-wrapper.tax-verification:last-child {
        margin-bottom: 0; }
    @media (max-width: 991px) {
      .wage-tax-validation-item .ig-wrapper.min-wage .information-group {
        display: flex; }
        .wage-tax-validation-item .ig-wrapper.min-wage .information-group .information-group-title {
          padding-right: 5px; } }
    @media (min-width: 992px) {
      .wage-tax-validation-item .ig-wrapper:last-child {
        margin-bottom: 0; } }
    @media (max-width: 991px) {
      .wage-tax-validation-item .ig-wrapper {
        padding-right: 0; }
        .wage-tax-validation-item .ig-wrapper:last-child {
          margin-bottom: 0px; } }
    @media (max-width: 767px) {
      .wage-tax-validation-item .ig-wrapper {
        padding-right: 0;
        margin-bottom: 0px; }
        .wage-tax-validation-item .ig-wrapper:last-child {
          margin-bottom: 0px; } }
    .wage-tax-validation-item .ig-wrapper .information-group {
      font-size: 15px; }
      @media (max-width: 767px) {
        .wage-tax-validation-item .ig-wrapper .information-group {
          width: 100%; } }
      .wage-tax-validation-item .ig-wrapper .information-group.hr {
        border-bottom: 1px solid #ccc; }
  .wage-tax-validation-item:last-child {
    border-bottom: none; }
  .wage-tax-validation-item .min-wage-edit-button {
    color: #999; }
    @media (min-width: 992px) {
      .wage-tax-validation-item .min-wage-edit-button .nc-button.outline-button {
        float: right; } }

.wage-tax-sub-section .wage-tax-sub-header-section .wage-tax-sub-header-title-section {
  margin-bottom: 10px; }
  .wage-tax-sub-section .wage-tax-sub-header-section .wage-tax-sub-header-title-section .wage-tax-sub-header-title {
    font-weight: 600;
    margin-right: 10px;
    font-size: 15px; }

.wage-tax-sub-section .wage-tax-sub-header-description-section {
  margin-bottom: 10px;
  font-size: 15px; }
  .wage-tax-sub-section .wage-tax-sub-header-description-section .wage-tax-sub-header-update-link {
    text-align: right;
    color: #009999;
    cursor: pointer; }

.wage-tax-sub-section .wage-table-container {
  margin-top: 20px; }

.accordian-information-group .information-group {
  margin-bottom: 15px; }
  .accordian-information-group .information-group.hr {
    border-bottom: 1px solid #ccc; }
  .accordian-information-group .information-group:last-child {
    margin-bottom: 0; }
    .accordian-information-group .information-group:last-child.hr {
      border-bottom: none; }
