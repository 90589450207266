.map-gl-snapshot.modal#activity-history{
    @media(min-width:992px){
         .modal-dialog{
            width:800px;
        }
    }
    .employer-paid-table{
.second-column{
    @media(min-width:992px){
    text-align: center;
    }
}
    }
    .employee-paid-table{
        .employee-node-row{
            @media(max-width:991px){
                .employee-node-row-item {
                    &:not(:first-child){
margin-top: 5px;
                    }
                }
                &:first-child{
                    padding-top: 0;
    
                }
                &:last-child{
                    padding-bottom: 0;
    
                }
            }
           
        }

    }
    .employer-node-row{
        .employer-node-row-item {
&:not(:first-child){margin-top: 5px;

                        
                    }
        }
        @media(max-width:991px){
            &:first-child{
                padding-top: 0;

            }
            &:last-child{
                padding-bottom: 0;

            }
        }
        .other-column {
            @media(min-width:992px){
              text-align: center;
            }
            @media(max-width:991px){

              margin-top: 5px;
            }
        
          }
    }
    
}