.st0 {
  fill: #339966; }

.st1 {
  fill: #ffffff; }

.st3 {
  fill: #009999; }
  .st3:hover {
    fill: #0c4f48; }

.st4 {
  fill: #cccccc; }

.st5 {
  fill: #555555; }

.st6 {
  fill: #a3712c; }

.st7 {
  fill: #4762b2; }

.st8 {
  fill: #3d3d3d; }

.st9 {
  fill: #3d4766; }

.st10 {
  fill: #578009; }
