/*Icons for PTo approve actions*/
.nc-red {
  color: #FB6B5B; }

.nc-green {
  color: #339966; }

.MultiSectionTableItem {
  clear: both;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9e9e9; }
  @media (min-width: 992px) {
    .MultiSectionTableItem {
      display: flex; } }
  .MultiSectionTableItem li {
    list-style-type: none; }
  @media (max-width: 991px) {
    .MultiSectionTableItem .sub-item-container {
      clear: both; } }
  @media (max-width: 991px) {
    .MultiSectionTableItem:first-child {
      padding-top: 10px !important; } }

.MultiSectionTableItem:last-child {
  border-bottom: none;
  padding-bottom: 0; }

@media (max-width: 991px) {
  .leave-type {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 8px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .pto-available-header {
    width: 15%; }
  .pto-future-approved {
    width: 13%; }
  .pto-employee {
    width: 19%; }
  .unapproved-pto-row-header, .unapproved-pto-row {
    width: 72%; }
  .unapproved-pto-row-header-first-column {
    width: 19%; }
  .pto-action {
    width: 13.6%; }
  .pto-future-approved {
    width: 13%; }
  .pto-available {
    width: 15%; } }

@media (min-width: 1200px) {
  .pto-action {
    width: 13%; }
  .pto-future-approved {
    width: 12%; } }

.contact-all-approvers .primary-link-button {
  padding-top: 0 !important; }
