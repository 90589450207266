.nc-entity-page-container .payroll-schedule-input {
  margin: 30px 0; }
  .nc-entity-page-container .payroll-schedule-input .payroll-name-description {
    margin-bottom: 10px;
    font-size: 15px; }

.nc-entity-page-container .deduction-name-input {
  margin-bottom: 10px; }
  .nc-entity-page-container .deduction-name-input i {
    margin-left: 5px;
    color: #009999; }
  .nc-entity-page-container .deduction-name-input .edit-icon a {
    float: right; }
    .nc-entity-page-container .deduction-name-input .edit-icon a i {
      color: #555; }
  .nc-entity-page-container .deduction-name-input .review-field {
    font-size: 15px;
    margin-bottom: 5px; }
  .nc-entity-page-container .deduction-name-input .payroll-name-description {
    margin-bottom: 10px;
    font-size: 15px; }
  .nc-entity-page-container .deduction-name-input .input-field {
    margin-right: 0; }
  .nc-entity-page-container .deduction-name-input .input-field-sm {
    width: 80px; }
  .nc-entity-page-container .deduction-name-input .input-character {
    font-size: 15px;
    margin: 8px 0 0 15px;
    font-style: italic;
    color: #a7a4a4; }
  .nc-entity-page-container .deduction-name-input .side-info {
    font-size: 15px;
    margin: 8px 0 0 15px; }

.pay-period-Monthly {
  margin-top: 10px;
  padding: 15px;
  background: #e9e9e9;
  font-size: 15px; }
  .pay-period-Monthly:first-child {
    margin-top: 0px; }
  @media (min-width: 992px) {
    .pay-period-Monthly {
      width: 70%; } }
  .pay-period-Monthly .drop-down-first {
    width: fit-content;
    padding: 0;
    margin-left: 5px;
    margin-right: 5px;
    position: relative; }
    @media (max-width: 992px) {
      .pay-period-Monthly .drop-down-first {
        width: 105px; }
        .pay-period-Monthly .drop-down-first button {
          height: 34px;
          padding: 6px 10px; } }
  .pay-period-Monthly .drop-down-sec {
    width: fit-content;
    padding: 0;
    position: relative;
    margin-left: 5px;
    margin-right: 5px; }
    @media (max-width: 992px) {
      .pay-period-Monthly .drop-down-sec {
        width: 105px; }
        .pay-period-Monthly .drop-down-sec button {
          height: 34px;
          padding: 6px 10px; } }
  .pay-period-Monthly .select-month {
    padding: 0;
    background: white;
    width: 220px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-left: 5px; }

.pay-period-anually {
  margin-top: 10px;
  padding: 15px;
  background: #e9e9e9;
  font-size: 15px; }
  .pay-period-anually:first-child {
    margin-top: 0px; }
  @media (min-width: 992px) {
    .pay-period-anually {
      width: 70%; } }
  .pay-period-anually .drop-down-first {
    padding: 0;
    margin-left: 5px;
    margin-right: 5px;
    position: relative; }
    @media (max-width: 992px) {
      .pay-period-anually .drop-down-first button {
        height: 34px;
        padding: 6px 10px; } }
    .pay-period-anually .drop-down-first .nc-select-container:first-child {
      margin-right: 15px; }
      .pay-period-anually .drop-down-first .nc-select-container:first-child button {
        width: 120px; }
    .pay-period-anually .drop-down-first .nc-select-container button {
      width: 105px; }
  .pay-period-anually .drop-down-sec {
    padding: 0;
    position: relative;
    margin-left: 5px;
    margin-right: 5px; }
    @media (max-width: 992px) {
      .pay-period-anually .drop-down-sec button {
        height: 34px;
        padding: 6px 10px; } }
    .pay-period-anually .drop-down-sec .nc-select-container:first-child {
      margin-right: 15px; }
      .pay-period-anually .drop-down-sec .nc-select-container:first-child button {
        width: 120px; }
    .pay-period-anually .drop-down-sec .nc-select-container button {
      width: 105px; }
  .pay-period-anually .anually-section {
    display: flex;
    align-items: center; }

.switch-field {
  font-family: "Lucida Grande", Tahoma, Verdana, sans-serif;
  padding: 40px;
  overflow: hidden; }

.switch-title {
  margin-bottom: 6px; }

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden; }

.switch-field label {
  float: left; }

.switch-field label {
  margin: 0px;
  display: inline-block;
  width: 50%;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  padding: 10px 6px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }

.switch-field label:hover {
  cursor: pointer; }

.switch-field input:checked + label {
  background-color: #009999;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff; }

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px; }

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0; }

/** Commenting this caused issue in all placeholders including dropdowns, filters **/
