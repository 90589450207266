.switch-radio-field {
  border-left: 1px solid #ccc;
  border-right: none;
  border-radius: 5px;
  padding: 0px;
  margin-left: 15px;
  height: 34px; }
  .switch-radio-field div label {
    padding: 8px 6px;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-radius: 0px; }
  .switch-radio-field div:first-of-type label {
    border-radius: 4px 0 0 4px; }
  .switch-radio-field div:last-of-type label {
    border-radius: 0 4px 4px 0; }

.switch-radio-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden; }

.switch-radio-field label {
  float: left; }

.switch-radio-field label {
  margin: 0px;
  display: inline-block;
  width: 50%;
  background-color: #ffffff;
  color: #555;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  padding: 10px 6px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }

.switch-radio-field label:hover {
  cursor: pointer; }

.switch-radio-field input:checked + label {
  background-color: #009999;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff; }
