.nc-page-container, .nc-list-page-container,
.ncl-page-container {
  height: 100% !important;
  position: relative !important;
  padding: 30px 55px !important;
  color: #555 !important;
  font-family: "Source Sans Pro", sans-serif !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .nc-page-container, .nc-list-page-container,
    .ncl-page-container {
      padding: 10px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .nc-page-container, .nc-list-page-container,
    .ncl-page-container {
      padding: 20px !important; } }
  .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .ncl-page-container .pageTitle-container {
    padding-bottom: 25px; }
    .nc-page-container .pageTitle-container .pageTitle, .nc-list-page-container .pageTitle-container .pageTitle, .ncl-page-container .pageTitle-container .pageTitle {
      float: left;
      font-size: 25px;
      font-weight: 400; }
      @media (max-width: 767px) {
        .nc-page-container .pageTitle-container .pageTitle, .nc-list-page-container .pageTitle-container .pageTitle, .ncl-page-container .pageTitle-container .pageTitle {
          float: initial;
          font-size: 25px;
          /* DEVIATION : This was added because visually it did not look good without this */ } }
    @media (min-width: 768px) and (max-width: 991px) {
      .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .ncl-page-container .pageTitle-container {
        padding-bottom: 20px; } }
    @media (max-width: 767px) {
      .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .ncl-page-container .pageTitle-container {
        padding-bottom: 10px; } }

.nc-page-container::before, .nc-list-page-container::before,
.ncl-page-container::before {
  display: table;
  content: " "; }

.nc-page-container::after, .nc-list-page-container::after,
.ncl-page-container::after {
  display: table;
  content: " ";
  clear: both; }

.nc-list-page-container .pageTitle-container,
.ncl-page-container .pageTitle-container {
  padding-bottom: 2.5px; }
  @media (min-width: 768px) {
    .nc-list-page-container .pageTitle-container,
    .ncl-page-container .pageTitle-container {
      padding-bottom: 15px; } }
  .nc-list-page-container .pageTitle-container .pageTitle,
  .ncl-page-container .pageTitle-container .pageTitle {
    padding-bottom: 0px; }

.nc-list-page-container .page-description,
.ncl-page-container .page-description {
  font-size: 15px;
  line-height: 1.2;
  padding-bottom: 20px; }

.nc-list-page-container .deduction-page-description-container .deduction-page-description-content,
.ncl-page-container .deduction-page-description-container .deduction-page-description-content {
  padding-bottom: 30px; }
  .nc-list-page-container .deduction-page-description-container .deduction-page-description-content p,
  .ncl-page-container .deduction-page-description-container .deduction-page-description-content p {
    line-height: 1.3;
    font-size: 15px !important; }
  @media (max-width: 991px) {
    .nc-list-page-container .deduction-page-description-container .deduction-page-description-content,
    .ncl-page-container .deduction-page-description-container .deduction-page-description-content {
      padding-bottom: 5px; } }

.nc-list-page-container .deduction-page-description-container .deduction-page-description-button,
.ncl-page-container .deduction-page-description-container .deduction-page-description-button {
  float: right; }
  .nc-list-page-container .deduction-page-description-container .deduction-page-description-button button,
  .ncl-page-container .deduction-page-description-container .deduction-page-description-button button {
    float: right; }
    @media (max-width: 991px) {
      .nc-list-page-container .deduction-page-description-container .deduction-page-description-button button,
      .ncl-page-container .deduction-page-description-container .deduction-page-description-button button {
        float: left; } }
  @media (max-width: 991px) {
    .nc-list-page-container .deduction-page-description-container .deduction-page-description-button,
    .ncl-page-container .deduction-page-description-container .deduction-page-description-button {
      float: left;
      margin-bottom: 20px; } }

.nc-list-page-container .deduction-page-description-container .page-description-button,
.ncl-page-container .deduction-page-description-container .page-description-button {
  float: right; }
  .nc-list-page-container .deduction-page-description-container .page-description-button button,
  .ncl-page-container .deduction-page-description-container .page-description-button button {
    float: right; }
    @media (max-width: 991px) {
      .nc-list-page-container .deduction-page-description-container .page-description-button button,
      .ncl-page-container .deduction-page-description-container .page-description-button button {
        float: left; } }
  @media (max-width: 991px) {
    .nc-list-page-container .deduction-page-description-container .page-description-button,
    .ncl-page-container .deduction-page-description-container .page-description-button {
      float: left;
      margin-bottom: 20px; } }

.nc-list-page-container .add-deduction-button,
.ncl-page-container .add-deduction-button {
  min-width: 200px !important; }

.nc-list-page-container .activity-history-button,
.ncl-page-container .activity-history-button {
  min-width: 200px !important; }
  @media (max-width: 991px) {
    .nc-list-page-container .activity-history-button,
    .ncl-page-container .activity-history-button {
      float: left !important;
      margin-bottom: 10px; } }

@media (min-width: 992px) {
  .nc-list-page-container .footer-button-container .nc-button-group button,
  .ncl-page-container .footer-button-container .nc-button-group button {
    margin-right: 20px; } }

@media (max-width: 767px) {
  .nc-list-page-container .footer-button-container .nc-button-group .nc-link-group-mobile,
  .ncl-page-container .footer-button-container .nc-button-group .nc-link-group-mobile {
    width: 100%;
    margin-right: 0px; } }

@media (min-width: 768px) {
  .nc-list-page-container .footer-button-container .nc-button-group .nc-link-group-mobile,
  .ncl-page-container .footer-button-container .nc-button-group .nc-link-group-mobile {
    display: inline; } }

.nc-list-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button,
.ncl-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button {
  margin-right: 20px; }
  @media (max-width: 767px) {
    .nc-list-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button,
    .ncl-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button {
      width: 100%;
      margin-right: 0px; } }
  @media (min-width: 992px) {
    .nc-list-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button,
    .ncl-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button {
      margin-right: 20px; } }

.nc-list-page-container .no-entity-list-page,
.ncl-page-container .no-entity-list-page {
  clear: both;
  width: 100%;
  background-color: #e6e6e6;
  padding: 50px;
  text-align: center; }
  .nc-list-page-container .no-entity-list-page .header,
  .ncl-page-container .no-entity-list-page .header {
    font-size: 18px;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px; }

.nc-list-page-container::before {
  display: table;
  content: " "; }

.nc-list-page-container::after {
  display: table;
  content: " ";
  clear: both; }

.table-row {
  clear: both; }

.timestamp-column {
  text-align: right;
  padding-right: 0px; }

.timestamp-column-value {
  padding-right: 0px;
  text-align: right;
  font-size: 15px; }
  @media (min-width: 320px) and (max-width: 991px) {
    .timestamp-column-value {
      text-align: left; } }

.field-description-row-mobile {
  background-color: #e9e9e9;
  padding: 6px 12px 6px 16px !important;
  margin-bottom: 5px; }

.field-header {
  color: #555;
  display: table-cell;
  min-width: 70px;
  font-weight: 600;
  margin-right: 10px; }

.field-value {
  display: table-cell; }

.status-container {
  margin-top: -7px;
  text-transform: capitalize; }
  @media (max-width: 991px) {
    .status-container {
      margin-top: -4px;
      padding-bottom: 0px; } }

.list-page-grid-table {
  font-size: 15px;
  padding: 0px;
  border-collapse: separate;
  border-spacing: 0px 2px;
  margin-bottom: 0px;
  width: 100%;
  max-width: 100%; }
  @media (min-width: 320px) and (max-width: 500px) {
    .list-page-grid-table #copy-earning-cancel-button {
      width: 100%; }
    .list-page-grid-table #copy-earning-button {
      width: 100%; } }
  @media (max-width: 767px) {
    .list-page-grid-table #copy-earning-cancel-button {
      width: 100%; } }
  @media (min-width: 768px) {
    .list-page-grid-table #copy-earning-cancel-button {
      margin-left: 25px; } }
  .list-page-grid-table .nc-rowcount-results {
    clear: both;
    margin-bottom: 15px;
    font-size: 15px; }
    @media (max-width: 991px) {
      .list-page-grid-table .nc-rowcount-results {
        margin-bottom: 0px; } }
  .list-page-grid-table .name-column {
    color: #009999;
    font-size: 18px; }
    .list-page-grid-table .name-column.__no-hover {
      cursor: default; }
      .list-page-grid-table .name-column.__no-hover:hover {
        cursor: default; }
      .list-page-grid-table .name-column.__no-hover a {
        cursor: default; }
        .list-page-grid-table .name-column.__no-hover a:hover {
          cursor: default;
          color: #009999; }
    @media (min-width: 320px) and (max-width: 991px) {
      .list-page-grid-table .name-column {
        font-size: 20px; } }
    @media (min-width: 992px) {
      .list-page-grid-table .name-column {
        padding-right: 50px !important; } }
    .list-page-grid-table .name-column .name-code-column {
      color: #555;
      font-size: 15px; }
  @media (min-width: 992px) {
    .list-page-grid-table .category-container {
      padding-right: 50px !important; } }
  @media (max-width: 991px) {
    .list-page-grid-table .status-container {
      margin-bottom: 0px;
      padding-bottom: 0px !important; } }
  @media (min-width: 992px) {
    .list-page-grid-table .status-container {
      margin-bottom: -5px; } }
  .list-page-grid-table .table-cell {
    padding-right: 5px; }
  .list-page-grid-table .list-page-grid-table-cell-actions {
    float: right;
    padding-left: 0px !important;
    padding-right: 20px;
    font-size: 18px; }
    @media (max-width: 991px) {
      .list-page-grid-table .list-page-grid-table-cell-actions {
        font-size: 20px; } }
    .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section,
    .list-page-grid-table .list-page-grid-table-cell-actions .list-page-calender-section,
    .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section {
      float: right; }
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section .list-page-edit-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section .list-page-calender-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section .list-page-delete-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-calender-section .list-page-edit-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-calender-section .list-page-calender-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-calender-section .list-page-delete-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section .list-page-edit-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section .list-page-calender-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section .list-page-delete-icon {
        color: #555 !important;
        margin-right: 0px; }
        .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section .list-page-edit-icon.fa-icon-disable,
        .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section .list-page-calender-icon.fa-icon-disable,
        .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section .list-page-delete-icon.fa-icon-disable,
        .list-page-grid-table .list-page-grid-table-cell-actions .list-page-calender-section .list-page-edit-icon.fa-icon-disable,
        .list-page-grid-table .list-page-grid-table-cell-actions .list-page-calender-section .list-page-calender-icon.fa-icon-disable,
        .list-page-grid-table .list-page-grid-table-cell-actions .list-page-calender-section .list-page-delete-icon.fa-icon-disable,
        .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section .list-page-edit-icon.fa-icon-disable,
        .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section .list-page-calender-icon.fa-icon-disable,
        .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section .list-page-delete-icon.fa-icon-disable {
          color: #e9e9e9 !important;
          cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer !important; }
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section .list-page-disable-edit-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section .list-page-disable-calender-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section .list-page-disable-delete-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-calender-section .list-page-disable-edit-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-calender-section .list-page-disable-calender-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-calender-section .list-page-disable-delete-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section .list-page-disable-edit-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section .list-page-disable-calender-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section .list-page-disable-delete-icon {
        color: #c7c7c7 !important;
        margin-right: 0px; }
    .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section {
      margin-left: 35px; }
      @media (max-width: 767px) {
        .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section {
          margin-left: 15px; } }
    .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section {
      margin-left: 35px; }
      @media (max-width: 767px) {
        .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section {
          margin-left: 15px; } }
    .list-page-grid-table .list-page-grid-table-cell-actions .reactivate-button {
      height: 30px;
      padding: 5px 15px;
      font-size: 15px; }
  .list-page-grid-table .reactivate-mobile-button {
    margin-top: 10px; }
    .list-page-grid-table .reactivate-mobile-button .reactivate-button {
      min-height: 30px;
      height: 30px;
      padding: 5px 15px;
      font-size: 15px;
      width: auto !important; }
  .list-page-grid-table .table-header {
    font-size: 14px;
    font-weight: 700; }
  .list-page-grid-table .table-row {
    border-bottom: 1px solid #dbd6d6;
    clear: both;
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 15px;
    padding-bottom: 15px; }
    @media (min-width: 320px) and (max-width: 991px) {
      .list-page-grid-table .table-row {
        padding: 20px 0px; } }
    @media (min-width: 991px) {
      .list-page-grid-table .table-row .inline-table-row {
        display: flex;
        align-items: center; } }
    .list-page-grid-table .table-row div {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 5px; }
      @media (min-width: 992px) {
        .list-page-grid-table .table-row div {
          padding-bottom: 0px; } }
      @media (min-width: 992px) {
        .list-page-grid-table .table-row div div {
          padding-bottom: 0px; } }
    .list-page-grid-table .table-row div:first-child {
      padding-bottom: 0px; }
    @media (min-width: 320px) and (max-width: 991px) {
      .list-page-grid-table .table-row .category-container {
        padding-top: 5px; } }
  .list-page-grid-table .table-row:first-child .table-row:last-child {
    border-bottom: none; }

.tooltip {
  font-family: "Source Sans Pro", sans-serif !important; }
  .tooltip .tooltip-arrow {
    border-top-color: #555 !important; }
  .tooltip .tooltip-inner {
    background-color: #555;
    text-align: left;
    border-radius: 2.5px;
    padding: 10px 15px;
    line-height: 1.2; }

.title-band-green h3,
.title-band h3 {
  font-size: 18px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }
