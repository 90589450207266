.modal#earningsGroups-activity-history {
  padding-left: 0px !important; }
  .modal#earningsGroups-activity-history .modal-dialog {
    width: 900px; }
    @media (max-width: 991px) {
      .modal#earningsGroups-activity-history .modal-dialog {
        margin: 20px;
        width: auto !important; } }
    .modal#earningsGroups-activity-history .modal-dialog .modal-content {
      border-radius: 0px;
      box-shadow: none; }
      .modal#earningsGroups-activity-history .modal-dialog .modal-content hr {
        margin-top: 0px;
        margin-bottom: 20px !important; }
      @media (min-width: 768px) {
        .modal#earningsGroups-activity-history .modal-dialog .modal-content {
          padding: 30px; } }
      @media (max-width: 767px) {
        .modal#earningsGroups-activity-history .modal-dialog .modal-content {
          padding: 20px; } }
      .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-header {
        border-bottom: none;
        padding: 0px; }
        .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-header .modal-title-description {
          font-size: 12px;
          margin-bottom: 20px; }
        .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-header .close-icon {
          cursor: pointer;
          text-align: right; }
          @media (min-width: 768px) {
            .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: -15px;
              margin-left: 15px; } }
          @media (max-width: 767px) {
            .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: 0px;
              margin-left: 0px; } }
          .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-header .close-icon i {
            margin-top: 0px;
            margin-right: 0px; }
        .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-header .before-after-link {
          margin-top: 10px; }
      .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-body {
        padding: 0px; }
        .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-body .nc-activity-history-modal-row {
          margin-bottom: 40px; }
          .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-body .nc-activity-history-modal-row .table-header {
            font-size: 18px;
            font-weight: 700; }
            .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-body .nc-activity-history-modal-row .table-header .table-header-name {
              margin-bottom: 5px; }
            .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-body .nc-activity-history-modal-row .table-header .table-header-name-hr {
              margin-bottom: 20px;
              border-bottom: 2px solid #66cc66;
              width: 210px; }
        @media (min-width: 992px) {
          .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-body div:last-child .nc-activity-history-modal-row:last-child {
            margin-bottom: 0px; } }
      .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-footer {
        padding: 20px 0px 0px 0px; }
        @media (max-width: 767px) {
          .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-footer {
            text-align: center; } }
        @media (min-width: 768px) {
          .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-footer {
            text-align: left; } }
        @media (min-width: 992px) {
          .modal#earningsGroups-activity-history .modal-dialog .modal-content .modal-footer {
            display: none; } }

@media (max-width: 991px) {
  .earning-group-snapshot-no-data {
    margin-bottom: 20px; } }

.earning-group-snapshot-grid-table {
  display: table;
  font-size: 15px;
  padding: 0px;
  border-collapse: separate;
  border-spacing: 0px 2px;
  margin-bottom: 0px;
  width: 100%;
  max-width: 100%; }
  .earning-group-snapshot-grid-table .nc-rowcount-results {
    clear: both;
    margin-bottom: 15px;
    font-size: 15px; }
  @media screen and (min-width: 992px) {
    .earning-group-snapshot-grid-table .column-header {
      font-size: 15px;
      font-weight: 600; } }
  .earning-group-snapshot-grid-table .mobile-row {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 15px;
    border-top: 1px solid #dbd6d6; }
    .earning-group-snapshot-grid-table .mobile-row:not(:last-child) {
      padding-bottom: 15px; }
    @media (max-width: 767px) {
      .earning-group-snapshot-grid-table .mobile-row .column-header {
        margin-bottom: 10px; }
      .earning-group-snapshot-grid-table .mobile-row .table-row-content-list {
        margin-bottom: 5px; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .earning-group-snapshot-grid-table .column-header-mobile {
      font-size: 15px;
      font-weight: 700; }
    .earning-group-snapshot-grid-table .mobile-row-content {
      font-size: 15px; }
    .earning-group-snapshot-grid-table .table-row-content-list-wrapper {
      margin-bottom: -15px; }
      .earning-group-snapshot-grid-table .table-row-content-list-wrapper .table-row-content-list {
        padding-bottom: 15px; } }
  .earning-group-snapshot-grid-table .table-row {
    border-top: 1px solid #dbd6d6;
    clear: both;
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 15px;
    padding-bottom: 15px; }
    @media (min-width: 320px) and (max-width: 991px) {
      .earning-group-snapshot-grid-table .table-row {
        padding: 20px 0px; } }
    .earning-group-snapshot-grid-table .table-row > div {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 5px; }
      @media (min-width: 992px) {
        .earning-group-snapshot-grid-table .table-row > div {
          padding-bottom: 0px; } }
      @media (min-width: 992px) {
        .earning-group-snapshot-grid-table .table-row > div > div {
          padding-bottom: 0px; } }
      .earning-group-snapshot-grid-table .table-row > div:first-child {
        padding-bottom: 0px; }
    .earning-group-snapshot-grid-table .table-row .table-row-content-list {
      padding-bottom: 15px;
      display: flex; }
  .earning-group-snapshot-grid-table .table-row:last-child {
    border-top: none; }
