.nc-page-container, .nc-activity-history-page-container {
  height: 100% !important;
  position: relative !important;
  padding: 30px 55px !important;
  color: #555 !important;
  font-family: "Source Sans Pro", sans-serif !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .nc-page-container, .nc-activity-history-page-container {
      padding: 10px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .nc-page-container, .nc-activity-history-page-container {
      padding: 20px !important; } }
  .nc-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
    padding-bottom: 25px; }
    .nc-page-container .pageTitle-container .pageTitle, .nc-activity-history-page-container .pageTitle-container .pageTitle {
      float: left;
      font-size: 25px;
      font-weight: 400; }
      @media (max-width: 767px) {
        .nc-page-container .pageTitle-container .pageTitle, .nc-activity-history-page-container .pageTitle-container .pageTitle {
          float: initial;
          font-size: 25px;
          /* DEVIATION : This was added because visually it did not look good without this */ } }
    @media (min-width: 768px) and (max-width: 991px) {
      .nc-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
        padding-bottom: 20px; } }
    @media (max-width: 767px) {
      .nc-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
        padding-bottom: 10px; } }

.nc-page-container::before, .nc-activity-history-page-container::before {
  display: table;
  content: " "; }

.nc-page-container::after, .nc-activity-history-page-container::after {
  display: table;
  content: " ";
  clear: both; }

.nc-activity-history-page-container .export-excel-button {
  float: right; }

.nc-activity-history-page-container .navigation-button-container {
  clear: both;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px; }
  @media (max-width: 991px) {
    .nc-activity-history-page-container .navigation-button-container.ah-back-button-container {
      margin-top: 5px; } }

.nc-activity-history-page-container .table-row {
  clear: both; }

.nc-activity-history-page-container .timestamp-column-header {
  text-align: right;
  padding-right: 0px; }

.nc-activity-history-page-container .timestamp-column-value {
  padding-right: 0px;
  text-align: right;
  font-size: 15px; }
  @media (min-width: 320px) and (max-width: 991px) {
    .nc-activity-history-page-container .timestamp-column-value {
      text-align: left; } }

.nc-activity-history-page-container .field-description-row-mobile {
  background-color: #e9e9e9;
  padding: 6px 12px 6px 16px !important;
  margin-bottom: 5px; }

.nc-activity-history-page-container .field-header {
  color: #555;
  font-weight: 600;
  margin-right: 10px;
  min-width: 50px;
  /* DEVIATION : This was required to ensure that the label does not wrap in mobile view  */ }

.nc-activity-history-page-container .user-activity-information {
  font-weight: 600;
  margin-bottom: 10px; }

.nc-activity-history-page-container .table {
  display: table;
  padding: 0px;
  border-collapse: separate;
  border-spacing: 0px 2px;
  margin-bottom: 0px;
  width: 100%;
  max-width: 100%; }
  .nc-activity-history-page-container .table .result-rowcount {
    clear: both;
    margin-bottom: 15px; }
  .nc-activity-history-page-container .table .thead {
    display: table-header-group; }
    .nc-activity-history-page-container .table .thead .tr {
      display: table-row; }
      .nc-activity-history-page-container .table .thead .tr .th {
        display: table-cell;
        border: none !important;
        font-weight: 600;
        padding: 5px 12px 5px 12px !important; }
  .nc-activity-history-page-container .table .tbody {
    display: table-row-group;
    background-color: #e9e9e9; }
    .nc-activity-history-page-container .table .tbody .tr {
      display: table-row; }
      .nc-activity-history-page-container .table .tbody .tr .td {
        display: table-cell;
        padding: 2px 12px 2px 12px; }
        @media (min-width: 320px) and (max-width: 991px) {
          .nc-activity-history-page-container .table .tbody .tr .td {
            padding: 2px 12px 4px 12px; }
            .nc-activity-history-page-container .table .tbody .tr .td .field-header {
              display: table-cell; }
            .nc-activity-history-page-container .table .tbody .tr .td .field-value {
              display: table-cell; } }
  .nc-activity-history-page-container .table .table-header {
    font-weight: 700; }
  @media (max-width: 991px) {
    .nc-activity-history-page-container .table .table-header + .table-row {
      padding-top: 0px; } }
  .nc-activity-history-page-container .table .table-row {
    border-bottom: 1px solid #dbd6d6;
    clear: both;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px; }
    .nc-activity-history-page-container .table .table-row div {
      padding-left: 0px;
      padding-right: 0px; }
    .nc-activity-history-page-container .table .table-row .table .tbody .tr .td {
      padding-left: 15px;
      padding-right: 15px; }
    @media (min-width: 992px) {
      .nc-activity-history-page-container .table .table-row .table .tbody .tr .td {
        padding-left: 15px;
        padding-right: 15px; }
      .nc-activity-history-page-container .table .table-row .table .tbody .tr:first-child .td {
        padding-top: 15px; }
      .nc-activity-history-page-container .table .table-row .table .tbody .tr:last-child .td {
        padding-bottom: 15px; } }
    @media (min-width: 321px) and (max-width: 991px) {
      .nc-activity-history-page-container .table .table-row .table .tbody .tr .td {
        min-height: 26px; }
      .nc-activity-history-page-container .table .table-row .table .tbody .tr .td:last-child {
        padding-bottom: 15px; }
      .nc-activity-history-page-container .table .table-row .table .tbody .tr:first-child .td:first-child {
        padding-top: 15px;
        min-height: 39px; }
      .nc-activity-history-page-container .table .table-row .table .tbody .tr:last-child .td:last-child {
        padding-bottom: 15px; } }
    .nc-activity-history-page-container .table .table-row .status-name {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 20px; }
      @media (min-width: 320px) and (max-width: 767px) {
        .nc-activity-history-page-container .table .table-row .status-name {
          margin-bottom: 5px;
          /* DEVIATION : 5px bottom margin for visual aspect*/ } }
      @media (min-width: 768px) and (max-width: 991px) {
        .nc-activity-history-page-container .table .table-row .status-name {
          /* DEVIATION : 5px bottom margin for visual aspect*/ } }
    .nc-activity-history-page-container .table .table-row .edit_note {
      margin-top: 5px; }
  .nc-activity-history-page-container .table .table-row:last-child {
    border-bottom: none; }

@media (min-width: 320px) and (max-width: 767px) {
  .nc-activity-history-page-container .table:last-child {
    margin-bottom: 5px;
    /* DEVIATION : 5px bottom margin for visual aspect*/ } }

@media (min-width: 768px) and (max-width: 991px) {
  .nc-activity-history-page-container .table:last-child {
    margin-bottom: 5px;
    /* DEVIATION : 5px bottom margin for visual aspect*/ } }

.nc-activity-history-page-container::before {
  display: table;
  content: " "; }

.nc-activity-history-page-container::after {
  display: table;
  content: " ";
  clear: both; }

.modal#activity-history {
  padding-left: 0px !important; }
  .modal#activity-history .modal-dialog {
    width: 700px; }
    @media (max-width: 767px) {
      .modal#activity-history .modal-dialog {
        margin: 20px;
        width: auto !important; } }
    .modal#activity-history .modal-dialog .modal-content {
      border-radius: 0px;
      box-shadow: none; }
      .modal#activity-history .modal-dialog .modal-content hr {
        margin-top: 0px;
        margin-bottom: 20px !important; }
      @media (min-width: 768px) {
        .modal#activity-history .modal-dialog .modal-content {
          padding: 30px; } }
      @media (max-width: 767px) {
        .modal#activity-history .modal-dialog .modal-content {
          padding: 20px; } }
      .modal#activity-history .modal-dialog .modal-content .modal-header {
        border-bottom: none;
        padding: 0px; }
        .modal#activity-history .modal-dialog .modal-content .modal-header .modal-title-description {
          font-size: 12px;
          margin-bottom: 20px; }
        .modal#activity-history .modal-dialog .modal-content .modal-header .close-icon {
          cursor: pointer;
          text-align: right; }
          @media (min-width: 768px) {
            .modal#activity-history .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: -15px;
              margin-left: 15px; } }
          @media (max-width: 767px) {
            .modal#activity-history .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: 0px;
              margin-left: 0px; } }
          .modal#activity-history .modal-dialog .modal-content .modal-header .close-icon i {
            margin-top: 0px;
            margin-right: 0px; }
          .modal#activity-history .modal-dialog .modal-content .modal-header .close-icon svg.svg-inline--fa.fa-times-circle {
            font-size: 18px; }
      .modal#activity-history .modal-dialog .modal-content .modal-body {
        padding: 0px; }
        .modal#activity-history .modal-dialog .modal-content .modal-body .nc-activity-history-modal-row {
          margin-bottom: 20px; }
        @media (min-width: 992px) {
          .modal#activity-history .modal-dialog .modal-content .modal-body div:last-child .nc-activity-history-modal-row:last-child {
            margin-bottom: 0px; } }
      .modal#activity-history .modal-dialog .modal-content .modal-footer {
        padding: 20px 0px 0px 0px; }
        @media (max-width: 767px) {
          .modal#activity-history .modal-dialog .modal-content .modal-footer {
            text-align: center; } }
        @media (min-width: 768px) {
          .modal#activity-history .modal-dialog .modal-content .modal-footer {
            text-align: left; } }
        @media (min-width: 992px) {
          .modal#activity-history .modal-dialog .modal-content .modal-footer {
            display: none; } }
