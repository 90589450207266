.alternate-employee-identification-container .contact-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px; }
  .alternate-employee-identification-container .contact-info .work-email-textbox {
    width: 280px; }
  .alternate-employee-identification-container .contact-info .work-ph-no-textbox {
    width: 170px; }
  .alternate-employee-identification-container .contact-info .work-ph-no-ext-textbox {
    width: 90px;
    padding-left: 10px; }
  .alternate-employee-identification-container .contact-info .alt-emp-identification-item {
    width: 230px; }
