.view-selector-bar {
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 15px; }

.view-label {
  color: #999;
  margin-right: 20px; }

.view-icons a {
  padding: 0px 10px;
  border-right: 1px solid #ccc; }
  .view-icons a:last-child {
    padding-right: 0;
    border-right: none; }
  .view-icons a:first-child {
    padding-left: 0; }

.employee-results-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px; }

.employee-add-actions-list-container {
  position: absolute;
  z-index: 5;
  border-radius: 3px;
  margin-left: 91px;
  margin-top: 36px;
  background-color: #006699;
  padding: 10px; }
  .employee-add-actions-list-container ul.list {
    padding: 0;
    margin: 0;
    font-size: 16px; }
    .employee-add-actions-list-container ul.list li.list-item {
      padding: 10px 10px;
      color: #fff;
      border-bottom: 1px solid #FFF; }
      .employee-add-actions-list-container ul.list li.list-item:last-child {
        border-bottom: none; }
      .employee-add-actions-list-container ul.list li.list-item:hover {
        cursor: pointer;
        background-color: #015782; }
