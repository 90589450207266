.modal#mapGL-activity-history {
  padding-left: 0px !important; }
  .modal#mapGL-activity-history .modal-dialog {
    width: 60%; }
    @media (max-width: 767px) {
      .modal#mapGL-activity-history .modal-dialog {
        margin: 20px;
        width: auto !important; } }
    .modal#mapGL-activity-history .modal-dialog .modal-content {
      border-radius: 0px;
      box-shadow: none; }
      .modal#mapGL-activity-history .modal-dialog .modal-content hr {
        margin-top: 0px;
        margin-bottom: 20px !important; }
      @media (min-width: 768px) {
        .modal#mapGL-activity-history .modal-dialog .modal-content {
          padding: 30px; } }
      @media (max-width: 767px) {
        .modal#mapGL-activity-history .modal-dialog .modal-content {
          padding: 20px; } }
      .modal#mapGL-activity-history .modal-dialog .modal-content .modal-header {
        border-bottom: none;
        padding: 0px; }
        .modal#mapGL-activity-history .modal-dialog .modal-content .modal-header .modal-title-description {
          font-size: 12px;
          margin-bottom: 20px; }
        .modal#mapGL-activity-history .modal-dialog .modal-content .modal-header .close-icon {
          cursor: pointer;
          text-align: right; }
          @media (min-width: 768px) {
            .modal#mapGL-activity-history .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: -15px;
              margin-left: 15px; } }
          @media (max-width: 767px) {
            .modal#mapGL-activity-history .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: 0px;
              margin-left: 0px; } }
          .modal#mapGL-activity-history .modal-dialog .modal-content .modal-header .close-icon i {
            margin-top: 0px;
            margin-right: 0px; }
        .modal#mapGL-activity-history .modal-dialog .modal-content .modal-header .before-after-link {
          margin-top: 10px; }
      .modal#mapGL-activity-history .modal-dialog .modal-content .modal-body {
        padding: 0px; }
        .modal#mapGL-activity-history .modal-dialog .modal-content .modal-body .nc-activity-history-modal-row {
          margin-bottom: 40px; }
          .modal#mapGL-activity-history .modal-dialog .modal-content .modal-body .nc-activity-history-modal-row .table-header {
            font-size: 18px;
            font-weight: 700; }
            .modal#mapGL-activity-history .modal-dialog .modal-content .modal-body .nc-activity-history-modal-row .table-header .table-header-name {
              margin-bottom: 5px; }
            .modal#mapGL-activity-history .modal-dialog .modal-content .modal-body .nc-activity-history-modal-row .table-header .table-header-name-hr {
              margin-bottom: 20px;
              border-bottom: 2px solid #66cc66;
              width: 210px; }
        @media (min-width: 992px) {
          .modal#mapGL-activity-history .modal-dialog .modal-content .modal-body div:last-child .nc-activity-history-modal-row:last-child {
            margin-bottom: 0px; } }
      .modal#mapGL-activity-history .modal-dialog .modal-content .modal-footer {
        padding: 20px 0px 0px 0px; }
        @media (max-width: 767px) {
          .modal#mapGL-activity-history .modal-dialog .modal-content .modal-footer {
            text-align: center; } }
        @media (min-width: 768px) {
          .modal#mapGL-activity-history .modal-dialog .modal-content .modal-footer {
            text-align: left; } }
        @media (min-width: 992px) {
          .modal#mapGL-activity-history .modal-dialog .modal-content .modal-footer {
            display: none; } }

.list-page-grid-table {
  font-size: 15px;
  padding: 0px;
  border-collapse: separate;
  border-spacing: 0px 2px;
  margin-bottom: 0px;
  width: 100%;
  max-width: 100%; }
  .list-page-grid-table .nc-rowcount-results {
    clear: both;
    margin-bottom: 15px;
    font-size: 15px; }
    @media (max-width: 991px) {
      .list-page-grid-table .nc-rowcount-results {
        margin-bottom: 0px; } }
  .list-page-grid-table .column-header {
    font-size: 14px;
    font-weight: 600; }
  .list-page-grid-table .table-row {
    border-bottom: 1px solid #dbd6d6;
    clear: both;
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 15px;
    padding-bottom: 15px; }
    @media (min-width: 320px) and (max-width: 991px) {
      .list-page-grid-table .table-row {
        padding: 20px 0px; } }
    .list-page-grid-table .table-row div {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 5px; }
      @media (min-width: 992px) {
        .list-page-grid-table .table-row div {
          padding-bottom: 0px; } }
      @media (min-width: 992px) {
        .list-page-grid-table .table-row div div {
          padding-bottom: 0px; } }
    .list-page-grid-table .table-row div:first-child {
      padding-bottom: 0px; }
  .list-page-grid-table .table-row:last-child {
    border-bottom: none; }

.nc-activity-history-modal-row {
  margin-bottom: 40px; }
  .nc-activity-history-modal-row .table-header {
    font-size: 18px;
    font-weight: 700; }
    .nc-activity-history-modal-row .table-header .table-header-name {
      margin-bottom: 5px; }
    .nc-activity-history-modal-row .table-header .table-header-name-hr {
      margin-bottom: 20px;
      border-bottom: 2px solid #66cc66;
      width: 210px; }

@media (min-width: 992px) {
  div:last-child .nc-activity-history-modal-row:last-child {
    margin-bottom: 0px; } }
