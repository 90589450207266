.profile-view-page-container {
  padding: 30px 0px;
  padding-bottom: 0px; }
  .profile-view-page-container .profile-view-header {
    display: flex;
    border-bottom: 1px solid #ccc;
    padding-left: 30px; }
    .profile-view-page-container .profile-view-header .profile-image {
      position: absolute;
      padding: 3px;
      border-radius: 50%;
      border: 2px solid #339966;
      overflow: hidden;
      z-index: 1; }
      .profile-view-page-container .profile-view-header .profile-image img {
        border-radius: 50%; }
      .profile-view-page-container .profile-view-header .profile-image .edit-link {
        color: #fff;
        text-align: center;
        background-color: rgba(51, 51, 51, 0.5);
        padding: 8px;
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0; }
        .profile-view-page-container .profile-view-header .profile-image .edit-link a {
          color: #fff; }
    .profile-view-page-container .profile-view-header .profile-information {
      padding-top: 10px;
      flex-grow: 5; }
      .profile-view-page-container .profile-view-header .profile-information .personal-information {
        padding-left: 240px;
        padding-right: 55px;
        padding-bottom: 20px;
        border-bottom: 1px solid #ccc; }
        .profile-view-page-container .profile-view-header .profile-information .personal-information .title {
          font-size: 32px;
          margin-bottom: 5px;
          display: flex;
          justify-content: space-between; }
        .profile-view-page-container .profile-view-header .profile-information .personal-information .description {
          display: flex;
          align-items: center; }
          .profile-view-page-container .profile-view-header .profile-information .personal-information .description div {
            padding: 0px 15px; }
            .profile-view-page-container .profile-view-header .profile-information .personal-information .description div:first-child {
              border-right: 1px solid #999;
              padding-left: 0px; }
            .profile-view-page-container .profile-view-header .profile-information .personal-information .description div:last-child {
              padding: 0px; }
      .profile-view-page-container .profile-view-header .profile-information .bussiness-information {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding-bottom: 20px;
        padding-left: 240px;
        padding-right: 55px; }
        .profile-view-page-container .profile-view-header .profile-information .bussiness-information .bussiness-component {
          display: flex;
          align-items: center;
          padding-right: 25px; }
          .profile-view-page-container .profile-view-header .profile-information .bussiness-information .bussiness-component .icon {
            margin-right: 10px; }
          .profile-view-page-container .profile-view-header .profile-information .bussiness-information .bussiness-component .content .field-label {
            text-transform: uppercase;
            font-size: 12px; }
          .profile-view-page-container .profile-view-header .profile-information .bussiness-information .bussiness-component .content .field-value {
            font-size: 18px; }
  .profile-view-page-container .profile-view-page-content {
    background-color: #e9e9e9;
    padding: 55px; }

.bottom-navigation-container {
  transition: transform 0.25s;
  position: sticky;
  bottom: 0;
  z-index: 50; }
  .bottom-navigation-container .bottom-navigation-bar {
    color: #fff;
    display: flex;
    padding: 0px 20px;
    justify-content: center;
    height: 80px;
    background-color: rgba(51, 51, 51, 0.95); }
    .bottom-navigation-container .bottom-navigation-bar .left-link-section {
      position: absolute;
      display: flex;
      left: 20px;
      bottom: 0;
      top: 0; }
    .bottom-navigation-container .bottom-navigation-bar .right-link-section {
      position: absolute;
      display: flex;
      right: 20px;
      bottom: 0;
      top: 0; }
    .bottom-navigation-container .bottom-navigation-bar .left-link {
      display: flex;
      align-items: center;
      color: #ccc;
      transition: color 0.25s; }
      .bottom-navigation-container .bottom-navigation-bar .left-link:hover {
        color: #fff;
        cursor: pointer; }
    .bottom-navigation-container .bottom-navigation-bar .right-link {
      display: flex;
      align-items: center;
      text-align: right;
      color: #ccc;
      transition: color 0.25s; }
      .bottom-navigation-container .bottom-navigation-bar .right-link:hover {
        color: #fff;
        cursor: pointer; }
    .bottom-navigation-container .bottom-navigation-bar .icons-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      min-width: 240px; }
      .bottom-navigation-container .bottom-navigation-bar .icons-section .icon {
        height: 100%;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.5s; }
        .bottom-navigation-container .bottom-navigation-bar .icons-section .icon i {
          margin-right: 0; }
        .bottom-navigation-container .bottom-navigation-bar .icons-section .icon:hover {
          background-color: #196666;
          cursor: pointer; }
      .bottom-navigation-container .bottom-navigation-bar .icons-section .clear-filters-link {
        font-size: 14px;
        font-weight: 400;
        color: #ccc; }
        .bottom-navigation-container .bottom-navigation-bar .icons-section .clear-filters-link:hover {
          color: #fff;
          cursor: pointer; }
    .bottom-navigation-container .bottom-navigation-bar .search-section {
      display: flex;
      align-items: center;
      background-color: #196666;
      height: 100%; }
      .bottom-navigation-container .bottom-navigation-bar .search-section input {
        width: 0;
        height: 100%;
        background-color: #196666;
        color: #fff;
        border: none !important;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        opacity: 0; }
        .bottom-navigation-container .bottom-navigation-bar .search-section input::placeholder {
          color: #ccc; }
        .bottom-navigation-container .bottom-navigation-bar .search-section input:focus {
          box-shadow: none !important;
          border: none !important;
          width: 300px;
          height: 100%;
          padding: 20px;
          opacity: 1; }
      .bottom-navigation-container .bottom-navigation-bar .search-section i {
        margin-right: 15px;
        cursor: pointer; }

.draggable-area {
  border: 2px dashed #ccc;
  color: #999;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .draggable-area .image-icon {
    text-align: center; }
    .draggable-area .image-icon i {
      margin-right: 0px; }

.employe-files-tile {
  color: #009999;
  font-size: 20px;
  padding-bottom: 5px !important; }

.employe-files-name {
  color: #666;
  font-size: 14px; }

.employee-file-timestamp {
  font-style: italic;
  color: #999;
  font-size: 14px; }

.employee-file-action {
  padding-top: 10px; }

.employee-file-popover {
  padding-top: 5px !important;
  padding-right: 10px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important; }

.nc-progress-bar .progress {
  background-color: #ccc;
  width: 500px;
  height: 12px;
  margin-bottom: 5px; }
  .nc-progress-bar .progress .nc-progress {
    background-color: #339966; }

.nc-progress-bar .progress-percentage {
  color: #339966;
  font-size: 16px;
  font-weight: 600;
  padding-left: 10px; }
