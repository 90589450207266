.add-note-type-modal.modal .modal-dialog {
  width: 750px; }

.add-note-type-modal.modal .modal-content {
  border-radius: 0%; }
  .add-note-type-modal.modal .modal-content .modal-header {
    border: none;
    padding: 15px 15px 0px 30px; }
    .add-note-type-modal.modal .modal-content .modal-header .close-icon {
      text-align: right;
      cursor: pointer; }
    .add-note-type-modal.modal .modal-content .modal-header .title {
      font-weight: 600;
      font-size: 17px; }
      .add-note-type-modal.modal .modal-content .modal-header .title i {
        margin-right: 10px; }
  .add-note-type-modal.modal .modal-content .modal-body {
    padding: 15px 15px 30px; }
    .add-note-type-modal.modal .modal-content .modal-body .modal-text {
      padding: 0px 15px 15px 15px; }
    .add-note-type-modal.modal .modal-content .modal-body .no-address .title {
      text-align: center;
      padding: 10px;
      background: #ffffcc;
      color: #8a6d3b;
      font-weight: 300;
      margin-top: 5px; }
