/* DayPicker styles */
.modal.calendar {
  overflow-y: hidden; }
  .modal.calendar .modal-dialog {
    width: auto; }
    @media (max-width: 991px) {
      .modal.calendar .modal-dialog {
        margin: 0px; } }
    .modal.calendar .modal-dialog .modal-content {
      border: none;
      border-radius: 0px;
      box-shadow: none;
      height: 100vh;
      padding: 0px; }
      .modal.calendar .modal-dialog .modal-content .modal-header {
        border: none;
        border-bottom: 1px solid #ccc;
        height: 40px;
        justify-content: center;
        align-items: center; }
        .modal.calendar .modal-dialog .modal-content .modal-header .date-range-label {
          font-size: 14px;
          text-align: center; }
        .modal.calendar .modal-dialog .modal-content .modal-header .close-icon {
          font-size: 18px;
          left: 10px;
          position: absolute;
          margin-left: 0; }
          .modal.calendar .modal-dialog .modal-content .modal-header .close-icon i {
            vertical-align: unset;
            font-size: 20px;
            cursor: pointer; }
        .modal.calendar .modal-dialog .modal-content .modal-header .date-picker-header {
          font-size: 18px; }
      .modal.calendar .modal-dialog .modal-content .modal-mobile-body {
        padding: 0px; }
        .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar {
          width: 100vw; }
          .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption {
            text-align: center;
            margin-top: -5px;
            margin-bottom: 20px; }
            @media (min-width: 768px) and (max-width: 991px) {
              .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption {
                margin: 0px auto;
                width: 40%; } }
            @media (min-width: 320px) and (max-width: 767px) {
              .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption {
                margin: 0px auto;
                display: flex;
                justify-content: center;
                align-items: center; } }
            .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption:focus {
              outline: none; }
            .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .Select {
              height: 23px; }
            .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .month-name {
              font-weight: 400;
              color: #555;
              font-size: 18px; }
              @media (min-width: 768px) and (max-width: 991px) {
                .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .month-name {
                  font-weight: 600;
                  font-size: 18px;
                  float: left; } }
              @media (min-width: 320px) and (max-width: 767px) {
                .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .month-name {
                  font-weight: 600;
                  font-size: 18px;
                  float: left; } }
            .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption select {
              border: none;
              font-weight: 400;
              color: #555;
              font-size: 18px;
              text-align: center; }
              .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption select:focus {
                outline: none; }
            .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector {
              display: inline-block;
              color: #999; }
              @media (min-width: 320px) and (max-width: 991px) {
                .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector {
                  float: right; } }
              .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-control {
                border: none;
                border-radius: 0px;
                height: auto;
                margin-top: 5px;
                width: 80px; }
                @media (min-width: 320px) and (max-width: 991px) {
                  .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-control {
                    margin-top: 0px; } }
                .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-control:hover {
                  box-shadow: none; }
                .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-control:focus {
                  box-shadow: none; }
                .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-control:not(.is-open) {
                  box-shadow: none; }
                .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-control .Select-multi-value-wrapper:focus {
                  outline: none; }
                .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-control .Select-multi-value-wrapper .Select-value {
                  position: initial;
                  line-height: initial;
                  padding: 0px; }
                  .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label {
                    font-size: 18px;
                    font-weight: 400;
                    color: #999; }
                    @media (min-width: 768px) and (max-width: 991px) {
                      .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label {
                        font-size: 30px;
                        font-weight: 600; } }
                    @media (min-width: 320px) and (max-width: 767px) {
                      .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label {
                        font-size: 25px;
                        font-weight: 600; } }
                    .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label:focus {
                      outline: none; }
                .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-control .Select-multi-value-wrapper .Select-input {
                  height: auto; }
                .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-control .Select-arrow-zone {
                  vertical-align: initial; }
                  @media (min-width: 768px) {
                    .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-control .Select-arrow-zone {
                      margin-top: 5px; } }
                  .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-control .Select-arrow-zone i {
                    color: #999; }
                    @media (min-width: 768px) and (max-width: 991px) {
                      .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-control .Select-arrow-zone i {
                        font-size: 15px;
                        font-weight: 600; } }
              .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-menu-outer {
                margin-top: 5px; }
                .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-menu-outer .Select-option:hover {
                  background-color: #f1f1f1; }
                .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-menu-outer .is-focused {
                  background-color: #f1f1f1; }
                .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Caption .year-selector .Select-menu-outer .is-selected {
                  background-color: #f1f1f1; }
          .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Weekdays {
            width: 100vw;
            border-bottom: 1px solid #ccc;
            width: 230px;
            margin-bottom: 15px; }
            @media (min-width: 320px) and (max-width: 991px) {
              .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Weekdays {
                margin-top: 10px; } }
            .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Weekdays .DayPicker-WeekdaysRow {
              width: 350px;
              margin-left: auto;
              margin-right: auto;
              padding-left: 35px;
              margin-left: -30px;
              margin-right: -20px; }
              @media (min-width: 320px) and (max-width: 767px) {
                .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Weekdays .DayPicker-WeekdaysRow {
                  width: 320px; } }
              @media (min-width: 992px) {
                .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Weekdays .DayPicker-WeekdaysRow {
                  border-bottom: 1px solid #cccccc;
                  width: 305px; } }
              @media (min-width: 320px) and (max-width: 991px) {
                .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Weekdays .DayPicker-WeekdaysRow {
                  padding-left: 0px; } }
              .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Weekdays .DayPicker-WeekdaysRow .DayPicker-Weekday {
                padding: 0.5rem;
                font-size: 15px;
                font-weight: 400;
                text-align: center;
                color: #555;
                height: 30px;
                width: 33px;
                float: left; }
                @media (min-width: 768px) and (max-width: 991px) {
                  .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Weekdays .DayPicker-WeekdaysRow .DayPicker-Weekday {
                    font-size: 22px;
                    font-weight: 600;
                    width: 60px;
                    margin-bottom: 10px; } }
                @media (min-width: 320px) and (max-width: 767px) {
                  .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Weekdays .DayPicker-WeekdaysRow .DayPicker-Weekday {
                    font-size: 18px;
                    font-weight: 600;
                    width: 45px;
                    margin-bottom: 10px; } }
          .modal.calendar .modal-dialog .modal-content .modal-mobile-body .wrapper-without-navbar .DayPicker-Month {
            margin-left: auto;
            margin-right: auto; }
      .modal.calendar .modal-dialog .modal-content .modal-footer {
        text-align: center;
        bottom: 0px;
        left: 0px;
        right: 0px;
        position: fixed;
        padding: 15px 13px 15px 13px;
        background-color: #fff; }
        .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group {
          max-width: 320px;
          margin-left: auto;
          margin-right: auto; }
          @media (min-width: 992px) {
            .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group .primary-button {
              width: 75% !important;
              margin-right: 20px; } }
          @media (max-width: 991px) {
            .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group .primary-button {
              width: 100%;
              margin-bottom: 10px; } }
          @media (max-width: 991px) {
            .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group .secondary-link-button {
              width: 100%; } }
          .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group .set-display {
            display: flex;
            padding-top: 5px;
            padding-bottom: 20px; }
            .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group .set-display .start-date {
              font-size: 22px;
              font-weight: 600;
              margin-left: 5px; }
              @media (min-width: 320px) and (max-width: 767px) {
                .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group .set-display .start-date {
                  font-size: 18px;
                  font-weight: 600; } }
            .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group .set-display .end-date {
              font-size: 22px;
              font-weight: 600;
              margin-left: 5px; }
              @media (min-width: 320px) and (max-width: 767px) {
                .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group .set-display .end-date {
                  font-size: 18px;
                  font-weight: 600; } }
        @media (min-width: 768px) and (max-width: 991px) {
          .modal.calendar .modal-dialog .modal-content .modal-footer {
            padding-left: 20px;
            padding-right: 20px; } }

.calendar-icon {
  margin-right: 10px; }

.date-seperator {
  padding-left: 5px;
  padding-right: 5px; }

.YearNavigation {
  width: 305px;
  position: absolute;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 9px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0 2px 9px 0px rgba(0, 0, 0, 0.35);
  outline: 1px solid #999;
  border-radius: 0;
  margin-left: 0;
  margin-top: 5px;
  z-index: 10;
  /* Default modifiers */
  /* Example modifiers */
  /* DayPickerInput */ }
  @media (max-width: 991px) {
    .YearNavigation {
      margin-top: 0px;
      width: 100%;
      height: 100%;
      outline: none;
      box-shadow: none; } }
  @media (max-width: 991px) {
    .YearNavigation .calendar-button-group-wrapper {
      position: absolute;
      width: 100%;
      z-index: 0;
      bottom: 0px;
      width: 100%; } }
  .YearNavigation .calendar-button-group-wrapper .calendar-button-group {
    width: 320px;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 15px 15px 15px; }
    @media (min-width: 992px) {
      .YearNavigation .calendar-button-group-wrapper .calendar-button-group .primary-button {
        width: 75% !important;
        margin-right: 5px; } }
    @media (max-width: 991px) {
      .YearNavigation .calendar-button-group-wrapper .calendar-button-group .primary-button {
        width: 100%;
        margin-bottom: 10px; } }
    @media (max-width: 991px) {
      .YearNavigation .calendar-button-group-wrapper .calendar-button-group .secondary-link-button {
        width: 100%; } }
    @media (min-width: 992px) {
      .YearNavigation .calendar-button-group-wrapper .calendar-button-group .secondary-link-button {
        padding: 10px 10px; } }
  .YearNavigation .DayPicker-NavButton--interactionDisabled {
    display: none; }
  .YearNavigation .DayPicker-Caption {
    text-align: center;
    margin-top: -5px;
    margin-bottom: 20px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .YearNavigation .DayPicker-Caption {
        margin: 0px auto;
        width: 40%; } }
    @media (min-width: 320px) and (max-width: 767px) {
      .YearNavigation .DayPicker-Caption {
        margin: 0px auto;
        width: 70%; } }
    .YearNavigation .DayPicker-Caption:focus {
      outline: none; }
    .YearNavigation .DayPicker-Caption .Select {
      height: 23px; }
    .YearNavigation .DayPicker-Caption .month-name {
      font-weight: 400;
      color: #555;
      font-size: 18px; }
      @media (min-width: 768px) and (max-width: 991px) {
        .YearNavigation .DayPicker-Caption .month-name {
          font-weight: 600;
          font-size: 18px;
          float: left; } }
      @media (min-width: 320px) and (max-width: 767px) {
        .YearNavigation .DayPicker-Caption .month-name {
          font-weight: 600;
          font-size: 18px;
          float: left; } }
    .YearNavigation .DayPicker-Caption select {
      border: none;
      font-weight: 400;
      color: #555;
      font-size: 18px;
      text-align: center; }
      .YearNavigation .DayPicker-Caption select:focus {
        outline: none; }
    .YearNavigation .DayPicker-Caption .year-selector {
      display: inline-block;
      color: #999; }
      @media (min-width: 320px) and (max-width: 991px) {
        .YearNavigation .DayPicker-Caption .year-selector {
          float: right; } }
      .YearNavigation .DayPicker-Caption .year-selector .Select-control {
        border: none;
        border-radius: 0px;
        height: auto;
        margin-top: 5px;
        width: 80px; }
        @media (min-width: 320px) and (max-width: 991px) {
          .YearNavigation .DayPicker-Caption .year-selector .Select-control {
            margin-top: 0px; } }
        .YearNavigation .DayPicker-Caption .year-selector .Select-control:hover {
          box-shadow: none; }
        .YearNavigation .DayPicker-Caption .year-selector .Select-control:focus {
          box-shadow: none; }
        .YearNavigation .DayPicker-Caption .year-selector .Select-control:not(.is-open) {
          box-shadow: none; }
        .YearNavigation .DayPicker-Caption .year-selector .Select-control .Select-multi-value-wrapper:focus {
          outline: none; }
        .YearNavigation .DayPicker-Caption .year-selector .Select-control .Select-multi-value-wrapper .Select-value {
          position: initial;
          line-height: initial;
          padding: 0px; }
          .YearNavigation .DayPicker-Caption .year-selector .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label {
            font-size: 18px;
            font-weight: 400;
            color: #999; }
            @media (min-width: 768px) and (max-width: 991px) {
              .YearNavigation .DayPicker-Caption .year-selector .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label {
                font-size: 30px;
                font-weight: 600; } }
            @media (min-width: 320px) and (max-width: 767px) {
              .YearNavigation .DayPicker-Caption .year-selector .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label {
                font-size: 25px;
                font-weight: 600; } }
            .YearNavigation .DayPicker-Caption .year-selector .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label:focus {
              outline: none; }
        .YearNavigation .DayPicker-Caption .year-selector .Select-control .Select-multi-value-wrapper .Select-input {
          height: auto; }
        .YearNavigation .DayPicker-Caption .year-selector .Select-control .Select-arrow-zone {
          vertical-align: initial; }
          @media (min-width: 768px) {
            .YearNavigation .DayPicker-Caption .year-selector .Select-control .Select-arrow-zone {
              margin-top: 5px; } }
          .YearNavigation .DayPicker-Caption .year-selector .Select-control .Select-arrow-zone i {
            color: #999; }
            @media (min-width: 768px) and (max-width: 991px) {
              .YearNavigation .DayPicker-Caption .year-selector .Select-control .Select-arrow-zone i {
                font-size: 15px;
                font-weight: 600; } }
      .YearNavigation .DayPicker-Caption .year-selector .Select-menu-outer {
        margin-top: 5px; }
        .YearNavigation .DayPicker-Caption .year-selector .Select-menu-outer .Select-option:hover {
          background-color: #f1f1f1; }
        .YearNavigation .DayPicker-Caption .year-selector .Select-menu-outer .is-focused {
          background-color: #f1f1f1; }
        .YearNavigation .DayPicker-Caption .year-selector .Select-menu-outer .is-selected {
          background-color: #f1f1f1; }
  .YearNavigation .DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5rem;
    text-align: right;
    vertical-align: middle;
    min-width: 1rem;
    font-size: 0.75em;
    cursor: pointer;
    color: #8b9898; }
  .YearNavigation .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default; }
  .YearNavigation .DayPicker-Footer {
    display: table-caption;
    caption-side: bottom;
    padding-top: 0.5rem; }
  .YearNavigation .DayPicker-TodayButton {
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
    color: #4a90e2;
    font-size: 0.875em; }
  .YearNavigation .DayPicker-Day--today {
    color: #009999;
    font-weight: 700; }
  .YearNavigation .DayPicker-Day--disabled {
    color: #dce0e0;
    cursor: default;
    background-color: #eff1f1; }
  .YearNavigation .DayPicker-Day--sunday {
    background-color: #f7f8f8; }
  .YearNavigation .DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #dce0e0; }
  .YearNavigation .DayPickerInput {
    display: inline-block; }
  .YearNavigation .DayPickerInput-OverlayWrapper {
    position: relative; }
  .YearNavigation .DayPickerInput-Overlay {
    left: 0;
    position: absolute;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); }

.modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker, .YearNavigation .DayPicker {
  display: inline-block;
  font: inherit; }
  @media (max-width: 991px) {
    .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker, .YearNavigation .DayPicker {
      display: block; } }
  .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper, .YearNavigation .DayPicker .DayPicker-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    user-select: none;
    flex-direction: row;
    padding: 25px 40px 13px 40px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper, .YearNavigation .DayPicker .DayPicker-wrapper {
        padding: 25px 190px 13px 190px; } }
    @media (min-width: 320px) and (max-width: 767px) {
      .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper, .YearNavigation .DayPicker .DayPicker-wrapper {
        padding: 25px 30px; } }
    .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-NavBar, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-NavBar {
      width: 260px;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      left: 0;
      right: 0;
      padding: 0 0.5rem;
      top: 1rem;
      margin-top: 20px; }
      @media (min-width: 768px) and (max-width: 991px) {
        .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-NavBar, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-NavBar {
          width: 60%;
          margin-top: 20px; } }
      @media (min-width: 320px) and (max-width: 767px) {
        .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-NavBar, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-NavBar {
          position: fixed;
          -ms-flex-pack: justify;
          justify-content: space-between;
          display: -ms-flexbox;
          display: flex;
          top: 50px;
          left: 30px;
          right: 30px; } }
      .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton {
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer; }
        .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton:focus, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton:focus {
          outline: none; }
      .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton--prev, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton--prev {
        margin-left: 20px; }
        .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton--prev:focus, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton--prev:focus {
          outline: none; }
        @media (min-width: 320px) and (max-width: 767px) {
          .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton--prev, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton--prev {
            margin-left: 12px; } }
      .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton--next, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton--next {
        right: 0;
        margin-right: 20px; }
        @media (min-width: 320px) and (max-width: 767px) {
          .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton--next, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton--next {
            margin-right: 27px; } }
    .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-Month, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-Month {
      display: table;
      border-collapse: collapse;
      border-spacing: 0;
      user-select: none;
      margin: 0 1rem;
      width: 225px;
      margin-bottom: 5px; }
      @media (min-width: 320px) and (max-width: 991px) {
        .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-Month, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-Month {
          margin-top: 20px; } }
      .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body {
        display: table-row-group; }
        .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week {
          display: table-row; }
          .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day {
            display: table-cell;
            padding: 6px;
            font-size: 15px;
            font-weight: 400;
            color: #555;
            text-align: center;
            cursor: pointer;
            vertical-align: middle;
            border-bottom: 7px solid white;
            border-right: 2.5px solid white;
            border-radius: 2.5px;
            /* Default modifiers */ }
            @media (min-width: 768px) and (max-width: 991px) {
              .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day {
                padding: 4px 12px;
                font-size: 22px;
                font-weight: 600; } }
            @media (min-width: 320px) and (max-width: 767px) {
              .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day {
                padding: 9px 13px;
                font-size: 18px;
                font-weight: 600; } }
            .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-Day--outside, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-Day--outside {
              cursor: default;
              color: #dce0e0; }
            .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-TodayButton, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-TodayButton {
              border: none;
              background-image: none;
              background-color: transparent;
              box-shadow: none;
              cursor: pointer;
              color: #4a90e2;
              font-size: 0.875em; }
            .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-Day--today, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-Day--today {
              color: #009999;
              font-weight: 700; }
            .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-Day--disabled, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-Day--disabled {
              color: #dce0e0;
              cursor: default;
              background-color: #eff1f1; }
            .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside), .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
              color: #555;
              background-color: #e9e9e9; }
            .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-Day--start, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-Day--start, .modal.calendar .modal-dialog .modal-content .modal-mobile-body .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-Day--end, .YearNavigation .DayPicker .DayPicker-wrapper .DayPicker-Month .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-Day--end {
              background-color: #009999 !important;
              color: #fff !important; }
