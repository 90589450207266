.tree-list {
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: #555;
  list-style-type: none; }
  .tree-list-item {
    padding-left: 35px; }
  .tree-list .indent-container {
    float: left; }
    .tree-list .indent-container .indent {
      float: left;
      width: 16px;
      border-bottom: 1px dashed #999;
      height: 8px;
      margin-right: 3px; }
      .tree-list .indent-container .indent:first-child {
        border-left: 1px dashed #999; }
