.MultiSectionListSubitem {
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #e9e9e9; }

.MultiSectionListSubitem:last-child {
  border-bottom: none;
  padding-bottom: 0; }

.MultiSectionListSubitem:first-child {
  padding-top: 10px; }
