.left-bar-container {
  padding: 55px 0px;
  border-right: 1px solid #ccc;
  flex-grow: 1;
  position: sticky;
  top: 0px;
  background-color: #e9e9e9;
  min-height: 100vh; }
  .left-bar-container .panel-container {
    border-bottom: 1px solid #ccc; }
    .left-bar-container .panel-container a .panel-header {
      font-size: 16px;
      font-weight: 600;
      padding: 20px 30px;
      color: #555;
      background-color: none !important;
      border: none;
      cursor: pointer; }
      .left-bar-container .panel-container a .panel-header i {
        float: right; }
    .left-bar-container .panel-container a:last-child .panel-header {
      margin-bottom: 0; }
    .left-bar-container .panel-container .panel {
      border: none;
      margin-bottom: 0; }
      .left-bar-container .panel-container .panel .panel-body {
        border: none;
        padding: 0;
        background-color: #e9e9e9; }
        .left-bar-container .panel-container .panel .panel-body-container {
          display: flex;
          background-color: #abdeab; }
          .left-bar-container .panel-container .panel .panel-body-container .scroll-bar {
            margin-left: 30px;
            margin-top: 10px;
            margin-bottom: 20px;
            transition: transform 0.2s;
            display: flex;
            justify-content: center; }
            .left-bar-container .panel-container .panel .panel-body-container .scroll-bar .scroll-track {
              width: 2px;
              background-color: #339966; }
            .left-bar-container .panel-container .panel .panel-body-container .scroll-bar .scroll-handle {
              position: absolute;
              width: 8px;
              background-color: #0C4F48;
              height: 30px;
              border-radius: 10px;
              transform: translate(0px, 0px); }
              .left-bar-container .panel-container .panel .panel-body-container .scroll-bar .scroll-handle:hover {
                cursor: move; }
        .left-bar-container .panel-container .panel .panel-body ul.entity-list {
          margin-bottom: 15px;
          padding: 0;
          list-style-type: none; }
          .left-bar-container .panel-container .panel .panel-body ul.entity-list li.entity-list-item {
            padding: 10px 30px;
            text-transform: uppercase; }
            .left-bar-container .panel-container .panel .panel-body ul.entity-list li.entity-list-item:hover {
              cursor: pointer; }
            .left-bar-container .panel-container .panel .panel-body ul.entity-list li.entity-list-item a {
              color: #333; }
              .left-bar-container .panel-container .panel .panel-body ul.entity-list li.entity-list-item a.active-item {
                font-weight: 600; }
