.raised-radio-switch {
  overflow: hidden;
  width: fit-content;
  padding: 0px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 25px; }

.switch-title {
  margin-bottom: 6px; }

.raised-radio-switch input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden; }

.raised-radio-switch label {
  float: left; }

.raised-radio-switch label {
  margin: 0px;
  display: inline-block;
  width: fit-content;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  padding: 7px 11px;
  border-left: 1px solid #ccc;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }

.raised-radio-switch label:hover {
  cursor: pointer; }

.raised-radio-switch input:checked + label {
  background-color: #009999;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff; }
