.nc-snapshot-table{
    .nc-snapshot-table-row{
        display: flex;
        
        align-items:center;
        font-size: 15px;
    border: 1px solid #999;
    border-radius: 4px;
    margin-top: 5px;
    padding-left: 30px;
    position: relative;
    @media (max-width: 991px) {
        padding: 10px 15px;
    }
    @media (min-width: 992px) {
        height: 44px;
    }
    }
    .nc-snapshot-table-row-item{
        &.icon{
            position: absolute;
            right: 30px;
        }
        
    }
    .nc-snapshot-table-header{
        font-weight: 600;
        display: flex;
        padding-left: 30px;

    }
   
}