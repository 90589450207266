.user-icon-horizontal {
  display: table-row;
  padding: 16px 0px; }
  .user-icon-horizontal .user-icon-name {
    color: #555;
    display: table-cell;
    font-size: 15px;
    font-weight: 400;
    vertical-align: middle; }
    @media (min-width: 320px) and (max-width: 991px) {
      .user-icon-horizontal .user-icon-name {
        font-size: 20px !important;
        padding-bottom: 10px;
        width: auto; } }
  .user-icon-horizontal .user-icon-image {
    display: table-cell;
    height: 38px;
    margin-right: 15px;
    width: 38px; }
    .user-icon-horizontal .user-icon-image img {
      height: 40px;
      border-radius: 24px;
      margin-right: 15px; }
