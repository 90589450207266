.right-bar-container {
  flex-grow: 1; }
  .right-bar-container .header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px; }
  .right-bar-container .file-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0; }
    .right-bar-container .file-list-item {
      border-bottom: 1px solid #ccc;
      padding: 20px 0px; }
      .right-bar-container .file-list-item:last-child {
        border-bottom: none; }
      .right-bar-container .file-list-item .file-name {
        margin-bottom: 5px; }
      .right-bar-container .file-list-item .file-description {
        color: #999;
        font-size: 14px; }
