.checkbox-list {
  font-size: 15px;
  list-style-type: none;
  padding-left: 0px;
  color: #555; }

.disabled-checkbox input[type="checkbox"]:checked + label:before {
  color: #ccc; }

.disabled-checkbox input[type="checkbox"] + label:before {
  color: #999; }

.disabled-cursor, .disabled-checkbox label, .disabled-checkbox input[type="checkbox"]:checked + label:before, .disabled-checkbox input[type="checkbox"] + label {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }
