.adv-filter-container {
  position: absolute !important;
  left: 0;
  right: 0;
  background-color: #e9e9e9; }
  .adv-filter-container .adv-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px; }
    .adv-filter-container .adv-header .adv-title {
      font-size: 18px;
      font-weight: 600; }
    .adv-filter-container .adv-header .right-icons .close-icon {
      margin-left: 25px; }
      .adv-filter-container .adv-header .right-icons .close-icon:hover {
        cursor: pointer; }
  .adv-filter-container .adv-filter-content {
    display: flex; }
    .adv-filter-container .adv-filter-content .filter-column {
      padding-right: 30px; }
      .adv-filter-container .adv-filter-content .filter-column .column-title {
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 20px; }
