.deduction-activity-history-button {
  width: 200px !important; }

.deduction-page-section-description {
  padding-top: 10px !important;
  padding-bottom: 0px !important; }

.deduction-page-section-label, .add-popup-deduction-page-section-label {
  padding-top: 30px !important;
  padding-bottom: 10px !important; }
  @media (min-width: 320px) and (max-width: 991px) {
    .deduction-page-section-label, .add-popup-deduction-page-section-label {
      padding-bottom: 5px !important; } }

.add-third-party-payee-label {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }
  @media (min-width: 320px) and (max-width: 991px) {
    .add-third-party-payee-label {
      padding-bottom: 5px !important; } }

.page-section-fieldgroup-label {
  padding-top: 15px !important;
  padding-bottom: 10px !important; }

.deduction-page-section-input .deduction-taxability-button .mobile-netchex-radio-button {
  margin-left: 0px !important; }

.deduction-page-section-input .deduction-page-section-input-textbox,
.deduction-page-section-input .deduction-subcategory-dropdown {
  padding-left: 0px !important; }

.deduction-page-section-input div .form-group {
  margin-bottom: 0px !important; }

.deduction-page-section-input .input-info-message {
  padding-left: 0px !important;
  padding-top: 5px !important;
  font-style: italic;
  font-size: 14px;
  color: #999; }

.deduction-page-header .deduction-page-header-section {
  padding-left: 0px;
  padding-right: 0px; }
  .deduction-page-header .deduction-page-header-section .deduction-page-header {
    padding: 0px !important; }
    .deduction-page-header .deduction-page-header-section .deduction-page-header h1 {
      font-size: 25px;
      font-weight: 400;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding-bottom: 25px; }
  .deduction-page-header .deduction-page-header-section .deduction-page-header-buttons {
    padding: 0px !important; }

.deduction-page-header .deduction-page-description-section .deduction-page-description-content {
  padding: 0px;
  padding-bottom: 30px; }
  .deduction-page-header .deduction-page-description-section .deduction-page-description-content p {
    font-size: 15px !important; }

.deduction-page-title-section {
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 0px !important;
  padding-right: 0px !important; }
  .deduction-page-title-section .deductions-page-badge-section {
    float: left !important; }
  .deduction-page-title-section .deduction-page-number {
    padding: 7px 10px 10px 10px !important;
    height: 30px;
    width: 30px;
    border-radius: 100px !important;
    font-size: 18px !important;
    background-color: #555555 !important;
    margin-left: 5px !important; }
  .deduction-page-title-section .deductions-page-title {
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 600;
    font-size: 18px;
    color: #555555;
    margin-left: 15px !important;
    float: left !important;
    padding-top: 3px !important; }

.deduction-page-section-track-progress .add-deduction-step {
  float: right !important; }

.deduction-page-section-track-progress-mobile {
  background-color: #e9e9e9;
  padding-bottom: 20px; }

.deduction-category-button,
.deduction-hsa-button,
.deduction-taxability-button,
.deduction-calcuation-radio-buttons,
.inline-radio-button,
.inline-block-button {
  display: inline-block !important; }

@media (min-width: 320px) and (max-width: 991px) {
  .deduction-category-button {
    display: block !important; } }

.deduction-page-section-header {
  padding-top: 20px !important; }

.deducion-taxability-options .text-link {
  font-size: 15px; }

.deducion-taxability-options-collapsible-panel,
.hide-show-collapsible-panel {
  border: 0px none white !important;
  box-shadow: none !important;
  margin-bottom: 0px !important; }
  .deducion-taxability-options-collapsible-panel .panel-body,
  .hide-show-collapsible-panel .panel-body {
    border: 0px none white !important;
    padding: 17px 17px 0px 0px !important; }
    @media (max-width: 767px) {
      .deducion-taxability-options-collapsible-panel .panel-body,
      .hide-show-collapsible-panel .panel-body {
        padding: 17px 0px 0px 0px !important; } }
    .deducion-taxability-options-collapsible-panel .panel-body .deducion-taxability-options-description,
    .deducion-taxability-options-collapsible-panel .panel-body .hide-show-collapsible-panel-description,
    .hide-show-collapsible-panel .panel-body .deducion-taxability-options-description,
    .hide-show-collapsible-panel .panel-body .hide-show-collapsible-panel-description {
      font-family: "Source Sans Pro", sans-serif !important;
      font-weight: 400;
      font-size: 15px;
      color: #555555;
      padding-left: 0px !important; }
    .deducion-taxability-options-collapsible-panel .panel-body .deducion-taxability-options-list,
    .deducion-taxability-options-collapsible-panel .panel-body .hide-show-collapsible-panel-list,
    .hide-show-collapsible-panel .panel-body .deducion-taxability-options-list,
    .hide-show-collapsible-panel .panel-body .hide-show-collapsible-panel-list {
      padding: 10px 10px 0px 0px !important;
      color: #7d7d7d;
      padding-bottom: 0px !important; }
    .deducion-taxability-options-collapsible-panel .panel-body .deducion-taxability-options-states,
    .hide-show-collapsible-panel .panel-body .deducion-taxability-options-states {
      padding: 0px 10px 0px 0px !important;
      color: #7d7d7d; }
      .deducion-taxability-options-collapsible-panel .panel-body .deducion-taxability-options-states .system-level-deduction-description-states-list-description,
      .hide-show-collapsible-panel .panel-body .deducion-taxability-options-states .system-level-deduction-description-states-list-description {
        font-family: "Source Sans Pro", sans-serif !important;
        font-weight: 600;
        font-size: 15px;
        color: #555555; }

.deduction-description-checkbox-group {
  padding-left: 0px !important; }

.deduction-exclude-states-group-section {
  padding-top: 20px !important;
  padding-bottom: 10px !important; }

.system-level-deduction-description-checkbox-label {
  cursor: pointer !important;
  padding-left: 0px !important;
  display: block;
  width: fit-content; }
  .system-level-deduction-description-checkbox-label input[type="checkbox"] {
    margin-right: 5px !important; }

.deduction-review-and-save-section .deduction-review-and-save-section-cell, .deduction-review-and-save-section .deduction-review-and-save-section-label, .deduction-review-and-save-section .deduction-review-and-save-section-value, .deduction-review-and-save-section .deduction-review-and-save-section-button {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

@media (min-width: 320px) and (max-width: 991px) {
  .deduction-review-and-save-section .deduction-review-and-save-section-value {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    color: #888 !important; } }

.deduction-review-and-save-section .deduction-review-and-save-section-button a {
  float: right !important;
  color: #555 !important;
  cursor: pointer; }

.select-enterprise-payee-dropdown {
  padding-left: 0px !important; }

.selected-deduction-page {
  background-color: #99cc99;
  color: #333 !important;
  width: 220px;
  border-radius: 5px; }

.nc-navigation-button-family button.primary-link-button {
  padding: 6px 20px;
  margin-right: 10px; }

.nc-navigation-button-family button.primary-button {
  width: auto; }

.list-page-edit-section span.list-page-edit-icon {
  font-size: 15px; }

.w-2-summary-section {
  padding: 35px 100px !important;
  background-color: #e9e9e9;
  margin-left: 0px !important; }
  @media (min-width: 320px) and (max-width: 761px) {
    .w-2-summary-section {
      padding: 0px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .w-2-summary-section {
      padding: 0px !important; } }
  .w-2-summary-section .w-2-summary-header-section {
    text-align: center !important;
    padding-bottom: 20px !important; }
    .w-2-summary-section .w-2-summary-header-section .w2-summary-header-sublabel {
      color: #333 !important;
      font-family: "Source Sans Pro", sans-serif !important;
      padding-bottom: 15px !important; }

.w2-summary-subsection {
  padding-bottom: 20px !important; }
  @media (min-width: 320px) and (max-width: 991px) {
    .w2-summary-subsection {
      padding-left: 0px !important; } }
  .w2-summary-subsection .w2-summary-subsection-label, .w2-summary-subsection .review-save-w2-summary-label {
    color: #333 !important;
    font-family: "Source Sans Pro", sans-serif !important;
    padding-bottom: 10px !important; }
    @media (min-width: 320px) and (max-width: 991px) {
      .w2-summary-subsection .w2-summary-subsection-label, .w2-summary-subsection .review-save-w2-summary-label {
        padding-left: 0px !important; } }
  .w2-summary-subsection .w2-summary-deduction-sublabel {
    color: #555 !important;
    font-family: "Source Sans Pro", sans-serif !important;
    padding-bottom: 5px !important; }
    @media (min-width: 320px) and (max-width: 991px) {
      .w2-summary-subsection .w2-summary-deduction-sublabel {
        padding-left: 0px !important; } }

.remove-padding {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.bg-green {
  background-color: #339966;
  min-height: 42px;
  max-height: 42px;
  max-width: 42px;
  padding-top: 8px;
  text-align: center;
  color: white;
  border: 4px solid white;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto; }

.bg-grey {
  background-color: #e9e9e9;
  min-height: 42px;
  max-height: 42px;
  max-width: 42px;
  padding-top: 8px;
  text-align: center;
  color: white;
  border: 4px solid white;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto; }

.step-name {
  margin-top: 5px;
  color: #555;
  font-size: 12px; }

@media (min-width: 320px) and (max-width: 767px) {
  .deduction-steps-item-section {
    width: 80px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .deduction-steps-item-section {
    width: 115px; } }

.deduction-circle-icons {
  display: table-cell; }
  .deduction-circle-icons i {
    margin-right: 0px; }

.add-deduction-step-mobile {
  overflow-x: scroll; }
  .add-deduction-step-mobile .hbar {
    top: 41px; }
    @media (max-width: 991px) and (min-width: 768px) {
      .add-deduction-step-mobile .hbar {
        width: 84%;
        margin-left: auto;
        margin-right: auto;
        min-width: initial; } }

.add-deduction-step-mobile-table {
  position: relative;
  display: table;
  width: 100%;
  margin-top: 20px; }

.add-deduction-step-sub-menu-mobile {
  display: table-row; }

.visible {
  visibility: visible; }

.invisible {
  visibility: hidden; }

@media (min-width: 320px) and (max-width: 767px) {
  .deduction-modal-dialog {
    margin-left: 0px !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .deduction-modal-dialog {
    margin-left: 0px !important;
    width: auto !important; } }

@media (min-width: 1200px) and (max-width: 1600px) {
  .deduction-modal-dialog {
    margin-left: 0px !important; } }

@media (min-width: 320px) and (max-width: 761px) {
  .deduction-modal-dialog {
    margin-left: 0px !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .deduction-modal-dialog {
    margin-left: 0px !important;
    width: auto !important; } }

@media (min-width: 1200px) and (max-width: 1600px) {
  .deduction-modal-dialog {
    margin-left: 0px !important; } }

.deduction-modal-content {
  border-radius: 0px !important;
  box-shadow: none !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .deduction-modal-content {
      margin-left: 1% !important;
      width: 98% !important;
      min-height: 160px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .deduction-modal-content {
      margin-left: 10% !important;
      width: 80% !important;
      min-height: 160px !important; } }
  @media (min-width: 1200px) and (max-width: 1600px) {
    .deduction-modal-content {
      margin-left: 20% !important;
      width: 60% !important;
      min-height: 160px !important;
      top: 10px; } }

.deduction-modal-description-line-one {
  color: #606060; }
  @media (min-width: 320px) and (max-width: 767px) {
    .deduction-modal-description-line-one {
      font-size: 16px;
      margin-top: 20px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .deduction-modal-description-line-one {
      font-size: 16px; } }
  @media (min-width: 1400px) and (max-width: 1600px) {
    .deduction-modal-description-line-one {
      font-size: 16px;
      text-align: center !important;
      margin-top: 20px; } }

.btn-delete-deduction-modal {
  background-color: #00cccc;
  color: white;
  border: none !important;
  border-color: white !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .btn-delete-deduction-modal {
      padding: 5px 10px 5px 10px !important;
      font-size: 14px;
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin-left: 0px !important;
      margin-right: 10px !important;
      margin-top: 20px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .btn-delete-deduction-modal {
      padding: 5px 10px 5px 10px !important;
      font-size: 14px;
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin-left: 0px !important;
      margin-right: 10px !important; } }
  @media (min-width: 1200px) and (max-width: 1600px) {
    .btn-delete-deduction-modal {
      padding: 5px 20px 7px 20px !important;
      font-size: 18px; } }

.btn-cancel-deduction-modal {
  color: #999 !important;
  text-align: center !important;
  font-weight: bold;
  background-color: white !important;
  margin-left: 30px !important;
  border: none !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .btn-cancel-deduction-modal {
      padding: 10px 20px 14px 0px !important;
      font-size: 16px;
      margin-left: 0px !important;
      margin-top: 10px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .btn-cancel-deduction-modal {
      padding: 10px 20px 14px 0px !important;
      font-size: 16px;
      margin-left: 0px !important; } }
  @media (min-width: 1400px) and (max-width: 1600px) {
    .btn-cancel-deduction-modal {
      padding: 10px 20px 14px 10px !important;
      font-size: 16px; } }

@media (min-width: 320px) and (max-width: 767px) {
  .mobile-radio-button {
    width: 50%;
    padding-right: 10px !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .mobile-radio-button {
    width: 50%;
    padding-right: 10px !important; } }

@media (min-width: 768px) and (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  margin-bottom: 5px !important; }

@media (min-width: 320px) and (max-width: 767px) {
  .mobile-radio-button {
    width: 50%;
    padding-right: 10px !important; } }

.deductions-page-footer-modal-button,
.deductions-page-footer-modal-button:hover {
  background-color: #00cccc !important;
  border-radius: 3px !important;
  text-transform: uppercase !important;
  color: #fff !important;
  font-size: 16px !important;
  border-color: transparent; }
  @media (min-width: 320px) and (max-width: 767px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-bottom: 5px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-bottom: 5px !important;
      margin-right: 5px !important; } }
  @media (min-width: 992px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-right: 5px !important;
      padding: 10px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-bottom: 5px !important; } }

.remove-padding-left-right {
  padding-left: 0 !important;
  padding-right: 0 !important; }

@media (min-width: 320px) and (max-width: 991px) {
  .deduction-activity-history-button {
    width: 135px !important;
    font-size: 10px !important;
    float: left !important; }
  .deduction-page-header .deduction-page-header-section .deduction-page-header h1 {
    font-size: 18px;
    font-weight: bold; }
  .deduction-page-description-content {
    padding-bottom: 10px !important; }
  .deduction-page-description-button {
    float: left !important; }
  .main-action-button {
    font-size: 14px !important; } }

.remove-padding-right {
  padding-right: 0 !important; }

@media (min-width: 768px) and (max-width: 991px) {
  #system-level-deduction-save-changes {
    width: 30% !important; } }

@media (min-width: 992px) {
  #system-level-deduction-save-changes {
    width: 30% !important; } }

.mobile-reactivate-button {
  margin-top: 5px !important; }

.center-buttonlink-cancel {
  padding: 0px 20px 14px 10px !important;
  color: #999 !important;
  background-color: white !important;
  font-size: 16px;
  text-align: center !important;
  font-weight: 400;
  margin-left: 30px !important;
  border: none !important;
  text-decoration: none !important; }
  @media (min-width: 320px) and (max-width: 991px) {
    .center-buttonlink-cancel {
      width: 100% !important;
      margin-left: 0px !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  #cancel-button-unsaved-changes-warning-save-changes {
    width: 30% !important; } }

@media (min-width: 992px) {
  #cancel-button-unsaved-changes-warning-save-changes {
    width: 30% !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  #back-button-unsaved-changes-warning-save-changes {
    width: 30% !important; } }

@media (min-width: 992px) {
  #back-button-unsaved-changes-warning-save-changes {
    width: 30% !important; } }

@media (min-width: 320px) and (max-width: 991px) {
  .w2-summary-remove-padding {
    padding-left: 0px !important; } }

.review-save-remove-padding {
  padding-left: 0px !important;
  padding-right: 0px !important; }

@media (min-width: 320px) and (max-width: 991px) {
  .mobile-buttonlink-revert-back {
    width: 100% !important;
    margin-left: 0px !important; } }

.margin-top-15-px {
  margin-top: 20px !important; }

.add-pop-mobile-radio-btn {
  min-width: 150px !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .add-pop-mobile-radio-btn {
      width: 100%;
      padding-right: 10px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .add-pop-mobile-radio-btn {
      width: 50%;
      padding-right: 10px !important; } }

.add-tpp-popup-footer {
  padding-left: 0px !important; }

.add-popup-deduction-page-section-label {
  padding-top: 0px !important; }

@media (min-width: 768px) and (max-width: 991px) {
  .dedcution-custom-earning-group-modal .modal-dialog {
    width: 700px !important; } }

@media (min-width: 991px) {
  .dedcution-custom-earning-group-modal .modal-dialog {
    width: 950px !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .company-level-default-value-contribution-tire-button {
    text-align: left; } }

@media (min-width: 992px) {
  .company-level-default-value-contribution-tire-button {
    text-align: right !important;
    font-family: "SourceSansPro" !important; } }

@media (min-width: 320px) and (max-width: 480px) {
  .default-value-tier-label {
    padding-top: 5px; } }

@media (min-width: 481px) and (max-width: 767px) {
  .default-value-tier-label {
    padding-top: 5px !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .default-value-tier-label {
    padding-top: 10px;
    padding-left: 0px !important; } }

@media (min-width: 992px) {
  .default-value-tier-label {
    padding-top: 12px !important;
    text-align: center !important; } }

@media (min-width: 320px) and (max-width: 767px) {
  .default-value-tier-sub-label1,
  .default-value-tier-sub-label2 {
    width: 160px;
    margin-top: 12px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .default-value-tier-sub-label1,
  .default-value-tier-sub-label2 {
    width: 20% !important;
    margin-left: 10px; } }

@media (min-width: 992px) {
  .default-value-tier-sub-label1,
  .default-value-tier-sub-label2 {
    margin-left: 10px;
    margin-right: 10px; } }

@media (min-width: 1300px) {
  .tier-data-container {
    display: flex;
    align-items: center; } }

.calculation-tier-text-container {
  display: flex;
  align-items: center;
  width: 70%; }
  @media (min-width: 768px) and (max-width: 991px) {
    .calculation-tier-text-container {
      width: 90%; } }
  @media (max-width: 767px) {
    .calculation-tier-text-container {
      display: block;
      width: 100%; } }

@media (min-width: 992px) and (max-width: 1299px) {
  .tier-button {
    margin-right: 0px !important; } }

.calculation-tier-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%; }
  @media (min-width: 992px) and (max-width: 1299px) {
    .calculation-tier-button-container {
      justify-content: flex-start;
      width: 30%; } }
  @media (min-width: 320px) and (max-width: 991px) {
    .calculation-tier-button-container {
      justify-content: flex-start;
      margin-bottom: 5px; } }
  .calculation-tier-button-container .nc-link-group {
    float: right; }
    @media (min-width: 320px) and (max-width: 991px) {
      .calculation-tier-button-container .nc-link-group {
        display: flex;
        padding-right: 10px !important;
        float: left;
        padding-top: 5px; }
        .calculation-tier-button-container .nc-link-group button {
          margin-right: 10px;
          margin-bottom: 0px;
          text-align: left;
          margin-left: 5px; } }
    @media (min-width: 992px) {
      .calculation-tier-button-container .nc-link-group {
        display: flex;
        align-items: center;
        margin-bottom: 5px; }
        .calculation-tier-button-container .nc-link-group button {
          font-size: 15px !important;
          padding: 10px 10px;
          margin-right: 10px; } }

@media (min-width: 320px) and (max-width: 767px) {
  .default-value-tier-textbox1,
  .default-value-tier-textbox2 {
    width: 100px;
    margin-bottom: 5px;
    margin-right: 10px; } }

@media (min-width: 992px) {
  .default-value-tier-textbox1,
  .default-value-tier-textbox2 {
    width: auto; } }

@media (min-width: 320px) and (max-width: 767px) {
  .default-value-tier-sub-label3 {
    margin-top: 12px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .default-value-tier-sub-label3 {
    margin-left: 10px;
    width: 20% !important; } }

@media (min-width: 992px) {
  .default-value-tier-sub-label3 {
    margin-left: 10px;
    padding: 0px !important; } }

.deductions-page-footer-modal-button,
.deductions-page-footer-modal-button:hover {
  background-color: #00cccc !important;
  border-radius: 3px !important;
  text-transform: uppercase !important;
  color: #fff !important;
  font-size: 16px !important;
  border-color: transparent; }
  @media (min-width: 320px) and (max-width: 767px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-bottom: 5px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-bottom: 5px !important;
      margin-right: 5px !important; } }
  @media (min-width: 992px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-right: 5px !important;
      padding: 10px !important; } }

@-moz-document url-prefix() {
  .open-deduction-based-popover-button-close {
    border-radius: 50px !important;
    width: 30px;
    height: 30px;
    background-color: #019999 !important;
    border: none !important;
    color: white; }
    @media (min-width: 320px) and (max-width: 479px) {
      .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        margin-left: -5px !important; } }
    @media (min-width: 480px) and (max-width: 767px) {
      .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        margin-left: -5px !important; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        margin-left: -5px !important; } }
    @media (min-width: 992px) {
      .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        margin-left: -5px !important; } } }
