.dropdown-wrapper .Select-control {
  border-radius: 0px;
  outline: none !important;
  box-shadow: none !important;
  border-color: #ccc !important; }
  .dropdown-wrapper .Select-control .Select-multi-value-wrapper {
    box-shadow: none; }
    .dropdown-wrapper .Select-control .Select-multi-value-wrapper .Select-placeholder {
      padding-left: 10px; }

.dropdown-wrapper .Select-menu-outer {
  margin-top: 5px;
  border-radius: 0px;
  border: 1px solid #555;
  box-shadow: 2px 0px 9px 0px rgba(0, 0, 0, 0.35) !important; }
  .dropdown-wrapper .Select-menu-outer .Select-menu .options {
    color: #555;
    font-weight: 400;
    font-size: 15px;
    padding: 15px 10px;
    background-color: #fff; }
  .dropdown-wrapper .Select-menu-outer .Select-menu .options:hover {
    background-color: #f1f1f1; }

.nc-select-container {
  position: relative; }
  .nc-select-container > button {
    background-color: #fff;
    border: 1px solid #999;
    color: #555;
    font-size: 15px;
    padding: 6px 12px;
    border-radius: 0px;
    height: 34px;
    width: 100%;
    text-align: left; }
    .nc-select-container > button i {
      margin-top: 1.5px; }
  .nc-select-container span.selected-value {
    max-width: 72%;
    height: 22px;
    overflow-y: hidden;
    overflow-x: hidden;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis; }
    @media (min-width: 992px) and (max-width: 1100px) {
      .nc-select-container span.selected-value {
        max-width: 72%; } }
    @media (min-width: 1101px) and (max-width: 1199px) {
      .nc-select-container span.selected-value {
        max-width: 63%; } }
    @media (min-width: 1200px) and (max-width: 1299px) {
      .nc-select-container span.selected-value {
        max-width: 67%; } }
    @media (min-width: 1300px) and (max-width: 1399px) {
      .nc-select-container span.selected-value {
        max-width: 70%; } }
  @media (max-width: 991px) {
    .nc-select-container > button {
      font-size: 15px;
      width: 100%;
      background: none;
      outline: none;
      border: none;
      border: 1px solid #999;
      background-color: #fff;
      border-radius: 0px;
      text-align: left;
      padding: 10px;
      height: 44px; }
      .nc-select-container > button span.selected-value {
        max-width: 85%;
        height: 17px;
        overflow-y: hidden;
        overflow-x: hidden;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis; } }
    @media (max-width: 991px) and (max-width: 330px) {
      .nc-select-container > button span.selected-value {
        max-width: 89%; } }
  @media (max-width: 991px) {
      .nc-select-container > button .previous-login-text {
        margin-top: -5px; } }
    @media (max-width: 991px) and (min-width: 320px) {
      .nc-select-container > button .previous-login-text {
        font-size: 9px; } }
    @media (max-width: 991px) and (min-width: 480px) {
      .nc-select-container > button .previous-login-text {
        font-size: 10px; } }
    @media (max-width: 991px) and (min-width: 768px) {
      .nc-select-container > button .previous-login-text {
        font-size: 11px; } }
    @media (max-width: 991px) and (min-width: 992px) {
      .nc-select-container > button .previous-login-text {
        font-size: 12px; } }
    @media (max-width: 991px) and (min-width: 1200px) {
      .nc-select-container > button .previous-login-text {
        font-size: 12px; } }
    @media (max-width: 991px) and (min-width: 1400px) {
      .nc-select-container > button .previous-login-text {
        font-size: 12px; } }

/* Written for single select and contains hover*/
.nc-dropdown-navigation {
  color: #555;
  margin-top: 10px;
  list-style-type: none;
  padding: 0; }
  @media (max-width: 991px) {
    .nc-dropdown-navigation {
      padding-top: 0px; } }
  .nc-dropdown-navigation .list-item {
    font-size: 15px;
    padding: 10px 10px; }
    .nc-dropdown-navigation .list-item label {
      display: block;
      margin: 0px;
      font-weight: 400 !important; }
    .nc-dropdown-navigation .list-item label:hover {
      cursor: pointer; }
    .nc-dropdown-navigation .list-item .ncl-dropdown-option-description {
      color: #999;
      font-size: 12px;
      margin-left: 20px;
      margin-top: 5px; }
  .nc-dropdown-navigation .list-item:hover {
    background-color: #f1f1f1;
    cursor: pointer; }

/* Written for multi-select and without hover*/
.nc-dropdown-navigation-multiselect {
  list-style-type: none;
  padding: 0; }
  .nc-dropdown-navigation-multiselect .grid-container {
    width: 60%; }
    .nc-dropdown-navigation-multiselect .grid-container .grid-option {
      width: 33%;
      float: left; }
  @media (min-width: 601px) and (max-width: 991px) {
    .nc-dropdown-navigation-multiselect .grid-container {
      width: 100%; }
      .nc-dropdown-navigation-multiselect .grid-container .grid-option {
        width: 50%;
        float: left; } }
  @media (min-width: 320px) and (max-width: 600px) {
    .nc-dropdown-navigation-multiselect .grid-container {
      width: 100%; }
      .nc-dropdown-navigation-multiselect .grid-container .grid-option {
        width: 100%;
        float: left; } }
  @media (max-width: 991px) {
    .nc-dropdown-navigation-multiselect input[type="checkbox"] + label:before {
      font-size: 18px; } }
  @media (min-width: 992px) {
    .nc-dropdown-navigation-multiselect {
      padding-top: 5px; } }
  .nc-dropdown-navigation-multiselect .list-item {
    padding: 10px 10px;
    font-size: 15px; }
    .nc-dropdown-navigation-multiselect .list-item label {
      display: block;
      margin: 0px;
      font-weight: 400; }
      @media (min-width: 992px) {
        .nc-dropdown-navigation-multiselect .list-item label .left-shift {
          margin-left: 20px;
          margin-top: -20px; } }
    .nc-dropdown-navigation-multiselect .list-item label:hover {
      cursor: pointer; }
  .nc-dropdown-navigation-multiselect .list-item:hover {
    cursor: pointer; }

.nc-dropdown-overlay {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 2px 9px 0px rgba(0, 0, 0, 0.35);
  border: 1px solid #999;
  border-radius: 0;
  margin-left: 0;
  margin-top: 5px;
  z-index: 10;
  width: 100%; }
  .nc-dropdown-overlay .form-group {
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px; }
    .nc-dropdown-overlay .form-group .field input:focus {
      border-color: #999; }
    @media (min-width: 768px) and (max-width: 991px) {
      .nc-dropdown-overlay .form-group {
        padding-left: 20px;
        padding-right: 20px; } }

.modal#dropdown {
  overflow-y: hidden; }
  .modal#dropdown .modal-dialog {
    width: auto; }
    @media (max-width: 991px) {
      .modal#dropdown .modal-dialog {
        margin: 0px; } }
  .modal#dropdown .modal-content {
    border: none;
    border-radius: 0px;
    box-shadow: none;
    height: 100vh;
    padding: 0px; }
    .modal#dropdown .modal-content .modal-mobile-body {
      padding: 0px; }
      .modal#dropdown .modal-content .modal-mobile-body .header-container {
        border-bottom: 1px solid #ccc;
        height: 40px;
        padding-bottom: 15px;
        padding: 10px; }
        @media (min-width: 768px) and (max-width: 991px) {
          .modal#dropdown .modal-content .modal-mobile-body .header-container {
            padding-left: 20px;
            padding-right: 20px; } }
        .modal#dropdown .modal-content .modal-mobile-body .header-container .close-icon {
          font-size: 18px; }
          .modal#dropdown .modal-content .modal-mobile-body .header-container .close-icon i {
            vertical-align: unset;
            font-size: 20px;
            cursor: pointer; }
          @media (max-width: 991px) {
            .modal#dropdown .modal-content .modal-mobile-body .header-container .close-icon {
              margin-left: 0;
              padding-left: 0;
              padding-right: 0; } }
        .modal#dropdown .modal-content .modal-mobile-body .header-container .sort-label {
          font-size: 15px;
          font-weight: 400;
          text-align: center; }
        .modal#dropdown .modal-content .modal-mobile-body .header-container .clear-all-link {
          text-align: right; }
        @media (min-width: 768px) and (max-width: 991px) {
          .modal#dropdown .modal-content .modal-mobile-body .header-container {
            padding-left: 20px;
            padding-right: 20px; } }
      .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation-multiselect {
        padding-top: 0px; }
        .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation-multiselect .list-item {
          padding-left: 20px;
          padding-right: 20px;
          border-bottom: 1px solid #ccc; }
          @media (max-width: 767px) {
            .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation-multiselect .list-item {
              padding-left: 10px;
              padding-right: 10px; } }
          .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation-multiselect .list-item label {
            font-size: 18px; }
            @media (max-width: 991px) {
              .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation-multiselect .list-item label .left-shift {
                margin-left: 20px;
                margin-top: -20px; } }
          .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation-multiselect .list-item label:before {
            padding-right: 25px; }
      .modal#dropdown .modal-content .modal-mobile-body .form-group {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px; }
        @media (max-width: 991px) {
          .modal#dropdown .modal-content .modal-mobile-body .form-group {
            margin-bottom: 0px; }
            .modal#dropdown .modal-content .modal-mobile-body .form-group .field input {
              height: 44px;
              font-weight: 400; }
            .modal#dropdown .modal-content .modal-mobile-body .form-group .field input:focus {
              box-shadow: none !important;
              border-color: #999; } }
        @media (max-width: 767px) {
          .modal#dropdown .modal-content .modal-mobile-body .form-group {
            padding-left: 10px;
            padding-right: 10px; } }
      .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation {
        color: #555; }
        .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item {
          min-height: 44px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 30px;
          padding-right: 30px;
          margin-bottom: 0px;
          font-weight: 400;
          font-size: 18px;
          border-bottom: 1px solid #ccc; }
          .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item .grid-container {
            width: 100%; }
            .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item .grid-container .grid-option {
              width: 33%;
              float: left; }
          @media (min-width: 768px) and (max-width: 991px) {
            .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item {
              padding-left: 20px;
              padding-right: 20px; } }
          @media (min-width: 481px) and (max-width: 767px) {
            .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item {
              padding-left: 10px;
              padding-right: 10px; }
              .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item .grid-container {
                width: 100%; }
                .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item .grid-container .grid-option {
                  width: 33%;
                  float: left; } }
          @media (min-width: 320px) and (max-width: 480px) {
            .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item {
              padding-left: 10px;
              padding-right: 10px; }
              .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item .grid-container {
                width: 100%; }
                .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item .grid-container .grid-option {
                  width: 50%;
                  float: left; } }
          .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item label {
            margin-bottom: 0px;
            font-weight: 400 !important;
            font-size: 18px; }
      .modal#dropdown .modal-content .modal-mobile-body .selected {
        background-color: #ccffff;
        font-weight: 600 !important; }
    .modal#dropdown .modal-content .modal-footer {
      position: fixed;
      padding: 15px 10px !important;
      height: 82px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: #fff;
      -webkit-box-shadow: 0 -2px 20px 2px #e9e9e9;
      box-shadow: 0 -2px 20px 2px #e9e9e9;
      bottom: 0;
      left: 0;
      right: 0; }
      .modal#dropdown .modal-content .modal-footer .mobile-filter-panel-button-section {
        width: 100%; }
        @media (max-width: 767px) {
          .modal#dropdown .modal-content .modal-footer .mobile-filter-panel-button-section button {
            width: 100%; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .modal#dropdown .modal-content .modal-footer {
          padding-left: 20px;
          padding-right: 20px; } }
      @media (max-width: 767px) {
        .modal#dropdown .modal-content .modal-footer {
          padding-left: 10px;
          padding-right: 10px; } }
      @media (max-width: 767px) {
        .modal#dropdown .modal-content .modal-footer {
          padding-left: 10px;
          padding-right: 10px; } }

.filter-select-badge {
  color: white;
  font-size: 15px;
  font-weight: 600;
  background-color: #009999;
  margin-right: 10px; }
  .filter-select-badge .filter-select-dropdown-icon {
    background-color: green; }

.__disabled-dropdown {
  background-color: #e9e9e9 !important;
  border: 1px solid #e9e9e9 !important; }

.disabled-cursor, .__disabled-dropdown {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

.__disabled-dropdown {
  background-color: #e9e9e9 !important;
  border: 1px solid #ccc !important;
  color: #999 !important; }

.disabled-cursor, .__disabled-dropdown {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }
