@media screen and (min-width: 320px) and (max-width: 767px) {
  .app-work-page {
    width: 100% !important; }
  .deduction-page-header-section {
    padding: 0px; }
  html,
  body,
  div,
  h1,
  p,
  button {
    font-family: "Source Sans Pro", sans-serif !important; }
  .mobile-page-title {
    padding-top: 8px;
    font-size: 20px !important;
    font-weight: 500 !important;
    padding-bottom: 15px !important; }
  .app-work-page {
    width: 100% !important; }
  .mobile-history-button-container button {
    font-weight: 500;
    font-size: 12px;
    width: 200px !important;
    color: #009999;
    border: 1.2px solid #009999;
    border-radius: 2px !important;
    padding-left: 20px;
    padding-right: 20px;
    padding: 3px;
    float: left !important; }
  .mobile-deduction-page-description-content {
    padding-top: 15px !important;
    padding-bottom: 5px !important; }
    .mobile-deduction-page-description-content p {
      font-size: 15px !important; }
  .mobile-add-button-container {
    float: left !important;
    margin-top: 5px;
    margin-bottom: 12.5px; }
    .mobile-add-button-container button {
      color: white;
      background-color: #0077a0;
      width: 180px !important;
      font-size: 12px !important;
      border-radius: 2px !important;
      padding: 6px !important;
      float: none !important; }
    .mobile-add-button-container button:hover {
      font-size: 12px !important;
      padding: 6px !important; }
  .mobile-list-page-container {
    padding: 0px !important; }
  .sort-filter-button-group {
    margin-top: 5px;
    margin-bottom: 5px; }
    .sort-filter-button-group button,
    .sort-filter-button-group button:hover,
    .sort-filter-button-group button:active {
      width: 50% !important;
      float: left !important; }
    .sort-filter-button-group button:first-child {
      border-radius: 2px 0px 2px 0px; }
    .sort-filter-button-group button:last-child {
      border-radius: 0px 2px 0px 2px; }
  .list-page-grid-table-row {
    padding: 25px 12.5px 20px 12.5px !important; }
    .list-page-grid-table-row div {
      padding: 0px !important; }
  .list-page-grid-table-cell {
    padding: 5px 0px 0px 0px !important; }
    .list-page-grid-table-cell span {
      margin-right: 10px; }
  .column-header {
    font-size: 15px;
    font-weight: 600;
    color: #555555; }
  .row-header {
    padding-top: 0px !important;
    font-size: 20px;
    word-wrap: break-word; }
  .list-page-grid-table-cell-actions {
    min-width: initial !important;
    margin-top: 5px;
    padding-right: 0px !important; }
    .list-page-grid-table-cell-actions div {
      min-width: initial !important; }
  .list-page-grid-table-cell-status-active {
    margin-top: 0px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .list-page-grid-table-cell-status-inprogress {
    margin-top: 0px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .list-page-grid-table-cell-status-inactive {
    margin-top: 0px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .deduction-page-header {
    padding: 12.5px; }
  .main-action-button,
  .main-action-button:hover {
    font-size: 12px !important;
    padding: 6px !important; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .deduction-page-header-section {
    margin-top: 24px; }
  .app-work-page {
    width: 100% !important; }
  .deduction-page-header-section {
    padding: 0px; }
  html,
  body,
  div,
  h1,
  p,
  button {
    font-family: "Source Sans Pro", sans-serif !important; }
  .mobile-page-title {
    padding-top: 8px;
    font-size: 22px !important;
    font-weight: 500 !important; }
  .app-work-page {
    width: 100% !important; }
  .mobile-history-button-container {
    float: right; }
    .mobile-history-button-container button {
      font-weight: 500;
      font-size: 12px;
      width: 100% !important;
      color: #009999;
      border: 1.2px solid #009999;
      border-radius: 2px !important;
      padding-left: 20px;
      padding-right: 20px;
      padding: 3px; }
  .mobile-deduction-page-description-content {
    padding-bottom: 5px !important; }
    .mobile-deduction-page-description-content p {
      font-size: 15px !important; }
  .mobile-add-button-container {
    float: left !important;
    margin-top: 5px;
    margin-bottom: 12.5px; }
    .mobile-add-button-container button {
      color: white;
      background-color: #0077a0;
      font-size: 12px !important;
      border-radius: 2px !important;
      padding: 6px !important;
      float: none !important; }
    .mobile-add-button-container button:hover {
      font-size: 12px !important;
      padding: 6px !important; }
  .mobile-list-page-container {
    padding: 0px !important; }
  .sort-filter-button-group {
    margin-top: 5px;
    margin-bottom: 5px; }
    .sort-filter-button-group button,
    .sort-filter-button-group button:hover,
    .sort-filter-button-group button:active {
      width: 50% !important;
      float: left !important; }
    .sort-filter-button-group button:first-child {
      border-radius: 2px 0px 2px 0px; }
    .sort-filter-button-group button:last-child {
      border-radius: 0px 2px 0px 2px; }
  .list-page-grid-table-row {
    padding: 12.5px 21px 12.5px 16.5px !important; }
    .list-page-grid-table-row div {
      padding: 0px !important; }
  .list-page-grid-table-cell {
    padding: 5px 0px 0px 0px !important; }
    .list-page-grid-table-cell span {
      margin-right: 10px; }
  .column-header {
    font-size: 15px;
    font-weight: 600;
    color: #555555; }
  .row-header {
    padding-top: 0px !important;
    font-size: 20px; }
  .list-page-grid-table-cell-actions {
    min-width: initial !important;
    margin-top: 5px;
    padding-right: 0px !important; }
    .list-page-grid-table-cell-actions div {
      min-width: initial !important; }
  .list-page-grid-table-cell-status-active {
    margin-top: 0px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .list-page-grid-table-cell-status-inprogress {
    margin-top: 0px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .list-page-grid-table-cell-status-inactive {
    margin-top: 0px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .deduction-page-header {
    padding: 12.5px; }
  .main-action-button,
  .main-action-button:hover {
    font-size: 12px !important;
    padding: 6px !important; }
  .deduction-page-header {
    padding-left: 16.5px !important;
    padding-right: 16.5px !important; } }

.mobile-filter-panel.popover.bottom {
  margin-top: 0 !important; }

.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important; }

@media (min-width: 320px) and (max-width: 991px) {
  .mobile-filter-panel-list-section {
    display: block !important; } }

@media (min-width: 992px) {
  .mobile-filter-panel-list-section {
    display: none !important; } }

.mobile-filter-panel-list-section .mobile-filter-panel-list-panel-bar {
  padding: 5px !important;
  font-size: 16px;
  font-weight: 700;
  color: #555555;
  background-color: #d1eae5 !important;
  border: none; }
  .mobile-filter-panel-list-section .mobile-filter-panel-list-panel-bar .mobile-filter-panel-list-icon {
    width: 40px !important;
    padding-right: 10px !important; }
  .mobile-filter-panel-list-section .mobile-filter-panel-list-panel-bar .mobile-filter-panel-list-badge {
    width: 32px !important;
    float: right !important; }
    .mobile-filter-panel-list-section .mobile-filter-panel-list-panel-bar .mobile-filter-panel-list-badge .filter-select-badge {
      color: white;
      font-size: 12px;
      font-weight: 600;
      background-color: #009999; }

.mobile-filter-panel-list-section .mobile-filter-panel-list-panel-bar-section .mobile-filter-panel-list-panel-default {
  margin-bottom: 2px !important; }

.mobile-filter-panel {
  top: 0 !important;
  position: fixed !important;
  height: 100%;
  width: 100% !important; }
  .mobile-filter-panel .arrow {
    display: none !important; }
  .mobile-filter-panel .popover-content {
    padding: 0 !important; }
  .mobile-filter-panel .mobile-filter-panel-parent-section {
    height: 35px;
    padding-top: 8px; }
    .mobile-filter-panel .mobile-filter-panel-parent-section .mobile-filter-panel-parent-label {
      text-align: center; }
    .mobile-filter-panel .mobile-filter-panel-parent-section .mobile-filter-panel-parent-clear-link {
      width: 65px !important;
      float: right !important; }
      .mobile-filter-panel .mobile-filter-panel-parent-section .mobile-filter-panel-parent-clear-link a {
        color: #009999 !important; }
      .mobile-filter-panel .mobile-filter-panel-parent-section .mobile-filter-panel-parent-clear-link a:hover,
      .mobile-filter-panel .mobile-filter-panel-parent-section .mobile-filter-panel-parent-clear-link a:active {
        text-decoration: none !important; }
  .mobile-filter-panel .mobile-filter-select-dropdown {
    display: block !important; }
  .mobile-filter-panel .mobile-filter-panel-button-section {
    width: 100%;
    bottom: 0;
    position: fixed; }
    .mobile-filter-panel .mobile-filter-panel-button-section button {
      width: 100%; }

.mobile-sort-panel-item {
  color: #555;
  border-bottom: 1px solid #ccc;
  padding-top: 5px;
  padding-bottom: 5px; }
  .mobile-sort-panel-item.__active {
    background-color: #cff;
    font-weight: 600; }
