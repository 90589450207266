.job-history-section-link-separator {
  margin: 0 32px;
  border-left: 1px solid black;
  display: inline; }

.job-history-pagetitle {
  float: left;
  font-size: 25px;
  font-weight: 400;
  color: #555555;
  padding-bottom: 25px; }

.job-history-section-links {
  float: right; }
