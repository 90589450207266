.status-overlay {
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  z-index: 51;
  padding: 60px 180px;
  box-shadow: 0px 0px 16px; }
  .status-overlay-header {
    font-size: 28px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  @media (min-width: 992px) and (max-width: 1199px) {
    .status-overlay {
      padding: 60px 95px; } }
  .status-overlay .nc-entity-page-container {
    padding: 20px !important; }
