.list-page-cancel.modal {
  padding-left: 0px !important; }
  .list-page-cancel.modal .modal-dialog {
    width: 600px; }
    @media (max-width: 767px) {
      .list-page-cancel.modal .modal-dialog {
        margin: 20px;
        width: auto !important; } }
    .list-page-cancel.modal .modal-dialog .modal-content {
      border-radius: 0px;
      box-shadow: none !important; }
      @media (min-width: 768px) {
        .list-page-cancel.modal .modal-dialog .modal-content {
          padding: 30px; } }
      @media (max-width: 767px) {
        .list-page-cancel.modal .modal-dialog .modal-content {
          padding: 20px; } }
      .list-page-cancel.modal .modal-dialog .modal-content .modal-header {
        border-bottom: none;
        padding: 0px; }
        .list-page-cancel.modal .modal-dialog .modal-content .modal-header .close-icon {
          text-align: right; }
          @media (min-width: 768px) {
            .list-page-cancel.modal .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: -15px;
              margin-left: 15px;
              margin-bottom: 5px; } }
          @media (max-width: 767px) {
            .list-page-cancel.modal .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: 0px;
              margin-left: 0px; } }
          .list-page-cancel.modal .modal-dialog .modal-content .modal-header .close-icon i {
            cursor: pointer;
            margin-top: 0px;
            margin-right: 0px;
            color: #555; }
      .list-page-cancel.modal .modal-dialog .modal-content .modal-body {
        color: #555;
        font-size: 18px;
        padding: 0px; }
        .list-page-cancel.modal .modal-dialog .modal-content .modal-body .modal-row {
          margin-bottom: 20px; }
        .list-page-cancel.modal .modal-dialog .modal-content .modal-body .header-text {
          text-align: left !important; }
        .list-page-cancel.modal .modal-dialog .modal-content .modal-body .message-footer ul.nc-earnings-error-list {
          font-size: 15px;
          padding-left: 30px; }
        .list-page-cancel.modal .modal-dialog .modal-content .modal-body .message-footer li.error-list:not(:last-child) {
          margin-bottom: 10px; }
      .list-page-cancel.modal .modal-dialog .modal-content .modal-footer {
        border: none;
        padding-bottom: 0px;
        text-align: center;
        clear: both; }
        .list-page-cancel.modal .modal-dialog .modal-content .modal-footer .button-group {
          width: 275px;
          margin-left: auto;
          margin-right: auto; }
          .list-page-cancel.modal .modal-dialog .modal-content .modal-footer .button-group button {
            margin-right: 15px; }
            @media (max-width: 767px) {
              .list-page-cancel.modal .modal-dialog .modal-content .modal-footer .button-group button {
                margin-bottom: 15px;
                margin-right: 0px; } }
          .list-page-cancel.modal .modal-dialog .modal-content .modal-footer .button-group button:last-child {
            margin-bottom: 0px;
            margin-right: 0px; }
          @media (max-width: 767px) {
            .list-page-cancel.modal .modal-dialog .modal-content .modal-footer .button-group {
              width: 100%; } }
        @media (max-width: 767px) {
          .list-page-cancel.modal .modal-dialog .modal-content .modal-footer {
            padding: 0px; }
            .list-page-cancel.modal .modal-dialog .modal-content .modal-footer button {
              width: 100%; } }
        .list-page-cancel.modal .modal-dialog .modal-content .modal-footer .error-list:not(:last-child) {
          margin-bottom: 10px; }
