.tab-container {
  flex-grow: 12;
  padding: 35px 30px !important; }
  .tab-container .seperator hr {
    margin-top: 0;
    border-top: 1px solid #ccc; }
  .tab-container .tab-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px; }
    .tab-container .tab-title-container .tab-title {
      font-size: 30px;
      float: left; }
    .tab-container .tab-title-container .links {
      float: right; }
      .tab-container .tab-title-container .links span {
        font-weight: 600; }
      .tab-container .tab-title-container .links a {
        padding-left: 15px;
        margin-left: 15px;
        border-left: 1px solid #ccc; }
  .tab-container .nc-filter-container {
    margin-top: 20px;
    margin-bottom: 0px; }
  .tab-container .expiration-date-badge {
    background-color: #fb6b5b;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 10px; }
  .tab-container .expired-date-badge {
    background-color: #d44646;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 10px; }
  .tab-container .expert-skill-badge {
    background-color: #30a779;
    font-size: 14px;
    font-weight: 400;
    min-width: 85px;
    padding: 5px 15px;
    text-align: center;
    color: #fff !important;
    font-style: normal !important; }
  .tab-container .other-skill-badge {
    background-color: #eff6e8;
    font-size: 14px;
    font-weight: 400;
    min-width: 85px;
    padding: 5px 15px;
    text-align: center;
    color: #555 !important;
    font-style: normal !important; }
  .tab-container .toaster {
    margin-bottom: 20px; }
  .tab-container .tab-container-section {
    display: flex;
    align-items: center; }
    .tab-container .tab-container-section .container-discription {
      font-size: 15px; }
  .tab-container .tab-container-list {
    padding: 30px 0px; }
    .tab-container .tab-container-list .tab-container-list-icon-section {
      display: flex; }
      .tab-container .tab-container-list .tab-container-list-icon-section a {
        color: #555; }
      .tab-container .tab-container-list .tab-container-list-icon-section .tab-container-list-edit-section {
        margin-right: 30px; }
    .tab-container .tab-container-list .user-icon .user-name {
      color: #555; }
    .tab-container .tab-container-list .user-icon .user-description {
      padding-top: 5px;
      color: #555; }
    .tab-container .tab-container-list .user-icon .user-employeement {
      padding: 15px 0px;
      display: flex; }
      .tab-container .tab-container-list .user-icon .user-employeement .user-employeement-period {
        margin-right: 15px; }
      .tab-container .tab-container-list .user-icon .user-employeement .user-employeement-period-years {
        border-left: 1px solid #ccc;
        padding-left: 10px; }
    .tab-container .tab-container-list .tab-container-list-item {
      border-bottom: 1px solid #ccc;
      padding-bottom: 15px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .tab-container .tab-container-list .table-header {
      font-size: 14px;
      font-weight: 700; }
    .tab-container .tab-container-list .table-row {
      border-bottom: 1px solid #dbd6d6;
      clear: both;
      margin-right: 0px;
      margin-left: 0px;
      padding: 20px 0px;
      display: flex;
      align-items: center;
      font-size: 15px; }
      .tab-container .tab-container-list .table-row .table-cell:first-child {
        color: #009999;
        font-size: 18px; }
      .tab-container .tab-container-list .table-row .table-cell span {
        color: #ccc;
        font-size: 15px;
        font-style: italic; }
        .tab-container .tab-container-list .table-row .table-cell span i {
          color: #396; }
  .tab-container .tab-container-add-section .toaster {
    margin-top: 20px; }
  .tab-container .tab-container-add-section .title-band-container .title-band {
    background-color: #396;
    padding: 12px 0 10px 20px;
    color: #fff;
    font-weight: 400;
    height: 45px;
    font-size: 18px;
    margin-left: 0; }
  .tab-container .tab-container-add-section .tab-container-add-sub-section {
    border-bottom: 1px solid #eee; }
    .tab-container .tab-container-add-section .tab-container-add-sub-section .section-title {
      color: #396;
      font-size: 20px; }
    .tab-container .tab-container-add-section .tab-container-add-sub-section .section-items {
      padding: 15px 15px; }
    .tab-container .tab-container-add-section .tab-container-add-sub-section .radioStyles {
      height: 36px; }
    .tab-container .tab-container-add-section .tab-container-add-sub-section:last-child {
      border-bottom: none; }
  .tab-container .nav.nav-tabs {
    border-bottom: 1px solid #ccc;
    padding-bottom: 2px; }
    .tab-container .nav.nav-tabs li {
      margin-right: 20px; }
      .tab-container .nav.nav-tabs li a {
        padding: 5px 0px;
        color: #555;
        border: none; }
        .tab-container .nav.nav-tabs li a:hover {
          background-color: #fff;
          color: #555;
          border: none; }
        .tab-container .nav.nav-tabs li a:focus {
          border-bottom: 4px solid #396;
          background-color: #fff;
          font-weight: 600; }
        .tab-container .nav.nav-tabs li a:active {
          border-bottom: 4px solid #396;
          background-color: #fff;
          font-weight: 600; }
      .tab-container .nav.nav-tabs li.active {
        background: #fff;
        width: inherit; }
        .tab-container .nav.nav-tabs li.active a {
          border-bottom: 4px solid #396;
          background-color: #fff;
          font-weight: 600; }
  .tab-container .tab-content {
    padding-top: 20px; }
    .tab-container .tab-content .tab-pane.active {
      background: #fff;
      width: inherit; }
  .tab-container .workplace-info-section-container .title {
    font-size: 20px;
    font-weight: 400; }
  .tab-container .workplace-info-section-container .info-section {
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 5px; }
  .tab-container .workplace-info-section-container .contact-info {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .tab-container .workplace-info-section-container .contact-info .work-email-textbox {
      width: 280px; }
    .tab-container .workplace-info-section-container .contact-info .work-ph-no-textbox {
      width: 170px; }
    .tab-container .workplace-info-section-container .contact-info .work-ph-no-ext-textbox {
      width: 90px;
      padding-left: 10px; }
