.fmla-calculator {
  background-color: #e9e9e9;
  padding: 30px;
  margin-bottom: 20px; }
  .fmla-calculator-header {
    margin-bottom: 15px; }
    .fmla-calculator-header i.fa-calculator {
      color: #339966; }
    .fmla-calculator-header .title {
      font-weight: 600;
      font-size: 18px; }
  .fmla-calculator .fmla-table {
    background-color: #FFF;
    padding: 10px 15px; }
    .fmla-calculator .fmla-table-header {
      font-weight: 600; }
    .fmla-calculator .fmla-table-item {
      border-bottom: 1px solid #ccc;
      padding-top: 10px;
      padding-bottom: 10px; }
      .fmla-calculator .fmla-table-item:last-child {
        border-bottom: none;
        padding-bottom: 0px; }
