.dashboard-tabs {
  display: flex;
  align-items: center; }
  @media (min-width: 992px) {
    .dashboard-tabs .rounded-tab-section-wrapper {
      margin-left: 36px;
      margin-right: 36px; } }
  .dashboard-tabs .rounded-tab-section-wrapper .rounded-tab-section {
    transition: transform 0.4s;
    transition-timing-function: ease-out;
    padding-left: 0;
    padding-right: 0;
    overflow-x: visible;
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    white-space: nowrap; }
    .dashboard-tabs .rounded-tab-section-wrapper .rounded-tab-section .tab-wrapper {
      padding-left: 17.5px;
      padding-right: 27.5px;
      flex-grow: 1; }
      .dashboard-tabs .rounded-tab-section-wrapper .rounded-tab-section .tab-wrapper:first-child {
        padding-left: 0; }
      .dashboard-tabs .rounded-tab-section-wrapper .rounded-tab-section .tab-wrapper:last-child {
        padding-right: 8px; }
      @media (max-width: 991px) {
        .dashboard-tabs .rounded-tab-section-wrapper .rounded-tab-section .tab-wrapper {
          padding-left: 5px;
          padding-right: 13px; }
          .dashboard-tabs .rounded-tab-section-wrapper .rounded-tab-section .tab-wrapper:first-child {
            padding-left: 0; }
          .dashboard-tabs .rounded-tab-section-wrapper .rounded-tab-section .tab-wrapper:last-child {
            padding-right: 8px; } }
      .dashboard-tabs .rounded-tab-section-wrapper .rounded-tab-section .tab-wrapper:last-child {
        margin-right: 0px; }
    .dashboard-tabs .rounded-tab-section-wrapper .rounded-tab-section .single-round-tab-content {
      border: 1px solid;
      padding: 5px 20px 5px 20px;
      border-radius: 20px;
      margin-right: 15px;
      border-color: #999999;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      cursor: pointer; }
      @media (max-width: 991px) {
        .dashboard-tabs .rounded-tab-section-wrapper .rounded-tab-section .single-round-tab-content {
          height: 40px; } }
      @media (min-width: 992px) {
        .dashboard-tabs .rounded-tab-section-wrapper .rounded-tab-section .single-round-tab-content:hover {
          background-color: #e9e9e9; } }
    .dashboard-tabs .rounded-tab-section-wrapper .rounded-tab-section .single-round-tab-content:last-child {
      margin-right: 0px; }
    .dashboard-tabs .rounded-tab-section-wrapper .rounded-tab-section .activeClass {
      background-color: #009999;
      color: whitesmoke;
      font-weight: 400 !important;
      border: none; }
      .dashboard-tabs .rounded-tab-section-wrapper .rounded-tab-section .activeClass:hover {
        background-color: #009999; }
