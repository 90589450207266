.deduction-activity-history-button {
  width: 200px !important; }

.deduction-page-section-description {
  padding-top: 10px !important;
  padding-bottom: 0px !important; }

.deduction-page-section-label, .add-popup-deduction-page-section-label {
  padding-top: 30px !important;
  padding-bottom: 10px !important; }
  @media (min-width: 320px) and (max-width: 991px) {
    .deduction-page-section-label, .add-popup-deduction-page-section-label {
      padding-bottom: 5px !important; } }

.add-third-party-payee-label {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }
  @media (min-width: 320px) and (max-width: 991px) {
    .add-third-party-payee-label {
      padding-bottom: 5px !important; } }

.page-section-fieldgroup-label {
  padding-top: 15px !important;
  padding-bottom: 10px !important; }

.deduction-page-section-input .deduction-taxability-button .mobile-netchex-radio-button {
  margin-left: 0px !important; }

.deduction-page-section-input .deduction-page-section-input-textbox,
.deduction-page-section-input .deduction-subcategory-dropdown {
  padding-left: 0px !important; }

.deduction-page-section-input div .form-group {
  margin-bottom: 0px !important; }

.deduction-page-section-input .input-info-message {
  padding-left: 0px !important;
  padding-top: 5px !important;
  font-style: italic;
  font-size: 14px;
  color: #999; }

.deduction-page-header .deduction-page-header-section {
  padding-left: 0px;
  padding-right: 0px; }
  .deduction-page-header .deduction-page-header-section .deduction-page-header {
    padding: 0px !important; }
    .deduction-page-header .deduction-page-header-section .deduction-page-header h1 {
      font-size: 25px;
      font-weight: 400;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding-bottom: 25px; }
  .deduction-page-header .deduction-page-header-section .deduction-page-header-buttons {
    padding: 0px !important; }

.deduction-page-header .deduction-page-description-section .deduction-page-description-content {
  padding: 0px;
  padding-bottom: 30px; }
  .deduction-page-header .deduction-page-description-section .deduction-page-description-content p {
    font-size: 15px !important; }

.deduction-page-title-section, .third-party-deductions-page-title {
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 0px !important;
  padding-right: 0px !important; }
  .deduction-page-title-section .deductions-page-badge-section, .third-party-deductions-page-title .deductions-page-badge-section {
    float: left !important; }
  .deduction-page-title-section .deduction-page-number, .third-party-deductions-page-title .deduction-page-number {
    padding: 7px 10px 10px 10px !important;
    height: 30px;
    width: 30px;
    border-radius: 100px !important;
    font-size: 18px !important;
    background-color: #555555 !important;
    margin-left: 5px !important; }
  .deduction-page-title-section .deductions-page-title, .third-party-deductions-page-title .deductions-page-title {
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 600;
    font-size: 18px;
    color: #555555;
    margin-left: 15px !important;
    float: left !important;
    padding-top: 3px !important; }

.deduction-page-section-track-progress .add-deduction-step {
  float: right !important; }

.deduction-page-section-track-progress-mobile {
  background-color: #e9e9e9;
  padding-bottom: 20px; }

.deduction-category-button,
.deduction-hsa-button,
.deduction-taxability-button,
.deduction-calcuation-radio-buttons,
.inline-radio-button,
.inline-block-button {
  display: inline-block !important; }

@media (min-width: 320px) and (max-width: 991px) {
  .deduction-category-button {
    display: block !important; } }

.deduction-page-section-header {
  padding-top: 20px !important; }

.deducion-taxability-options .text-link {
  font-size: 15px; }

.deducion-taxability-options-collapsible-panel,
.hide-show-collapsible-panel {
  border: 0px none white !important;
  box-shadow: none !important;
  margin-bottom: 0px !important; }
  .deducion-taxability-options-collapsible-panel .panel-body,
  .hide-show-collapsible-panel .panel-body {
    border: 0px none white !important;
    padding: 17px 17px 0px 0px !important; }
    @media (max-width: 767px) {
      .deducion-taxability-options-collapsible-panel .panel-body,
      .hide-show-collapsible-panel .panel-body {
        padding: 17px 0px 0px 0px !important; } }
    .deducion-taxability-options-collapsible-panel .panel-body .deducion-taxability-options-description,
    .deducion-taxability-options-collapsible-panel .panel-body .hide-show-collapsible-panel-description,
    .hide-show-collapsible-panel .panel-body .deducion-taxability-options-description,
    .hide-show-collapsible-panel .panel-body .hide-show-collapsible-panel-description {
      font-family: "Source Sans Pro", sans-serif !important;
      font-weight: 400;
      font-size: 15px;
      color: #555555;
      padding-left: 0px !important; }
    .deducion-taxability-options-collapsible-panel .panel-body .deducion-taxability-options-list,
    .deducion-taxability-options-collapsible-panel .panel-body .hide-show-collapsible-panel-list,
    .hide-show-collapsible-panel .panel-body .deducion-taxability-options-list,
    .hide-show-collapsible-panel .panel-body .hide-show-collapsible-panel-list {
      padding: 10px 10px 0px 0px !important;
      color: #7d7d7d;
      padding-bottom: 0px !important; }
    .deducion-taxability-options-collapsible-panel .panel-body .deducion-taxability-options-states,
    .hide-show-collapsible-panel .panel-body .deducion-taxability-options-states {
      padding: 0px 10px 0px 0px !important;
      color: #7d7d7d; }
      .deducion-taxability-options-collapsible-panel .panel-body .deducion-taxability-options-states .system-level-deduction-description-states-list-description,
      .hide-show-collapsible-panel .panel-body .deducion-taxability-options-states .system-level-deduction-description-states-list-description {
        font-family: "Source Sans Pro", sans-serif !important;
        font-weight: 600;
        font-size: 15px;
        color: #555555; }

.deduction-description-checkbox-group {
  padding-left: 0px !important; }

.deduction-exclude-states-group-section {
  padding-top: 20px !important;
  padding-bottom: 10px !important; }

.system-level-deduction-description-checkbox-label {
  cursor: pointer !important;
  padding-left: 0px !important;
  display: block;
  width: fit-content; }
  .system-level-deduction-description-checkbox-label input[type="checkbox"] {
    margin-right: 5px !important; }

.deduction-review-and-save-section .deduction-review-and-save-section-cell, .deduction-review-and-save-section .deduction-review-and-save-section-label, .deduction-review-and-save-section .deduction-review-and-save-section-value, .deduction-review-and-save-section .deduction-review-and-save-section-button {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

@media (min-width: 320px) and (max-width: 991px) {
  .deduction-review-and-save-section .deduction-review-and-save-section-value {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    color: #888 !important; } }

.deduction-review-and-save-section .deduction-review-and-save-section-button a {
  float: right !important;
  color: #555 !important;
  cursor: pointer; }

.select-enterprise-payee-dropdown {
  padding-left: 0px !important; }

.selected-deduction-page {
  background-color: #99cc99;
  color: #333 !important;
  width: 220px;
  border-radius: 5px; }

.nc-navigation-button-family button.primary-link-button {
  padding: 6px 20px;
  margin-right: 10px; }

.nc-navigation-button-family button.primary-button {
  width: auto; }

.list-page-edit-section span.list-page-edit-icon {
  font-size: 15px; }

.w-2-summary-section {
  padding: 35px 100px !important;
  background-color: #e9e9e9;
  margin-left: 0px !important; }
  @media (min-width: 320px) and (max-width: 761px) {
    .w-2-summary-section {
      padding: 0px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .w-2-summary-section {
      padding: 0px !important; } }
  .w-2-summary-section .w-2-summary-header-section {
    text-align: center !important;
    padding-bottom: 20px !important; }
    .w-2-summary-section .w-2-summary-header-section .w2-summary-header-sublabel {
      color: #333 !important;
      font-family: "Source Sans Pro", sans-serif !important;
      padding-bottom: 15px !important; }

.w2-summary-subsection {
  padding-bottom: 20px !important; }
  @media (min-width: 320px) and (max-width: 991px) {
    .w2-summary-subsection {
      padding-left: 0px !important; } }
  .w2-summary-subsection .w2-summary-subsection-label, .w2-summary-subsection .review-save-w2-summary-label {
    color: #333 !important;
    font-family: "Source Sans Pro", sans-serif !important;
    padding-bottom: 10px !important; }
    @media (min-width: 320px) and (max-width: 991px) {
      .w2-summary-subsection .w2-summary-subsection-label, .w2-summary-subsection .review-save-w2-summary-label {
        padding-left: 0px !important; } }
  .w2-summary-subsection .w2-summary-deduction-sublabel {
    color: #555 !important;
    font-family: "Source Sans Pro", sans-serif !important;
    padding-bottom: 5px !important; }
    @media (min-width: 320px) and (max-width: 991px) {
      .w2-summary-subsection .w2-summary-deduction-sublabel {
        padding-left: 0px !important; } }

.remove-padding {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.bg-green {
  background-color: #339966;
  min-height: 42px;
  max-height: 42px;
  max-width: 42px;
  padding-top: 8px;
  text-align: center;
  color: white;
  border: 4px solid white;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto; }

.bg-grey {
  background-color: #e9e9e9;
  min-height: 42px;
  max-height: 42px;
  max-width: 42px;
  padding-top: 8px;
  text-align: center;
  color: white;
  border: 4px solid white;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto; }

.step-name {
  margin-top: 5px;
  color: #555;
  font-size: 12px; }

@media (min-width: 320px) and (max-width: 767px) {
  .deduction-steps-item-section {
    width: 80px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .deduction-steps-item-section {
    width: 115px; } }

.deduction-circle-icons {
  display: table-cell; }
  .deduction-circle-icons i {
    margin-right: 0px; }

.add-deduction-step-mobile {
  overflow-x: scroll; }
  .add-deduction-step-mobile .hbar {
    top: 41px; }
    @media (max-width: 991px) and (min-width: 768px) {
      .add-deduction-step-mobile .hbar {
        width: 84%;
        margin-left: auto;
        margin-right: auto;
        min-width: initial; } }

.add-deduction-step-mobile-table {
  position: relative;
  display: table;
  width: 100%;
  margin-top: 20px; }

.add-deduction-step-sub-menu-mobile {
  display: table-row; }

.visible {
  visibility: visible; }

.invisible {
  visibility: hidden; }

@media (min-width: 320px) and (max-width: 767px) {
  .deduction-modal-dialog {
    margin-left: 0px !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .deduction-modal-dialog {
    margin-left: 0px !important;
    width: auto !important; } }

@media (min-width: 1200px) and (max-width: 1600px) {
  .deduction-modal-dialog {
    margin-left: 0px !important; } }

@media (min-width: 320px) and (max-width: 761px) {
  .deduction-modal-dialog {
    margin-left: 0px !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .deduction-modal-dialog {
    margin-left: 0px !important;
    width: auto !important; } }

@media (min-width: 1200px) and (max-width: 1600px) {
  .deduction-modal-dialog {
    margin-left: 0px !important; } }

.deduction-modal-content {
  border-radius: 0px !important;
  box-shadow: none !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .deduction-modal-content {
      margin-left: 1% !important;
      width: 98% !important;
      min-height: 160px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .deduction-modal-content {
      margin-left: 10% !important;
      width: 80% !important;
      min-height: 160px !important; } }
  @media (min-width: 1200px) and (max-width: 1600px) {
    .deduction-modal-content {
      margin-left: 20% !important;
      width: 60% !important;
      min-height: 160px !important;
      top: 10px; } }

.deduction-modal-description-line-one {
  color: #606060; }
  @media (min-width: 320px) and (max-width: 767px) {
    .deduction-modal-description-line-one {
      font-size: 16px;
      margin-top: 20px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .deduction-modal-description-line-one {
      font-size: 16px; } }
  @media (min-width: 1400px) and (max-width: 1600px) {
    .deduction-modal-description-line-one {
      font-size: 16px;
      text-align: center !important;
      margin-top: 20px; } }

.btn-delete-deduction-modal {
  background-color: #00cccc;
  color: white;
  border: none !important;
  border-color: white !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .btn-delete-deduction-modal {
      padding: 5px 10px 5px 10px !important;
      font-size: 14px;
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin-left: 0px !important;
      margin-right: 10px !important;
      margin-top: 20px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .btn-delete-deduction-modal {
      padding: 5px 10px 5px 10px !important;
      font-size: 14px;
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin-left: 0px !important;
      margin-right: 10px !important; } }
  @media (min-width: 1200px) and (max-width: 1600px) {
    .btn-delete-deduction-modal {
      padding: 5px 20px 7px 20px !important;
      font-size: 18px; } }

.btn-cancel-deduction-modal {
  color: #999 !important;
  text-align: center !important;
  font-weight: bold;
  background-color: white !important;
  margin-left: 30px !important;
  border: none !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .btn-cancel-deduction-modal {
      padding: 10px 20px 14px 0px !important;
      font-size: 16px;
      margin-left: 0px !important;
      margin-top: 10px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .btn-cancel-deduction-modal {
      padding: 10px 20px 14px 0px !important;
      font-size: 16px;
      margin-left: 0px !important; } }
  @media (min-width: 1400px) and (max-width: 1600px) {
    .btn-cancel-deduction-modal {
      padding: 10px 20px 14px 10px !important;
      font-size: 16px; } }

@media (min-width: 320px) and (max-width: 767px) {
  .mobile-radio-button {
    width: 50%;
    padding-right: 10px !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .mobile-radio-button {
    width: 50%;
    padding-right: 10px !important; } }

@media (min-width: 768px) and (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  margin-bottom: 5px !important; }

@media (min-width: 320px) and (max-width: 767px) {
  .mobile-radio-button {
    width: 50%;
    padding-right: 10px !important; } }

.deductions-page-footer-modal-button,
.deductions-page-footer-modal-button:hover {
  background-color: #00cccc !important;
  border-radius: 3px !important;
  text-transform: uppercase !important;
  color: #fff !important;
  font-size: 16px !important;
  border-color: transparent; }
  @media (min-width: 320px) and (max-width: 767px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-bottom: 5px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-bottom: 5px !important;
      margin-right: 5px !important; } }
  @media (min-width: 992px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-right: 5px !important;
      padding: 10px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-bottom: 5px !important; } }

.remove-padding-left-right {
  padding-left: 0 !important;
  padding-right: 0 !important; }

@media (min-width: 320px) and (max-width: 991px) {
  .deduction-activity-history-button {
    width: 135px !important;
    font-size: 10px !important;
    float: left !important; }
  .deduction-page-header .deduction-page-header-section .deduction-page-header h1 {
    font-size: 18px;
    font-weight: bold; }
  .deduction-page-description-content {
    padding-bottom: 10px !important; }
  .deduction-page-description-button {
    float: left !important; }
  .main-action-button {
    font-size: 14px !important; } }

.remove-padding-right {
  padding-right: 0 !important; }

@media (min-width: 768px) and (max-width: 991px) {
  #system-level-deduction-save-changes {
    width: 30% !important; } }

@media (min-width: 992px) {
  #system-level-deduction-save-changes {
    width: 30% !important; } }

.mobile-reactivate-button {
  margin-top: 5px !important; }

.center-buttonlink-cancel {
  padding: 0px 20px 14px 10px !important;
  color: #999 !important;
  background-color: white !important;
  font-size: 16px;
  text-align: center !important;
  font-weight: 400;
  margin-left: 30px !important;
  border: none !important;
  text-decoration: none !important; }
  @media (min-width: 320px) and (max-width: 991px) {
    .center-buttonlink-cancel {
      width: 100% !important;
      margin-left: 0px !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  #cancel-button-unsaved-changes-warning-save-changes {
    width: 30% !important; } }

@media (min-width: 992px) {
  #cancel-button-unsaved-changes-warning-save-changes {
    width: 30% !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  #back-button-unsaved-changes-warning-save-changes {
    width: 30% !important; } }

@media (min-width: 992px) {
  #back-button-unsaved-changes-warning-save-changes {
    width: 30% !important; } }

@media (min-width: 320px) and (max-width: 991px) {
  .w2-summary-remove-padding {
    padding-left: 0px !important; } }

.review-save-remove-padding {
  padding-left: 0px !important;
  padding-right: 0px !important; }

@media (min-width: 320px) and (max-width: 991px) {
  .mobile-buttonlink-revert-back {
    width: 100% !important;
    margin-left: 0px !important; } }

.margin-top-15-px {
  margin-top: 20px !important; }

.add-pop-mobile-radio-btn {
  min-width: 150px !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .add-pop-mobile-radio-btn {
      width: 100%;
      padding-right: 10px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .add-pop-mobile-radio-btn {
      width: 50%;
      padding-right: 10px !important; } }

.add-tpp-popup-footer {
  padding-left: 0px !important; }

.add-popup-deduction-page-section-label {
  padding-top: 0px !important; }

@media (min-width: 768px) and (max-width: 991px) {
  .dedcution-custom-earning-group-modal .modal-dialog {
    width: 700px !important; } }

@media (min-width: 991px) {
  .dedcution-custom-earning-group-modal .modal-dialog {
    width: 950px !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .company-level-default-value-contribution-tire-button {
    text-align: left; } }

@media (min-width: 992px) {
  .company-level-default-value-contribution-tire-button {
    text-align: right !important;
    font-family: "SourceSansPro" !important; } }

@media (min-width: 320px) and (max-width: 480px) {
  .default-value-tier-label {
    padding-top: 5px; } }

@media (min-width: 481px) and (max-width: 767px) {
  .default-value-tier-label {
    padding-top: 5px !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .default-value-tier-label {
    padding-top: 10px;
    padding-left: 0px !important; } }

@media (min-width: 992px) {
  .default-value-tier-label {
    padding-top: 12px !important;
    text-align: center !important; } }

@media (min-width: 320px) and (max-width: 767px) {
  .default-value-tier-sub-label1,
  .default-value-tier-sub-label2 {
    width: 160px;
    margin-top: 12px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .default-value-tier-sub-label1,
  .default-value-tier-sub-label2 {
    width: 20% !important;
    margin-left: 10px; } }

@media (min-width: 992px) {
  .default-value-tier-sub-label1,
  .default-value-tier-sub-label2 {
    margin-left: 10px;
    margin-right: 10px; } }

@media (min-width: 1300px) {
  .tier-data-container {
    display: flex;
    align-items: center; } }

.calculation-tier-text-container {
  display: flex;
  align-items: center;
  width: 70%; }
  @media (min-width: 768px) and (max-width: 991px) {
    .calculation-tier-text-container {
      width: 90%; } }
  @media (max-width: 767px) {
    .calculation-tier-text-container {
      display: block;
      width: 100%; } }

@media (min-width: 992px) and (max-width: 1299px) {
  .tier-button {
    margin-right: 0px !important; } }

.calculation-tier-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%; }
  @media (min-width: 992px) and (max-width: 1299px) {
    .calculation-tier-button-container {
      justify-content: flex-start;
      width: 30%; } }
  @media (min-width: 320px) and (max-width: 991px) {
    .calculation-tier-button-container {
      justify-content: flex-start;
      margin-bottom: 5px; } }
  .calculation-tier-button-container .nc-link-group {
    float: right; }
    @media (min-width: 320px) and (max-width: 991px) {
      .calculation-tier-button-container .nc-link-group {
        display: flex;
        padding-right: 10px !important;
        float: left;
        padding-top: 5px; }
        .calculation-tier-button-container .nc-link-group button {
          margin-right: 10px;
          margin-bottom: 0px;
          text-align: left;
          margin-left: 5px; } }
    @media (min-width: 992px) {
      .calculation-tier-button-container .nc-link-group {
        display: flex;
        align-items: center;
        margin-bottom: 5px; }
        .calculation-tier-button-container .nc-link-group button {
          font-size: 15px !important;
          padding: 10px 10px;
          margin-right: 10px; } }

@media (min-width: 320px) and (max-width: 767px) {
  .default-value-tier-textbox1,
  .default-value-tier-textbox2 {
    width: 100px;
    margin-bottom: 5px;
    margin-right: 10px; } }

@media (min-width: 992px) {
  .default-value-tier-textbox1,
  .default-value-tier-textbox2 {
    width: auto; } }

@media (min-width: 320px) and (max-width: 767px) {
  .default-value-tier-sub-label3 {
    margin-top: 12px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .default-value-tier-sub-label3 {
    margin-left: 10px;
    width: 20% !important; } }

@media (min-width: 992px) {
  .default-value-tier-sub-label3 {
    margin-left: 10px;
    padding: 0px !important; } }

.deductions-page-footer-modal-button,
.deductions-page-footer-modal-button:hover {
  background-color: #00cccc !important;
  border-radius: 3px !important;
  text-transform: uppercase !important;
  color: #fff !important;
  font-size: 16px !important;
  border-color: transparent; }
  @media (min-width: 320px) and (max-width: 767px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-bottom: 5px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-bottom: 5px !important;
      margin-right: 5px !important; } }
  @media (min-width: 992px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-right: 5px !important;
      padding: 10px !important; } }

@-moz-document url-prefix() {
  .open-deduction-based-popover-button-close {
    border-radius: 50px !important;
    width: 30px;
    height: 30px;
    background-color: #019999 !important;
    border: none !important;
    color: white; }
    @media (min-width: 320px) and (max-width: 479px) {
      .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        margin-left: -5px !important; } }
    @media (min-width: 480px) and (max-width: 767px) {
      .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        margin-left: -5px !important; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        margin-left: -5px !important; } }
    @media (min-width: 992px) {
      .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        margin-left: -5px !important; } } }

/* Added Temporarily*/
/* This class should added to StyleGuide*/
.review-field {
  font-size: 15px;
  margin-bottom: 5px; }

.review-field-edit-icon {
  color: #555 !important; }

@media (max-width: 767px) {
  .contains-single-link button {
    width: 100%; } }

.entity-form-section-secondary > .title {
  color: #009966;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px; }

.entity-form-section-secondary .content {
  padding-left: 10px; }
  .entity-form-section-secondary .content .nc-inline-fields-group .nc-field-group {
    margin-right: 15px; }
  .entity-form-section-secondary .content .nc-inline-fields-group .nc-field-group:last-child {
    margin-right: 0px; }
  .entity-form-section-secondary .content .nc-field-group .field-name {
    margin-bottom: 15px;
    font-size: 15px; }
  .entity-form-section-secondary .content .nc-field-group .field-description {
    font-size: 14px !important;
    color: #999;
    margin-bottom: 5px;
    margin-top: 5px; }
  .entity-form-section-secondary .content .nc-field-group .input-field {
    margin-bottom: 15px; }
    .entity-form-section-secondary .content .nc-field-group .input-field .form-group {
      margin-bottom: 0px; }
  @media (min-width: 768px) {
    .entity-form-section-secondary .content .nc-field-group .ssn-radio-buttons {
      display: flex; } }
  .entity-form-section-secondary .content .nc-field-group .radio-button-group .single-radio-button {
    display: inline; }
    @media (max-width: 767px) {
      .entity-form-section-secondary .content .nc-field-group .radio-button-group .single-radio-button {
        width: 50%;
        float: left; } }
  @media (max-width: 767px) {
    .entity-form-section-secondary .content .nc-field-group .radio-button-group .single-radio-button:nth-of-type(odd) {
      padding-right: 5px; } }
  @media (max-width: 767px) {
    .entity-form-section-secondary .content .nc-field-group .radio-button-group .single-radio-button:nth-of-type(even) {
      padding-left: 5px; } }

.form-control {
  padding-left: 6px !important;
  padding-right: 6px !important; }

.nc-dropdown-navigation-multiselect {
  list-style-type: none;
  padding: 0; }
  @media (min-width: 992px) {
    .nc-dropdown-navigation-multiselect {
      padding-top: 5px; } }
  .nc-dropdown-navigation-multiselect .list-item {
    padding: 10px 10px;
    font-size: 15px; }
    .nc-dropdown-navigation-multiselect .list-item label {
      display: block;
      margin: 0px;
      font-weight: 400; }
      @media (min-width: 992px) {
        .nc-dropdown-navigation-multiselect .list-item label .left-shift {
          margin-left: 20px;
          margin-top: -20px; } }
    .nc-dropdown-navigation-multiselect .list-item label:hover {
      cursor: pointer; }
  .nc-dropdown-navigation-multiselect .list-item:hover {
    cursor: pointer; }

.nc-dropdown-overlay {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 2px 9px 0px rgba(0, 0, 0, 0.35);
  border: 1px solid #999;
  border-radius: 0;
  margin-left: 0;
  margin-top: 39px;
  z-index: 10;
  width: 100%; }
  .nc-dropdown-overlay .form-group {
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px; }
    .nc-dropdown-overlay .form-group .field input:focus {
      border-color: #999;
      box-shadow: none !important; }

#Heading {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px; }

#Heading-Description {
  font-size: 14px;
  letter-spacing: 0.5px;
  padding-top: 20px; }

.btn-btn-inverse {
  background: white;
  border: 1px solid deepskyblue;
  color: deepskyblue;
  padding: 5px 10px 5px 10px;
  text-align: right; }

.listing-category {
  background: #E9E9E9;
  padding: 5px 0px 5px 0px;
  height: 45px; }
  @media (min-width: 1400px) {
    .listing-category {
      margin-left: 15px !important;
      width: 778px !important; } }
  @media (min-width: 1700px) {
    .listing-category {
      margin-left: -165px;
      max-width: 1740px !important; } }

#strip-title {
  font-size: 18px;
  font-weight: bold;
  margin-left: -30px;
  margin-right: 30px;
  padding-top: 10px;
  padding-bottom: 0px; }

.deduction-primary-button {
  padding: 10px 20px 10px 20px !important;
  font-size: 14px !important;
  border-radius: 0px !important;
  margin-left: -50px; }

.filter-icon {
  padding-top: 5px;
  color: deepskyblue;
  font-size: 17px;
  letter-spacing: 0.25px; }

.showing {
  padding-top: 8px !important;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-left: -20px;
  color: #555555;
  font-weight: bold;
  padding-left: 15px !important;
  padding-bottom: 17px !important;
  padding-right: 15px !important; }

#table-title {
  padding-top: 30px;
  padding-bottom: 20px; }

#main-table {
  padding-top: 20px; }

#divider {
  background: #AAAAAA;
  height: 5px; }

#filtername {
  padding: 10px; }

#filter-btn {
  padding: 5px 60px 5px 60px; }

#cancel-btn {
  padding: 5px 60px 5px 60px; }

.status {
  font-weight: bold; }

.category {
  font-weight: bold; }

.number-of-results {
  padding-top: 15px; }

.icon-pencil {
  color: #555; }

.icon-thrash {
  color: #555; }

.btn-deduction {
  text-align: right; }

.category-content-popover {
  width: 244px; }

@media (min-width: 1400px) {
  .status-content-popover {
    width: 186px !important; } }

@media (min-width: 1700px) {
  .status-content-popover {
    width: 204px !important; } }

.deduction-content-popover {
  min-width: 248px; }

.deduction-listing-table div div {
  border: 0; }
  .deduction-listing-table div div div .table thead tr th {
    border-bottom: none; }
  .deduction-listing-table div div div .table tbody tr {
    border-bottom: 1px solid #ddd; }
    .deduction-listing-table div div div .table tbody tr td {
      color: #555;
      border-top: none; }

.heading-modal {
  font-size: 20px; }

.radio-btn-modal {
  color: #555;
  font-size: 40px;
  width: 0%;
  text-align: none;
  vertical-align: middle; }

.divider-modal {
  size: 2px; }

.footer-modal {
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px; }
  .footer-modal .link-modal {
    color: #55B6B6; }

.btn-deduction-modal {
  background: #00CCCC;
  letter-spacing: 0.5 px;
  color: white;
  margin: 10px 0px 12px 0px;
  padding: 5px 25px 5px 25px;
  font-size: 15px;
  border: none; }

.btn-cancel-modal {
  background-color: white;
  color: #B6B6B6;
  margin: 13px 0px 11px 20px;
  border: none;
  padding: 5px 20px 4px 20px;
  text-align: center; }

.checked {
  background-color: #CCFFFF;
  border-color: #00ffff; }

.not-checked {
  background-color: #ffffff !important;
  border-color: #AAAAAA !important; }

.radio-button-item > input[type="radio"]:checked + span:after {
  background-color: #555 !important; }

.radio-button-item > input[type="radio"]:checked + span + span {
  color: #555 !important; }

.radio-button-item:hover .radio-dot:before {
  border-color: #555 !important; }

.radio-button-item:hover .radio-dot:after {
  background-color: #CCFFFF !important; }

.radio-button-item {
  min-width: 200px;
  margin: 10px;
  height: 40px; }

.radio-button-item-word {
  font-size: 14px !important;
  margin-left: 20px !important; }

.btn-item {
  text-align: left !important; }

.radio-dot:before {
  width: 10% !important;
  height: 50% !important;
  margin-top: -3px; }

.radio-dot:after {
  width: 4% !important;
  height: 20% !important;
  margin-top: -1px;
  margin-left: -2px; }

.description-delete-modal {
  color: #555555;
  font-size: 18px;
  margin-top: 10px; }

.btn-cancel-deduction-modal:hover {
  color: #555 !important;
  text-decoration: none !important; }

#delete-deduction-buttons {
  margin-top: 40px; }

.inactive-modal {
  font-size: 16px;
  color: #595959;
  text-align: center !important;
  margin-top: 10px; }

.description-inactive {
  color: #848484;
  margin-top: 20px;
  font-size: 15px;
  text-align: center !important; }

.btn-inactivate-deduction-modal {
  background-color: #00CCCC;
  color: white;
  padding: 10px 0px 10px 0px;
  font-size: 15px;
  margin-top: 20px;
  border: none; }

.btn-cancel-inactive-modal {
  padding: 8px 20px 8px 20px;
  color: #333333;
  background-color: #CCCCCC;
  font-size: 16px;
  font-weight: bold;
  margin-left: 20px; }

#inactivate-deductions-btn {
  margin-top: 30px; }

#space {
  text-align: center; }

.delete-deduction-panel {
  max-width: 560px !important;
  top: 120px; }

.inactivate-deduction-panel {
  max-width: 560px !important;
  top: 120px; }

.add-deduction-panel {
  max-width: 725px !important;
  padding-right: 0px !important;
  padding-bottom: 25px !important;
  padding-left: 0px !important; }

.modal-footer {
  text-align: center !important; }

.system-level-delete-status-panel {
  position: fixed !important;
  max-width: 850px !important;
  top: 250px;
  border-radius: 0px; }

#system-level-delete-deduction-modal-delete {
  position: fixed !important;
  max-width: 950px !important;
  top: 192px !important;
  border-radius: 0px !important; }

.delete-inactivate-success-panel {
  position: fixed !important;
  max-width: 850px !important;
  top: 250px;
  border-radius: 0px; }

.delete-inactivate-success-panel-description {
  font-size: 16px;
  text-align: center;
  padding: 50px;
  margin-top: -5px; }

.system-level-delete-status-panel-description {
  font-size: 16px;
  text-align: center; }

.deduction-setup-close-icon-position {
  float: right;
  font-size: 22px;
  cursor: pointer;
  padding-right: 0px !important; }

.inactive-panel {
  opacity: 0 !important; }

.asterisk-symbol-red {
  color: #CC6733; }

.company-level-deduction-cycle-menu {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  color: #009999;
  font-weight: bold; }

.system-level-deduction-description-menu {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  color: #009999;
  font-weight: bold; }

.system-level-calculation-method-menu {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  color: #009999;
  font-weight: bold; }

.system-level-default-value-menu {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  color: #009999;
  font-weight: bold; }

#system-level-deduction-description-menu-back-button, #company-level-deduction-description-menu-back-button, #system-level-calculation-method-menu-back-button, #system-level-deduction-options-menu-back-button, #system-level-default-value-menu-back-button, #system-level-review-and-save-menu-back-button {
  background-color: white;
  color: #009999;
  font-weight: bold;
  border: none;
  padding: 10px 10px 10px 10px;
  margin-left: -28px;
  margin-top: 10px;
  margin-bottom: 10px; }

.company-level-calculation-method-deduction-title {
  background-color: #339966;
  padding: 15px 15px 15px 22px;
  margin-left: -5px;
  color: white;
  font-weight: bold;
  margin-top: 36px;
  height: 46px; }

.system-level-deduction-description-title, .system-level-claculation-method-title, .system-level-deduction-cycle-title, .system-level-deduction-options-title, .system-level-default-value-title, .system-level-review-and-save-title {
  background-color: #339966;
  padding: 15px 15px 15px 22px;
  margin-left: -8px;
  color: white;
  font-weight: bold;
  margin-top: 36px !important;
  height: 46px !important; }

.company-level-deduction-description-menu-badge {
  color: #555555 !important;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px; }

.company-level-deduction-description-title {
  background-color: #339966;
  padding: 15px 15px 15px 22px !important;
  margin-left: -5px;
  color: white;
  font-weight: bold;
  margin-top: 36px !important;
  height: 46px !important; }

.system-level-deduction-options-menu-badge, .system-level-review-and-save-menu-badge {
  color: #555555 !important;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px; }

.company-level-deduction-description-menu-badge {
  color: #555555 !important;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 27px;
  font-size: 16px;
  margin-left: 10px; }

.system-level-calculation-method-menu-badge {
  color: #555555 !important;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  margin-left: 0px !important; }

.system-level-calculation-method-definition {
  margin-left: 10px;
  font-family: 'SourceSansPro';
  font-weight: 600;
  size: 15px;
  color: #555555; }

.system-level-deduction-options-definition {
  font-family: 'SourceSansPro';
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-left: -9px; }

.system-level-deduction-description-menu-badge, .system-level-calculation-method-menu-badge {
  color: #555555 !important;
  font-weight: bold;
  margin-top: 22px !important;
  margin-bottom: 27px !important;
  font-size: 16px;
  margin-left: 10px; }

.system-level-deduction-cycle-menu-badge {
  color: #555555 !important;
  font-weight: bold;
  margin-top: 22px !important;
  margin-bottom: 27px !important;
  font-size: 16px;
  margin-left: -7px; }

.system-level-deduction-description-page-body {
  margin-left: -25px; }

.system-level-deduction-description-page-header {
  margin-left: -20px; }

.system-level-deduction-cycle-page-header {
  margin-left: -20px; }

.system-level-calculation-method-page-header {
  margin-left: -20px; }

.system-level-footer-header {
  margin-left: -20px; }

.system-level-default-value-menu-badge {
  color: #555555 !important;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 27px;
  font-size: 16px;
  margin-left: -5px; }

.system-level-default-value-definition {
  margin-left: 5px;
  font-family: 'SourceSansPro';
  font-weight: 600;
  size: 15px;
  color: #555555; }

#deduction-cycle-divider {
  margin-left: -2px; }

.system-level-deduction-options-menu-badge {
  color: #555555 !important;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 27px;
  font-size: 16px;
  margin-left: -10px; }

.system-level-options-page-body {
  margin-left: -5px; }

.deduction-description-badge-number {
  padding: 14px 10px 10px 10px !important;
  height: 40px;
  width: 40px;
  border-radius: 100px !important;
  font-size: 15px !important;
  background-color: #555555 !important;
  margin-right: 5px !important; }

.calculation-method-badge-number, .default-value-badge-number, .review-and-save-badge-number {
  padding: 14px 10px 10px 10px !important;
  height: 40px;
  width: 40px;
  border-radius: 100px !important;
  font-size: 15px !important;
  background-color: #555555 !important;
  margin-right: 5px;
  margin-left: 10px; }

.system-level-review-and-save-definition {
  margin-left: 8px;
  font-family: 'SourceSansPro';
  font-weight: 600;
  size: 15px;
  color: #555555; }

.system-level-deduction-options-badge-number {
  padding: 14px 10px 10px 10px !important;
  height: 40px;
  width: 40px;
  border-radius: 100px !important;
  font-size: 15px !important;
  background-color: #555555 !important;
  margin-right: 5px; }

.badge-deduction {
  height: 1000px !important; }

.fa-information-blue {
  color: #009999;
  cursor: pointer;
  size: 15px; }

.next-button-calculation-method {
  background-color: #00CCCC !important;
  padding: 15px 25px 15px 25px !important;
  font-weight: bold !important; }

.system-level-inverse-info-back-button {
  background: white;
  border: 1px solid #009999;
  color: #009999;
  padding: 10px 20px 10px 20px;
  text-align: center;
  margin-left: 20px;
  margin-right: 25px;
  font-weight: 700px !important;
  font-size: 16px; }

.next-button-review-and-save {
  background-color: #00CCCC !important;
  padding: 10px 20px 10px 20px !important;
  font-weight: bold !important; }

.default-button {
  background-color: white !important;
  color: #808080  !important;
  margin-left: 20px;
  border: none !important;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  margin-top: 20px !important; }

.company-level-deduction-description-name-textbox {
  width: 100% !important;
  margin-top: 10px; }

.system-level-deduction-description-name-textbox {
  margin-left: -15px; }

.system-level-deduction-description-debit-section {
  margin-left: -15px; }

.system-level-deduction-description-debit-radio-buttons {
  margin-left: -5px !important; }

.system-level-deduction-description-short-name-textbox {
  width: 450px !important;
  margin-left: -18px; }

.company-level-deduction-description-short-name-textbox {
  margin-bottom: 30px;
  margin-right: 23px;
  margin-top: 14px;
  margin-left: -20px;
  width: 500px !important; }

.w2-summary-box {
  background-color: #F4F1F1;
  width: 800px;
  height: 500px;
  margin-left: 20px;
  margin-top: 25px; }

.company-level-deduction-description-dropdown, .system-level-deduction-description-dropdown {
  width: 300px !important;
  margin-top: 10px; }

.validation-short-description-name {
  font-style: italic;
  color: #CCCCCC;
  padding-top: 7px !important; }

.system-level-deduction-options-enterprise-payee-dropdown {
  margin-left: -15px; }

.system-level-deduction-options-enterprise-title, .system-level-deduction-options-vendor-title {
  color: #009966 !important;
  font-weight: 600 !important;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif !important; }

.system-level-default-value-label-questions {
  margin-left: 20px;
  margin-bottom: 14px;
  font-family: 'SourceSansPro';
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px; }

.system-level-calculation-method-advance-options-radio-buttons {
  margin-left: 20px !important; }

.system-level-calculation-method-advance-options-label-questions {
  margin-left: 10px !important;
  font-weight: bold !important;
  margin-top: 25px;
  margin-bottom: 5px;
  color: #5B5B5B; }

.system-level-calculation-method-other-btn-info-model {
  font-weight: lighter;
  margin-left: 15px;
  font-family: 'SourceSansPro'; }

.system-level-deduction-description-label-questions, .system-level-calculation-method-advance-options-label-questions {
  margin-left: -10px;
  padding-left: 30px !important;
  margin-top: 33px;
  margin-bottom: 14px;
  color: #555555;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  font-size: 15px; }

.system-level-all-label-questions {
  margin-left: -18px;
  margin-top: 33px !important;
  margin-bottom: 14px !important;
  color: #555555;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  font-size: 15px; }

.system-level-label-question-advance-option-calculations-method {
  padding-left: 30px !important;
  margin-top: 33px !important;
  margin-bottom: 14px !important;
  color: #555555;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  font-size: 15px; }

.system-level-deduction-description-category-dropdown-section {
  margin-left: -15px; }

.system-level-label-enterprise-payee-question {
  margin-left: -15px;
  padding-left: 25px !important;
  margin-bottom: 14px;
  font-family: 'SourceSansPro';
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px; }

.system-level-label-enterprise-payee-radio-button-question {
  margin-left: 3px;
  padding-left: 25px !important;
  margin-bottom: 14px;
  font-family: 'SourceSansPro';
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px; }

.system-level-deduction-options-add-new-enterprise-payee {
  color: #009999;
  font-weight: bold !important;
  margin-top: 5px; }

.system-level-deduction-options-dropdown-title, .system-level-deduction-options-textbox-title {
  font-weight: bold !important;
  margin-top: 25px;
  margin-bottom: 5px;
  padding-left: 25px !important;
  color: #5B5B5B; }

.system-level-deduction-options-select-enterprise-payee-dropdown {
  margin-left: 10px; }

.system-level-deduction-options-vendor-category-employee-textbox, .system-level-deduction-options-vendor-category-employer-textbox {
  margin-left: -1px; }

#company-level-label-questions-w2box {
  margin-left: 5px;
  font-weight: bold !important;
  margin-top: 25px;
  margin-bottom: 5px;
  padding-left: 5px !important;
  color: #5B5B5B; }

.company-level-label-questions-panel-w2box {
  margin-left: 10px;
  padding-left: 5px !important;
  color: #5B5B5B;
  width: 620px !important;
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  margin-top: 33px !important; }

.company-level-label-question-taxability {
  margin-left: 5px;
  margin-top: 25px;
  padding-left: 11px !important;
  margin-bottom: 14px;
  color: #5B5B5B;
  font-family: "SourceSansPro" !important;
  font-weight: 600;
  size: 15px;
  margin-top: 33px !important; }

.system-level-label-question-taxability {
  margin-bottom: 14px;
  font-family: 'SourceSansPro';
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px;
  margin-left: -10px;
  padding-left: 30px !important; }

.w-box-label {
  color: #009966 !important;
  font-weight: 600 !important;
  font-size: 18px;
  font-weight: 600 !important;
  font-family: 'Source Sans Pro', sans-serif !important; }

.trial-button {
  background-color: #00CCCC !important;
  padding: 15px 25px 15px 25px !important;
  font-weight: bold !important; }

#deduction-description-divider {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: -20px; }

.system-level-deduction-description-tax-option-link, .company-level-deduction-description-tax-option-link, .system-level-calculation-method-tax-option-link, .system-level-calculation-method-advance-option-link {
  text-decoration: none !important;
  color: #00CCCC !important;
  cursor: pointer; }

#system-level-deduction-description-tax-option-link {
  margin-left: -5px; }

.system-level-deduction-description-tax-option-link-section {
  margin-top: 20px !important; }

#system-level-calculation-method-tax-option-link {
  margin-top: 20px !important;
  margin-left: -5px !important; }

.system-level-deduction-description-panel {
  box-shadow: none !important; }

.system-level-calculation-method-panel {
  margin-left: -30px;
  margin-right: -120px;
  border: 0px none white !important;
  box-shadow: none !important; }

.system-level-deduction-panel-description {
  margin-bottom: 14px;
  font-family: 'SourceSansPro';
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px;
  margin-left: -20px; }

.system-level-deduction-description-states-list-description {
  margin-bottom: 14px;
  font-family: 'SourceSansPro';
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px;
  margin-left: -18px; }

.custom-netchex-radio-button {
  float: left; }

.system-level-advanced-tax-options-group {
  padding: 10px 10px 10px 10px !important;
  color: #7D7D7D;
  margin-left: -40px !important; }

.system-level-advanced-tax-options-exclude-states {
  padding: 10px 10px 10px 10px !important;
  color: #7D7D7D;
  margin-left: -15px; }

.system-level-advanced-tax-options, .system-level-advanced-tax-options-states {
  padding: 3px 0px 3px 0px; }

#system-level-deduction-deduction-checkbox-group {
  font-weight: 100px !important;
  margin-left: 10px; }

#system-level-deduction-exclude-states-checkbox-group {
  font-weight: 100px !important; }

#company-level-deduction-deduction-checkbox-group {
  font-weight: 100px !important; }

.custom-netchex-radio-button-label-description {
  font-weight: 100px !important; }

.custom-netchex-radio-button-label {
  font-weight: bold; }

.system-level-deduction-description-radio-buttons {
  font-weight: bold !important; }

.system-level-calculation-method-radio-buttons {
  font-weight: bold !important;
  margin-left: -25px !important; }

#system-level-calculation-method-page-footer-buttons {
  margin-left: -20px; }

#system-level-deduction-cycle-page-footer-buttons {
  margin-left: 5px; }

.system-level-default-value-radio-buttons {
  font-weight: bold !important;
  margin-left: 16px; }

.system-level-deduction-options-radio-buttons {
  font-weight: bold !important;
  margin-left: 5px; }

.custom-netchex-radio-button-selected {
  font-weight: bold; }

.system-level-deduction-description-taxability-section {
  margin-left: 15px; }

.system-level-deduction-description-w2-box-14-section {
  margin-left: 15px !important; }

.system-level-deduction-description-checkbox-label {
  cursor: pointer !important; }

#add-deduction-step-header {
  color: #555555;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Source Sans Pro', sans-serif !important; }

.add-deduction-step {
  border-radius: 10px;
  margin-top: 20px;
  padding-left: 15px;
  padding-bottom: 10px !important;
  background-color: #E9E9E9;
  width: 250px !important;
  margin-right: 0px  !important; }

#company-level-deduction-description-tax-option-link {
  margin-top: 20px !important;
  margin-left: -5px; }

.add-deduction-step-sub-menu {
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif !important;
  width: 232 px;
  height: 40 px;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif !important;
  border-radius: 5px;
  width: 232 px;
  height: 40 px; }

.system-level-deduction-description-panel, .company-level-deduction-description-panel {
  border: 0px none white !important; }

#add-deduction-step-sub-menu-badge {
  color: #339966;
  padding-right: 15px; }

.add-deduction-step-deduction-cycle {
  border-radius: 0px !important;
  background-color: #99CC99;
  margin-right: 15px;
  padding-top: 8px; }

.asterisk-symbol-red {
  color: #CC6733; }

.add-deduction-step-sub-menu-without-checked {
  padding: 10px 5px 10px 40px !important;
  font-weight: 400; }

.add-deduction-step-sub-menu-with-checked {
  padding: 10px 5px 10px 5px;
  color: #333 !important;
  font-weight: 600; }

.add-deduction-step-sub-menu-with-checked:hover {
  background-color: #99CC99;
  width: 220px !important;
  border-radius: 5px; }

.add-deduction-step-sub-menu-without-checked:hover {
  background-color: #99CC99;
  width: 220px !important;
  border-radius: 5px; }

.add-deduction-step-sub-menu-link-name {
  cursor: pointer;
  color: #555555;
  text-decoration: none !important; }

.add-deduction-step-sub-menu-link-name:hover {
  color: #555555; }

.company-level-deduction-panel-description {
  color: #646464 !important;
  font-weight: bold;
  margin-left: -33px; }

.system-level-deduction-panel-description {
  color: #646464 !important;
  font-weight: bold;
  margin-left: -33px; }

.custom-netchex-radio-button {
  float: left; }

.system-level-advanced-tax-options-group, .system-level-advanced-tax-options-exclude-states {
  padding: 10px 10px 10px 10px !important;
  color: #7D7D7D; }

.company-level-advanced-tax-options-group, .company-level-advanced-tax-options-exclude-states {
  padding: 10px 10px 10px 10px !important;
  color: #7D7D7D;
  margin-left: -20px; }

.system-level-advanced-tax-options, .system-level-advanced-tax-options-states, .company-level-advanced-tax-options, .company-level-advanced-tax-options-states {
  padding: 3px 0px 3px 0px; }

#company-level-deduction-deduction-checkbox-group {
  font-weight: 100px !important; }

.custom-netchex-radio-button-label-description {
  font-weight: 100px !important; }

.custom-netchex-radio-button-label {
  font-weight: bold; }

.system-level-deduction-description-radio-buttons, .company-level-deduction-description-radio-buttons {
  font-weight: bold !important;
  padding-left: 10px !important; }

.company-level-deduction-description-radio-btn {
  font-weight: bold !important;
  padding-left: 0px !important; }

#company-level-deduction-description-radio-buttons-w2box-button {
  font-weight: bold !important;
  padding-left: 10px !important;
  width: 645px !important; }

#company-level-deduction-description-radio-buttons-w2box {
  font-weight: bold !important;
  padding-left: 25px !important;
  margin-left: -15px !important; }

.custom-netchex-radio-button-selected {
  font-weight: bold; }

.system-level-deduction-description-taxability-section, .company-level-deduction-description-taxability-section {
  margin-left: 15px; }

.system-level-deduction-description-w2-box-14-section, .company-level-deduction-description-w2-box-14-section {
  margin-left: 15px !important; }

.system-level-deduction-description-checkbox-label, .company-level-deduction-description-checkbox-label {
  cursor: pointer !important; }

.system-level-deduction-description-exit-changes-warning-description {
  font-size: 16px;
  text-align: center;
  padding: 20px; }

.system-level-deduction-description-exit-changes-warning-description-buttons {
  margin-left: 40px !important; }

.company-level-deduction-description-cancel-button-modal-buttons-modal-content {
  min-height: 200px !important;
  min-width: 900px !important;
  margin-left: -200px;
  top: 250px !important; }

.company-level-deduction-description-cancel-body-modal-buttons-close-icon {
  font-size: 22px;
  float: right; }

.company-level-deduction-description-cancel-button-modal-div-1 {
  padding-left: 10px !important; }

.company-level-deduction-description-cancel-button-modal-div-2 {
  padding-left: 10px !important; }

.company-level-deduction-description-cancel-footer-modal-buttons {
  margin-left: 40px !important;
  padding: 30px; }

.system-level-inverse-info-back-button {
  background: white;
  border: 1px solid #009999;
  color: #009999;
  padding: 10px 20px 10px 20px;
  text-align: center;
  margin-left: 20px;
  margin-right: 25px;
  font-weight: 700px !important;
  font-size: 16px; }

.next-button-deduction-cycle {
  background-color: #00CCCC !important;
  padding: 10px 20px 10px 20px !important;
  font-weight: bold !important; }

.system-level-deduction-calculation-panel, .system-level-deduction-calculation-panel {
  border: 0px none white !important;
  margin-right: -120px !important;
  margin-left: -30px; }

.system-level-aca-description {
  color: #555555; }

.system-level-calculation-method-aca-description-link {
  text-decoration: none !important;
  color: #00CCCC !important;
  cursor: pointer; }

#back-button-unsaved-changes-warning-leave, #cancel-button-unsaved-changes-warning-leave {
  margin-left: 40px !important;
  padding: 5px; }

#back-button-unsaved-changes-warning-stay, #cancel-button-unsaved-changes-warning-stay {
  margin-left: 60px !important;
  padding: 5px; }

.select-dropdown-label {
  min-width: 170px; }

.back-button-unsaved-changes-warning-description, .cancel-button-unsaved-changes-warning-description {
  margin-left: 180px !important;
  padding: 20px;
  font-size: 16px; }

.back-button-unsaved-changes-modal, .cancel-button-unsaved-changes-modal, .system-level-calculation-method-back-button-unsaved-changes-modal, .system-level-calculation-method-cancel-button-unsaved-changes-modal {
  max-width: 550px !important;
  height: 150px;
  top: -280px; }

#system-level-options-footer-buttons {
  margin-left: -20px; }

#system-level-footer-section-options-page {
  margin-left: -20px; }

#divider-system-level-options-page {
  margin-left: -18px; }

#system-level-calculation-method-tax-option-link {
  margin-top: 20px !important;
  margin-left: -12px; }

.select-status-dropdown {
  width: 22.8% !important; }

.company-level-calculation-method-lable-quastion {
  margin-left: -12px;
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px; }

.company-level-calculation-method-lable-quastion-model {
  margin-left: -5px !important;
  font-weight: bold !important;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-left: -10px !important;
  padding-bottom: 10px !important;
  color: #5B5B5B; }

.company-level-calculation-method-radio-btn {
  padding-left: 0px !important;
  margin-left: -4px !important; }

.company-level-calculation-method-radio-btn-1 {
  padding-left: 0px !important;
  margin-left: 0px !important; }

.company-level-calculation-method-link-div {
  margin-left: 5px;
  font-weight: bold !important;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 20px !important;
  background-color: #FFFFCC; }

#company-level-calculation-method-linktag {
  color: #39ABA2 !important; }

.custom-earnings-group-model-heading {
  font-size: 25px;
  color: #555555;
  padding-bottom: 15px;
  font-family: 'Source Sans Pro', sans-serif !important; }

.custom-earnings-group-model-heading-subcontent {
  padding-bottom: 15px;
  font-family: 'Source Sans Pro', sans-serif !important;
  color: #555555; }

.custom-earnings-group-model-lable-question {
  margin-bottom: 14px !important;
  color: #5B5B5B !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  margin-top: 33px !important; }

.custom-earnings-group-model-textbox {
  width: 350px !important;
  border-radius: 0px !important;
  padding-bottom: 20px; }

.custom-earnings-group-model-border-div {
  border: 1px solid #E8E8E8;
  padding: 5px,20px,20px,20px; }

#custom-earnings-group-model-border-div-header {
  padding-bottom: 10px !important;
  font-weight: bold !important;
  margin-bottom: 5px;
  color: #5B5B5B;
  font-size: 15px; }

.custom-earnings-group-model-next-button-calculation-method {
  background-color: #006599 !important;
  padding: 12px 25px 12px 25px !important;
  font-weight: bold !important;
  margin-top: 10px !important;
  margin-left: 0px !important; }

.custom-earnings-group-model-default-button {
  background-color: white !important;
  color: #808080  !important;
  margin-left: 20px;
  border: none !important;
  padding: 15px 25px 15px 25px !important;
  text-align: center !important;
  margin-top: 0px !important; }

.custom-earnings-group-model-header-Checkbox {
  padding-top: 15px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important; }

#back-button-unsaved-changes-warning-leave, #cancel-button-unsaved-changes-warning-leave {
  margin-left: 40px !important;
  padding: 5px; }

#back-button-unsaved-changes-warning-stay, #cancel-button-unsaved-changes-warning-stay {
  margin-left: 60px !important;
  padding: 5px; }

.system-level-calculation-method-exit-changes-warning-description {
  font-size: 16px;
  text-align: center;
  padding: 20px; }

.system-level-calculation-method-exit-changes-warning-description-buttons {
  margin-left: 50px !important; }

.system-level-calculation-method-inverse-info-back-button {
  background: white;
  border: 1px solid #009999;
  color: #009999;
  padding: 8px 20px 8px 20px;
  text-align: center;
  margin-left: 20px;
  margin-right: 25px;
  font-weight: 700px !important;
  font-size: 16px; }

.system-level-review-and-save-pencil-icon {
  border: none;
  background-color: white;
  font-size: 18px; }

.system-level-review-and-save-label-questions {
  margin-bottom: 14px;
  font-family: 'SourceSansPro';
  font-weight: 600;
  size: 15px;
  color: #555555; }

.back-button-review-and-save {
  background: white !important;
  border: 1px solid #009999;
  color: #009999 !important;
  padding: 9px 30px 9px 30px !important;
  text-align: center !important;
  margin-left: 20px;
  margin-right: 25px;
  font-weight: 700px !important;
  font-size: 16px !important;
  border-radius: 0px !important; }

.system-level-create-deduction-button {
  padding: 10px 15px 10px 15px !important;
  border-radius: 0px !important; }

.system-level-review-and-save-cancel-button-unsaved-modal {
  max-width: 500px !important;
  height: 150px;
  top: -280px; }

.system-level-review-and-save-calculation-formula {
  background-color: #E9E9E9;
  padding: 15px 100px 18px 10px;
  float: left;
  width: 80%;
  display: table-cell; }
  @media (min-width: 320px) and (max-width: 767px) {
    .system-level-review-and-save-calculation-formula {
      padding: 15px 10px 18px 10px;
      float: left;
      width: 80%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .system-level-review-and-save-calculation-formula {
      padding: 15px 100px 18px 10px;
      float: left;
      width: 80%; } }

.system-level-review-and-save-green-calculator-icon {
  background-color: #26A977;
  padding: 15px 14px 15px 14px  !important;
  color: white;
  font-size: 16px;
  display: table-cell;
  vertical-align: middle;
  text-align: center; }
  .system-level-review-and-save-green-calculator-icon i {
    margin-right: 0px !important; }

.calculation-method-formula {
  display: table-row; }

.align-calculation-formula {
  display: table !important;
  vertical-align: middle; }

.system-level-review-save-cancel-button-modal-content {
  min-height: 200px !important;
  min-width: 900px !important;
  margin-left: -200px;
  top: 200px !important; }

.system-level-review-save-cancel-body-modal-data {
  text-align: center;
  font-size: 16px; }

.system-level-review-save-cancel-body-modal-close-icon {
  font-size: 22px;
  float: right; }

#system-level-review-save-cancel-button-close-icon {
  cursor: pointer; }

.cancel-modal-review-save-footer-buttons {
  margin-left: 40px !important;
  padding: 30px; }

#system-level-review-save-cancel-button-unsaved-changes-warning-save-changes {
  padding: 5px;
  margin-right: 50px !important;
  color: white;
  background-color: #00CCCC;
  border: none; }

#system-level-review-save-cancel-button-unsaved-changes-warning-continue-editing {
  padding: 5px;
  margin-right: 50px !important;
  color: white;
  background-color: #00CCCC;
  border: none; }

#system-level-review-save-cancel-button-unsaved-changes-warning-delete-deduction {
  padding: 5px;
  color: white;
  background-color: #00CCCC;
  border: none; }

.system-level-deduction-description-row {
  margin-bottom: 10px !important;
  margin-left: 3px !important; }

.system-level-type-of-deduction-row {
  margin-bottom: 10px !important;
  margin-left: 3px !important; }

.system-level-taxability-row {
  margin-bottom: 10px !important;
  margin-left: 3px !important; }

.system-level-advanced-tax-options-row {
  margin-bottom: 10px !important;
  margin-left: 3px !important; }

.system-level-calculation-method-row {
  margin-bottom: 10px !important;
  margin-left: 3px !important; }

.taxability-modal-deduction-description-page {
  text-align: center; }

.taxability-modal-link {
  color: #009999;
  cursor: pointer; }

#system-level-deduction-description-deduction-name-close-icon {
  float: right;
  font-size: 18px;
  cursor: pointer; }

#system-level-cancel-button-close-icon {
  cursor: pointer; }

.system-level-calculator-row {
  margin-bottom: 10px !important;
  margin-left: 3px !important; }

.system-level-deduction-cycle-row {
  margin-bottom: 10px !important;
  margin-left: 3px !important; }

.system-level-extra-paychecks-row {
  margin-bottom: 10px !important;
  margin-left: 3px !important; }

.system-level-payroll-runs-row {
  margin-bottom: 10px !important;
  margin-left: 3px !important; }

.system-level-default-value-row {
  margin-bottom: 10px !important;
  margin-left: 3px !important; }

.select-status-dropdown {
  width: 22.8% !important; }

.system-level-listing-page-thrash-icon {
  color: #7B7B7B;
  font-size: 16px;
  cursor: pointer; }

#status-content-popover-body {
  min-width: 180px;
  margin-left: -5px; }

.system-level-deduction-listing-status-inprogress-background-color {
  background-color: #FFFFCC;
  width: 100px;
  font-style: normal !important;
  padding: 5px 0px 5px 12px;
  border-radius: 4px; }

#system-level-deduction-listing-status-inprogress-background-color {
  font-style: normal !important; }

.system-level-deduction-listing-garnishment-category {
  background-color: white;
  width: 100px;
  font-style: normal !important; }

.system-level-deduction-listing-insurance-category {
  background-color: white;
  width: 100px;
  font-style: normal !important; }

.system-level-deduction-listing-medical-insurance-category {
  background-color: white;
  width: 200px;
  font-style: normal !important; }

.system-level-deduction-listing-retirement-category {
  background-color: white;
  width: 100px;
  font-style: normal !important; }

.system-level-deduction-listing-retirement-loan-category {
  background-color: white;
  width: 100px;
  font-style: normal !important; }

#system-level-deduction-listing-other-category {
  background-color: white;
  width: 100px;
  font-style: normal !important; }

.system-level-deduction-listing-status-active-background-color {
  width: 50px;
  background-color: #CCFFCC;
  padding: 5px 50px 5px 10px;
  border-radius: 4px; }

#system-level-deduction-listing-status-active-background-color {
  font-style: normal !important; }

.system-level-deduction-listing-status-inactive-background-color {
  max-width: 80px !important;
  background-color: #CCFFCC;
  padding: 5px 10px 5px 10px !important;
  border-radius: 4px; }

#system-level-deduction-listing-status-inactive-background-color {
  font-style: normal !important; }

.table-notification .table-notification-content {
  border-bottom: 1px solid #E5E5E5 !important; }
  @media (min-width: 1400px) {
    .table-notification .table-notification-content {
      max-width: 1475px !important; } }
  @media (min-width: 1700px) {
    .table-notification .table-notification-content {
      max-width: 1750px !important; } }

.table-notification-content {
  margin-left: -27px !important; }

.table-notification-header {
  font-weight: bold;
  padding-bottom: 20px;
  margin-left: -27px !important; }

.system-level-description-cancel-button-modal-content {
  min-height: 200px !important;
  min-width: 900px !important;
  margin-left: -200px;
  top: 300px !important; }

.system-level-deduction-description-taxability-section-popover {
  font-size: 15px;
  padding-bottom: 3px; }

.system-level-deduction-description-deduction-name-section-popover {
  font-size: 15px;
  padding-bottom: 3px; }

#system-level-deduction-description-taxability-category-name-close-icon {
  font-size: 18px;
  float: right;
  cursor: pointer; }

.info-inverse-save-and-continue-system-level-deduction-description {
  background-color: #00CCCC !important;
  color: white  !important;
  margin-left: 20px;
  border: none !important;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  margin-top: 20px !important;
  font-weight: bold; }

.system-level-deduction-description-footer-button-group {
  margin-left: -20px;
  margin-top: -25px; }

.open-deduction-description-new-info-popover {
  background-color: #E9E9E9 !important;
  margin-top: 20px !important;
  padding: 10px !important;
  border: 1px solid black !important;
  width: 832px !important;
  height: 60px !important; }

.open-deduction-description-new-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 805px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.open-deduction-description-info-popover-data {
  padding: 10px;
  margin-bottom: 10px !important;
  margin-top: -30px !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600 !important;
  color: #555555 !important; }

.open-deduction-description-Taxability-info-popover {
  background-color: #E9E9E9 !important;
  margin-top: 20px !important;
  padding: 10px !important;
  border: 1px solid black !important;
  width: 832px !important;
  height: 450px !important;
  margin-bottom: 20px;
  margin-left: 15px !important; }

.open-deduction-description-Taxability-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 805px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.open-deduction-description-info-popover-data {
  padding: 10px;
  margin-bottom: 10px !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600 !important;
  color: #555555 !important; }

#system-level-deduction-listing-name-column-value {
  color: #009999;
  font-weight: bold; }

.status-select-button {
  background-color: white;
  border-radius: 0 !important;
  border-color: #747474 !important;
  min-width: 190px; }

.system-level-deduction-listing-main-delete-modal-footer-buttons {
  margin-top: 80px !important;
  margin-left: 20px; }

@media (min-width: 1200px) {
  .deduction-listing-page-hr-divider {
    max-width: 0000px;
    margin-left: -3px; } }

@media (min-width: 1400px) {
  .deduction-listing-page-hr-divider {
    min-width: 1460px;
    margin-left: -12px; } }

@media (min-width: 1700px) {
  .deduction-listing-page-hr-divider {
    max-width: 1730px;
    margin-left: -12px; } }

.system-level-listing-page-delete-icon-portion {
  float: right;
  margin-top: -20px; }

@media (min-width: 1700px) {
  .table-notification-content-status-cell {
    margin-right: 280px; } }

.table-notification-header-cell {
  cursor: pointer; }

@media (min-width: 1400px) {
  .system-level-listing-page-main-delete-modal {
    top: 250px !important; } }

@media (min-width: 1700px) {
  .system-level-listing-page-main-delete-modal {
    top: 250px !important;
    border-radius: 0px !important; } }

@media (min-width: 1400px) {
  #system-level-delete-status-panel {
    top: 250px !important; } }

@media (min-width: 1700px) {
  #system-level-delete-status-panel {
    top: 250px !important;
    border-radius: 0px !important; } }

.system-level-employee-amount-row {
  margin-bottom: 10px !important;
  margin-left: 3px !important; }

.system-level-carry-balance-forward-row {
  margin-bottom: 10px !important;
  margin-left: 3px !important; }

.system-level-delete-status-panel {
  position: fixed !important;
  max-width: 850px !important;
  top: 200px; }

.system-level-delete-deduction-modal-delete {
  position: fixed !important;
  max-width: 850px !important;
  top: 120px; }

.delete-inactivate-success-panel {
  position: fixed !important;
  max-width: 850px !important;
  top: 200px; }

.system-level-options-row {
  margin-bottom: 10px !important;
  margin-left: 3px !important; }

.system-level-enterprise-payee-row {
  margin-bottom: 10px !important;
  margin-left: 3px !important; }

.system-level-employee-deduction-row {
  margin-bottom: 10px !important;
  margin-left: 3px !important; }

.system-level-employer-deduction-row {
  margin-bottom: 10px !important;
  margin-left: 3px !important; }

#system-level-review-and-save-footer-section-buttons {
  margin-left: -20px; }

.system-level-review-and-save-footer-section {
  margin-left: -20px; }

.system-level-review-and-save-page-body {
  margin-left: -22px; }

#system-level-calculation-method-cancel-button-unsaved-changes-warning-yes {
  padding: 5px;
  margin-right: 30px; }

#system-level-calculation-method-cancel-button-unsaved-changes-warning-no {
  padding: 5px; }

.back-button-unsaved-changes-warning-description, .cancel-button-unsaved-changes-warning-description {
  margin-left: 180px !important;
  padding: 20px;
  font-size: 16px; }

.system-level-delete-status-panel-description {
  font-size: 16px;
  text-align: center;
  padding: 50px; }

.btn-inactivate-cancle-modal {
  background-color: white !important;
  color: #B7B7B7  !important;
  margin-left: 10px;
  border: none !important;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  margin-top: 20px !important; }

.delete-inactivate-panel {
  max-width: 5000px;
  top: 190px !important; }

#delete-inactivate-success-panel {
  top: 300px; }

.system-level-listing-page-inactivate-modal-content {
  min-height: 150px !important;
  min-width: 720px !important;
  margin-left: -89px;
  top: 100px;
  border-radius: 0px !important;
  box-shadow: none !important; }

.system-level-inactivate-modals-footer-buttons {
  margin-left: 50px !important; }

.system-level-listing-page-inactivate-success-modal-content {
  min-height: 150px !important;
  min-width: 720px !important;
  margin-left: -89px;
  top: -10px;
  border-radius: 0px !important;
  box-shadow: none !important; }

.system-level-listing-page-delete-success-modal-content {
  min-height: 180px !important;
  min-width: 720px !important;
  margin-left: -89px;
  top: 10px;
  border-radius: 0px !important;
  box-shadow: none !important; }

.company-level-inverse-info-back-button {
  background: white;
  border: 1px solid #009999;
  color: #009999;
  padding: 10px 20px 10px 20px;
  text-align: center;
  margin-left: 20px;
  margin-right: 25px;
  font-weight: 700px !important;
  font-size: 16px; }

.company-level-deduction-calcution-menu-badge {
  color: #555555 !important;
  font-weight: bold;
  font-size: 16px;
  margin-top: 22px;
  margin-bottom: 27px;
  margin-left: -2px; }

.company-level-deduction-calcution-definition {
  padding-left: 0px !important;
  width: 100%;
  margin-left: 22px !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555; }

.company-level-calculation-method-panel {
  padding-left: 0px;
  border: none;
  border-color: transparent !important;
  padding-top: 15px !important; }
  .company-level-calculation-method-panel div .panel-body {
    padding: 0px !important;
    margin-left: 20px !important; }

.company-level-label-questions-panel {
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px;
  margin-left: -10px; }

#system-level-calculation-method-save-continue-button {
  background-color: #00CCCC !important;
  color: white  !important;
  margin-left: 20px;
  border: none !important;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  margin-top: 20px !important;
  font-weight: bold; }

.system-level-calculation-method-cancel-button-modal-content {
  min-height: 200px !important;
  min-width: 900px !important;
  margin-left: -200px;
  top: 300px !important; }

.system-level-calculation-method-cancel-body-modal-data {
  text-align: center;
  font-size: 16px; }

.system-level-calculation-method-cancel-body-modal-close-icon {
  font-size: 22px;
  float: right; }

#system-level-calculation-method-cancel-button-close-icon {
  cursor: pointer; }

.cancel-modal-calculation-method-footer-buttons {
  margin-left: 40px !important;
  padding: 30px; }

#system-level-calculation-method-cancel-button-unsaved-changes-warning-save-changes {
  padding: 5px;
  margin-right: 50px !important;
  color: white;
  background-color: #00CCCC;
  border: none; }

#system-level-calculation-method-cancel-button-unsaved-changes-warning-continue-editing {
  padding: 5px;
  margin-right: 50px !important;
  color: white;
  background-color: #00CCCC;
  border: none; }

#system-level-calculation-method-cancel-button-unsaved-changes-warning-delete-deduction {
  padding: 5px;
  color: white;
  background-color: #00CCCC;
  border: none; }

.system-level-calculation-method-back-button-modal-content {
  min-height: 200px !important;
  min-width: 900px !important;
  margin-left: -200px;
  top: 300px !important; }

.system-level-calculation-method-back-body-modal-data {
  text-align: center;
  font-size: 16px; }

.system-level-calculation-method-back-body-modal-close-icon {
  font-size: 22px;
  float: right; }

#system-level-calculation-method-back-button-close-icon {
  cursor: pointer; }

.back-modal-calculation-method-footer-buttons {
  margin-left: 40px !important;
  padding: 30px; }

#system-level-calculation-method-back-button-unsaved-changes-warning-save-changes {
  padding: 5px;
  margin-right: 50px !important;
  color: white;
  background-color: #00CCCC;
  border: none; }

#system-level-calculation-method-back-button-unsaved-changes-warning-continue-editing {
  padding: 5px;
  margin-right: 50px !important;
  color: white;
  background-color: #00CCCC;
  border: none; }

#system-level-calculation-method-back-button-unsaved-changes-warning-delete-deduction {
  padding: 5px;
  color: white;
  background-color: #00CCCC;
  border: none; }

.open-calculation-method-info-popover-data {
  margin-top: -20px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600 !important;
  color: #555555 !important; }

.company-open-calculation-method-percentage-info-popover {
  background-color: #E9E9E9;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid black;
  width: 832px !important;
  height: 300px !important;
  margin-left: 0px; }

.open-calculation-method-hour-based-info-popover {
  background-color: #E9E9E9;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid black;
  width: 832px !important;
  height: 300px !important;
  margin-left: -20px !important; }

.open-calculation-method-percentage-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 804px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.open-calculation-method-other-info-popover-data {
  margin-top: -20px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600 !important;
  color: #555555 !important; }

.open-calculation-method-other-info-popover {
  background-color: #E9E9E9;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid black;
  width: 832px !important;
  height: 300px !important; }

.open-calculation-method-other-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 804px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.system-level-calculation-method-taxability-modal-link {
  color: #009999;
  cursor: pointer;
  font-style: italic !important; }

.general-ledger-account-type-section {
  padding-left: 0 !important; }

.general-ledger-form-label {
  font-weight: 600; }

.general-ledger-footer {
  border-top: 1px solid #ccc; }

.system-level-default-value-exit-changes-warning-description-buttons {
  margin-left: 50px !important; }

#system-level-default-value-cancel-button-unsaved-changes-warning-yes {
  padding: 5px;
  margin-right: 30px; }

#system-level-default-value-cancel-button-unsaved-changes-warning-no {
  padding: 5px; }

.system-level-default-value-page-container {
  margin-left: -35px !important; }

.system-level-default-value-bottom-button-divider {
  margin-bottom: -10px;
  margin-left: -10px; }

.system-level-default-value-page-header {
  margin-left: -15px; }

#system-level-default-value-save-continue-button {
  background-color: #00CCCC !important;
  color: white  !important;
  margin-left: 20px;
  border: none !important;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  margin-top: 20px !important;
  font-weight: bold; }

.account-gl-type-section {
  margin-left: 2px;
  margin-bottom: 20px; }

.account-gl-input-section {
  margin-bottom: 20px; }

.account-gl-modal-header {
  border-bottom: 0 !important; }

.general-ledger-account {
  padding: 10px 20px 10px 20px;
  width: 500px; }

.general-ledger-modal {
  height: 250px !important; }

.general-ledger-leave-stay-modal {
  height: 150px !important; }
  .general-ledger-leave-stay-modal .general-ledger-leave-stay-content {
    text-align: center;
    padding: 10px; }
  .general-ledger-leave-stay-modal .general-ledger-leave-stay-buttons {
    text-align: center; }

.general-ledger-save-btn {
  text-transform: uppercase;
  min-width: 143px;
  height: 35px;
  margin-top: 15px;
  font-size: 15px;
  font-weight: 600;
  background-color: #00cccc;
  border-color: transparent;
  margin-right: 10px; }

.system-level-review-and-save-exit-changes-warning-description {
  font-size: 16px;
  text-align: center;
  padding: 20px; }

#system-level-review-and-save-cancel-button-unsaved-changes-warning-yes {
  padding: 5px;
  margin-right: 60px !important; }

#system-level-review-and-save-cancel-button-unsaved-changes-warning-no {
  padding: 5px; }

.system-level-review-and-save-exit-changes-warning-description-buttons {
  margin-left: 40px !important; }

.system-level-default-value-inverse-info-back-button {
  background: white;
  border: 1px solid #009999;
  color: #009999;
  padding: 10px 20px 10px 20px;
  text-align: center;
  margin-left: -10px;
  margin-right: 25px;
  font-size: 16px; }

#system-level-default-value-back-button {
  font-weight: bold !important; }

.system-level-default-value-employee-amount-section {
  margin-left: -3px;
  color: #009966 !important;
  font-weight: 600 !important;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif !important; }

.system-level-default-value-cancel-button-unsaved-changes-modal {
  max-width: 550px !important;
  height: 150px;
  top: -280px; }

.system-level-default-value-exit-changes-warning-description {
  font-size: 16px;
  text-align: center;
  padding: 20px; }

.open-deduction-description-info-popover {
  background-color: #E9E9E9;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid black; }

.open-deduction-description-info-popover-data {
  padding: 10px;
  margin-bottom: 10px !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600 !important;
  color: #555555 !important; }

.open-deduction-description-w2box-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 805px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.open-deduction-description-w2box-info-popover {
  background-color: #E9E9E9 !important;
  margin-top: 20px !important;
  padding: 10px !important;
  border: 1px solid black !important;
  width: 832px !important;
  height: 100px !important;
  margin-left: 15px; }

.open-deduction-description-w2box-name-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 805px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.open-deduction-description-w2box-name-info-popover {
  background-color: #E9E9E9 !important;
  margin-top: 20px !important;
  padding: 10px !important;
  border: 1px solid black !important;
  width: 832px !important;
  height: 100px !important; }

.open-deduction-description-employer-contribution-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 805px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.open-deduction-description-employer-contribution-info-popover {
  background-color: #E9E9E9 !important;
  margin-top: 20px !important;
  padding: 10px !important;
  border: 1px solid black !important;
  width: 832px !important;
  height: 100px !important; }

.add_deduction_popup_panel_header {
  padding: 5px;
  font-size: 16px;
  font-weight: 700;
  color: #555555;
  background-color: #D1EAE5 !important;
  border: none;
  cursor: pointer; }

.add_deduction_popup_panel_link {
  padding-right: 15px !important; }

.add_deduction_popup_panel_body {
  border: none !important;
  box-shadow: none;
  margin-bottom: 10px; }
  .add_deduction_popup_panel_body .panel-body {
    padding: 15px 0px 0px 0px !important; }
    @media (min-width: 320px) and (max-width: 767px) {
      .add_deduction_popup_panel_body .panel-body {
        padding: 15px 0px 0px 0px !important; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .add_deduction_popup_panel_body .panel-body {
        padding: 15px 0px 0px 0px !important; } }

.add_deduction_popup_description {
  color: #555555;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 5px 5px 0px !important;
  margin: 0px 0px 0px !important; }

.add_deduction_popup_description_heading {
  color: #555555;
  font-size: 18px;
  font-weight: 700;
  padding-left: 25px !important;
  margin: 0px !important; }

.add_deduction_popup_divider_modal {
  size: 2px;
  height: 20px;
  color: #E5E5E5; }

.add_deduction_popup_description_main_div {
  padding: 10px 10px 10px 25px  !important; }

.add-deduction-setup-close-icon-position {
  float: right;
  font-size: 20px;
  padding-top: 0px !important;
  padding-right: 0px;
  height: 1px;
  width: 20px; }

.open-default-value-deduction-balance-info-popover-data {
  padding: 10px;
  margin-bottom: 30px !important;
  margin-top: -20px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600 !important;
  color: #555555 !important; }

.open-default-value-deduction-balance-info-popover {
  background-color: #E9E9E9;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid black;
  width: 832px !important;
  height: 220px !important;
  margin-left: 35px; }

.open-default-value-deduction-balance-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 804px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.system-level-default-value-back-button-modal-content {
  min-height: 200px !important;
  min-width: 900px !important;
  margin-left: -200px;
  top: 300px !important; }

.system-level-default-value-back-body-modal-data {
  text-align: center;
  font-size: 16px; }

.system-level-default-value-back-body-modal-close-icon {
  font-size: 22px;
  float: right; }

#system-level-default-value-back-button-close-icon {
  cursor: pointer; }

.back-modal-default-value-footer-buttons {
  margin-left: 40px !important;
  padding: 30px; }

#system-level-default-value-back-button-unsaved-changes-warning-save-changes {
  padding: 5px;
  margin-right: 50px !important;
  color: white;
  background-color: #00CCCC;
  border: none; }

#system-level-default-value-back-button-unsaved-changes-warning-continue-editing {
  padding: 5px;
  margin-right: 50px !important;
  color: white;
  background-color: #00CCCC;
  border: none; }

#system-level-default-value-back-button-unsaved-changes-warning-delete-deduction {
  padding: 5px;
  color: white;
  background-color: #00CCCC;
  border: none; }

.system-level-default-value-cancel-button-modal-content {
  min-height: 200px !important;
  min-width: 900px !important;
  margin-left: -200px;
  top: 300px !important; }

.system-level-default-value-cancel-body-modal-data {
  text-align: center;
  font-size: 16px; }

.system-level-default-value-cancel-body-modal-close-icon {
  font-size: 22px;
  float: right; }

#system-level-default-value-cancel-button-close-icon {
  cursor: pointer; }

.cancel-modal-default-value-footer-buttons {
  margin-left: 40px !important;
  padding: 30px; }

.open-deduction-description-new-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 805px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.open-deduction-description-new-info-popover {
  background-color: #E9E9E9 !important;
  margin-top: 20px !important;
  padding: 10px !important;
  border: 1px solid black !important;
  width: 832px !important;
  height: 100px !important;
  margin-bottom: 30px !important; }

.company-level-w2box-label-questions {
  padding-left: 25px !important;
  color: #5B5B5B;
  margin-top: 25px;
  margin-left: -12px;
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  margin-top: 33px !important; }

.company-level-deduction-description-footer-button-group {
  margin-left: -40px;
  margin-top: -25px; }

.deduction-description-name-div {
  width: 350px  !important;
  margin-bottom: 30px;
  margin-right: 23px; }

.deduction-description-name-lebal {
  width: 200px  !important;
  font-style: italic;
  padding-top: 7px !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  size: 14px;
  color: #999999; }

.cancel-button-unsaved-changes-warning-description-modal {
  height: 50px !important;
  margin-left: -300px; }

#system-level-default-value-cancel-button-unsaved-changes-warning-save-changes {
  padding: 5px;
  margin-right: 50px !important;
  color: white;
  background-color: #00CCCC;
  border: none; }

#system-level-default-value-cancel-button-unsaved-changes-warning-continue-editing {
  padding: 5px;
  margin-right: 50px !important;
  color: white;
  background-color: #00CCCC;
  border: none; }

#system-level-default-value-cancel-button-unsaved-changes-warning-delete-deduction {
  padding: 5px;
  color: white;
  background-color: #00CCCC;
  border: none; }

.add_deduction_popup_tab_body {
  padding-top: 20px; }

.add_deduction_popup_tab_div {
  padding-left: 0px;
  padding-right: 0px; }

.add_deduction_popup_tab {
  margin-top: 25px; }
  .add_deduction_popup_tab ul li.active > a {
    color: #555555;
    border-radius: 0px;
    margin-right: 5px;
    border-top: 4px solid #339966 !important; }
    @media (min-width: 320px) and (max-width: 767px) {
      .add_deduction_popup_tab ul li.active > a {
        border: 1px solid #AAAAAA !important;
        margin-bottom: 10px  !important;
        background-color: #CCFFFF !important;
        cursor: pointer; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .add_deduction_popup_tab ul li.active > a {
        border: 1px solid #AAAAAA !important;
        margin-bottom: 10px  !important;
        background-color: #CCFFFF !important;
        cursor: pointer; } }
  @media (min-width: 320px) and (max-width: 767px) {
    .add_deduction_popup_tab ul li {
      width: 100%  !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .add_deduction_popup_tab ul li {
      width: 50%  !important; } }
  .add_deduction_popup_tab ul li a {
    color: #555555;
    border-radius: 0px;
    margin-right: 5px;
    border-top: 1px solid #CCCCCC;
    border-right: 1px solid #CCCCCC;
    border-left: 1px solid #CCCCCC; }
    @media (min-width: 320px) and (max-width: 767px) {
      .add_deduction_popup_tab ul li a {
        border: 1px solid #AAAAAA !important;
        margin-bottom: 10px  !important;
        cursor: pointer; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .add_deduction_popup_tab ul li a {
        border: 1px solid #AAAAAA !important;
        margin-bottom: 10px  !important;
        cursor: pointer; } }

.add_deduction_popup_footer {
  margin-top: 10px; }

.add_deduction_popup_panel_custom_lable {
  color: #555555;
  font: bold;
  font-weight: 550;
  padding: 5px; }

#btn-deduction-modal-bootstrap {
  background: #00CCCC;
  letter-spacing: 0.5 px;
  color: white;
  margin: 10px 0px 12px 0px;
  padding: 5px 25px 5px 25px;
  font-size: 15px;
  border: none; }

.employer-expense-liability-accounts-description {
  margin-top: 10px;
  margin-bottom: 10px; }

.employer-expence-filter {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px; }

.employer-expence-liability-accounts-section {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #555; }
  .employer-expence-liability-accounts-section .employer-expence-liability-accounts-table {
    padding-left: 35px;
    padding-right: 35px; }
    .employer-expence-liability-accounts-section .employer-expence-liability-accounts-table .employer-expence-liability-accounts-row .employer-expence-liability-accounts-header-cell {
      color: #333;
      font-weight: 600;
      padding-top: 5px;
      padding-bottom: 5px;
      cursor: pointer; }
    .employer-expence-liability-accounts-section .employer-expence-liability-accounts-table .table-employer-expence-liability-accounts-content {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc; }

.company_level_default_value {
  padding-left: 0px !important;
  color: #009966 !important;
  font-weight: 600 !important;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif !important; }

.company_level_default_value_number_badge {
  padding: 14px 10px 10px 10px !important;
  height: 40px;
  width: 40px;
  border-radius: 100px !important;
  font-size: 15px !important;
  background-color: #555555 !important;
  margin-right: 5px; }

.TextInput-With-Sign {
  background: transparent !important;
  background-color: transparent !important; }

.company-level-default-value-contribution-tire {
  margin-left: 5px;
  font-weight: bold !important;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-left: 0px !important;
  background-color: white; }

.company-level-default-value-contribution-tire-WithGray {
  font-weight: bold !important;
  margin-top: 3px;
  margin-bottom: 10px;
  padding: 4px 0px 4px 0px !important;
  background-color: #E9E9E9; }
  @media (max-width: 991px) {
    .company-level-default-value-contribution-tire-WithGray {
      padding: 5px !important;
      margin-left: -10px; } }

.company-level-default-value-method-add-liability-option-link {
  padding-left: 0px !important;
  text-decoration: none !important;
  color: #009999 !important;
  cursor: pointer; }

.company-level-default-value-lable-quastion-model {
  margin-left: -5px !important;
  font-weight: bold !important;
  padding-left: -10px !important;
  padding-bottom: 10px !important;
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px; }

.company-level-default-value-lable-quastion {
  margin-left: -5px;
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px;
  padding-left: -10px !important; }

.company-level-default-value-lable-quastion-inside-div {
  padding-left: 8px !important;
  padding-bottom: 10px !important;
  padding-top: 10px;
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 20px;
  margin-left: -8px; }

.company-level-default-value-heading-lable-General-Ledger {
  font-weight: bold !important;
  padding-left: 0px !important;
  padding-bottom: 10px !important;
  padding-top: 10px;
  color: #5B5B5B; }

.company-level-default-value-lable-quastion-general-ledger {
  padding-top: 12px !important;
  padding-bottom: 5px !important;
  color: #5B5B5B; }

.company-level-default-value-exit-changes-warning-description {
  font-size: 16px;
  text-align: center;
  padding: 20px; }

.company-level-default-value-exit-changes-warning-description-buttons {
  margin-left: 50px !important; }

.company-level-default-value-menu-back-button {
  background-color: white;
  color: #009999;
  font-weight: bold;
  border: none;
  padding: 10px 10px 10px 10px;
  margin-left: -28px;
  margin-top: 10px;
  margin-bottom: 10px; }

.company-level-default-value-title {
  margin-left: -5px;
  background-color: #339966;
  padding: 15px 15px 15px 22px;
  color: white;
  font-weight: bold;
  margin-top: 36px;
  height: 46px; }

.company-level-default-value-menu-badge {
  padding-left: 0px !important;
  color: #555555 !important;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 27px;
  font-size: 16px;
  margin-left: 13px; }

.company-level-default-value-radio-btn {
  padding-left: 0px !important; }

.company-level-default-value-netchex-create-payment-radio-btn {
  margin-left: -15px !important; }

.company-level-default-value-definition {
  padding-left: 0px !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555; }

.company-level-default-value-bottom-button-divider {
  margin-bottom: -10px;
  margin-left: -20px; }

.company-level-default-value-inverse-info-back-button {
  background: white;
  border: 1px solid #009999;
  color: #009999;
  padding: 10px 20px 10px 20px;
  text-align: center;
  margin-left: -10px;
  margin-right: 25px;
  font-weight: 700px !important;
  font-size: 16px; }

.open-default-value-employee-contribution-info-popover-data {
  margin-top: -20px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600 !important;
  color: #555555 !important; }

.open-default-value-employee-contribution-info-popover {
  background-color: #E9E9E9;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid black;
  width: 832px !important;
  height: 150px !important;
  margin-left: 10px; }

.open-default-value-employee-contribution-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 804px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.open-default-value-employee-ytd-contribution-info-popover {
  background-color: #E9E9E9;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid black;
  width: 832px !important;
  height: 210px !important; }

.open-default-value-employee-ytd-contribution-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 804px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

#company-level-default-value-cancel-button-unsaved-changes-modal {
  max-width: 550px !important;
  height: 150px;
  top: -280px; }

.company-level-default-value-back-button-modal-content {
  min-height: 200px !important;
  min-width: 900px !important;
  margin-left: -200px;
  top: 250px !important; }

.company-level-default-value-back-body-modal-data {
  text-align: center;
  font-size: 16px; }

.company-level-default-value-back-body-modal-close-icon {
  font-size: 22px;
  float: right; }

.back-modal-default-value-footer-buttons {
  margin-left: 40px !important;
  padding: 30px; }

#company-level-default-value-back-button-unsaved-changes-warning-save-changes {
  padding: 5px;
  margin-right: 50px !important; }

#company-level-default-value-back-button-unsaved-changes-warning-continue-editing {
  padding: 5px;
  margin-right: 50px !important; }

#company-level-default-value-back-button-unsaved-changes-warning-delete-deduction {
  padding: 5px; }

.company-level-deduction-options-title {
  background-color: #339966;
  padding: 15px 15px 15px 22px;
  margin-left: -5px;
  color: white;
  font-weight: bold;
  margin-top: 36px;
  height: 46px; }

.company-level-deduction-options-menu-badge {
  color: #555555 !important;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 27px;
  margin-left: 11px;
  font-size: 16px; }

.company-level-deduction-options-enterprise-title, .company-level-deduction-options-vendor-title {
  color: #009966 !important;
  font-weight: 600 !important;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif !important; }

.company-level-label-enterprise-payee-question {
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px;
  margin-left: 15px;
  padding-left: 25px !important; }

.company-level-deduction-options-radio-buttons {
  font-weight: bold !important;
  margin-left: 20px; }

.company-level-deduction-options-select-enterprise-payee-dropdown {
  margin-left: 20px; }

.company-level-deduction-options-dropdown-title {
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px;
  padding-left: 35px !important; }

.company-level-deduction-options-add-new-enterprise-payee {
  color: #009999;
  font-weight: bold !important;
  margin-top: 5px; }

.company-level-deduction-options-vendor-title {
  font-weight: bold !important;
  color: #67BF9C; }

.company-level-deduction-options-vendor-category-employee-textbox {
  margin-left: 10px; }

.company-level-deduction-options-textbox-title {
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px;
  padding-left: 25px !important; }

.company-level-deduction-options-badge-number {
  padding: 14px 10px 10px 10px !important;
  height: 40px;
  width: 40px;
  border-radius: 100px !important;
  font-size: 15px !important;
  background-color: #555555 !important;
  margin-right: 5px; }

.company-level-default-value-cancel-button-modal-buttons-modal-content {
  min-height: 200px !important;
  min-width: 900px !important;
  margin-left: -200px;
  top: 250px !important; }

.company-level-default-value-cancel-body-modal-buttons-close-icon {
  font-size: 22px;
  float: right; }

.company-level-default-value-cancel-button-modal-div-1 {
  padding-left: 220px !important; }

.company-level-default-value-cancel-button-modal-div-2 {
  padding-left: 120px !important; }

.company-level-default-value-cancel-footer-modal-buttons {
  margin-left: 70px !important;
  padding: 30px; }

.company-level-review-and-save-label-questions {
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555; }

.company-level-review-and-save-menu {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  color: #009999;
  font-weight: bold; }

.company-level-review-and-save-title {
  background-color: #339966;
  padding: 15px 15px 15px 22px;
  margin-left: -5px;
  color: white;
  font-weight: bold;
  margin-top: 36px;
  height: 46px; }

.company-level-review-and-save-menu-badge {
  color: #555555 !important;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 27px;
  font-size: 16px;
  padding-left: 0px !important;
  margin-left: 5px; }

.company-level-review-and-save-badge-number {
  padding: 14px 10px 10px 10px !important;
  height: 40px;
  width: 40px;
  border-radius: 100px !important;
  font-size: 15px !important;
  background-color: #555555 !important;
  margin-right: 5px; }

.company-level-review-and-save-definition {
  padding-left: 0px !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555; }

.company-level-deduction-categary-row {
  margin-top: 10px;
  margin-bottom: 10px !important;
  padding-left: 0px !important; }

.company-level-review-and-save-pencil-icon {
  border: none;
  background-color: white;
  font-size: 18px; }

.company-level-review-and-save-green-calculator-icon {
  background-color: #26A977;
  padding: 10px;
  color: white;
  font-size: 16px; }

.company-level-review-and-save-calculation-formula {
  background-color: #E9E9E9;
  padding: 11px; }

.company-level-deduction-categary-default-value-row-1 {
  margin-top: 10px !important; }

.company-level-deduction-categary-default-value-row-2 {
  margin-top: 70px !important; }

.company-level-deduction-categary-default-value-row-3 {
  margin-top: 130px !important; }

.company-level-review-and-save-categary-default-value-label-questions {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  padding-left: 0px !important; }

.company-level-review-and-General-Ledger-value-label-questions {
  margin-top: 30px !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  padding-left: 0px !important; }

.company-level-review-and-General-Ledger-sub-div {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  padding-left: 0px !important; }

.company-level-review-and-save-General-Ledger-sub-div-label-questions {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  padding-left: 0px !important; }

.company-level-review-and-General-Ledger-sub-div-2 {
  padding-left: 0px !important;
  margin-top: 25px !important;
  margin-bottom: 35px !important; }

.company-level-review-and-save-General-Ledger-sub-div-2-label-questions {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555; }

.company-level-review-and-save-cancel-button-modal-buttons-modal-content {
  min-height: 200px !important;
  min-width: 900px !important;
  margin-left: -250px;
  top: 250px !important; }

.company-level-review-and-save-cancel-body-modal-buttons-close-icon {
  font-size: 22px;
  float: right; }

.company-level-review-and-save-cancel-button-modal-div-1 {
  padding-left: 220px !important; }

.company-level-review-and-save-cancel-button-modal-div-2 {
  padding-left: 120px !important; }

.company-level-review-and-save-cancel-footer-modal-buttons {
  margin-left: 70px !important;
  padding: 30px; }

.company-level-deduction-categary-option-row-1 {
  margin-top: 10px !important; }

.company-level-deduction-categary-option-row-2 {
  margin-top: 50px !important; }

.company-level-deduction-categary-option-row-3 {
  margin-top: 100px !important; }

.company-level-deduction-categary-option-row-4 {
  margin-top: 150px !important; }

.company-level-deduction-categary-option-row-5 {
  margin-top: 200px !important; }

.company-level-deduction-categary-option-row-6 {
  margin-top: 250px !important; }

.company-level-deduction-categary-option-row-7 {
  margin-top: 300px !important; }

.company-level-deduction-cycle-title {
  background-color: #339966;
  padding: 15px 15px 15px 22px;
  margin-left: -5px;
  color: white;
  font-weight: bold;
  margin-top: 36px;
  height: 46px; }

.company-level-deduction-cycle-definition {
  margin-left: 10px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555; }

.company-level-deduction-cycle-menu-badge {
  color: #555555 !important;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 27px;
  font-size: 16px;
  margin-left: -2px; }

.company-level-deduction-cycle-badge-number {
  padding: 14px 10px 10px 10px !important;
  height: 40px;
  width: 40px;
  border-radius: 100px !important;
  font-size: 15px !important;
  background-color: #555555 !important;
  margin-right: 5px; }

.company-level-deduction-cycle-label-title {
  font-weight: bold !important;
  color: #67BF9C; }

.company-level-cycle-label-question {
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px;
  margin-left: -12px; }

.company-level-deduction-cycle-radio-buttons {
  font-weight: bold !important;
  margin-left: -20px; }

.open-deduction-cycle-info-popover-data {
  margin-top: -20px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600 !important;
  color: #555555 !important; }

.open-calculation-method-info-popover-data {
  padding: 10px;
  margin-bottom: 10px !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600 !important;
  color: #555555 !important; }

.open-calculation-method-percentage-info-popover {
  background-color: #E9E9E9;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid black;
  width: 832px !important;
  height: 380px !important;
  margin-left: 0px;
  margin-bottom: 30px !important; }

.open-calculation-method-percentage-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 804px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.open-calculation-method-hour-based-info-popover {
  background-color: #E9E9E9;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid black;
  width: 832px !important;
  height: 380px !important; }

.open-calculation-method-hour-based-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 804px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.cancel-footer-modal-buttons {
  margin-left: 40px !important;
  padding: 30px; }

.cancel-body-modal-buttons {
  text-align: center;
  font-size: 16px; }

.cancel-button-modal-buttons-modal-content {
  min-height: 200px !important;
  min-width: 900px !important;
  margin-left: -200px;
  top: 300px; }

.cancel-body-modal-buttons-close-icon {
  float: right;
  font-size: 22px; }

#delete-success-close-icon {
  cursor: pointer;
  color: #555; }

.info-inverse-save-and-continue-company-level-deduction-description {
  background-color: #00CCCC !important;
  color: white  !important;
  margin-left: 20px;
  border: none !important;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  margin-top: 20px !important;
  font-weight: bold; }

.open-deduction-cycle-deduction-withheld-info-popover {
  background-color: #E9E9E9;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid black;
  width: 832px !important;
  height: 180px !important; }

#company-level-deduction-description-cancel-button-unsaved-changes-warning-save-changes {
  padding: 5px;
  margin-right: 50px !important; }

#company-level-deduction-description-cancel-button-unsaved-changes-warning-continue-editing {
  padding: 5px;
  margin-right: 50px !important; }

#company-level-deduction-description-cancel-button-unsaved-changes-warning-delete-deduction {
  padding: 5px; }

.taxability-modal-link {
  color: #009999;
  cursor: pointer; }

.company-level-advance-option-calculation-method-radio-buttons {
  font: bold;
  margin-top: 10px !important;
  padding-left: 0px !important; }

.company-level-calculation-method-other-btn-info-model {
  font-weight: lighter; }

.short-description-details {
  font-style: italic;
  color: #999;
  padding-top: 7px !important; }

.company-level-calculation-method-aca-show-div {
  padding-left: 0px !important;
  padding-top: 50px !important;
  margin-top: 100px !important;
  margin-left: -5px  !important; }

.company-level-calculation-method-aca-show-sub-div {
  padding-left: 0px !important;
  margin-top: 10px !important;
  margin-left: -5px !important; }

.open-deduction-cycle-withheld-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 804px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.open-deduction-cycle-additional-paycheck-info-popover {
  background-color: #E9E9E9;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid black;
  width: 832px !important;
  height: 200px !important; }

.open-deduction-cycle-additional-paycheck-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 804px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.payrolls-run-month-button {
  height: 50px;
  width: 50px;
  border-radius: 0px !important;
  font-size: 18px !important;
  font-weight: bold !important; }

.payrolls-run-month-button:hover {
  background-color: #019999;
  color: white; }

.company-level-listing-page-add-deduction-modal-content {
  margin-left: -150px;
  min-height: 100% !important;
  min-width: 800px !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .company-level-listing-page-add-deduction-modal-content {
      min-width: 100% !important;
      margin-left: 0px;
      top: 0px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .company-level-listing-page-add-deduction-modal-content {
      min-width: 100% !important;
      margin-left: 0px;
      top: 0px !important; } }

.company-level-default-value-TextInputWithSign {
  padding-left: 5px !important;
  border-radius: 0px !important; }

.company-level-default-value-ytd-lable-quastion-model {
  margin-left: -5px !important;
  font-weight: bold !important;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-left: -10px !important;
  padding-bottom: 10px !important;
  color: #5B5B5B; }

.company-level-default-value-ytd-radio-btn {
  margin-left: -5px !important; }

.company-level-default-value-third-party-radio-btn {
  margin-left: -15px !important; }

.company-level-default-value-employer-contribution-lable-quastion-model {
  margin-left: -10px !important;
  font-weight: bold !important;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-left: -10px !important;
  padding-bottom: 10px !important;
  color: #5B5B5B; }

.company-level-deduction-description-cancel-button-modal-div-save, .company-level-deduction-description-cancel-button-modal-div-edit {
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #00CCCC;
  color: white;
  border: none;
  font-size: 15px; }

.company-level-deduction-description-cancel-button-modal-div-delete {
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #00CCCC;
  color: white;
  border: none;
  font-size: 15px; }

.company-level-calculation-method-Custom-Earnings-Group-link {
  margin-top: 20px;
  font-weight: 1000 !important;
  text-decoration: none !important;
  color: #00CCCC !important;
  background-color: white;
  cursor: pointer;
  padding-top: 10px;
  padding-left: 0px !important;
  margin-left: 3px; }

.company-level-calculation-method-advance-option-link {
  padding-left: 0px !important;
  margin-bottom: 1000px !important;
  text-decoration: none !important;
  color: #00CCCC !important;
  cursor: pointer;
  margin-left: 3px; }

.company-level-custom-earnings-group-modal-content {
  min-height: 900px !important;
  min-width: 900px !important;
  margin-left: -200px;
  top: 50px !important; }

#company-level-custom-earnings-group-select-earning-groups-dropdown {
  margin-left: -50px; }

.company-level-custom-earnings-group-filter-btn {
  font-size: 15px !important;
  background-color: #00CCCC !important;
  border-radius: 0px !important;
  border: none !important; }
  @media (min-width: 1400px) {
    .company-level-custom-earnings-group-filter-btn {
      margin-left: -60px !important;
      padding: 6px 30px 9px 30px !important;
      margin-top: 1px; } }
  @media (min-width: 1700px) {
    .company-level-custom-earnings-group-filter-btn {
      margin-left: -65px !important;
      padding: 7px 30px 7px 30px !important; } }

.custom-earnings-group-all-checkbox-group {
  padding-top: 15px !important; }

.custom-earnings-group-checked-checkbox-group-badge {
  background-color: #009999 !important;
  padding: 8px 5px 5px 4px !important;
  height: 30px;
  width: 30px;
  border-radius: 100px !important;
  font-size: 15px !important;
  margin-right: 5px; }

.custom-earnings-group-checked-checkbox-group-badge-div {
  padding-bottom: 10px;
  color: #009999; }

.company-level-calculation-method-button-unsaved-changes-warning-modal-btn {
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #00CCCC;
  color: white;
  border: none;
  font-size: 15px; }

.company-level-calculation-method-back-footer-modal-buttons {
  margin-left: 70px !important;
  padding: 35px; }

.company-level-calculation-method-cancel-footer-modal-buttons {
  margin-left: 70px !important;
  padding: 35px; }

.company-level-deduction-cycle-back-button-modal-buttons-modal-content {
  min-height: 200px !important;
  min-width: 900px !important;
  margin-left: -200px;
  top: 250px !important; }

.company-level-deduction-cycle-back-body-modal-buttons-close-icon {
  font-size: 22px;
  float: right; }

.company-level-deduction-cycle-back-button-modal-div-1 {
  padding-left: 50px !important; }

.company-level-deduction-cycle-back-button-modal-div-2 {
  padding-left: 20px !important; }

.company-level-deduction-cycle-footer-modal-buttons {
  margin-left: 75px !important;
  padding: 30px; }

.company-level-deduction-cycle-button-unsaved-changes-warning-modal-btn {
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #00CCCC;
  color: white;
  border: none;
  font-size: 15px; }

.company-level-deduction-cycle-cancel-button-modal-div-1 {
  padding-left: 50px !important; }

.company-level-deduction-cycle-cancel-button-modal-div-2 {
  padding-left: 20px !important; }

.company-level-default-value-button-unsaved-changes-warning-modal-btn {
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #00CCCC;
  color: white;
  border: none;
  font-size: 15px; }

.company-level-deduction-option-back-body-modal-buttons-close-icon {
  font-size: 22px;
  float: right; }

.company-level-deduction-option-back-button-modal-div-1 {
  padding-left: 50px !important; }

.company-level-deduction-option-back-button-modal-div-2 {
  padding-left: 20px !important; }

.company-level-deduction-option-footer-modal-buttons {
  margin-left: 75px !important;
  padding: 30px; }

.company-level-deduction-option-button-unsaved-changes-warning-modal-btn {
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #00CCCC;
  color: white;
  border: none;
  font-size: 15px; }

.company-level-deduction-option-cancel-button-modal-div-1 {
  padding-left: 50px !important; }

.company-level-deduction-option-cancel-button-modal-div-2 {
  padding-left: 20px !important; }

.company-level-review-and-save-button-unsaved-changes-warning-modal-btn {
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #00CCCC;
  color: white;
  border: none;
  font-size: 15px; }

.company-level-review-and-save-back-button-modal-div-1 {
  padding-left: 50px !important; }

.company-level-review-and-save-back-button-modal-div-2 {
  padding-left: 20px !important; }

.company-level-review-and-save-footer-modal-buttons {
  margin-left: 75px !important;
  padding: 30px; }

.company-level-review-and-save-cancel-button-modal-div-1 {
  padding-left: 50px !important; }

.company-level-review-and-save-cancel-button-modal-div-2 {
  padding-left: 20px !important; }

.company-level-page-container {
  padding-bottom: 100px !important;
  overflow-x: hidden;
  margin-top: 10px !important;
  margin-left: 55px !important; }

.company-level-page-container-fluid {
  padding-bottom: 100px !important;
  overflow-x: hidden;
  margin-top: 10px !important; }

.open-calculation-method-other-btn-info-popover {
  background-color: #E9E9E9;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid black;
  width: 832px !important;
  height: 230px !important;
  margin-left: 45px; }

.open-calculation-method-other-btn-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 804px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

div label input {
  margin-right: 100px; }

body {
  font-family: sans-serif; }

#ck-button {
  margin: 0px !important;
  background-color: white;
  border-radius: 0px;
  overflow: auto;
  float: left; }
  #ck-button label {
    margin-bottom: 0; }

#ck-button label {
  float: left;
  width: 2em;
  border: 1px solid gray; }

#ck-button label span {
  text-align: center;
  padding: 8px 5px 8px 5px;
  display: block;
  border-radius: 0px; }

#ck-button label input {
  position: absolute; }

#ck-button input:hover + span {
  background-color: #CCFFFF; }

#ck-button input:checked + span {
  background-color: #009999;
  color: #fff; }

#ck-button input:checked:hover + span {
  background-color: #009999;
  color: #fff; }

#ck-button .hidden-checkbox {
  visibility: hidden; }

.contribution-tire-button-list-fa-information-blue {
  color: #009999;
  cursor: pointer;
  background-color: #E9E9E9; }

.company-level-create-deduction-button {
  padding: 10px 15px 10px 15px !important;
  border-radius: 0px !important; }

.info-inverse-back-company-level-deduction-options {
  background: white;
  border: 1px solid #009999;
  color: #009999;
  padding: 9px 20px 9px 20px;
  text-align: center;
  margin-left: -10px;
  margin-right: 20px;
  font-size: 16px; }

.info-inverse-save-and-continue-company-level-deduction-options {
  background-color: #00CCCC !important;
  color: white  !important;
  margin-left: 20px;
  border: none !important;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  margin-top: 20px !important;
  font-weight: bold; }

.info-inverse-back-company-level-deduction-calculation-method {
  background: white;
  border: 1px solid #009999;
  color: #009999;
  padding: 9px 20px 9px 20px;
  text-align: center;
  margin-left: -10px;
  margin-right: 20px;
  font-size: 16px; }

.info-inverse-save-and-continue-company-level-deduction-calculation-method {
  background-color: #00CCCC !important;
  color: white  !important;
  margin-left: 20px;
  border: none !important;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  margin-top: 20px !important;
  font-weight: bold; }

.info-inverse-back-company-level-deduction-description {
  background: white;
  border: 1px solid #009999;
  color: #009999;
  padding: 9px 20px 9px 20px;
  text-align: center;
  margin-left: -10px;
  margin-right: 20px;
  font-size: 16px; }

.info-inverse-save-and-continue-company-level-deduction-description {
  background-color: #00CCCC !important;
  color: white  !important;
  margin-left: 20px;
  border: none !important;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  margin-top: 20px !important;
  font-weight: bold; }

.info-inverse-back-company-level-deduction-cycle {
  background: white;
  border: 1px solid #009999;
  color: #009999;
  padding: 9px 20px 9px 20px;
  text-align: center;
  margin-left: -10px;
  margin-right: 20px;
  font-size: 16px; }

.info-inverse-save-and-continue-company-level-deduction-cycle {
  background-color: #00CCCC !important;
  color: white  !important;
  margin-left: 20px;
  border: none !important;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  margin-top: 20px !important;
  font-weight: bold; }

.info-inverse-save-and-continue-company-level-default-value {
  background-color: #00CCCC !important;
  color: white  !important;
  margin-left: 15px;
  border: none !important;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  margin-top: 20px !important;
  font-weight: bold; }

.info-inverse-back-company-level-default-value {
  background: white;
  border: 1px solid #009999;
  color: #009999;
  padding: 9px 20px 9px 20px;
  text-align: center;
  margin-left: -10px;
  margin-right: 20px;
  font-size: 16px; }

.info-inverse-back-company-level-review-and-save {
  background: white;
  border: 1px solid #009999;
  color: #009999;
  padding: 9px 20px 9px 20px;
  text-align: center;
  margin-left: -10px;
  margin-right: 20px;
  font-size: 16px; }

.info-inverse-save-and-continue-company-level-review-and-save {
  background-color: #00CCCC !important;
  color: white  !important;
  margin-left: 20px;
  border: none !important;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  margin-top: 20px !important;
  font-weight: bold; }

.info-inverse-save-and-continue-company-level-deduction-description {
  background-color: #00CCCC !important;
  color: white  !important;
  margin-left: 20px;
  border: none !important;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  margin-top: 20px !important;
  font-weight: bold; }

.company-level-footer-button {
  padding-left: 25px  !important; }

@media (min-width: 768px) {
  .deduction-step-list-margin-right {
    margin-left: -230px; } }

@media (min-width: 980px) {
  .deduction-step-list-margin-right {
    margin-left: -180px; } }

@media (min-width: 1200px) {
  .deduction-step-list-margin-right {
    margin-left: -110px; } }

@media (min-width: 1400px) {
  .deduction-step-list-margin-right {
    margin-left: -60px; } }

@media (min-width: 1700px) {
  .deduction-step-list-margin-right {
    margin-left: 0px; } }

.deduction-review-and-save-step-list-margin-right {
  margin-left: -9px  !important; }

.company-level-deduction-description-debit-section {
  margin-left: -25px  !important; }

.company-level-deduction-description-loan {
  margin-left: -15px  !important; }

.company-level-advanced-tax-options-group {
  padding: 10px 10px 10px 10px !important;
  color: #7D7D7D;
  margin-left: -27px; }

.company-level-advanced-tax-options-exclude-states {
  padding: 10px 10px 10px 10px !important;
  color: #7D7D7D;
  margin-left: -27px; }

.company-level-deduction-panel-description {
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px;
  margin-left: -13px !important; }

.company-level-label-questions {
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px; }

.company-level-label-questions-1 {
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px;
  margin-left: -12px; }

.company-level-calculation-method-percentage-lable-quastion {
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  color: #555555;
  size: 15px;
  margin-top: 33px;
  margin-left: -10px; }

.open-option--info-popover-data {
  padding: 10px;
  margin-bottom: 10px !important;
  margin-top: -30px !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600 !important;
  color: #555555 !important; }

.open-option-employee-info-popover {
  background-color: #E9E9E9 !important;
  margin-top: 20px !important;
  padding: 10px !important;
  border: 1px solid black !important;
  width: 832px !important;
  height: 160px !important; }

.open-option-employee-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 805px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.open-option-employer-info-popover {
  background-color: #E9E9E9 !important;
  margin-top: 20px !important;
  padding: 10px !important;
  border: 1px solid black !important;
  width: 832px !important;
  height: 110px !important; }

.open-option-employer-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 805px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.company-level-w2box-sub-section {
  margin-left: -13px; }

.system-level-deduction-cycle-definition {
  padding-left: 0px !important;
  margin-left: 10px;
  font-family: 'SourceSansPro';
  font-weight: 600;
  size: 15px;
  color: #555555; }

.system-level-deduction-cycle-badge-number {
  padding: 14px 10px 10px 10px !important;
  height: 40px;
  width: 40px;
  border-radius: 100px !important;
  font-size: 15px !important;
  background-color: #555555 !important;
  margin-right: 5px; }

.system-level-deduction-cycle-label-title {
  font-weight: bold !important;
  color: #67BF9C; }

#deduction-options-divider {
  margin-left: -15px !important; }

#deduction-cycle-divider {
  margin-left: -20px; }

#deduction-cycle-footer-divider {
  margin-left: -10px; }

.deduction-cycle-footer-section {
  margin-left: -10px; }

.company-level-default-value-footer-button {
  margin-left: 5px; }

.system-level-cycle-label-question {
  margin-bottom: 14px !important;
  font-family: 'SourceSansPro';
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px;
  margin-left: -30px;
  padding-left: 30px !important; }

.system-level-cycle-label-question-witheld-section {
  margin-bottom: 14px !important;
  font-family: "SourceSansPro" !important;
  font-weight: 600 !important;
  size: 15px !important;
  color: #555555 !important;
  margin-top: 33px !important;
  margin-left: -30px !important;
  padding-left: 30px !important; }

.system-level-payroll-rins-in-month-section {
  margin-left: -15px; }

.system-level-deduction-cycle-radio-buttons {
  font-weight: bold !important;
  margin-left: 20px;
  padding-left: 0px !important;
  margin-left: -5px !important; }

.system-level-deduction-cycle-witheld-radio-buttons {
  font-weight: bold !important;
  margin-left: 20px;
  padding-left: 0px !important;
  margin-left: -5px !important;
  margin-top: 14px !important; }

.open-calculation-method-info-popover-data {
  padding: 10px;
  margin-bottom: 10px !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600 !important;
  color: #555555 !important; }

.open-calculation-method-percentage-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 804px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.open-calculation-method-hour-based-info-popover {
  background-color: #E9E9E9;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid black;
  width: 832px !important;
  height: 380px !important; }

.open-calculation-method-hour-based-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 804px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.cancel-footer-modal-buttons {
  margin-left: 40px !important;
  padding: 30px; }

.cancel-body-modal-buttons {
  text-align: center;
  font-size: 16px; }

.cancel-button-modal-buttons-modal-content {
  min-height: 200px !important;
  min-width: 900px !important;
  margin-left: -200px;
  top: 300px; }

.cancel-body-modal-buttons-close-icon {
  float: right;
  font-size: 22px; }

#delete-success-close-icon {
  cursor: pointer; }

.info-inverse-save-and-continue-system-level-deduction-description {
  background-color: #00CCCC !important;
  color: white  !important;
  margin-left: 20px;
  border: none !important;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  margin-top: 20px !important;
  font-weight: bold; }

.taxability-modal-link {
  color: #009999;
  cursor: pointer; }

.open-deduction-cycle-additional-paycheck-info-popover {
  background-color: #E9E9E9;
  margin-top: 20px;
  padding: 10px 10px 100px 10px !important;
  border: 1px solid black;
  width: 832px !important;
  height: 150px !important;
  margin-left: 3px; }

.open-deduction-cycle-additional-paycheck-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  position: inherit;
  top: -25px !important;
  left: 804px !important;
  background-color: #019999;
  border: none !important;
  color: white; }

.payrolls-run-month-button {
  height: 50px;
  width: 50px;
  border-radius: 0px !important;
  font-size: 18px !important;
  font-weight: bold !important; }

.payrolls-run-month-button:hover {
  background-color: #019999;
  color: white; }

.system-level-deduction-cycle-back-button-modal-buttons-modal-content {
  min-height: 200px !important;
  min-width: 900px !important;
  margin-left: -200px;
  top: 250px !important; }

.system-level-deduction-cycle-back-body-modal-buttons-close-icon {
  font-size: 22px;
  float: right; }

.system-level-deduction-cycle-back-button-modal-div-1 {
  padding-left: 50px !important; }

.system-level-deduction-cycle-back-button-modal-div-2 {
  padding-left: 20px !important; }

.system-level-deduction-cycle-footer-modal-buttons {
  margin-left: 75px !important;
  padding: 30px; }

.system-level-deduction-cycle-button-unsaved-changes-warning-modal-btn {
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #00CCCC;
  color: white;
  border: none;
  font-size: 15px; }

.system-level-deduction-cycle-cancel-button-modal-div-1 {
  padding-left: 50px !important; }

.system-level--deduction-cycle-cancel-button-modal-div-2 {
  padding-left: 20px !important; }

div label input {
  margin-right: 100px; }

body {
  font-family: sans-serif; }

#ck-button {
  margin: 0px !important;
  background-color: white;
  border-radius: 0px;
  overflow: auto;
  float: left; }

#ck-button label {
  float: left;
  width: 2.2em; }

#ck-button label span {
  text-align: center;
  padding: 8px 5px 8px 5px;
  display: block;
  border-radius: 0px; }

#ck-button label input {
  position: absolute; }

#ck-button input:hover + span {
  background-color: #CCFFFF; }

#ck-button input:checked + span {
  background-color: #009999;
  color: #fff; }

#ck-button input:checked:hover + span {
  background-color: #009999;
  color: #fff; }

#ck-button .hidden-checkbox {
  visibility: hidden; }

.system-level-deduction-cycle-info-inverse-back-button {
  background: white;
  border: 1px solid #009999;
  color: #009999;
  padding: 10px 20px 10px 20px;
  text-align: center;
  margin-left: -10px;
  font-size: 16px; }

#system-level-deduction-cycle-save-continue-button {
  background-color: #00CCCC !important;
  color: white  !important;
  margin-left: 20px;
  border: none !important;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  margin-top: 20px !important;
  font-weight: bold; }

.deduction-step-list-margin-right-calculation-method {
  margin-left: -15px !important; }

.deduction-step-list-margin-right-default-value {
  margin-left: -20px !important; }

.deduction-step-list-margin-right-deduction-cycle {
  margin-left: -17px !important; }

.deduction-step-list-margin-right-options {
  margin-left: -20px !important; }

.deduction-page-badge-number {
  padding: 14px 10px 10px 10px !important;
  height: 40px;
  width: 40px;
  border-radius: 100px !important;
  font-size: 15px !important;
  background-color: #555555 !important;
  margin-right: 5px; }

.system-level-review-and-save-menu-badge {
  color: #555555 !important;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 27px;
  font-size: 16px;
  margin-left: -3px; }

.system-level-default-value-employee-amounts {
  margin-left: -20px; }

.deduction-page-name-badge-div {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  font-size: 18px;
  color: #555555;
  margin-left: 15px !important; }

.deduction-page-badge-number {
  padding: 14px 10px 10px 10px !important;
  height: 40px;
  width: 40px;
  border-radius: 100px !important;
  font-size: 15px !important;
  background-color: #555555 !important;
  margin-right: 5px; }

.system-level-review-and-save-menu-badge {
  color: #555555 !important;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 27px;
  font-size: 16px; }

.company-level-deduction-options-definition {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555; }

#company-level-label-questions-w2box {
  margin-bottom: 14px;
  color: #5B5B5B;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  margin-top: 33px !important; }

#deduction-review-save-divider {
  margin-top: 0px;
  margin-bottom: -10px;
  margin-left: -20px  !important; }

#deduction-review-save-footer-divider {
  margin-top: 0px;
  margin-bottom: -10px;
  margin-left: -30px  !important; }

.system-level-deduction-description-retirement-loan-loan-number-textbox {
  margin-bottom: 30px;
  margin-right: 23px; }

.hr-divider {
  margin-left: -25px; }

.label-question-calculation-method {
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px;
  margin-left: -22px; }

.calculation-method-radio-button {
  margin-left: -10px; }

.calculation-method-label-dropdown {
  margin-bottom: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555;
  margin-top: 33px;
  margin-left: -30px;
  padding-left: 25px !important; }

.add-deduction-step-sub-menu {
  color: #555555;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif !important;
  border-radius: 5px;
  width: 232 px;
  height: 40 px;
  list-style: none; }

#add-deduction-step-header {
  padding-top: 27px !important;
  padding-bottom: 27px !important;
  font-size: 20px;
  padding-left: 20px;
  color: #555555;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Source Sans Pro', sans-serif !important; }

.deduction-cycle-payroll-runs {
  margin-left: -20px !important; }

.company-level-deduction-description-span-9-div {
  padding-left: 0px  !important; }

.company-level-deduction-calculation-method-footer-button-group {
  margin-left: 6px; }

.calculation-method-other-earning-radio-button {
  font-weight: bold !important;
  margin-left: -28px !important;
  margin-top: 14px !important; }

.company-level-review-and-save-footer {
  margin-left: -5px !important; }

.company-level-review-and-save-page-span-9-div {
  margin-left: 7px; }

.listing-page-heading-section, .listing-page-heading-section-third-party {
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: -15px;
  margin-right: -15px; }
  .listing-page-heading-section .info-button, .listing-page-heading-section-third-party .info-button {
    background-color: white;
    border: 1px solid  #009999;
    color: #009999;
    font-weight: 600px;
    border-radius: 3px;
    float: right; }
  .listing-page-heading-section .info-button:hover, .listing-page-heading-section-third-party .info-button:hover {
    color: #009999 !important; }

.listing-page-heading-description-section .listing-page-heading-description-content, .listing-page-heading-description-section .listing-page-heading-description-content-third-party, .listing-page-heading-description-section .listing-page-heading-section-third-party-second {
  margin-top: 0px !important;
  font-weight: 400px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-size: 15px;
  line-height: 20px;
  color: #555555; }

.listing-page-heading-description-section .listing-page-middle-section-add-deduction-button .listing-page-add-deduction-button, .listing-page-heading-description-section .listing-page-middle-section-add-deduction-button .listing-page-add-deduction-button-third-party {
  padding: 11px 23px;
  background-color: #0077a0;
  border-radius: 3px;
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  float: right;
  margin-right: 5px; }

.deduction-dropdown-down-icon {
  padding-left: 10px;
  margin-right: -20px; }

.pageTitle, .pageTitle-third-party {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-size: 25px;
  color: #555555; }
  .pageTitle h1, .pageTitle-third-party h1 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }

.listing-page-container {
  margin-left: 45px !important; }
  .listing-page-container .listing-page-heading-section .deduction-step-history-button, .listing-page-container .listing-page-heading-section-third-party .deduction-step-history-button {
    min-width: 205px !important; }
  .listing-page-container .listing-page-heading-description-section {
    margin-left: -15px !important;
    margin-right: -15px !important; }
    .listing-page-container .listing-page-heading-description-section .listing-page-heading-description-content, .listing-page-container .listing-page-heading-description-section .listing-page-heading-description-content-third-party, .listing-page-container .listing-page-heading-description-section .listing-page-heading-section-third-party-second {
      margin-top: 10px;
      font-weight: 300;
      font-family: 'Source Sans Pro', sans-serif !important;
      font-size: 15px;
      line-height: 20px;
      color: #555555; }
    .listing-page-container .listing-page-heading-description-section .listing-page-middle-section-add-deduction-button .listing-page-add-deduction-button, .listing-page-container .listing-page-heading-description-section .listing-page-middle-section-add-deduction-button .listing-page-add-deduction-button-third-party {
      padding-left: 23px;
      padding-top: 11px;
      padding-bottom: 11px;
      background-color: #0077a0;
      border-radius: 3px;
      text-transform: uppercase;
      color: #fff;
      font-size: 16px; }
  .listing-page-container .listing-page-middle-section {
    margin-bottom: 20px; }
    .listing-page-container .listing-page-middle-section .listing-page-middle-section-title {
      padding-top: 10px; }
      .listing-page-container .listing-page-middle-section .listing-page-middle-section-title .page-sub-title {
        font-family: 'Source Sans Pro', sans-serif !important;
        font-size: 20px;
        color: #555555; }
    .listing-page-container .listing-page-middle-section .listing-page-middle-section-add-deduction-button .listing-page-add-deduction-button, .listing-page-container .listing-page-middle-section .listing-page-middle-section-add-deduction-button .listing-page-add-deduction-button-third-party {
      padding-left: 23px;
      padding-top: 11px;
      padding-bottom: 11px;
      background-color: #0077a0;
      border-radius: 3px;
      text-transform: uppercase;
      color: #fff;
      font-size: 16px; }
  .listing-page-container .listing-page-advance-filter-section {
    min-width: 20px !important;
    padding-left: 0;
    margin-top: 36px;
    padding-right: 5px !important; }
    .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section {
      background: #E9E9E9;
      height: 46px !important;
      margin-bottom: 20px;
      padding-top: 2px !important;
      display: table-row !important; }
      .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-showing {
        letter-spacing: 1px;
        display: table-cell !important; }
        @media (min-width: 1200px) {
          .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-showing {
            margin-bottom: 17px !important;
            margin-left: 0px !important;
            margin-right: 15px !important;
            padding-left: 0px !important;
            padding-bottom: 0px !important;
            padding-right: 0px !important;
            padding-top: 10px !important; } }
        @media (min-width: 1400px) {
          .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-showing {
            margin-bottom: 17px !important;
            margin-left: 0px !important;
            margin-right: 15px !important;
            padding-left: 0px !important;
            padding-bottom: 0px !important;
            padding-right: 0px !important;
            padding-top: 10px !important; } }
        @media (min-width: 1700px) {
          .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-showing {
            margin-bottom: 17px !important;
            margin-left: 0px !important;
            margin-right: 15px !important;
            padding-left: 0px !important;
            padding-bottom: 0px !important;
            padding-right: 0px !important;
            padding-top: 10px !important; } }
      .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section {
        padding-left: 0px;
        display: table-cell !important;
        border-spacing: 10px 0; }
        .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-deduction-dropdown {
          padding-left: 0px; }
          .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-deduction-dropdown .select-deduction .select-deduction-button {
            width: 252px;
            padding-left: 0;
            background-color: white;
            border-color: #747474;
            border-radius: 0px !important; }
            @media (min-width: 1400px) {
              .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-deduction-dropdown .select-deduction .select-deduction-button {
                margin-left: 10px; } }
            @media (min-width: 1600px) {
              .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-deduction-dropdown .select-deduction .select-deduction-button {
                margin-left: 20px; } }
            .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-deduction-dropdown .select-deduction .select-deduction-button .system-level-listing-page-deduction-dropdown-label {
              padding-top: 4px !important;
              padding-left: 3px !important;
              color: #555;
              font-weight: 400;
              font-size: 15px;
              margin-top: 0 !important;
              margin-left: 0 !important;
              margin-right: 0px; }
            .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-deduction-dropdown .select-deduction .select-deduction-button .advance-filter-cstm-badge {
              color: white;
              font-size: 15px;
              font-weight: 600;
              background-color: #009999;
              margin-right: 20px; }
              @media (min-width: 1400px) {
                .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-deduction-dropdown .select-deduction .select-deduction-button .advance-filter-cstm-badge {
                  margin-right: 20px; } }
              @media (min-width: 1700px) {
                .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-deduction-dropdown .select-deduction .select-deduction-button .advance-filter-cstm-badge {
                  margin-left: 10px; } }
              .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-deduction-dropdown .select-deduction .select-deduction-button .advance-filter-cstm-badge .deduction-dropdown-down-icon {
                background-color: green; }
        .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-category-dropdown .select-category .select-category-button {
          width: 252px;
          padding-left: 0px;
          background-color: white;
          border-color: #747474;
          border-radius: 0px !important; }
          @media (min-width: 1400px) {
            .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-category-dropdown .select-category .select-category-button {
              margin-left: -50px; } }
          @media (min-width: 1600px) {
            .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-category-dropdown .select-category .select-category-button {
              margin-left: -100px; } }
          .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-category-dropdown .select-category .select-category-button .system-level-listing-page-category-dropdown-label {
            padding-top: 4px !important;
            padding-left: 3px !important;
            color: #555;
            font-weight: 400;
            font-size: 15px;
            margin-top: 0 !important;
            margin-left: 0 !important;
            margin-right: 0px; }
          .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-category-dropdown .select-category .select-category-button .advance-filter-cstm-badge {
            color: white;
            font-size: 15px;
            font-weight: 600;
            margin-right: 20px;
            background-color: #009999;
            margin-right: 20px; }
            @media (min-width: 1400px) {
              .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-category-dropdown .select-category .select-category-button .advance-filter-cstm-badge {
                margin-right: 20px; } }
            @media (min-width: 1700px) {
              .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-category-dropdown .select-category .select-category-button .advance-filter-cstm-badge {
                margin-left: 10px; } }
            .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .select-category-dropdown .select-category .select-category-button .advance-filter-cstm-badge .category-dropdown-icon {
              padding-left: 3px; }
        .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .advance-filter-status-dropdown .role-select .status-select-button {
          background-color: white;
          border-color: #747474;
          width: 252px;
          padding-left: 0;
          border-radius: 0px !important; }
          @media (min-width: 1400px) {
            .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .advance-filter-status-dropdown .role-select .status-select-button {
              margin-left: -80px; } }
          @media (min-width: 1600px) {
            .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .advance-filter-status-dropdown .role-select .status-select-button {
              margin-left: -190px; } }
        .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .advance-filter-status-dropdown .role-select .system-level-listing-page-status-dropdown-label {
          padding-top: 4px !important;
          padding-left: 3px !important;
          color: #555;
          font-weight: 400;
          font-size: 15px;
          margin-top: 0 !important;
          margin-left: 0 !important; }
        .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .advance-filter-status-dropdown .role-select .advance-filter-cstm-badge {
          color: white;
          font-size: 15px;
          font-weight: 600;
          background-color: #009999;
          margin-right: 15px; }
          @media (min-width: 1400px) {
            .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .advance-filter-status-dropdown .role-select .advance-filter-cstm-badge {
              margin-right: 10px; } }
          @media (min-width: 1700px) {
            .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .advance-filter-status-dropdown .role-select .advance-filter-cstm-badge {
              margin-left: 1px; } }
          .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-grey-section .advance-filter-status-dropdown .role-select .advance-filter-cstm-badge .system-level-status-dropdown-icon {
            padding-left: 30px; }
      .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-button {
        margin-top: 3px;
        margin-left: 30px; }
        .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-button .btn-filter {
          background-color: #00cccc;
          padding: 5px 30px 8px 30px !important;
          border-radius: 0px;
          border: none !important; }
          @media (min-width: 1400px) {
            .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-button .btn-filter {
              margin-left: -150px; } }
          @media (min-width: 1700px) {
            .listing-page-container .listing-page-advance-filter-section .listing-page-advance-filter .advance-filter-section .advance-filter-button .btn-filter {
              margin-left: -315px; } }

.listing-page-grid-section {
  padding-top: 10px;
  padding-bottom: 0px !important;
  border-bottom: none !important;
  border: none; }
  .listing-page-grid-section .listing-page-grid-content .listing-page-grid-result {
    margin-bottom: 25px;
    margin-left: -15px !important;
    font-weight: 400;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-size: 15px;
    color: #555555; }
  .listing-page-grid-section .listing-page-grid-content .listing-page-grid-table {
    padding-left: 35px;
    padding-right: 35px; }
    .listing-page-grid-section .listing-page-grid-content .listing-page-grid-table .listing-page-grid-table-header {
      margin-bottom: 20px; }
      .listing-page-grid-section .listing-page-grid-content .listing-page-grid-table .listing-page-grid-table-header .listing-page-grid-table-header-cell {
        color: #333;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
        font-family: 'Source Sans Pro', sans-serif !important;
        font-weight: 700; }
      .listing-page-grid-section .listing-page-grid-content .listing-page-grid-table .listing-page-grid-table-header .listing-page-grid-table-header-category-cell {
        color: #333;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
        font-family: 'Source Sans Pro', sans-serif !important;
        font-weight: 700; }
      .listing-page-grid-section .listing-page-grid-content .listing-page-grid-table .listing-page-grid-table-header .listing-page-grid-table-header-status-cell {
        color: #333;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
        font-family: 'Source Sans Pro', sans-serif !important;
        font-weight: 700; }
    .listing-page-grid-section .listing-page-grid-content .listing-page-grid-table .listing-page-grid-table-data {
      border-bottom: 1px solid #ccc;
      margin-left: -35px !important;
      margin-top: 25px;
      padding-bottom: 15px;
      font-size: 15px; }
      .listing-page-grid-section .listing-page-grid-content .listing-page-grid-table .listing-page-grid-table-data .listing-page-grid-table-data-name-cell {
        font-family: 'Source Sans Pro', sans-serif !important;
        font-weight: 400;
        color: #009999;
        margin-left: -15px;
        font-size: 15px; }
      .listing-page-grid-section .listing-page-grid-content .listing-page-grid-table .listing-page-grid-table-data .listing-page-grid-table-data-cell {
        margin-left: 5px; }
      .listing-page-grid-section .listing-page-grid-content .listing-page-grid-table .listing-page-grid-table-data .listing-page-grid-table-data-status-cell .listing-page-grid-table-data-status-in-progress-cell-background-color {
        background-color: #FFFFCC;
        width: 100px;
        font-style: normal !important;
        border-radius: 4px;
        padding-top: 7px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 6px;
        padding-bottom: 7px;
        margin-top: -8px;
        text-align: center; }
      .listing-page-grid-section .listing-page-grid-content .listing-page-grid-table .listing-page-grid-table-data .listing-page-grid-table-data-status-cell .listing-page-grid-table-data-status-active-cell-background-color {
        background-color: #CCFFCC;
        width: 70px;
        font-style: normal !important;
        border-radius: 4px;
        padding-top: 7px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 7px;
        margin-top: -8px;
        text-align: center; }
      .listing-page-grid-section .listing-page-grid-content .listing-page-grid-table .listing-page-grid-table-data .listing-page-grid-table-data-status-cell .listing-page-grid-table-data-status-in-active-cell-background-color {
        background-color: #CCCCCC;
        width: 85px;
        font-style: normal !important;
        border-radius: 4px;
        padding-top: 7px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 7px;
        margin-top: -8px;
        text-align: center; }
      .listing-page-grid-section .listing-page-grid-content .listing-page-grid-table .listing-page-grid-table-data .listing-page-grid-table-data-cell-pencil-icon {
        float: right !important; }
        .listing-page-grid-section .listing-page-grid-content .listing-page-grid-table .listing-page-grid-table-data .listing-page-grid-table-data-cell-pencil-icon .system-level-listing-page-pencil-icon-portion .system-level-listing-page-pencil {
          color: #7b7b7b;
          color: #7B7B7B;
          font-size: 18px;
          cursor: pointer; }
        .listing-page-grid-section .listing-page-grid-content .listing-page-grid-table .listing-page-grid-table-data .listing-page-grid-table-data-cell-pencil-icon .system-level-deduction-description-reactivate-button {
          background: white;
          border: 1px solid #009999;
          color: #009999;
          padding: 6px 12px;
          text-align: center;
          border-radius: 3px; }

.show {
  display: table-cell !important; }

.company-level-page-deduction-header {
  margin-left: 10px; }

.company-level-deduction-options-footer-button {
  margin-left: -10px; }

.filter-select-dropdown {
  color: #555;
  font-weight: 400;
  font-size: 15px;
  display: table-cell !important; }
  .filter-select-dropdown div .filter-select-button {
    background-color: white;
    border-color: #747474;
    border-radius: 0px !important;
    display: table-cell !important; }
    .filter-select-dropdown div .filter-select-button .filter-select-label {
      padding-left: 15px !important;
      color: #555;
      font-weight: 400;
      font-size: 15px;
      margin-top: 0 !important;
      margin-left: -12px !important;
      margin-right: 0px;
      text-align: left !important; }
    .filter-select-dropdown div .filter-select-button .filter-select-badge {
      color: white;
      font-size: 15px;
      font-weight: 600;
      background-color: #009999;
      margin-right: 10px; }
      .filter-select-dropdown div .filter-select-button .filter-select-badge .filter-select-dropdown-icon {
        background-color: green; }
  .filter-select-dropdown div .show {
    display: table-cell !important; }

.filter-select-filter-button {
  display: table-cell !important; }
  .filter-select-filter-button button {
    background-color: #00cccc !important;
    border-color: transparent !important;
    border-radius: 3px !important;
    color: white !important;
    padding: 7px 20px !important; }

.system-level-all-page-title {
  margin-left: -5px;
  background-color: #339966;
  padding-top: 15px !important;
  padding-left: 22px !important;
  color: white;
  font-weight: bold;
  height: 46px !important; }

.system-level-all-page-menu-badge {
  padding-left: 0px !important;
  color: #555555 !important;
  font-weight: bold;
  margin-top: 22px !important;
  margin-bottom: 27px !important;
  font-size: 16px !important;
  margin-left: 10px !important; }

.system-level-below-menu-badge-definition {
  padding-left: 0px !important;
  margin-left: 10px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555; }

.system-level-all-page-badge-number {
  padding: 14px 10px 10px 10px !important;
  height: 40px;
  width: 40px;
  border-radius: 100px !important;
  font-size: 15px !important;
  background-color: #555555 !important; }

.system-level-all-label-questions {
  margin-left: -18px !important;
  margin-top: 33px;
  margin-bottom: 14px;
  color: #555555;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  font-size: 15px; }

.system-level-deduction-footer-buttons {
  margin-left: 10px !important; }

.system-level-all-page-title {
  margin-left: -5px;
  background-color: #339966;
  padding-top: 15px !important;
  padding-left: 22px !important;
  color: white;
  font-weight: bold;
  height: 46px !important; }

.system-level-all-page-menu-badge {
  padding-left: 0px !important;
  color: #555555 !important;
  font-weight: bold !important;
  margin-top: 22px !important;
  margin-bottom: 27px !important;
  font-size: 16px !important;
  margin-left: 10px !important; }

.system-level-below-menu-badge-definition {
  padding-left: 0px !important;
  margin-left: 10px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  size: 15px;
  color: #555555; }

.system-level-all-page-badge-number {
  padding: 14px 10px 10px 10px !important;
  height: 40px;
  width: 40px;
  border-radius: 100px !important;
  font-size: 15px !important;
  background-color: #555555 !important; }

.listing-page-heading-description-content-third-party {
  margin-left: 15px !important;
  padding-top: 11px !important; }

.listing-page-heading-section-third-party-second {
  margin-left: 1px !important;
  margin-top: -11px !important; }

.pageTitle-third-party {
  padding-top: 8px !important; }

.deduction-step-history-button-third-party {
  padding-top: 15px !important;
  margin-left: 8px; }

.listing-page-heading-section-third-party {
  margin-bottom: 6px !important; }

.listing-page-add-deduction-button-third-party {
  margin-left: 10px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 24px !important;
  padding-right: 24px !important; }

.listing-page-middle-section-add-deduction-button-third-party {
  margin-bottom: 7px !important; }

.add-deduction-popup-model {
  height: 1500px; }

.indent15 {
  padding-left: 15px !important; }

.add-deduction-page-title {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 600;
  font-size: 18px;
  color: #555555;
  float: left !important;
  padding-top: 3px !important; }

.add_deduction_popup_tab .tab-content .active {
  background-color: transparent !important;
  width: 100% !important; }

.add_deduction_popup_tab ul li.active {
  background-color: transparent !important; }

.toaster-message-container {
  font-weight: bold;
  text-align: center;
  padding-top: 20px; }

.tier-button {
  min-height: 25px !important;
  height: 25px !important; }

.toaster-container .fa-caret-right {
  color: #009999 !important; }

.fa-icon-disable {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

.__disabled-checkbox input[type="checkbox"]:disabled + label:before {
  border-color: #ccc;
  color: #e9e9e9; }

.__disabled-checkbox input[type="checkbox"] + label {
  color: #ccc !important; }

.disabled-cursor, .__disabled-checkbox input[type="checkbox"]:disabled + label:before, .__disabled-checkbox input[type="checkbox"] + label {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer !important; }

.disabled-reactivate-button {
  line-height: initial;
  height: 30px !important;
  padding: 06px 20px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  border: none !important;
  background: #e9e9e9 !important; }
  .disabled-reactivate-button:hover {
    cursor: no-drop !important;
    background-color: #e0dfdf !important; }

.fa-icon-disable {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

#add-thirdparty-popup-modal {
  overflow-x: hidden !important;
  overflow-y: auto !important; }

.deduction-code-label {
  color: #555;
  font-size: 16px; }

@media (max-width: 767px) {
  .company-tpp-buttonlink-cancel {
    margin-left: 0px !important; } }

@media (max-width: 991px) {
  .company-tpp-buttonlink-cancel {
    margin-left: 0px !important; } }

.deductions-page-footer-modal-button,
.deductions-page-footer-modal-button:hover {
  background-color: #00cccc !important;
  border-radius: 3px !important;
  text-transform: uppercase !important;
  color: #fff !important;
  font-size: 16px !important;
  border-color: transparent; }
  @media (min-width: 320px) and (max-width: 767px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-bottom: 5px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-bottom: 5px !important;
      margin-right: 5px !important; } }
  @media (min-width: 992px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-right: 5px !important;
      padding: 10px !important; } }

.company-level-listing-page-add-payee-modal-content {
  margin-left: -150px;
  min-height: 100% !important;
  min-width: 700px !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .company-level-listing-page-add-payee-modal-content {
      min-width: 100% !important;
      margin-left: 0px;
      top: 0px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .company-level-listing-page-add-payee-modal-content {
      min-width: 100% !important;
      margin-left: 0px;
      top: 0px !important; } }

.add-tpp-popup-tab-div {
  padding-right: 0px !important; }

.add-tpp-popup-tab {
  margin-top: 25px; }
  .add-tpp-popup-tab ul li.active > a {
    color: #555555;
    border-radius: 0px;
    margin-right: 5px;
    border-top: 4px solid #339966 !important; }
    @media (min-width: 320px) and (max-width: 767px) {
      .add-tpp-popup-tab ul li.active > a {
        border: 1px solid #aaaaaa !important;
        margin-bottom: 10px !important;
        background-color: #ccffff !important;
        cursor: pointer; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .add-tpp-popup-tab ul li.active > a {
        border: 1px solid #aaaaaa !important;
        margin-bottom: 10px !important;
        background-color: #ccffff !important;
        cursor: pointer; } }
  @media (min-width: 320px) and (max-width: 767px) {
    .add-tpp-popup-tab ul li {
      width: 100% !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .add-tpp-popup-tab ul li {
      width: 50% !important; } }
  .add-tpp-popup-tab ul li a {
    color: #555555;
    border-radius: 0px;
    margin-right: 5px;
    border-top: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-left: 1px solid #cccccc; }
    @media (min-width: 320px) and (max-width: 767px) {
      .add-tpp-popup-tab ul li a {
        border: 1px solid #aaaaaa !important;
        margin-bottom: 10px !important;
        cursor: pointer; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .add-tpp-popup-tab ul li a {
        border: 1px solid #aaaaaa !important;
        margin-bottom: 10px !important;
        cursor: pointer; } }

.remove-padding-right {
  padding-right: 0 !important; }

.select-payment-question {
  padding-left: 0 !important;
  padding-top: 0 !important; }

.add-tpp-popup-footer {
  margin-top: 10px; }

.add-popup-dropdown {
  margin-top: 20px !important; }

.indent25 {
  padding-left: 25px !important; }

@media (max-width: 767px) {
  .company-tpp-buttonlink-cancel {
    margin-left: 0px !important; } }

.yes-no-radio-buttons {
  margin-right: 10px !important;
  text-align: center; }
  .yes-no-radio-buttons .mobile-netchex-radio-button .mobile-netchex-radio-button-unselected,
  .yes-no-radio-buttons .mobile-netchex-radio-button .mobile-netchex-radio-button-selected {
    padding-left: 30px !important;
    padding-right: 30px !important; }

.third-party-deductions-page-title {
  margin-left: 0px !important; }

.deductions-page-footer-modal-button,
.deductions-page-footer-modal-button:hover {
  background-color: #00cccc !important;
  border-radius: 3px !important;
  text-transform: uppercase !important;
  color: #fff !important;
  font-size: 16px !important;
  border-color: transparent; }
  @media (min-width: 320px) and (max-width: 767px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-bottom: 5px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-bottom: 5px !important;
      margin-right: 5px !important; } }
  @media (min-width: 992px) {
    .deductions-page-footer-modal-button,
    .deductions-page-footer-modal-button:hover {
      margin-right: 5px !important;
      padding: 10px !important; } }

.third-party-input-info-message {
  font-style: italic !important;
  font-size: 14px !important;
  color: #999; }

.thirdparty-page-section-input-textbox {
  padding-left: 0px !important; }

.deduction-page-section-input-zipcode-textbox {
  height: 35px !important; }
  @media (min-width: 992px) {
    .deduction-page-section-input-zipcode-textbox {
      width: 20% !important; } }
  @media (max-width: 991px) {
    .deduction-page-section-input-zipcode-textbox {
      width: 30%; } }
  @media (max-width: 767px) {
    .deduction-page-section-input-zipcode-textbox {
      width: 20%; } }

#zip-state-info {
  padding-left: 20px !important; }

.list-page-edit-icon-disabled {
  color: #e9e9e9 !important;
  margin-right: 0px !important; }

.open-info-popover {
  background-color: #e9e9e9 !important;
  margin-top: 20px !important;
  padding: 10px !important;
  margin-bottom: 30px !important;
  height: 100% !important;
  margin-left: 20px !important; }
  @media (min-width: 320px) and (max-width: 479px) {
    .open-info-popover {
      width: 80% !important; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .open-info-popover {
      width: 80% !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .open-info-popover {
      width: 60% !important; } }
  @media (min-width: 992px) {
    .open-info-popover {
      width: 60% !important; } }

.open-info-popover-data {
  font-size: 14px;
  font-family: "Source Sans Pro", sans-serif !important;
  color: #555555 !important; }

.open-info-popover-btn-close {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  background-color: #019999 !important;
  border: none !important;
  color: white;
  top: -15px !important;
  position: absolute !important; }
  @media (min-width: 320px) and (max-width: 479px) {
    .open-info-popover-btn-close {
      margin-left: 96% !important; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .open-info-popover-btn-close {
      margin-left: 96% !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .open-info-popover-btn-close {
      margin-left: 96% !important; } }
  @media (min-width: 992px) {
    .open-info-popover-btn-close {
      margin-left: 96% !important; } }

@-moz-document url-prefix() {
  .open-info-popover-btn-close {
    border-radius: 50px !important;
    width: 30px;
    height: 30px;
    background-color: #019999 !important;
    border: none !important;
    color: white;
    top: -15px !important;
    position: absolute !important; }
    @media (min-width: 320px) and (max-width: 479px) {
      .open-info-popover-btn-close {
        margin-left: -5px !important; } }
    @media (min-width: 480px) and (max-width: 767px) {
      .open-info-popover-btn-close {
        margin-left: -5px !important; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .open-info-popover-btn-close {
        margin-left: -5px !important; } }
    @media (min-width: 992px) {
      .open-info-popover-btn-close {
        margin-left: -5px !important; } } }

@media (min-width: 768px) and (max-width: 991px) {
  .system-level-third-party-payee-cancel-button {
    width: 30% !important; } }

@media (min-width: 992px) {
  .system-level-third-party-payee-cancel-button {
    width: 30% !important; } }

.third-party-modal-close-icon {
  cursor: pointer;
  font-size: 22px;
  float: right; }

.add-popup-inline-block-radiobutton {
  display: inline; }
