.nc-page-container {
  height: 100% !important;
  position: relative !important;
  padding: 30px 55px !important;
  color: #555 !important;
  font-family: "Source Sans Pro", sans-serif !important;

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 10px !important;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding: 20px !important;
  }

  .pageTitle-container {
    padding-bottom: 25px;
    .pageTitle {
      float: left;
      font-size: 25px;
      font-weight: 400;

      @media (max-width: 767px) {
        float: initial;
        font-size: 25px;
        /* DEVIATION : This was added because visually it did not look good without this */
      }
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding-bottom: 20px;
    }
    @media (max-width: 767px) {
      padding-bottom: 10px;
    }
  }
}
.nc-page-container::before {
  display: table;
  content: " ";
}
.nc-page-container::after {
  display: table;
  content: " ";
  clear: both;
}
