body {
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 15px; }

.main-page-overlay-panel {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0; }
  .main-page-overlay-panel .default-overlay {
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 13; }

#root .app-main-page {
  margin-left: 67px;
  display: flex !important;
  min-height: calc(100vh - 133px);
  box-sizing: border-box; }
  @media (min-width: 1200px) {
    #root .app-main-page {
      min-height: calc(100vh - 138px); } }
  @media (min-width: 1400px) {
    #root .app-main-page {
      min-height: calc(100vh - 143px); } }

.app-side-menu-bar {
  z-index: 1; }

.app-work-page {
  width: 100%;
  min-height: inherit;
  display: inline-block;
  vertical-align: top; }

.banner {
  background: url(../images/topgreen.png);
  background-position: center;
  background-color: #26a977;
  background-repeat: no-repeat;
  background-size: cover;
  height: 213px;
  padding-top: 40px; }

.nc-logo {
  background: url(../images/whitelogo.png);
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  height: 69px;
  margin: 20px 20px; }

#login-alerts {
  background: #0f554d;
  color: white;
  height: 64px;
  padding: 10px 20px; }

.img-user-icon {
  border-radius: 50%;
  border: 1px solid grey; }

.advanceFilterChildCheckbox {
  padding: 5px 10px 15px 20px; }

.pageTitle {
  font-size: 28px;
  font-weight: 400;
  color: #555; }

.page-sub-title {
  font-size: 24px;
  font-weight: 400;
  color: #555;
  margin-top: 5px;
  margin-bottom: 5px; }

.page-container, .list-page-container {
  height: 100% !important;
  width: 100% !important;
  position: relative !important;
  padding: 30px 55px !important;
  color: #555 !important;
  font-family: "Source Sans Pro", sans-serif !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .page-container, .list-page-container {
      padding: 5px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .page-container, .list-page-container {
      padding: 10px !important; } }

.leaf-page-container {
  margin: 10px 10px 10px 7.5%;
  position: relative; }

.react-toggle--checked .react-toggle-track {
  background-color: #24a272 !important; }

.react-toggle-track {
  background-color: #e9e9e9 !important;
  width: 65px !important;
  height: 34px !important; }

.react-toggle-thumb {
  width: 32px !important;
  height: 32px !important; }

.react-toggle--checked .react-toggle-thumb {
  left: 32px !important; }

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px transparent !important;
  -moz-box-shadow: 0px 0px 3px 2px transparent !important;
  box-shadow: 0px 0px 2px 3px transparent !important; }

.cstm555icon {
  color: #555; }

.btn:focus {
  outline: none !important; }

.btn-cancel-link {
  padding: 10px 20px 14px 10px !important;
  color: #999 !important;
  background-color: white !important;
  font-size: 16px;
  text-align: center !important;
  font-weight: bold;
  margin-left: 30px !important;
  border: none !important;
  text-decoration: none !important; }

.btn-cancel-link:hover,
.btn-cancel-link:active,
.btn-cancel-link:visited {
  color: #555 !important;
  text-decoration: none !important; }

.title-band, .title-band-green {
  padding: 15px 0px 5px 22px;
  color: white;
  font-weight: bold;
  height: 46px;
  font-size: 16px;
  margin-left: 0px; }
  .title-band h3, .title-band-green h3 {
    font-size: 18px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important; }

.title-band-green {
  background-color: #339966; }

.title-text, .title-text-green, .title-text-grey {
  font-weight: bold;
  font-size: 16px !important;
  margin-left: 0px; }
  .title-text h3, .title-text-green h3, .title-text-grey h3 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }

.title-text-green {
  color: #009966; }

.title-text-grey {
  color: #ccc; }

.input-info-message {
  padding-left: 23px !important;
  vertical-align: middle !important; }

.indent10 {
  padding-left: 10px !important; }

.indent15 {
  padding-left: 15px !important; }

.text-link {
  color: #009999 !important;
  font-family: "Source Sans Pro", sans-serif !important; }

.text-link:hover {
  cursor: pointer;
  text-decoration: none !important; }

.main-action-button,
.main-action-button:hover {
  padding: 10px 25px !important;
  background-color: #337ab7 !important;
  border-radius: 3px !important;
  text-transform: uppercase !important;
  color: #fff !important;
  font-size: 16px !important;
  border-color: transparent; }

@media (min-width: 320px) and (max-width: 991px) {
  .mobile-main-action-button {
    width: 100% !important; } }

.secondary-action-button,
.secondary-action-button:hover {
  padding: 10px 25px !important;
  background-color: #00cccc !important;
  border-radius: 3px !important;
  text-transform: uppercase !important;
  color: #fff !important;
  font-size: 16px !important;
  border-color: transparent; }

@media (min-width: 320px) and (max-width: 991px) {
  .mobile-secondary-action-button {
    width: 100% !important; } }

.secondary-action-button-disabled,
.secondary-action-button-disabled:hover {
  padding: 10px 25px !important;
  background-color: #ccc !important;
  border-radius: 3px !important;
  text-transform: uppercase !important;
  color: #fff !important;
  font-size: 16px !important;
  border-color: transparent; }

.back-action-button,
.back-action-button:hover {
  padding: 10px 25px !important;
  background-color: #fff !important;
  border: 1px solid #009999 !important;
  border-radius: 3px !important;
  text-transform: uppercase !important;
  font-size: 16px !important;
  color: #009999 !important;
  margin-right: 30px !important; }

@media (min-width: 320px) and (max-width: 991px) {
  .mobile-back-action-button {
    width: 100% !important;
    margin-bottom: 5px !important;
    margin-right: 0px !important; }
    .mobile-back-action-button .back-action-button {
      margin-right: 0px !important; } }

.activity-history-button,
.activity-history-button:hover {
  background-color: white;
  border: 1px solid #009999 !important;
  color: #009999 !important;
  font-weight: 600px !important;
  border-radius: 3px !important;
  float: right;
  width: 100%; }

.Mobile-Job-By-Id-Notification {
  background-color: white;
  border: 1px solid #009999 !important;
  color: #009999 !important;
  font-weight: 600px !important;
  border-radius: 15px !important;
  float: left !important;
  width: 100% !important; }

.Mobile-Job-By-Id-Notification-Tab {
  background-color: white;
  border: 1px solid #009999 !important;
  color: #009999 !important;
  font-weight: 600px !important;
  border-radius: 15px !important;
  float: left !important;
  width: 100% !important; }

.buttonlink {
  padding: 10px 20px 14px 10px !important;
  color: #009999 !important;
  background-color: white !important;
  font-size: 16px;
  text-align: center !important;
  font-weight: 400;
  margin-left: 30px !important;
  border: none !important;
  text-decoration: none !important; }

.buttonlink:hover,
.buttonlink:active,
.buttonlink:visited {
  color: #009999 !important;
  text-decoration: none !important; }

@media (min-width: 320px) and (max-width: 991px) {
  .mobile-buttonlink {
    width: 50% !important;
    margin-left: 0px !important; } }

.buttonlink-cancel {
  padding: 10px 20px 14px 10px !important;
  color: #999 !important;
  background-color: white !important;
  font-size: 16px;
  text-align: center !important;
  font-weight: 400;
  margin-left: 30px !important;
  border: none !important;
  text-decoration: none !important; }

.buttonlink-cancel:hover,
.buttonlink-cancel:active,
.buttonlink-cancel:visited {
  color: #555 !important;
  text-decoration: none !important; }

@media (min-width: 320px) and (max-width: 991px) {
  .mobile-buttonlink-cancel {
    width: 50% !important;
    margin-left: 0px !important; } }

@media (min-width: 320px) and (max-width: 991px) {
  .mobile-buttonlink-cancel-100per {
    width: 100% !important;
    margin-left: 0px !important; } }

.ck-button {
  margin: 0px !important;
  background-color: white;
  border-radius: 0px;
  overflow: auto;
  float: left; }
  .ck-button .hidden-checkbox {
    visibility: hidden; }
  .ck-button label {
    margin-bottom: 0;
    float: left;
    width: 2em;
    border: 1px solid gray;
    cursor: pointer; }
    .ck-button label span {
      text-align: center;
      padding: 4px 2px 4px 2px;
      display: block;
      border-radius: 0px; }
    .ck-button label input {
      position: absolute; }
    .ck-button label input:hover + span {
      background-color: #ccffff; }
    .ck-button label input:checked + span {
      background-color: #009999;
      color: #fff; }
    .ck-button label input:checked:hover + span {
      background-color: #009999;
      color: #fff; }

.ck-button:hover {
  cursor: pointer; }

.page-toaster-center {
  width: 100%;
  align-content: center; }

.input-group .form-group {
  margin-bottom: 0px !important; }

.has-error .Select-control {
  border-color: #a94442; }

.__disabled-textbox input[type="text"] {
  border: 1px solid #ccc !important;
  background-color: #e9e9e9 !important;
  box-shadow: none; }
  .__disabled-textbox input[type="text"]:hover {
    cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

.has-error-radio-button-section .netchex-radio-button label {
  border: 1px solid #a94442 !important; }

.spinner-element-loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7); }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5); }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff; } }

@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7); }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5); }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff; } }

.remove-margin-left-right {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.border-bottom-1px {
  border-bottom: 1px solid #ccc; }

.cursor-move {
  cursor: move !important; }

.cursor-pointer {
  cursor: pointer !important; }

.cursor-not-allowed {
  cursor: not-allowed !important; }

.margin-right-lg-15px {
  margin-right: 15px; }

.align-center {
  text-align: center; }

@media screen and (min-width: 320px) and (max-width: 991px) {
  .buttonlink-cancel {
    margin-bottom: 10px !important;
    margin-left: 0px !important; }
  .secondary-action-button {
    margin-bottom: 10px !important; } }

.error-label {
  color: red; }

@media (min-width: 320px) and (max-width: 991px) {
  .nc-sort-filter-containser-section .nc-sort-filter-container {
    display: block;
    margin-bottom: 20px; }
  .nc-sort-filter-containser-section .nc-filter-container {
    display: none; } }

@media (min-width: 992px) {
  .nc-sort-filter-containser-section .nc-sort-filter-container {
    display: none; }
  .nc-sort-filter-containser-section .nc-filter-container {
    display: block; } }

@media (min-width: 320px) and (max-width: 991px) {
  .filter-dropdown-view-section .filter-mobile-dropdown-view {
    display: block; }
  .filter-dropdown-view-section .filter-desktop-dropdown-view {
    display: none; } }

@media (min-width: 992px) {
  .filter-dropdown-view-section .filter-mobile-dropdown-view {
    display: none; }
  .filter-dropdown-view-section .filter-desktop-dropdown-view {
    display: block; } }

.Loader {
  position: relative !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  .Loader .Loader__background {
    position: fixed !important; }

@media (min-width: 992px) and (max-width: 1400px) {
  .third-party-listing-page-filter .nc-filter-dropdown {
    width: 31% !important; } }

.nc-filter-container {
  clear: both; }
  @media (min-width: 992px) {
    .nc-filter-container {
      display: flex;
      align-items: center;
      padding-top: 6px;
      background-color: #e6e6e6;
      margin: 0px 0px 15px 0px; }
      .nc-filter-container .nc-filter-group {
        float: left;
        width: 86%; }
        .nc-filter-container .nc-filter-group .nc-filter-row {
          margin-top: 13px; }
          .nc-filter-container .nc-filter-group .nc-filter-row .company-field {
            width: 33%;
            float: left; }
        .nc-filter-container .nc-filter-group .nc-filter-row:first-child {
          margin-top: 0px; }
        .nc-filter-container .nc-filter-group .nc-filter-row::before {
          display: table;
          content: " "; }
        .nc-filter-container .nc-filter-group .nc-filter-row::after {
          display: table;
          content: " ";
          clear: both; } }
  @media (min-width: 992px) and (min-width: 481px) and (max-width: 992px) {
    .nc-filter-container .nc-filter-group {
      width: 100%; }
      .nc-filter-container .nc-filter-group .nc-filter-row .company-field {
        width: 50%;
        float: left; } }
  @media (min-width: 992px) and (min-width: 320px) and (max-width: 480px) {
    .nc-filter-container .nc-filter-group {
      width: 100%; }
      .nc-filter-container .nc-filter-group .nc-filter-row .company-field {
        width: 100%;
        float: left; } }
  @media (min-width: 992px) and (min-width: 992px) and (max-width: 1399px) {
    .nc-filter-container #filter-activity-entity {
      width: 30%; } }
  @media (min-width: 992px) and (min-width: 320px) and (max-width: 480px) {
    .nc-filter-container .nc-filter-dropdown {
      margin-bottom: 10px; } }
  .nc-filter-container .content-filler {
    height: 20px;
    background-color: #e9e9e9; }

.filter-select-badge {
  color: white;
  font-size: 15px;
  font-weight: 600;
  background-color: #009999 !important; }

.list-page-filter-row {
  display: table-row;
  border-spacing: 10px 0; }

.filter-title {
  font-size: 14px;
  letter-spacing: 1px;
  color: #555;
  padding-left: 15px;
  padding-top: 9px; }
  @media (min-width: 992px) {
    .filter-title {
      width: 11%;
      min-width: 100px; } }

.list-item-10-filter-panel {
  max-height: 340px !important;
  overflow-y: scroll; }

@media (min-width: 320px) and (max-width: 991px) {
  .filter-panel-list-section {
    display: none !important; } }

@media (min-width: 992px) {
  .filter-panel-list-section {
    display: block !important; } }

.filter-select-dropdown {
  color: #555;
  font-weight: 400;
  font-size: 15px;
  display: table-cell !important;
  padding-left: 0px !important;
  padding-right: 15px !important;
  width: 21% !important; }
  @media (min-width: 992px) {
    .filter-select-dropdown {
      min-width: 230px; } }
  .filter-select-dropdown div .filter-select-button {
    background-color: white;
    border-color: #747474;
    border-radius: 0px !important;
    display: table-cell !important;
    width: 100%; }
    .filter-select-dropdown div .filter-select-button .filter-select-label {
      color: #555;
      font-weight: 400;
      font-size: 15px;
      margin-top: 0 !important;
      margin-left: 0 !important;
      margin-right: 0px;
      text-align: left !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      min-width: 110px !important; }
    .filter-select-dropdown div .filter-select-button .filter-select-badge {
      color: white;
      font-size: 15px;
      font-weight: 600;
      background-color: #009999; }
  .filter-select-dropdown div .show {
    display: table-cell !important; }

.filter-select-content-popover {
  min-width: 120px !important; }
  .filter-select-content-popover div div label {
    display: table-cell !important;
    font-family: "Source Sans Pro", sans-serif !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important; }
  .filter-select-content-popover div div label:hover {
    cursor: pointer; }
  .filter-select-content-popover .arrow {
    display: none !important; }

.filter-select-content-popover.popover.bottom {
  margin-top: 0; }
  @media (min-width: 768px) and (max-width: 991px) {
    .filter-select-content-popover.popover.bottom {
      width: 16% !important; } }
  @media (min-width: 992px) and (max-width: 1060px) {
    .filter-select-content-popover.popover.bottom {
      width: 20.5% !important; } }
  @media (min-width: 1061px) and (max-width: 1114px) {
    .filter-select-content-popover.popover.bottom {
      width: 20% !important; } }
  @media (min-width: 1115px) and (max-width: 1199px) {
    .filter-select-content-popover.popover.bottom {
      width: 18.3% !important; } }
  @media (min-width: 1200px) and (max-width: 1299) {
    .filter-select-content-popover.popover.bottom {
      width: 17% !important; } }
  @media (min-width: 1300px) and (max-width: 1400) {
    .filter-select-content-popover.popover.bottom {
      width: 16.8% !important; } }
  @media (min-width: 1400px) and (max-width: 1600px) {
    .filter-select-content-popover.popover.bottom {
      width: 17.55% !important; } }
  @media (min-width: 1601px) {
    .filter-select-content-popover.popover.bottom {
      width: 18% !important; } }
  @media (min-width: 1920px) {
    .filter-select-content-popover.popover.bottom {
      width: 19% !important; } }

.filter-select-filter-button {
  display: table-cell !important; }
  .filter-select-filter-button button {
    background-color: #00cccc !important;
    border-color: transparent !important;
    border-radius: 3px !important;
    color: white !important;
    padding: 7px 20px !important; }

.list-page-container .list-page-filter-section {
  height: 50px;
  display: table;
  width: 100%;
  background-color: #e9e9e9;
  padding-top: 6px;
  padding-bottom: 6px; }

.list-page-container .list-page-grid-section .list-page-grid-result {
  font-weight: 400;
  font-size: 15px;
  color: #555555;
  padding: 0px !important;
  padding-top: 15px !important;
  padding-bottom: 20px !important; }

.list-page-container .list-page-grid-section .list-page-grid-table {
  padding: 0px !important; }
  .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-header {
    padding-left: 0px !important;
    padding-right: 0px !important; }
    .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-header .list-page-grid-table-header-cell {
      color: #333;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      cursor: pointer;
      font-family: "Source Sans Pro", sans-serif !important;
      font-weight: 700; }
  .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row {
    border-bottom: 1px solid #ccc;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    font-size: 15px;
    font-weight: 400; }
    .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .col-xs-12,
    .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .col-xs-11,
    .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .col-xs-10,
    .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .col-xs-9,
    .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .col-xs-8,
    .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .col-xs-7 .col-xs-6,
    .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .col-xs-5,
    .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .col-xs-4,
    .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .col-xs-3,
    .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .col-sm-9,
    .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .col-lg-10,
    .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .col-md-10 {
      padding-left: 0px !important;
      padding-right: 0px !important; }
    .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .list-page-grid-table-cell {
      padding-top: 25px;
      padding-bottom: 15px;
      padding-left: 0px !important;
      padding-right: 0px !important; }
      .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .list-page-grid-table-cell .list-page-grid-table-cell-status-inprogress {
        background-color: #ffffcc;
        width: 100px;
        font-style: normal !important;
        border-radius: 4px;
        padding-top: 7px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 6px;
        padding-bottom: 7px;
        margin-top: -8px;
        text-align: center; }
      .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .list-page-grid-table-cell .list-page-grid-table-cell-status-active {
        background-color: #ccffcc;
        width: 70px;
        font-style: normal !important;
        border-radius: 4px;
        padding-top: 7px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 6px;
        padding-bottom: 7px;
        margin-top: -8px;
        text-align: center; }
      .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .list-page-grid-table-cell .list-page-grid-table-cell-status-inactive {
        background-color: #cccccc;
        width: 85px;
        font-style: normal !important;
        border-radius: 4px;
        padding-top: 7px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 6px;
        padding-bottom: 7px;
        margin-top: -8px;
        text-align: center; }
    .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .list-page-grid-table-cell-actions {
      float: right;
      padding-top: 25px;
      padding-bottom: 15px;
      padding-left: 0px !important;
      padding-right: 20px;
      font-size: 18px;
      color: #555 !important;
      min-width: 120px; }
      .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .list-page-grid-table-cell-actions .list-page-edit-section,
      .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .list-page-grid-table-cell-actions .list-page-delete-section {
        display: inline-block;
        float: right; }
        .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .list-page-grid-table-cell-actions .list-page-edit-section .list-page-edit-icon,
        .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .list-page-grid-table-cell-actions .list-page-edit-section .list-page-delete-icon,
        .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .list-page-grid-table-cell-actions .list-page-delete-section .list-page-edit-icon,
        .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .list-page-grid-table-cell-actions .list-page-delete-section .list-page-delete-icon {
          color: #757575 !important; }
      .list-page-container .list-page-grid-section .list-page-grid-table .list-page-grid-table-row .list-page-grid-table-cell-actions .list-page-delete-section {
        margin-left: 35px; }

.modal-content {
  box-shadow: none !important; }

.system-level-listing-page-main-delete-modal .modal-dialog {
  width: 100% !important; }

.netchex-modal {
  padding-top: 0px !important;
  padding-bottom: 25px !important;
  padding-left: 0px !important;
  padding-right: 0px !important; }

.modal-close-icon {
  padding-right: 13px !important;
  padding-top: 13px !important;
  cursor: pointer;
  font-size: 22px;
  float: right; }
  .modal-close-icon i {
    float: right; }

.netchex-modal-message-title {
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 15px !important;
  padding-bottom: 10px !important;
  font-size: 18px !important;
  color: #555; }

.netchex-modal-message-body {
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 15px !important;
  padding-bottom: 25px !important;
  font-size: 18px !important;
  color: #555; }

@media (min-width: 320px) and (max-width: 767px) {
  .netchex-modal-message-footer {
    padding-left: 15px;
    padding-right: 15px; }
    .netchex-modal-message-footer .btn-cancel-link {
      margin-left: 0px !important;
      padding-top: 25px !important;
      padding-bottom: 0px !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .netchex-modal-message-footer {
    padding-left: 15px;
    padding-right: 15px; }
    .netchex-modal-message-footer .btn-cancel-link {
      padding-top: 10px !important; } }

@media (min-width: 992px) {
  .netchex-modal-message-footer {
    padding-left: 15px;
    padding-right: 15px; }
    .netchex-modal-message-footer .btn-cancel-link {
      padding-top: 10px !important; } }

.text-color-red {
  color: red !important; }
