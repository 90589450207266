.profile-card {
  padding: 15px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 2px;
  width: 270px;
  height: 400px;
  margin-bottom: 20px;
  margin-left: 30px; }
  .profile-card .profile-card-icon {
    margin-bottom: 20px; }
  .profile-card .title {
    font-size: 24px;
    margin-bottom: 5px; }
  .profile-card .description {
    color: #999;
    border-bottom: 1px solid #999;
    padding-bottom: 20px;
    margin-bottom: 20px; }
  .profile-card-body .list {
    list-style-type: none;
    padding-left: 0; }
    .profile-card-body .list .card-list-item {
      margin-bottom: 5px; }
      .profile-card-body .list .card-list-item a {
        font-weight: 600; }

.profile-card-horizontal {
  padding: 15px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 2px;
  height: 150px;
  width: 370px;
  margin-left: 30px; }
  .profile-card-horizontal .title {
    font-size: 24px; }
  .profile-card-horizontal .description {
    color: #999;
    padding-bottom: 20px; }
  .profile-card-horizontal-body .list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0; }
    .profile-card-horizontal-body .list .card-list-item {
      margin-bottom: 5px; }
      .profile-card-horizontal-body .list .card-list-item a {
        font-weight: 600; }
