.list-page-delete.modal {
  padding-left: 0px !important; }
  .list-page-delete.modal .modal-dialog {
    width: 700px; }
    @media (max-width: 767px) {
      .list-page-delete.modal .modal-dialog {
        margin: 20px;
        width: auto !important; } }
    .list-page-delete.modal .modal-dialog .modal-content {
      border-radius: 0px;
      box-shadow: none !important; }
      @media (min-width: 768px) {
        .list-page-delete.modal .modal-dialog .modal-content {
          padding: 30px; } }
      @media (max-width: 767px) {
        .list-page-delete.modal .modal-dialog .modal-content {
          padding: 20px; } }
      .list-page-delete.modal .modal-dialog .modal-content .modal-header {
        border-bottom: none;
        padding: 0px; }
        .list-page-delete.modal .modal-dialog .modal-content .modal-header .close-icon {
          text-align: right; }
          @media (min-width: 768px) {
            .list-page-delete.modal .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: -15px;
              margin-left: 15px;
              margin-bottom: 5px; } }
          @media (max-width: 767px) {
            .list-page-delete.modal .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: 0px;
              margin-left: 0px; } }
          .list-page-delete.modal .modal-dialog .modal-content .modal-header .close-icon i {
            cursor: pointer;
            margin-top: 0px;
            margin-right: 0px;
            color: #555; }
      .list-page-delete.modal .modal-dialog .modal-content .modal-body {
        color: #555;
        font-size: 18px;
        padding: 0px; }
      .list-page-delete.modal .modal-dialog .modal-content .modal-footer {
        border: none;
        padding: 0px;
        margin-top: 20px;
        text-align: center; }
        .list-page-delete.modal .modal-dialog .modal-content .modal-footer .button-group {
          width: 275px;
          margin-left: auto;
          margin-right: auto; }
          .list-page-delete.modal .modal-dialog .modal-content .modal-footer .button-group button {
            margin-right: 15px; }
            @media (max-width: 767px) {
              .list-page-delete.modal .modal-dialog .modal-content .modal-footer .button-group button {
                margin-bottom: 15px;
                margin-right: 0px; } }
          .list-page-delete.modal .modal-dialog .modal-content .modal-footer .button-group button:last-child {
            margin-bottom: 0px;
            margin-right: 0px; }
          @media (max-width: 767px) {
            .list-page-delete.modal .modal-dialog .modal-content .modal-footer .button-group {
              width: 100%; } }
        @media (max-width: 767px) {
          .list-page-delete.modal .modal-dialog .modal-content .modal-footer {
            padding: 0px; }
            .list-page-delete.modal .modal-dialog .modal-content .modal-footer button {
              width: 100%; } }
