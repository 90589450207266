.timestamp-column-header {
  text-align: right;
  padding-right: 0px; }

.timestamp-column-content {
  text-align: right;
  padding-right: 0px;
  font-size: 15px; }

.yellow-icon {
  color: #d1ca62; }

.orange-icon {
  color: #fb6b5b !important; }

.gray-icon {
  color: #555555 !important; }

.orange {
  color: #ffb52e;
  margin-left: 5px; }

.dot-icon {
  border-radius: 0% !important;
  color: #555555 !important;
  margin-top: 2px !important; }

.gray-dot-icon {
  background-color: #f4f4f4;
  border-radius: 0% !important;
  color: #555555 !important;
  margin-top: 2px !important; }

.cancel-request-icon {
  color: #fb6b5b !important; }

.convert-text {
  text-transform: lowercase; }

.product-name-container {
  display: flex;
  margin-left: 25px;
  margin-right: 20px;
  justify-content: center;
  align-items: baseline; }
  .product-name-container .product-name {
    font-weight: 600 !important;
    font-size: 15px !important;
    color: #555;
    display: table-cell;
    vertical-align: middle; }
    @media (max-width: 991px) {
      .product-name-container .product-name {
        font-size: 18px !important; } }
  @media (max-width: 767px) {
    .product-name-container {
      margin-left: 0px;
      font-size: 20px !important; } }

.user-name {
  display: table-cell;
  vertical-align: middle;
  padding-left: 15px; }
  @media (min-width: 320px) and (max-width: 991px) {
    .user-name {
      padding-left: 10px; } }

.nc-notification-table-item-container .notification-table-item {
  clear: both;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 320px) and (max-width: 991px) {
    .nc-notification-table-item-container .notification-table-item {
      padding-top: 10px; }
      .nc-notification-table-item-container .notification-table-item .notification-table-item:first-child {
        padding-top: 0px !important; }
      .nc-notification-table-item-container .notification-table-item .user-icon-horizontal {
        padding: 0px; } }
  .nc-notification-table-item-container .notification-table-item .holiday-image {
    display: table-cell;
    vertical-align: middle;
    width: 50px; }
    @media (min-width: 320px) and (max-width: 991px) {
      .nc-notification-table-item-container .notification-table-item .holiday-image {
        padding-right: 20px; } }
  .nc-notification-table-item-container .notification-table-item .align-center {
    display: flex;
    justify-content: center;
    align-items: center; }

@media (min-width: 320px) and (max-width: 991px) {
  .nc-notification-table-item-container .user-icon-horizontal {
    padding: 0px;
    margin-bottom: 15px; }
    .nc-notification-table-item-container .user-icon-horizontal .product-name-container {
      margin-left: 0px;
      margin-right: 0px; }
    .nc-notification-table-item-container .user-icon-horizontal .notification-table-item-details .item-buttons {
      margin-left: 0px; } }
    @media (min-width: 320px) and (max-width: 991px) and (max-width: 991px) {
      .nc-notification-table-item-container .user-icon-horizontal .notification-table-item-details .item-buttons {
        padding-bottom: 5px; } }

.nc-notification-table-item-container .notification-table-item-details {
  margin-top: 5px; }
  .nc-notification-table-item-container .notification-table-item-details .item-buttons {
    display: flex;
    margin-left: 25px; }
    .nc-notification-table-item-container .notification-table-item-details .item-buttons .details-link {
      padding-right: 20px; }
      @media (max-width: 768px) {
        .nc-notification-table-item-container .notification-table-item-details .item-buttons .details-link {
          margin-top: 0px !important;
          padding-right: 10px; } }
      .nc-notification-table-item-container .notification-table-item-details .item-buttons .details-link .primary-link-button {
        padding-top: 5px !important;
        padding-bottom: 0px !important; }
      .nc-notification-table-item-container .notification-table-item-details .item-buttons .details-link .button-icon {
        padding-left: 5px;
        font-size: 12px; }
    .nc-notification-table-item-container .notification-table-item-details .item-buttons .job-history-link {
      padding-top: 5px !important;
      padding-bottom: 0px !important;
      padding-left: 20px;
      padding-right: 20px;
      border-left: 1px solid #dbd6d6; }
      @media (max-width: 767px) {
        .nc-notification-table-item-container .notification-table-item-details .item-buttons .job-history-link {
          padding-left: 10px; } }
      @media (min-width: 767px) and (max-width: 991px) {
        .nc-notification-table-item-container .notification-table-item-details .item-buttons .job-history-link {
          padding-left: 10px !important;
          padding-right: 10px !important; } }
    @media (max-width: 767px) {
      .nc-notification-table-item-container .notification-table-item-details .item-buttons {
        margin-left: 0px; } }
  @media (max-width: 767px) {
    .nc-notification-table-item-container .notification-table-item-details {
      padding-top: 5px;
      padding-bottom: 10px; } }
  .nc-notification-table-item-container .notification-table-item-details .go-to-verification-link {
    display: flex; }
    @media (min-width: 992px) {
      .nc-notification-table-item-container .notification-table-item-details .go-to-verification-link {
        margin-left: 25px; } }
    .nc-notification-table-item-container .notification-table-item-details .go-to-verification-link .go-to-link {
      padding-top: 5px !important;
      padding-bottom: 0px !important;
      color: #009999;
      text-transform: none;
      cursor: pointer; }
    .nc-notification-table-item-container .notification-table-item-details .go-to-verification-link .button-icon {
      padding-left: 5px;
      font-size: 12px; }

@media (max-width: 991px) {
  .nc-notification-table-item-container .notification-status-container {
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px; }
  .nc-notification-table-item-container .notification-user-container {
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px; }
  .nc-notification-table-item-container .notification-timestamp-container {
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px; } }
  @media (max-width: 991px) and (max-width: 767px) {
    .nc-notification-table-item-container .notification-timestamp-container {
      padding-top: 0px; } }

#view-job-history-company-link {
  font-size: 25px; }

.nc-notification-table-item-container:first-child {
  padding-top: 20px; }

.nc-display-toggle-content {
  margin-left: 75px; }
  @media (min-width: 767px) {
    .nc-display-toggle-content {
      margin-top: 20px; } }

.nc-display-toggle-exception-content {
  margin-top: 5px; }
  @media (min-width: 992px) {
    .nc-display-toggle-exception-content {
      margin-left: 75px;
      margin-top: 20px; } }
  .nc-display-toggle-exception-content .display-toggle-exception-item {
    font-size: 15px;
    margin-bottom: 15px; }
    .nc-display-toggle-exception-content .display-toggle-exception-item .exception-item-select-all {
      padding: 0px 15px 15px 0px;
      display: flex; }
      .nc-display-toggle-exception-content .display-toggle-exception-item .exception-item-select-all .exception-item-submit {
        padding-left: 20px;
        color: #555;
        font-weight: 400; }
        .nc-display-toggle-exception-content .display-toggle-exception-item .exception-item-select-all .exception-item-submit a {
          color: #009999;
          text-transform: none;
          cursor: pointer; }
        .nc-display-toggle-exception-content .display-toggle-exception-item .exception-item-select-all .exception-item-submit a.disabled {
          color: #ccc;
          font-size: 15px;
          font-weight: 400;
          cursor: not-allowed; }
    .nc-display-toggle-exception-content .display-toggle-exception-item .exception-item-container {
      border: 1px solid #ccc;
      background: #fff; }
      .nc-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-items {
        display: flex;
        align-items: center;
        padding: 20px 0;
        margin: 0 20px;
        border-bottom: 1px solid #ccc; }
        .nc-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-items:nth-child(odd) {
          background-color: #fefefe; }
        .nc-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-items:last-child {
          border-bottom: none; }
        .nc-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-items label {
          margin-bottom: 0px; }
      @media (min-width: 768px) {
        .nc-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-item-content {
          margin-left: 15px; } }
      .nc-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-item-content .content-issue {
        padding-bottom: 10px;
        display: flex;
        align-items: baseline; }
      .nc-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-item-content .content-expection {
        display: flex;
        margin-bottom: 10px;
        margin-left: 20px; }
        .nc-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-item-content .content-expection .content-expection-bullet {
          height: 16px;
          display: inline-flex;
          align-items: center; }
        .nc-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-item-content .content-expection:last-child {
          margin-bottom: 0px; }
        .nc-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-item-content .content-expection i {
          font-size: 5px; }

.display-toggle-content {
  background-color: #f4f4f4;
  padding: 20px 30px !important; }
  @media (min-width: 768px) and (max-width: 991px) {
    .display-toggle-content {
      padding: 15px 20px !important;
      margin-top: 10px; } }
  @media (min-width: 320px) and (max-width: 767px) {
    .display-toggle-content {
      padding: 15px 10px !important; } }
  .display-toggle-content .display-toggle-content-title {
    font-size: 20px;
    font-weight: 600; }
    @media (max-width: 991px) {
      .display-toggle-content .display-toggle-content-title {
        margin-top: 0px !important;
        padding-bottom: 0px !important; } }
  .display-toggle-content .display-toggle-content-item {
    display: flex;
    font-size: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding-top: 20px; }
    @media (max-width: 767px) {
      .display-toggle-content .display-toggle-content-item {
        padding-bottom: 20px;
        padding-top: 20px; } }
    @media (min-width: 992px) {
      .display-toggle-content .display-toggle-content-item .display-toggle-content-left {
        display: flex;
        align-items: flex-start; } }
    @media (max-width: 991px) {
      .display-toggle-content .display-toggle-content-item .display-toggle-content-left .subscriber-company-name-mobile {
        padding-top: 10px; } }
    @media (min-width: 767px) and (max-width: 991px) {
      .display-toggle-content .display-toggle-content-item .toggle-message {
        margin-right: 5px !important; } }
    .display-toggle-content .display-toggle-content-item .display-toggle-content-right {
      text-align: right;
      align-content: flex-end;
      justify-content: flex-end;
      color: #009999; }
      @media (max-width: 767px) {
        .display-toggle-content .display-toggle-content-item .display-toggle-content-right {
          margin-left: 10px; } }
    .display-toggle-content .display-toggle-content-item .display-toggle-content-right-name {
      margin-right: 10px; }
  .display-toggle-content .display-toggle-content-item:first-child {
    padding-top: 0px !important; }
  .display-toggle-content .display-toggle-content-item:last-child {
    border-bottom: none; }
    @media (max-width: 767px) {
      .display-toggle-content .display-toggle-content-item:last-child {
        padding-bottom: 0px; } }

.nc-notification-table-item-container:last-child {
  border-bottom: none; }

.cancel-request-link-container {
  padding-left: 20px;
  border-left: 1px solid #dbd6d6; }

.cancel-request-link {
  color: #fb6b5b !important;
  background: none;
  border: none;
  font-size: 15px;
  font-weight: 400;
  padding: 5px 0px 0px 0px;
  text-transform: capitalize; }
  @media (max-width: 767px) {
    .cancel-request-link {
      padding: 0px; } }

.cancel-request-link:hover {
  color: #fb6b5b !important; }

.list-header-container {
  border-bottom: 1px solid #ccc;
  padding-bottom: 30px; }
  .list-header-container .list-header-job-title {
    font-size: 25px; }
    @media (max-width: 991px) {
      .list-header-container .list-header-job-title {
        margin-bottom: 20px; } }

.notification-user-icon-horizontal {
  padding: 0 !important; }
  .notification-user-icon-horizontal .notification-user-name {
    color: #555;
    display: table-cell;
    font-size: 15px;
    font-weight: 400;
    vertical-align: middle; }
    @media (min-width: 320px) and (max-width: 991px) {
      .notification-user-icon-horizontal .notification-user-name {
        padding-left: 10px; } }
  .notification-user-icon-horizontal .notification-user-image {
    display: table-cell;
    height: 38px;
    width: 38px; }
    .notification-user-icon-horizontal .notification-user-image img {
      height: 37px;
      border-radius: 24px; }

.listpage-user-image {
  display: table-cell;
  vertical-align: middle;
  border-radius: 50%;
  border: 1px solid white;
  padding: 5px; }
  @media (min-width: 320px) and (max-width: 991px) {
    .listpage-user-image {
      padding-right: 20px; } }

.listpage-user-icon-horizontal {
  display: table-row;
  padding: 16px 0px; }
  .listpage-user-icon-horizontal .listpage-user-icon-name {
    color: #555;
    display: table-cell;
    font-size: 15px;
    font-weight: 400;
    vertical-align: middle; }
    @media (min-width: 320px) and (max-width: 991px) {
      .listpage-user-icon-horizontal .listpage-user-icon-name {
        padding-left: 3px; } }
    .listpage-user-icon-horizontal .listpage-user-icon-name .user-type-name {
      padding-top: 5px;
      font-style: italic; }
  .listpage-user-icon-horizontal .listpage-user-icon-image {
    display: table-cell;
    vertical-align: middle;
    border-radius: 50%;
    border: 1px solid white;
    padding: 5px; }
    @media (min-width: 320px) and (max-width: 991px) {
      .listpage-user-icon-horizontal .listpage-user-icon-image {
        padding-right: 20px; } }
    .listpage-user-icon-horizontal .listpage-user-icon-image img {
      border-radius: 24px; }

.nc-job-history-container {
  margin-top: 30px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .nc-job-history-container {
      margin-top: none !important; } }

.back-button-link .primary-link-button {
  padding-top: 0px !important;
  padding-bottom: 30px !important; }
  @media (max-width: 767px) {
    .back-button-link .primary-link-button {
      padding-top: 15px !important;
      padding-bottom: 15px !important; } }

.status-column-content {
  font-size: 15px; }
  @media (min-width: 992px) {
    .status-column-content {
      padding-right: 20px !important; } }

@media (min-width: 992px) {
  .user-column-content {
    padding-right: 20px !important; } }

#back-to-all-notifications-button {
  padding-bottom: 10px !important; }

.root-main-page-overlay .app-main-page {
  position: initial; }
