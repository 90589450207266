.employee-info-entity-table {
  background-color: #e9e9e9;
  padding: 20px;
  clear: both;
  margin-bottom: 20px; }
  .employee-info-entity-table .entity-info-item-header {
    font-weight: 600;
    display: flex;
    align-items: flex-end; }
  .employee-info-entity-table .entity-info-item {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc; }
    .employee-info-entity-table .entity-info-item .entity-column {
      padding-right: 30px; }
  .employee-info-entity-table .footer {
    padding-top: 15px;
    color: #999; }
