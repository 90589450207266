.green {
  color: #339966; }

.red {
  color: #fb6b5b; }

.light-grey {
  color: #ccc; }

.notifications-progress-tracker {
  padding: 50px;
  align-content: stretch;
  display: flex;
  flex-direction: column; }
  @media (min-width: 992px) {
    .notifications-progress-tracker {
      height: 500px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .notifications-progress-tracker {
      padding: 0px 30px 30px 0px; } }
  @media (max-width: 767px) {
    .notifications-progress-tracker {
      padding: 0px 0px 30px 0px !important; } }
  .notifications-progress-tracker .progress-item {
    display: flex; }
    .notifications-progress-tracker .progress-item .icon-bar {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 25px; }
      .notifications-progress-tracker .progress-item .icon-bar .icon {
        font-size: 25px;
        margin-right: 0; }
        .notifications-progress-tracker .progress-item .icon-bar .icon:last-child {
          padding-bottom: 0; }
        .notifications-progress-tracker .progress-item .icon-bar .icon.fa-exclamation-triangle {
          margin-top: 2px; }
    .notifications-progress-tracker .progress-item .status-item {
      padding-left: 20px; }
      .notifications-progress-tracker .progress-item .status-item:last-child {
        margin-bottom: 0; }
      .notifications-progress-tracker .progress-item .status-item .status-name {
        font-size: 18px;
        font-weight: 600; }
      .notifications-progress-tracker .progress-item .status-item .date-time {
        padding: 5px 0px;
        color: #999; }
      .notifications-progress-tracker .progress-item .status-item .link a,
      .notifications-progress-tracker .progress-item .status-item .link i {
        color: #fb6b5b; }
  .notifications-progress-tracker .status-bar {
    display: flex;
    flex-direction: column;
    padding-left: 20px; }
  .notifications-progress-tracker .line-container {
    display: flex;
    flex-direction: column;
    height: 100px; }
    .notifications-progress-tracker .line-container .dashed-line {
      border-left: 2px dashed #ccc; }
    .notifications-progress-tracker .line-container .solid-line {
      border-left: 2px solid #555; }
    .notifications-progress-tracker .line-container .dotted-line {
      border-left: 2px dotted #ccc; }
  @media (max-width: 767px) {
    .notifications-progress-tracker {
      padding: 0px 15px 15px 15px;
      height: auto; } }
