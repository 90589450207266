.approve-actions-popover {
  font-family: "Source Sans Pro", sans-serif !important;
  margin-top: 0px !important;
  padding: 0; }
  .approve-actions-popover .arrow {
    display: none; }
  .approve-actions-popover .popover-content {
    padding: 0; }
  .approve-actions-popover .bulk-actions-list {
    padding: 15px 15px;
    width: 220px; }
    .approve-actions-popover .bulk-actions-list .bulk-item {
      margin-left: 5px;
      padding-bottom: 10px;
      font-size: 15px; }
      .approve-actions-popover .bulk-actions-list .bulk-item:last-child {
        padding-bottom: 0px; }
      .approve-actions-popover .bulk-actions-list .bulk-item:hover {
        cursor: pointer; }
  @media (max-width: 991px) {
    .approve-actions-popover {
      padding-top: 0px; } }
  @media (min-width: 992px) {
    .approve-actions-popover {
      padding-top: 5px; } }
