.nc-entity-page-container .pageTitle-container {
  padding-bottom: 2.5px; }
  @media (min-width: 768px) {
    .nc-entity-page-container .pageTitle-container {
      padding-bottom: 15px; } }

.nc-entity-page-container .page-description {
  font-size: 15px;
  line-height: 1.2;
  padding-bottom: 20px; }
  .nc-entity-page-container .page-description p {
    margin: 0px; }

.nc-entity-page-container .page-description-button {
  float: right; }
  .nc-entity-page-container .page-description-button button {
    float: right; }
    @media (max-width: 991px) {
      .nc-entity-page-container .page-description-button button {
        float: left; } }
  @media (max-width: 991px) {
    .nc-entity-page-container .page-description-button {
      float: left;
      margin-bottom: 20px; } }

.nc-entity-page-container .title-band-container {
  clear: both;
  margin-bottom: 30px; }
  @media (max-width: 991px) {
    .nc-entity-page-container .title-band-container {
      margin-bottom: 0; } }
  .nc-entity-page-container .title-band-container .title-band {
    background-color: #396;
    padding: 12px 0 10px 20px;
    color: #fff;
    font-weight: 400;
    height: 45px;
    font-size: 18px;
    margin-left: 0; }

@media (max-width: 991px) {
  .nc-entity-page-container .title-band-container-sm {
    margin-bottom: 15px; } }

.nc-entity-page-container .description-content {
  margin-top: 20px; }
  @media (max-width: 991px) {
    .nc-entity-page-container .description-content {
      margin-left: 0; }
      .nc-entity-page-container .description-content.review-and-save .field-name {
        margin-bottom: 5px; } }
  .nc-entity-page-container .description-content .fa-calculator {
    color: #fff !important; }
  @media (max-width: 768px) {
    .nc-entity-page-container .description-content .contains-single-link button {
      width: 100%; } }
  .nc-entity-page-container .description-content .deduction-name-input {
    margin-bottom: 10px; }
    .nc-entity-page-container .description-content .deduction-name-input i {
      margin-left: 5px;
      color: #009999; }
    .nc-entity-page-container .description-content .deduction-name-input .edit-icon a {
      float: right; }
      .nc-entity-page-container .description-content .deduction-name-input .edit-icon a i {
        color: #555; }
    .nc-entity-page-container .description-content .deduction-name-input .review-field {
      font-size: 15px;
      margin-bottom: 5px; }
    .nc-entity-page-container .description-content .deduction-name-input .input-field {
      margin-right: 0; }
    .nc-entity-page-container .description-content .deduction-name-input .input-field-sm {
      width: 80px; }
    .nc-entity-page-container .description-content .deduction-name-input .input-character {
      font-size: 15px;
      margin: 8px 0 0 15px;
      font-style: italic;
      color: #a7a4a4; }
    .nc-entity-page-container .description-content .deduction-name-input .side-info {
      font-size: 15px;
      margin: 8px 0 0 15px; }

.nc-entity-page-container::before {
  display: table;
  content: " "; }

.nc-entity-page-container::after {
  display: table;
  content: " ";
  clear: both; }

.showOnlyUnmapped input[type="checkbox"] + label:before {
  width: 18px;
  font-size: 18px; }
