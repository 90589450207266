.header-conainer {
  margin-bottom: 10px; }
  .header-conainer .utility-header {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -1px;
    padding: 10px 10px;
    border-right: 1px solid #dedede;
    display: flex;
    align-items: center; }
    .header-conainer .utility-header i {
      font-size: 40px;
      margin-right: 15px; }
  .header-conainer .utility-description {
    font-size: 15px;
    padding: 10px 50px; }

@media (max-width: 990px) {
  .header-conainer .utility-description {
    padding: 20px 10px; } }
