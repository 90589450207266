.nc-page-container {
  height: 100% !important;
  position: relative !important;
  padding: 30px 55px !important;
  color: #555 !important;
  font-family: "Source Sans Pro", sans-serif !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .nc-page-container {
      padding: 10px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .nc-page-container {
      padding: 20px !important; } }
  .nc-page-container .pageTitle-container {
    padding-bottom: 25px; }
    .nc-page-container .pageTitle-container .pageTitle {
      float: left;
      font-size: 25px;
      font-weight: 400; }
      @media (max-width: 767px) {
        .nc-page-container .pageTitle-container .pageTitle {
          float: initial;
          font-size: 25px;
          /* DEVIATION : This was added because visually it did not look good without this */ } }
    @media (min-width: 768px) and (max-width: 991px) {
      .nc-page-container .pageTitle-container {
        padding-bottom: 20px; } }
    @media (max-width: 767px) {
      .nc-page-container .pageTitle-container {
        padding-bottom: 10px; } }

.nc-page-container::before {
  display: table;
  content: " "; }

.nc-page-container::after {
  display: table;
  content: " ";
  clear: both; }

.modal#activity-history {
  padding-left: 0px !important; }
  .modal#activity-history .modal-dialog {
    width: 700px; }
    @media (max-width: 767px) {
      .modal#activity-history .modal-dialog {
        margin: 20px;
        width: auto !important; } }
    .modal#activity-history .modal-dialog .modal-content {
      border-radius: 0px;
      box-shadow: none; }
      .modal#activity-history .modal-dialog .modal-content hr {
        margin-top: 0px;
        margin-bottom: 20px !important; }
      @media (min-width: 768px) {
        .modal#activity-history .modal-dialog .modal-content {
          padding: 30px; } }
      @media (max-width: 767px) {
        .modal#activity-history .modal-dialog .modal-content {
          padding: 20px; } }
      .modal#activity-history .modal-dialog .modal-content .modal-header {
        border-bottom: none;
        padding: 0px; }
        .modal#activity-history .modal-dialog .modal-content .modal-header .modal-title {
          font-weight: 600;
          padding-bottom: 5px; }
        .modal#activity-history .modal-dialog .modal-content .modal-header .modal-title-description {
          font-size: 12px;
          margin-bottom: 20px; }
        .modal#activity-history .modal-dialog .modal-content .modal-header .close-icon {
          cursor: pointer;
          text-align: right; }
          @media (min-width: 768px) {
            .modal#activity-history .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: -15px;
              margin-left: 15px; } }
          @media (max-width: 767px) {
            .modal#activity-history .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: 0px;
              margin-left: 0px; } }
          .modal#activity-history .modal-dialog .modal-content .modal-header .close-icon i {
            margin-top: 0px;
            margin-right: 0px; }
      .modal#activity-history .modal-dialog .modal-content .modal-body {
        padding: 0px; }
        .modal#activity-history .modal-dialog .modal-content .modal-body .nc-activity-history-modal-row {
          margin-bottom: 20px; }
        @media (min-width: 992px) {
          .modal#activity-history .modal-dialog .modal-content .modal-body div:last-child .nc-activity-history-modal-row:last-child {
            margin-bottom: 0px; } }
        .modal#activity-history .modal-dialog .modal-content .modal-body td {
          padding: 6px; }
        .modal#activity-history .modal-dialog .modal-content .modal-body .holiday-calendar-snapshot-tabs .nav-tabs {
          border-bottom: 0.5px solid #ddd; }
        .modal#activity-history .modal-dialog .modal-content .modal-body .holiday-calendar-snapshot-tabs .nav-tabs > li {
          margin-bottom: -2px;
          margin-right: 5px; }
        .modal#activity-history .modal-dialog .modal-content .modal-body .holiday-calendar-snapshot-tabs .nav-tabs > li > a {
          margin-right: 0px;
          border-radius: 0px; }
        .modal#activity-history .modal-dialog .modal-content .modal-body .holiday-calendar-snapshot-tabs .nav-tabs > li.active > a {
          border-top: 3px solid #339966;
          font-weight: 600; }
        .modal#activity-history .modal-dialog .modal-content .modal-body .holiday-calendar-snapshot-tabs ul .active {
          width: inherit;
          background-color: white; }
        .modal#activity-history .modal-dialog .modal-content .modal-body .holiday-calendar-snapshot-tabs ul li a {
          color: #555555;
          border-top: 1px solid #cccccc;
          border-right: 1px solid #cccccc;
          border-left: 1px solid #cccccc; }
        .modal#activity-history .modal-dialog .modal-content .modal-body .holiday-calendar-snapshot-tabs .holiday-calendar-snapshot-tab-body {
          width: 100%;
          background-color: #fff;
          font-size: 15px; }
        .modal#activity-history .modal-dialog .modal-content .modal-body .holiday-modal .modal-header {
          font-size: 18px;
          font-weight: 600;
          color: #009966; }
        .modal#activity-history .modal-dialog .modal-content .modal-body .holiday-modal .holiday-modal-table {
          font-size: 15px; }
        .modal#activity-history .modal-dialog .modal-content .modal-body .holiday-modal .nodeItem {
          padding-bottom: 5px;
          padding-top: 5px;
          font-size: 15px;
          display: flex;
          justify-content: flex-start;
          align-items: center; }
          .modal#activity-history .modal-dialog .modal-content .modal-body .holiday-modal .nodeItem .subTitle {
            font-weight: 600; }
      .modal#activity-history .modal-dialog .modal-content .modal-footer {
        padding: 20px 0px 0px 0px; }
        @media (max-width: 767px) {
          .modal#activity-history .modal-dialog .modal-content .modal-footer {
            text-align: center; } }
        @media (min-width: 768px) {
          .modal#activity-history .modal-dialog .modal-content .modal-footer {
            text-align: left; } }
        @media (min-width: 992px) {
          .modal#activity-history .modal-dialog .modal-content .modal-footer {
            display: none; } }

.holiday-calendar-table .seperator {
  padding: 20px 0px;
  border-bottom: 1px solid #cccccc; }

.holiday-calendar-table .title {
  font-size: 18px;
  font-weight: 600;
  color: #009966;
  margin-bottom: 15px; }

.holiday-calendar-table .node .nodeItem {
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .holiday-calendar-table .node .nodeItem .subTitle {
    font-weight: 600; }

.holiday-calendar-table .common-holidays {
  display: flex;
  flex-direction: column;
  height: 300px;
  flex-wrap: wrap; }
  @media (min-width: 320px) and (max-width: 530px) {
    .holiday-calendar-table .common-holidays {
      height: auto; } }
  @media (min-width: 531px) and (max-width: 767px) {
    .holiday-calendar-table .common-holidays {
      height: 360px; } }
  .holiday-calendar-table .common-holidays .common-holiday-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px; }
    .holiday-calendar-table .common-holidays .common-holiday-label .holiday-title {
      padding-left: 5px;
      font-weight: 400;
      font-size: 15px; }
