.no-address-component {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #eee;
  align-items: center;
  padding: 20px; }
  @media (max-width: 767px) {
    .no-address-component {
      width: 100%; } }
  .no-address-component .title {
    margin-bottom: 20px;
    color: #999; }
  @media (max-width: 767px) {
    .no-address-component .buttons-container button {
      width: inherit !important; } }
  .no-address-component .buttons-container button:first-child {
    margin-right: 10px; }
