/* 
This file will import all other styles, mixins etc used in the netches application
*/
@import "//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css";
.nc-globalnav-container .header-container {
  color: #FFF;
  height: 40px; }
  @media (min-width: 320px) and (max-width: 767px) {
    .nc-globalnav-container .header-container {
      height: 76px; } }

.nc-globalnav-container .body-container {
  background-color: #0c4f48;
  padding-top: 6px;
  height: 93px;
  color: white; }
  @media (min-width: 320px) and (max-width: 767px) {
    .nc-globalnav-container .body-container {
      height: 50px; } }
  .nc-globalnav-container .body-container .logo-container {
    float: left;
    width: 73px;
    padding-left: 15px;
    margin-top: 5px; }
    @media (min-width: 320px) and (max-width: 767px) {
      .nc-globalnav-container .body-container .logo-container {
        width: 60px; }
        .nc-globalnav-container .body-container .logo-container img {
          height: 26px;
          width: 22px; } }
  .nc-globalnav-container .body-container .title-container {
    float: left; }
    .nc-globalnav-container .body-container .title-container .level1-menu {
      font-weight: 400;
      font-size: 30px;
      padding-top: 7px; }
      @media (min-width: 320px) and (max-width: 767px) {
        .nc-globalnav-container .body-container .title-container .level1-menu {
          font-size: 15px; } }
    .nc-globalnav-container .body-container .title-container .level2-menu {
      height: 33px; }

html, body {
  font-family: Source Sans Pro, sans-serif; }

a {
  color: #009999; }

.nc-page-container, .nc-list-page-container, .nc-entity-page-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container, .nc-activity-history-page-container {
  height: 100% !important;
  position: relative !important;
  padding: 30px 55px;
  color: #555 !important;
  font-family: "Source Sans Pro", sans-serif !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .nc-page-container, .nc-list-page-container, .nc-entity-page-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container, .nc-activity-history-page-container {
      padding: 10px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .nc-page-container, .nc-list-page-container, .nc-entity-page-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container, .nc-activity-history-page-container {
      padding: 20px !important; } }
  .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
    padding-bottom: 15px; }
    .nc-page-container .pageTitle-container .pageTitle, .nc-list-page-container .pageTitle-container .pageTitle, .nc-entity-page-container .pageTitle-container .pageTitle, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container .pageTitle, .nc-activity-history-page-container .pageTitle-container .pageTitle {
      float: left;
      font-size: 25px;
      font-weight: 400; }
      @media (max-width: 767px) {
        .nc-page-container .pageTitle-container .pageTitle, .nc-list-page-container .pageTitle-container .pageTitle, .nc-entity-page-container .pageTitle-container .pageTitle, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container .pageTitle, .nc-activity-history-page-container .pageTitle-container .pageTitle {
          float: initial;
          font-size: 25px;
          /* DEVIATION : This was added because visually it did not look good without this */ } }
    @media (min-width: 768px) and (max-width: 991px) {
      .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
        padding-bottom: 15px; } }
    @media (max-width: 767px) {
      .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
        padding-bottom: 10px; } }
  .nc-page-container p, .nc-list-page-container p, .nc-entity-page-container p, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container p, .nc-activity-history-page-container p {
    line-height: 1.3; }

.nc-page-container::before, .nc-list-page-container::before, .nc-entity-page-container::before, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container::before, .nc-activity-history-page-container::before {
  display: table;
  content: " "; }

.nc-page-container::after, .nc-list-page-container::after, .nc-entity-page-container::after, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container::after, .nc-activity-history-page-container::after {
  display: table;
  content: " ";
  clear: both; }

.nc-list-page-container .pageTitle-container {
  padding-bottom: 2.5px; }
  @media (min-width: 768px) {
    .nc-list-page-container .pageTitle-container {
      padding-bottom: 15px; } }
  .nc-list-page-container .pageTitle-container .pageTitle {
    padding-bottom: 0px; }

.nc-list-page-container .page-description {
  font-size: 15px;
  line-height: 1.2;
  padding-bottom: 20px; }

.nc-list-page-container .bell-container i:hover {
  cursor: pointer; }

.nc-list-page-container .bell-container .badge {
  margin-left: 12px;
  margin-top: -12px; }

.nc-list-page-container .deduction-page-description-container .deduction-page-description-content {
  padding-bottom: 30px; }
  .nc-list-page-container .deduction-page-description-container .deduction-page-description-content p {
    line-height: 1.3;
    font-size: 15px !important; }
  @media (max-width: 991px) {
    .nc-list-page-container .deduction-page-description-container .deduction-page-description-content {
      padding-bottom: 5px; } }

.nc-list-page-container .deduction-page-description-container .deduction-page-description-button {
  float: right; }
  .nc-list-page-container .deduction-page-description-container .deduction-page-description-button button {
    float: right; }
    @media (max-width: 991px) {
      .nc-list-page-container .deduction-page-description-container .deduction-page-description-button button {
        float: left; } }
  @media (max-width: 991px) {
    .nc-list-page-container .deduction-page-description-container .deduction-page-description-button {
      float: left;
      margin-bottom: 20px; } }

.nc-list-page-container .deduction-page-description-container .page-description-button {
  float: right; }
  .nc-list-page-container .deduction-page-description-container .page-description-button button {
    float: right; }
    @media (max-width: 991px) {
      .nc-list-page-container .deduction-page-description-container .page-description-button button {
        float: left; } }
  @media (max-width: 991px) {
    .nc-list-page-container .deduction-page-description-container .page-description-button {
      float: left;
      margin-bottom: 20px; } }

.nc-list-page-container .add-deduction-button {
  min-width: 200px !important; }

.nc-list-page-container .activity-history-button {
  min-width: 200px !important; }
  @media (max-width: 991px) {
    .nc-list-page-container .activity-history-button {
      float: left !important;
      margin-bottom: 10px; } }

@media (min-width: 992px) {
  .nc-list-page-container .footer-button-container .nc-button-group button {
    margin-right: 20px; } }

@media (max-width: 767px) {
  .nc-list-page-container .footer-button-container .nc-button-group .nc-link-group-mobile {
    width: 100%;
    margin-right: 0px; } }

@media (min-width: 768px) {
  .nc-list-page-container .footer-button-container .nc-button-group .nc-link-group-mobile {
    display: inline; } }

.nc-list-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button {
  margin-right: 20px; }
  @media (max-width: 767px) {
    .nc-list-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button {
      width: 100%;
      margin-right: 0px; } }
  @media (min-width: 992px) {
    .nc-list-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button {
      margin-right: 20px; } }

.nc-list-page-container::before {
  display: table;
  content: " "; }

.nc-list-page-container::after {
  display: table;
  content: " ";
  clear: both; }

.table-row {
  clear: both; }

.timestamp-column {
  text-align: right;
  padding-right: 0px; }

.timestamp-column-value {
  padding-right: 0px;
  text-align: right; }
  @media (min-width: 320px) and (max-width: 991px) {
    .timestamp-column-value {
      text-align: left; } }

.field-description-row-mobile {
  background-color: #e9e9e9;
  padding: 6px 12px 6px 16px !important;
  margin-bottom: 5px; }

.field-header {
  color: #555;
  display: table-cell;
  min-width: 70px;
  font-weight: 600;
  margin-right: 10px; }

.field-value {
  display: table-cell; }

.status-container {
  margin-top: -7px;
  text-transform: capitalize; }
  @media (max-width: 991px) {
    .status-container {
      margin-top: -4px;
      padding-bottom: 0px; } }

.list-page-grid-table {
  font-size: 15px;
  padding: 0px;
  border-collapse: separate;
  border-spacing: 0px 2px;
  margin-bottom: 0px;
  width: 100%;
  max-width: 100%; }
  .list-page-grid-table .name-column {
    color: #009999;
    font-size: 18px;
    cursor: pointer; }
    .list-page-grid-table .name-column.__no-hover {
      cursor: default; }
      .list-page-grid-table .name-column.__no-hover:hover {
        cursor: default; }
      .list-page-grid-table .name-column.__no-hover a {
        cursor: default; }
        .list-page-grid-table .name-column.__no-hover a:hover {
          cursor: default;
          color: #009999; }
    @media (min-width: 320px) and (max-width: 991px) {
      .list-page-grid-table .name-column {
        font-size: 20px; } }
    @media (min-width: 992px) {
      .list-page-grid-table .name-column {
        padding-right: 50px !important; } }
  @media (min-width: 992px) {
    .list-page-grid-table .category-container {
      padding-right: 50px !important; } }
  @media (max-width: 991px) {
    .list-page-grid-table .status-container {
      margin-bottom: 0px;
      padding-bottom: 0px !important; } }
  @media (min-width: 992px) {
    .list-page-grid-table .status-container {
      margin-bottom: -5px; } }
  .list-page-grid-table .table-cell {
    padding-right: 5px; }
  .list-page-grid-table .list-page-grid-table-cell-actions {
    float: right;
    padding-left: 0px !important;
    padding-right: 20px;
    font-size: 18px; }
    @media (max-width: 991px) {
      .list-page-grid-table .list-page-grid-table-cell-actions {
        font-size: 20px; } }
    .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section,
    .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section {
      float: right; }
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section .list-page-edit-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section .list-page-delete-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section .list-page-edit-icon,
      .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section .list-page-delete-icon {
        color: #7b7b7b !important;
        margin-right: 0px; }
    .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section {
      margin-left: 35px; }
      @media (max-width: 767px) {
        .list-page-grid-table .list-page-grid-table-cell-actions .list-page-delete-section {
          margin-left: 15px; } }
    .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section {
      margin-left: 35px; }
      @media (max-width: 767px) {
        .list-page-grid-table .list-page-grid-table-cell-actions .list-page-edit-section {
          margin-left: 15px; } }
  .list-page-grid-table .reactivate-mobile-button {
    margin-top: 10px; }
  .list-page-grid-table .table-header {
    font-size: 14px;
    font-weight: 700; }
  .list-page-grid-table .table-row {
    border-bottom: 1px solid #dbd6d6;
    clear: both;
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 15px;
    padding-bottom: 15px; }
    @media (min-width: 320px) and (max-width: 991px) {
      .list-page-grid-table .table-row {
        padding: 20px 0px; } }
    .list-page-grid-table .table-row div {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 5px; }
      @media (min-width: 992px) {
        .list-page-grid-table .table-row div {
          padding-bottom: 0px; } }
      @media (min-width: 992px) {
        .list-page-grid-table .table-row div div {
          padding-bottom: 0px; } }
    .list-page-grid-table .table-row div:first-child {
      padding-bottom: 0px; }
  .list-page-grid-table .table-row:first-child .table-row:last-child {
    border-bottom: none; }

.tooltip {
  font-family: "Source Sans Pro", sans-serif !important; }
  .tooltip .tooltip-arrow {
    border-top-color: #555 !important; }
  .tooltip .tooltip-inner {
    background-color: #555; }

.nc-rowcount-results {
  clear: both;
  margin-bottom: 15px;
  font-size: 15px; }
  @media (max-width: 991px) {
    .nc-rowcount-results {
      margin-bottom: 0px; } }

.nc-filter-container {
  clear: both;
  display: flex;
  align-items: center;
  background-color: #e6e6e6;
  margin: 0px 0px 20px 0px; }
  .nc-filter-container .advanced-filter-links {
    font-size: 15px;
    display: flex;
    align-items: center; }
    .nc-filter-container .advanced-filter-links a {
      display: flex;
      align-items: center;
      margin-right: 20px; }
      .nc-filter-container .advanced-filter-links a .filter-select-badge {
        margin-left: 5px;
        margin-right: 0; }
      .nc-filter-container .advanced-filter-links a:last-child {
        margin-right: 0; }
  @media (min-width: 992px) {
    .nc-filter-container {
      padding-top: 6px;
      padding-bottom: 6px; } }
  .nc-filter-container .nc-filter-group {
    display: flex;
    flex-direction: column; }
    .nc-filter-container .nc-filter-group .nc-filter-row {
      display: flex;
      margin-top: 13px; }
      .nc-filter-container .nc-filter-group .nc-filter-row .company-field {
        width: 33%;
        float: left; }
    .nc-filter-container .nc-filter-group .nc-filter-row:first-child {
      margin-top: 0px; }
    .nc-filter-container .nc-filter-group .nc-filter-row::before {
      display: table;
      content: " "; }
    .nc-filter-container .nc-filter-group .nc-filter-row::after {
      display: table;
      content: " ";
      clear: both; }
  @media (min-width: 320px) and (max-width: 991px) {
    .nc-filter-container .nc-filter-group {
      width: 100%; }
      .nc-filter-container .nc-filter-group .nc-filter-row .company-field {
        width: 100%;
        float: left; } }
  @media (max-width: 767px) {
    .nc-filter-container .nc-filter-row .company-field {
      margin-top: 10px; } }
  @media (max-width: 767px) {
    .nc-filter-container .filter-activity-entity {
      width: 100% !important; } }
  @media (min-width: 767px) {
    .nc-filter-container .filter-activity-entity {
      width: 90% !important; } }
  @media (min-width: 320px) and (max-width: 480px) {
    .nc-filter-container .nc-filter-dropdown {
      margin-bottom: 10px; } }
  @media (min-width: 992px) and (max-width: 1100px) {
    .nc-filter-container span.selected-value {
      height: 22px;
      overflow-y: hidden;
      overflow-x: hidden;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 60% !important; } }
  @media (min-width: 1101px) {
    .nc-filter-container span.selected-value {
      max-width: 72%;
      height: 22px;
      overflow-y: hidden;
      overflow-x: hidden;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis; } }

.nc-filter-dropdown {
  min-width: 160px;
  max-width: 300px;
  flex-basis: 33.33%;
  padding-right: 15px;
  position: relative; }
  .nc-filter-dropdown .form-group {
    margin-bottom: 0px; }
  .nc-filter-dropdown .nc-select-container > button {
    background-color: #fff;
    border: 1px solid #999;
    padding: 6px 12px;
    border-radius: 0px;
    height: 32px;
    width: 100%;
    text-align: left; }
    .nc-filter-dropdown .nc-select-container > button:focus {
      background-color: #fff; }
    .nc-filter-dropdown .nc-select-container > button .filter-select-label {
      width: 65%;
      overflow-x: hidden; }
    .nc-filter-dropdown .nc-select-container > button .filter-badge {
      display: inline-block;
      position: relative;
      top: -1px;
      min-width: 10px;
      padding: 3px 7px;
      color: #fff;
      font-size: 15px;
      line-height: 1;
      font-weight: 600;
      background-color: #099;
      margin-right: 10px;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      border-radius: 10px; }
    .nc-filter-dropdown .nc-select-container > button .chevron-icon {
      width: 10%; }

.more-filter-link {
  float: left;
  color: #009999;
  font-weight: 400;
  margin-left: 15px; }
  .more-filter-link .primary-link-button {
    padding-top: 8px;
    padding-bottom: 8px; }
  .more-filter-link a:visited {
    color: #009999;
    text-decoration: none; }
  @media (min-width: 992px) and (max-width: 1399px) {
    .more-filter-link {
      margin-left: 0; } }

.showing-label {
  float: left;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #555;
  padding-left: 15px;
  padding-right: 15px; }

.nc-filter-container::before {
  display: table;
  content: " "; }

.nc-filter-container::after {
  display: table;
  content: " ";
  clear: both; }

@media (min-width: 320px) and (max-width: 991px) {
  .nc-sort-filter-container {
    padding: 8px 12px 8px 12px !important;
    background-color: #e9e9e9;
    height: 44px;
    margin-bottom: 20px;
    font-weight: 400; }
    .nc-sort-filter-container button {
      padding-top: 5px;
      padding-right: 10px;
      padding-bottom: 5px;
      padding-left: 10px;
      color: #009999;
      font-weight: 600;
      border: 1px solid #009999;
      background-color: #ffffff;
      width: 50%; }
    .nc-sort-filter-container button:first-child {
      border-top-left-radius: 2.5px;
      border-bottom-left-radius: 2.5px;
      border-right: 0px; }
    .nc-sort-filter-container button:last-child {
      border-top-right-radius: 2.5px;
      border-bottom-right-radius: 2.5px; } }

.dropdown-with-search {
  max-width: 820px;
  margin-top: 5px !important;
  border-radius: 0px !important;
  box-shadow: none !important; }

.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.daterangepicker_calendar_button .input-group-btn button {
  height: unset; }

.modal#mobile-filter {
  overflow: hidden; }
  .modal#mobile-filter .modal-dialog {
    width: auto; }
    @media (max-width: 991px) {
      .modal#mobile-filter .modal-dialog {
        margin: 0px; } }
    .modal#mobile-filter .modal-dialog .modal-content {
      border: none;
      border-radius: 0px;
      box-shadow: none;
      height: 100vh; }
      .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body {
        padding: 0px !important; }
        .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .header-container {
          border-bottom: 1px solid #ccc;
          padding: 10px 20px; }
          @media (max-width: 767px) {
            .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .header-container {
              padding: 10px; } }
          .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .header-container .close-icon {
            font-size: 20px;
            margin-left: 0px !important; }
            @media (max-width: 991px) {
              .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .header-container .close-icon {
                font-size: 18px !important; } }
            .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .header-container .close-icon i {
              vertical-align: unset;
              font-size: 20px;
              cursor: pointer; }
          .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .header-container .filter-label {
            font-size: 15px;
            font-weight: 400;
            text-align: center; }
            @media (max-width: 991px) {
              .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .header-container .filter-label {
                font-size: 16px !important; } }
          .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .header-container .clear-all-link {
            text-align: right;
            padding-top: 4px; }
            @media (max-width: 991px) {
              .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .header-container .clear-all-link {
                font-size: 16px !important;
                color: #009999; } }
          @media (min-width: 768px) and (max-width: 991px) {
            .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .header-container {
              padding-left: 20px;
              padding-right: 20px; } }
        .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .mobile-filter-select-dropdown {
          display: block !important;
          position: relative; }
          @media (min-width: 320px) and (max-width: 991px) {
            .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .mobile-filter-select-dropdown .mobile-filter-panel-list-section {
              display: block !important; } }
          @media (min-width: 992px) {
            .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .mobile-filter-select-dropdown .mobile-filter-panel-list-section {
              display: none !important; } }
          .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .mobile-filter-select-dropdown .mobile-filter-panel-list-section .mobile-filter-panel-list-panel-bar {
            padding: 11px 20px 11px 20px !important;
            font-size: 18px;
            font-weight: 700;
            color: #555555;
            background-color: #d1eae5 !important;
            border: none; }
            .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .mobile-filter-select-dropdown .mobile-filter-panel-list-section .mobile-filter-panel-list-panel-bar .mobile-filter-panel-list-icon {
              width: 40px !important;
              padding-right: 10px !important; }
            .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .mobile-filter-select-dropdown .mobile-filter-panel-list-section .mobile-filter-panel-list-panel-bar .mobile-filter-panel-list-badge {
              width: 32px !important;
              float: right !important; }
              .modal#mobile-filter .modal-dialog .modal-content .modal-mobile-body .mobile-filter-select-dropdown .mobile-filter-panel-list-section .mobile-filter-panel-list-panel-bar .mobile-filter-panel-list-badge .filter-select-badge {
                float: right;
                color: white;
                font-size: 14px;
                font-weight: 600;
                background-color: #009999; }
    .modal#mobile-filter .modal-dialog .modal-footer {
      text-align: center;
      bottom: 0px;
      left: 0px;
      right: 0px;
      position: fixed;
      padding: 15px 10px;
      background-color: #fff;
      box-shadow: 0 -2px 20px 2px #e9e9e9;
      height: 82px;
      border: none !important; }
      .modal#mobile-filter .modal-dialog .modal-footer .mobile-filter-panel-button-section {
        background-color: #fff;
        width: 100%; }
        @media (min-width: 992px) {
          .modal#mobile-filter .modal-dialog .modal-footer .mobile-filter-panel-button-section button {
            width: 100%; } }

.mobile-filter-panel-list-panel-default {
  margin-bottom: 2px !important;
  border: none;
  border-radius: 0px; }
  .mobile-filter-panel-list-panel-default .nc-dropdown-navigation-multiselect {
    padding-left: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px; }
    .mobile-filter-panel-list-panel-default .nc-dropdown-navigation-multiselect .list-item {
      padding-left: 15px;
      padding-right: 15px;
      border-bottom: 1px solid #ccc; }
      @media (max-width: 991px) {
        .mobile-filter-panel-list-panel-default .nc-dropdown-navigation-multiselect .list-item {
          padding-top: 0px;
          padding-bottom: 0px;
          min-height: 44px;
          word-break: break-word; }
          .mobile-filter-panel-list-panel-default .nc-dropdown-navigation-multiselect .list-item .tooltip-mobile-filter {
            padding-left: 25px;
            margin-top: -10px;
            font-size: 14px;
            padding-bottom: 10px; } }
      @media (max-width: 768px) {
        .mobile-filter-panel-list-panel-default .nc-dropdown-navigation-multiselect .list-item {
          padding-right: 10px;
          padding-left: 10px; } }
      .mobile-filter-panel-list-panel-default .nc-dropdown-navigation-multiselect .list-item label {
        font-size: 18px; }
        @media (max-width: 991px) {
          .mobile-filter-panel-list-panel-default .nc-dropdown-navigation-multiselect .list-item label .left-shift {
            margin-left: 20px;
            margin-top: -20px; } }
      .mobile-filter-panel-list-panel-default .nc-dropdown-navigation-multiselect .list-item label:before {
        padding-right: 25px; }
    .mobile-filter-panel-list-panel-default .nc-dropdown-navigation-multiselect .list-item:last-child {
      border-bottom: none; }
  .mobile-filter-panel-list-panel-default .panel-collapse .panel-body {
    padding: 0px; }
    .mobile-filter-panel-list-panel-default .panel-collapse .panel-body .form-group {
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px; }
      @media (max-width: 767px) {
        .mobile-filter-panel-list-panel-default .panel-collapse .panel-body .form-group {
          padding-left: 10px;
          padding-right: 10px; } }
    .mobile-filter-panel-list-panel-default .panel-collapse .panel-body input[type="text"]:focus {
      border-color: #999 !important;
      box-shadow: none !important; }
    .mobile-filter-panel-list-panel-default .panel-collapse .panel-body label {
      font-size: 18px;
      margin-bottom: 0px;
      padding-top: 10px;
      padding-bottom: 10px; }
    .mobile-filter-panel-list-panel-default .panel-collapse .panel-body input[type="checkbox"] + label:before {
      font-size: 18px !important;
      width: 18px; }
  .mobile-filter-panel-list-panel-default .panel-collapse div:last-child label {
    margin-bottom: 0px; }

.modal#mobile-sort .modal-dialog {
  width: auto; }
  @media (max-width: 991px) {
    .modal#mobile-sort .modal-dialog {
      margin: 0px; } }

.modal#mobile-sort .modal-content {
  border: none;
  border-radius: 0px;
  box-shadow: none;
  height: 100vh;
  padding: 0px; }
  .modal#mobile-sort .modal-content .modal-mobile-body {
    padding: 0px; }
    .modal#mobile-sort .modal-content .modal-mobile-body .header-container {
      border-bottom: 1px solid #ccc;
      height: 40px;
      margin-bottom: 15px;
      padding-bottom: 15px;
      padding: 10px 20px; }
      @media (max-width: 767px) {
        .modal#mobile-sort .modal-content .modal-mobile-body .header-container {
          padding-left: 10px;
          padding-right: 10px; } }
      .modal#mobile-sort .modal-content .modal-mobile-body .header-container .close-icon {
        font-size: 18px;
        margin-left: 0px !important; }
        @media (max-width: 991px) {
          .modal#mobile-sort .modal-content .modal-mobile-body .header-container .close-icon {
            font-size: 18px !important; } }
        .modal#mobile-sort .modal-content .modal-mobile-body .header-container .close-icon i {
          vertical-align: unset;
          font-size: 20px;
          cursor: pointer; }
      .modal#mobile-sort .modal-content .modal-mobile-body .header-container .sort-label,
      .modal#mobile-sort .modal-content .modal-mobile-body .header-container .filter-label {
        font-size: 15px;
        font-weight: 400;
        text-align: center; }
        @media (max-width: 991px) {
          .modal#mobile-sort .modal-content .modal-mobile-body .header-container .sort-label,
          .modal#mobile-sort .modal-content .modal-mobile-body .header-container .filter-label {
            font-size: 16px !important; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .modal#mobile-sort .modal-content .modal-mobile-body .header-container {
          padding-left: 20px;
          padding-right: 20px; } }
    .modal#mobile-sort .modal-content .modal-mobile-body .mobile-sort-panel-item {
      color: #555;
      border-bottom: 1px solid #ccc;
      height: 44px;
      font-size: 18px;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 20px; }
      @media (min-width: 768px) and (max-width: 991px) {
        .modal#mobile-sort .modal-content .modal-mobile-body .mobile-sort-panel-item {
          padding-left: 20px; } }
      @media (max-width: 767px) {
        .modal#mobile-sort .modal-content .modal-mobile-body .mobile-sort-panel-item {
          padding: 10px; } }
  .modal#mobile-sort .modal-content .modal-footer {
    text-align: center;
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: fixed;
    padding: 15px 10px;
    border: none !important; }
    .modal#mobile-sort .modal-content .modal-footer .mobile-filter-panel-button-section {
      background-color: #fff;
      width: 100%; }
      .modal#mobile-sort .modal-content .modal-footer .mobile-filter-panel-button-section button {
        width: 100%; }

.apply-filter-button {
  padding: 10px 25px !important;
  background-color: #0cc !important;
  border-radius: 3px !important;
  text-transform: uppercase !important;
  color: #fff !important;
  font-size: 16px !important;
  border-color: transparent; }
  @media (min-width: 767px) and (max-width: 991px) {
    .apply-filter-button {
      height: 44px; } }
  @media (max-width: 767px) {
    .apply-filter-button {
      width: 100%; } }

.border-bottom-1px {
  border-bottom: 1px solid #ccc; }

/* Button Colors*/
/* Font styles*/
/*Button Shape and Size*/
button:focus, button:active, button:hover {
  outline: none !important;
  box-shadow: none !important; }

.nc-button {
  border-radius: 3px;
  font-size: 16px;
  /* DEVIATION :  As per markup this is 18px but did not look right */
  font-weight: 400;
  /* DEVIATION :  As per markup this is 600 but did not look right */
  height: 36px;
  line-height: 1;
  padding: 10px 20px;
  text-transform: uppercase; }
  @media (max-width: 991px) {
    .nc-button {
      min-height: 44px;
      /* Added on 09 Sept as specified by Rebecca in the Review Video */ } }
  .nc-button.primary-button {
    background-color: #006699;
    border: none;
    color: #ffffff; }
    @media (max-width: 767px) {
      .nc-button.primary-button {
        width: 100%; } }
    .nc-button.primary-button:hover {
      background-color: #015782; }
  .nc-button.secondary-button {
    background-color: #00cccc;
    border: none;
    color: #ffffff; }
    @media (max-width: 767px) {
      .nc-button.secondary-button {
        width: 100% !important; } }
    .nc-button.secondary-button:hover {
      background-color: #00b6b6; }
  .nc-button.outline-button-right {
    border-radius: 3px;
    padding: 6px 20px;
    /* DEVIATION :  As per markup this is 12px but did not look right */
    color: #009999;
    border: 1px solid #009999;
    background: #fff; }
    .nc-button.outline-button-right:hover {
      background-color: #f0eeee; }
  .nc-button.outline-button {
    color: #009999;
    font-size: 16px;
    border: 1px solid #009999;
    background: #fff;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .nc-button.outline-button {
        width: 100% !important; } }
    @media (min-width: 992px) {
      .nc-button.outline-button :hover {
        background-color: #f0eeee !important; } }

.link-button {
  border: none;
  background: none;
  color: #009999;
  font-weight: 400; }

.secondary-link-button {
  color: #757575;
  background: none;
  border: none;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 0px;
  text-transform: capitalize; }
  @media (max-width: 767px) {
    .secondary-link-button {
      padding: 0px; } }
  .secondary-link-button:hover {
    color: #333333; }

.disabled-link {
  color: #757575;
  background: none;
  border: none;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 0px 10px 0px;
  text-transform: capitalize; }
  @media (max-width: 767px) {
    .disabled-link {
      padding: 0px; } }
  .disabled-link:hover {
    cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer;
    color: #333; }

.primary-link-button {
  color: #009999;
  background: none;
  border: none;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 0px;
  text-transform: capitalize; }
  @media (max-width: 767px) {
    .primary-link-button {
      padding: 0; } }
  .primary-link-button:hover {
    color: #0c4f48; }

.special-outline-button {
  line-height: initial;
  border-radius: 3px;
  height: 30px;
  padding-top: 6px 20px;
  /* DEVIATION :  As per markup this is 12px but did not look right */
  color: #009999;
  font-size: 14px;
  /* DEVIATION : As per markup this is 18px but that looked too big */
  font-weight: 400;
  border: 1px solid #009999;
  background: none; }
  .special-outline-button :hover {
    background-color: #f0eeee; }

.outline-fa-button {
  border-radius: 3px;
  padding: 6px 15px;
  /* DEVIATION :  As per markup this is 12px but did not look right */
  color: #009999;
  font-size: 16px;
  /* DEVIATION : As per markup this is 18px but that looked too big */
  font-weight: 400;
  border: 1px solid #009999;
  background: none; }
  .outline-fa-button .fa {
    margin-right: 0; }
  @media (max-width: 991px) {
    .outline-fa-button {
      min-height: 44px;
      /* Added on 09 Sept as specified by Rebecca in the Review Video */ } }
  .outline-fa-button:hover {
    background-color: #f0eeee; }

.disabled-button {
  padding: 10px 20px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  border: none;
  background: #e9e9e9; }
  @media (max-width: 767px) {
    .disabled-button {
      height: 44px;
      width: 100% !important; } }
  .disabled-button:hover {
    cursor: no-drop;
    background-color: #e0dfdf; }

.inpage-back-list {
  background-color: #fff;
  border: none;
  color: #009999;
  min-width: 247px;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  padding-left: 0px; }
  @media (min-width: 992px) {
    .inpage-back-list {
      padding: 20px 0px 0px 0px; } }
  @media (min-width: 992px) {
    .inpage-back-list {
      padding: 0; }
      .inpage-back-list:hover {
        color: #0c4f48; } }

.inpage-back-list-ah {
  background-color: #fff;
  border: none;
  color: #009999;
  padding: 0px 0px 0px 0px;
  font-size: 15px;
  font-weight: 400;
  text-align: left; }
  @media (max-width: 767px) {
    .inpage-back-list-ah {
      padding-bottom: 10px;
      /* DEVIATION : To make this look uniform with other elements */ } }
  @media (min-width: 992px) {
    .inpage-back-list-ah {
      padding: 0; } }
  .inpage-back-list-ah:hover {
    color: #0c4f48; }

@media (max-width: 991px) {
  .nc-navigation-button-family .primary-group {
    float: left; } }

@media (max-width: 991px) {
  .nc-navigation-button-family .secondary-group {
    float: right; } }

@media (min-width: 992px) {
  .nc-navigation-button-family .secondary-group button:last-child {
    margin-right: 0;
    border-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .nc-navigation-button-family .secondary-group button:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; } }

.nc-navigation-button-family button {
  float: right;
  margin-right: 10px; }
  .nc-navigation-button-family button:first-child {
    margin-right: 0; }

.nc-button-group button {
  margin-right: 15px; }
  @media (max-width: 767px) {
    .nc-button-group button {
      margin-right: 0;
      margin-bottom: 20px; } }
  .nc-button-group button:last-child {
    margin-right: 0; }

.nc-link-group button {
  margin-right: 25px; }
  @media (max-width: 767px) {
    .nc-link-group button {
      margin-right: 0;
      margin-bottom: 20px; } }
  .nc-link-group button:last-child {
    margin-right: 0; }

.nc-button-stack button {
  margin-bottom: 10px; }
  .nc-button-stack button:last-child {
    margin-bottom: 0; }

.entity-form-section-secondary {
  clear: both; }
  .entity-form-section-secondary > .title {
    color: #009966;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 10px; }
    .entity-form-section-secondary > .title .fa-arrow-circle-left {
      float: left; }
    .entity-form-section-secondary > .title i {
      padding: 0px 5px; }
    .entity-form-section-secondary > .title .title-label {
      padding: 4px 0px 0px 5px;
      color: #009999;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer; }
  .entity-form-section-secondary .content {
    padding-left: 10px;
    margin-top: 5px; }
    .entity-form-section-secondary .content .nc-inline-fields-group .nc-field-group {
      margin-right: 15px; }
    .entity-form-section-secondary .content .nc-inline-fields-group .nc-field-group:last-child {
      margin-right: 0px; }
    .entity-form-section-secondary .content .nc-field-group .field-name {
      margin-bottom: 15px;
      font-size: 15px; }
      .entity-form-section-secondary .content .nc-field-group .field-name .field-description {
        color: #999;
        margin-top: 5px;
        margin-bottom: 0px; }
    .entity-form-section-secondary .content .nc-field-group .field-description {
      font-size: 14px !important;
      color: #999;
      margin-bottom: 10px; }
    .entity-form-section-secondary .content .nc-field-group .inline-field-description {
      float: left;
      position: relative; }
      @media (min-width: 768px) {
        .entity-form-section-secondary .content .nc-field-group .inline-field-description {
          margin-top: 5px;
          font-size: 15px !important;
          color: #999;
          margin-left: 15px; } }
    .entity-form-section-secondary .content .nc-field-group .input-field {
      margin-bottom: 20px; }
      .entity-form-section-secondary .content .nc-field-group .input-field .form-group {
        margin-bottom: 0px; }
      @media (min-width: 992px) {
        .entity-form-section-secondary .content .nc-field-group .input-field.dollar-amount-field .has-error, .entity-form-section-secondary .content .nc-field-group .input-field.earnings-import-code-field .has-error, .entity-form-section-secondary .content .nc-field-group .input-field.earnings-code-field .has-error {
          white-space: nowrap; } }
    .entity-form-section-secondary .content .nc-field-group .radio-button-group .single-radio-button {
      display: inline; }
      @media (max-width: 767px) {
        .entity-form-section-secondary .content .nc-field-group .radio-button-group .single-radio-button {
          width: 50%;
          float: left; } }
    @media (max-width: 767px) {
      .entity-form-section-secondary .content .nc-field-group .radio-button-group .single-radio-button:nth-of-type(odd) {
        padding-right: 5px; } }
    @media (max-width: 767px) {
      .entity-form-section-secondary .content .nc-field-group .radio-button-group .single-radio-button:nth-of-type(even) {
        padding-left: 5px; } }

.entity-form-section-primary {
  clear: both; }
  .entity-form-section-primary .edit-icon {
    font-size: 15px; }
    @media (max-width: 991px) {
      .entity-form-section-primary .edit-icon {
        font-size: 20px; } }
  .entity-form-section-primary .content .nc-inline-fields-group .nc-field-group {
    margin-right: 15px; }
  .entity-form-section-primary .content .nc-inline-fields-group .nc-field-group:last-child {
    margin-right: 0px; }
  .entity-form-section-primary .content .nc-field-group .edit-icon a {
    float: right; }
    .entity-form-section-primary .content .nc-field-group .edit-icon a .fa-pencil {
      color: #555; }
  .entity-form-section-primary .content .nc-field-group .field-name {
    margin-bottom: 15px;
    font-size: 15px; }
    .entity-form-section-primary .content .nc-field-group .field-name .field-description {
      color: #999;
      margin-top: 5px; }
  .entity-form-section-primary .content .nc-field-group .field-description {
    color: #999; }
  .entity-form-section-primary .content .nc-field-group .field-information {
    font-size: 14px !important;
    margin-bottom: 5px; }
  .entity-form-section-primary .content .nc-field-group .inline-field-description {
    float: left;
    position: relative; }
    @media (min-width: 768px) {
      .entity-form-section-primary .content .nc-field-group .inline-field-description {
        margin-top: 7px;
        font-size: 15px !important;
        color: #999;
        margin-left: 15px; } }
  .entity-form-section-primary .content .nc-field-group .input-field {
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .entity-form-section-primary .content .nc-field-group .input-field.earnings-code-field .has-error {
        white-space: nowrap; } }
    .entity-form-section-primary .content .nc-field-group .input-field .form-group {
      margin-bottom: 0px; }
  .entity-form-section-primary .content .nc-field-group .radio-button-group .single-radio-button {
    display: inline; }
    @media (max-width: 767px) {
      .entity-form-section-primary .content .nc-field-group .radio-button-group .single-radio-button {
        width: 50%;
        float: left; } }
  @media (max-width: 767px) {
    .entity-form-section-primary .content .nc-field-group .radio-button-group .single-radio-button:nth-of-type(odd) {
      padding-right: 5px; } }
  @media (max-width: 767px) {
    .entity-form-section-primary .content .nc-field-group .radio-button-group .single-radio-button:nth-of-type(even) {
      padding-left: 5px; } }

.formFieldTooltip {
  background-color: #e9e9e9 !important;
  padding: 10px !important; }
  @media (min-width: 320px) and (max-width: 479px) {
    .formFieldTooltip {
      width: 98% !important;
      margin-top: 10px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .formFieldTooltip {
      width: 98% !important;
      margin-top: 10px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .formFieldTooltip {
      width: 98% !important; } }
  .formFieldTooltip .close-button {
    border-radius: 50px !important;
    width: 30px;
    height: 30px;
    background-color: #019999 !important;
    border: none !important;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center; }
    .formFieldTooltip .close-button i {
      color: #ffffff !important;
      font-size: 18px;
      margin-right: 0px;
      margin-left: 0px !important; }
    .formFieldTooltip .close-button svg {
      color: #ffffff !important;
      font-size: 18px; }
    @media (min-width: 320px) and (max-width: 479px) {
      .formFieldTooltip .close-button {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
    @media (min-width: 480px) and (max-width: 767px) {
      .formFieldTooltip .close-button {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .formFieldTooltip .close-button {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
    @media (min-width: 992px) {
      .formFieldTooltip .close-button {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
  .formFieldTooltip .content {
    padding: 10px;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 400 !important;
    color: #555555 !important; }

.nc-page-container, .nc-list-page-container, .nc-entity-page-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container, .nc-activity-history-page-container {
  height: 100% !important;
  position: relative !important;
  padding: 30px 55px !important;
  color: #555 !important;
  font-family: "Source Sans Pro", sans-serif !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .nc-page-container, .nc-list-page-container, .nc-entity-page-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container, .nc-activity-history-page-container {
      padding: 10px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .nc-page-container, .nc-list-page-container, .nc-entity-page-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container, .nc-activity-history-page-container {
      padding: 20px !important; } }
  .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
    padding-bottom: 25px; }
    .nc-page-container .pageTitle-container .pageTitle, .nc-list-page-container .pageTitle-container .pageTitle, .nc-entity-page-container .pageTitle-container .pageTitle, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container .pageTitle, .nc-activity-history-page-container .pageTitle-container .pageTitle {
      float: left;
      font-size: 25px;
      font-weight: 400; }
      @media (max-width: 767px) {
        .nc-page-container .pageTitle-container .pageTitle, .nc-list-page-container .pageTitle-container .pageTitle, .nc-entity-page-container .pageTitle-container .pageTitle, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container .pageTitle, .nc-activity-history-page-container .pageTitle-container .pageTitle {
          float: initial;
          font-size: 25px;
          /* DEVIATION : This was added because visually it did not look good without this */ } }
    @media (min-width: 768px) and (max-width: 991px) {
      .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
        padding-bottom: 20px; } }
    @media (max-width: 767px) {
      .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
        padding-bottom: 10px; } }

.nc-page-container::before, .nc-list-page-container::before, .nc-entity-page-container::before, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container::before, .nc-activity-history-page-container::before {
  display: table;
  content: " "; }

.nc-page-container::after, .nc-list-page-container::after, .nc-entity-page-container::after, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container::after, .nc-activity-history-page-container::after {
  display: table;
  content: " ";
  clear: both; }

.dropdown-wrapper .Select-control {
  border-radius: 0px;
  outline: none !important;
  box-shadow: none !important;
  border-color: #ccc !important; }
  .dropdown-wrapper .Select-control .Select-multi-value-wrapper {
    box-shadow: none; }
    .dropdown-wrapper .Select-control .Select-multi-value-wrapper .Select-placeholder {
      padding-left: 10px; }

.dropdown-wrapper .Select-menu-outer {
  margin-top: 5px;
  border-radius: 0px;
  border: 1px solid #555;
  box-shadow: 2px 0px 9px 0px rgba(0, 0, 0, 0.35) !important; }
  .dropdown-wrapper .Select-menu-outer .Select-menu .options {
    color: #555;
    font-weight: 400;
    font-size: 15px;
    padding: 15px 10px;
    background-color: #fff; }
  .dropdown-wrapper .Select-menu-outer .Select-menu .options:hover {
    background-color: #f1f1f1; }

.nc-select-container {
  position: relative; }
  .nc-select-container > button {
    background-color: #fff;
    border: 1px solid #999;
    color: #555;
    font-size: 15px;
    padding: 6px 12px;
    border-radius: 0px;
    height: 34px;
    width: 100%;
    text-align: left; }
    .nc-select-container > button i {
      margin-top: 1.5px; }
  .nc-select-container span.selected-value {
    max-width: 72%;
    height: 22px;
    overflow-y: hidden;
    overflow-x: hidden;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis; }
    @media (min-width: 992px) and (max-width: 1100px) {
      .nc-select-container span.selected-value {
        max-width: 72%; } }
    @media (min-width: 1101px) and (max-width: 1199px) {
      .nc-select-container span.selected-value {
        max-width: 63%; } }
    @media (min-width: 1200px) and (max-width: 1299px) {
      .nc-select-container span.selected-value {
        max-width: 67%; } }
    @media (min-width: 1300px) and (max-width: 1399px) {
      .nc-select-container span.selected-value {
        max-width: 70%; } }
  @media (max-width: 991px) {
    .nc-select-container > button {
      font-size: 15px;
      width: 100%;
      background: none;
      outline: none;
      border: none;
      border: 1px solid #999;
      background-color: #fff;
      border-radius: 0px;
      text-align: left;
      padding: 10px;
      height: 44px; }
      .nc-select-container > button span.selected-value {
        max-width: 85%;
        height: 17px;
        overflow-y: hidden;
        overflow-x: hidden;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis; } }
    @media (max-width: 991px) and (max-width: 330px) {
      .nc-select-container > button span.selected-value {
        max-width: 89%; } }
  @media (max-width: 991px) {
      .nc-select-container > button .previous-login-text {
        margin-top: -5px; } }
    @media (max-width: 991px) and (min-width: 320px) {
      .nc-select-container > button .previous-login-text {
        font-size: 9px; } }
    @media (max-width: 991px) and (min-width: 480px) {
      .nc-select-container > button .previous-login-text {
        font-size: 10px; } }
    @media (max-width: 991px) and (min-width: 768px) {
      .nc-select-container > button .previous-login-text {
        font-size: 11px; } }
    @media (max-width: 991px) and (min-width: 992px) {
      .nc-select-container > button .previous-login-text {
        font-size: 12px; } }
    @media (max-width: 991px) and (min-width: 1200px) {
      .nc-select-container > button .previous-login-text {
        font-size: 12px; } }
    @media (max-width: 991px) and (min-width: 1400px) {
      .nc-select-container > button .previous-login-text {
        font-size: 12px; } }

/* Written for single select and contains hover*/
.nc-dropdown-navigation {
  color: #555;
  margin-top: 10px;
  list-style-type: none;
  padding: 0; }
  @media (max-width: 991px) {
    .nc-dropdown-navigation {
      padding-top: 0px; } }
  .nc-dropdown-navigation .list-item {
    font-size: 15px;
    padding: 10px 10px; }
    .nc-dropdown-navigation .list-item label {
      display: block;
      margin: 0px;
      font-weight: 400 !important; }
    .nc-dropdown-navigation .list-item label:hover {
      cursor: pointer; }
    .nc-dropdown-navigation .list-item .ncl-dropdown-option-description {
      color: #999;
      font-size: 12px;
      margin-left: 20px;
      margin-top: 5px; }
  .nc-dropdown-navigation .list-item:hover {
    background-color: #f1f1f1;
    cursor: pointer; }

/* Written for multi-select and without hover*/
.nc-dropdown-navigation-multiselect {
  list-style-type: none;
  padding: 0; }
  .nc-dropdown-navigation-multiselect .grid-container {
    width: 60%; }
    .nc-dropdown-navigation-multiselect .grid-container .grid-option {
      width: 33%;
      float: left; }
  @media (min-width: 601px) and (max-width: 991px) {
    .nc-dropdown-navigation-multiselect .grid-container {
      width: 100%; }
      .nc-dropdown-navigation-multiselect .grid-container .grid-option {
        width: 50%;
        float: left; } }
  @media (min-width: 320px) and (max-width: 600px) {
    .nc-dropdown-navigation-multiselect .grid-container {
      width: 100%; }
      .nc-dropdown-navigation-multiselect .grid-container .grid-option {
        width: 100%;
        float: left; } }
  @media (max-width: 991px) {
    .nc-dropdown-navigation-multiselect input[type="checkbox"] + label:before {
      font-size: 18px; } }
  @media (min-width: 992px) {
    .nc-dropdown-navigation-multiselect {
      padding-top: 5px; } }
  .nc-dropdown-navigation-multiselect .list-item {
    padding: 10px 10px;
    font-size: 15px; }
    .nc-dropdown-navigation-multiselect .list-item label {
      display: block;
      margin: 0px;
      font-weight: 400; }
      @media (min-width: 992px) {
        .nc-dropdown-navigation-multiselect .list-item label .left-shift {
          margin-left: 20px;
          margin-top: -20px; } }
    .nc-dropdown-navigation-multiselect .list-item label:hover {
      cursor: pointer; }
  .nc-dropdown-navigation-multiselect .list-item:hover {
    cursor: pointer; }

.nc-dropdown-overlay {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 2px 9px 0px rgba(0, 0, 0, 0.35);
  border: 1px solid #999;
  border-radius: 0;
  margin-left: 0;
  margin-top: 5px;
  z-index: 10;
  width: 100%; }
  .nc-dropdown-overlay .form-group {
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px; }
    .nc-dropdown-overlay .form-group .field input:focus {
      border-color: #999; }
    @media (min-width: 768px) and (max-width: 991px) {
      .nc-dropdown-overlay .form-group {
        padding-left: 20px;
        padding-right: 20px; } }

.modal#dropdown {
  overflow-y: hidden; }
  .modal#dropdown .modal-dialog {
    width: auto; }
    @media (max-width: 991px) {
      .modal#dropdown .modal-dialog {
        margin: 0px; } }
  .modal#dropdown .modal-content {
    border: none;
    border-radius: 0px;
    box-shadow: none;
    height: 100vh;
    padding: 0px; }
    .modal#dropdown .modal-content .modal-mobile-body {
      padding: 0px; }
      .modal#dropdown .modal-content .modal-mobile-body .header-container {
        border-bottom: 1px solid #ccc;
        height: 40px;
        padding-bottom: 15px;
        padding: 10px; }
        @media (min-width: 768px) and (max-width: 991px) {
          .modal#dropdown .modal-content .modal-mobile-body .header-container {
            padding-left: 20px;
            padding-right: 20px; } }
        .modal#dropdown .modal-content .modal-mobile-body .header-container .close-icon {
          font-size: 18px; }
          .modal#dropdown .modal-content .modal-mobile-body .header-container .close-icon i {
            vertical-align: unset;
            font-size: 20px;
            cursor: pointer; }
          @media (max-width: 991px) {
            .modal#dropdown .modal-content .modal-mobile-body .header-container .close-icon {
              margin-left: 0;
              padding-left: 0;
              padding-right: 0; } }
        .modal#dropdown .modal-content .modal-mobile-body .header-container .sort-label {
          font-size: 15px;
          font-weight: 400;
          text-align: center; }
        .modal#dropdown .modal-content .modal-mobile-body .header-container .clear-all-link {
          text-align: right; }
        @media (min-width: 768px) and (max-width: 991px) {
          .modal#dropdown .modal-content .modal-mobile-body .header-container {
            padding-left: 20px;
            padding-right: 20px; } }
      .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation-multiselect {
        padding-top: 0px; }
        .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation-multiselect .list-item {
          padding-left: 20px;
          padding-right: 20px;
          border-bottom: 1px solid #ccc; }
          @media (max-width: 767px) {
            .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation-multiselect .list-item {
              padding-left: 10px;
              padding-right: 10px; } }
          .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation-multiselect .list-item label {
            font-size: 18px; }
            @media (max-width: 991px) {
              .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation-multiselect .list-item label .left-shift {
                margin-left: 20px;
                margin-top: -20px; } }
          .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation-multiselect .list-item label:before {
            padding-right: 25px; }
      .modal#dropdown .modal-content .modal-mobile-body .form-group {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px; }
        @media (max-width: 991px) {
          .modal#dropdown .modal-content .modal-mobile-body .form-group {
            margin-bottom: 0px; }
            .modal#dropdown .modal-content .modal-mobile-body .form-group .field input {
              height: 44px;
              font-weight: 400; }
            .modal#dropdown .modal-content .modal-mobile-body .form-group .field input:focus {
              box-shadow: none !important;
              border-color: #999; } }
        @media (max-width: 767px) {
          .modal#dropdown .modal-content .modal-mobile-body .form-group {
            padding-left: 10px;
            padding-right: 10px; } }
      .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation {
        color: #555; }
        .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item {
          min-height: 44px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 30px;
          padding-right: 30px;
          margin-bottom: 0px;
          font-weight: 400;
          font-size: 18px;
          border-bottom: 1px solid #ccc; }
          .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item .grid-container {
            width: 100%; }
            .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item .grid-container .grid-option {
              width: 33%;
              float: left; }
          @media (min-width: 768px) and (max-width: 991px) {
            .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item {
              padding-left: 20px;
              padding-right: 20px; } }
          @media (min-width: 481px) and (max-width: 767px) {
            .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item {
              padding-left: 10px;
              padding-right: 10px; }
              .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item .grid-container {
                width: 100%; }
                .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item .grid-container .grid-option {
                  width: 33%;
                  float: left; } }
          @media (min-width: 320px) and (max-width: 480px) {
            .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item {
              padding-left: 10px;
              padding-right: 10px; }
              .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item .grid-container {
                width: 100%; }
                .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item .grid-container .grid-option {
                  width: 50%;
                  float: left; } }
          .modal#dropdown .modal-content .modal-mobile-body .nc-dropdown-navigation .select-panel-item label {
            margin-bottom: 0px;
            font-weight: 400 !important;
            font-size: 18px; }
      .modal#dropdown .modal-content .modal-mobile-body .selected {
        background-color: #ccffff;
        font-weight: 600 !important; }
    .modal#dropdown .modal-content .modal-footer {
      position: fixed;
      padding: 15px 10px !important;
      height: 82px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: #fff;
      -webkit-box-shadow: 0 -2px 20px 2px #e9e9e9;
      box-shadow: 0 -2px 20px 2px #e9e9e9;
      bottom: 0;
      left: 0;
      right: 0; }
      .modal#dropdown .modal-content .modal-footer .mobile-filter-panel-button-section {
        width: 100%; }
        @media (max-width: 767px) {
          .modal#dropdown .modal-content .modal-footer .mobile-filter-panel-button-section button {
            width: 100%; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .modal#dropdown .modal-content .modal-footer {
          padding-left: 20px;
          padding-right: 20px; } }
      @media (max-width: 767px) {
        .modal#dropdown .modal-content .modal-footer {
          padding-left: 10px;
          padding-right: 10px; } }
      @media (max-width: 767px) {
        .modal#dropdown .modal-content .modal-footer {
          padding-left: 10px;
          padding-right: 10px; } }

.filter-select-badge {
  color: white;
  font-size: 15px;
  font-weight: 600;
  background-color: #009999;
  margin-right: 10px; }
  .filter-select-badge .filter-select-dropdown-icon {
    background-color: green; }

.__disabled-dropdown {
  background-color: #e9e9e9 !important;
  border: 1px solid #e9e9e9 !important; }

.disabled-cursor, .__disabled-dropdown, .__disabled-radio-button .nc-radio-button-input, .__disabled-radio-button .nc-radio-button-input span, .__disabled-radio-button .nc-radio-button-input span span.checked, .__disabled-radio-button:hover {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

.__disabled-dropdown {
  background-color: #e9e9e9 !important;
  border: 1px solid #ccc !important;
  color: #999 !important; }

.disabled-cursor, .__disabled-dropdown, .__disabled-radio-button .nc-radio-button-input, .__disabled-radio-button .nc-radio-button-input span, .__disabled-radio-button .nc-radio-button-input span span.checked, .__disabled-radio-button:hover {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

input[type="text"] {
  border-radius: 0px !important;
  border: 1px solid #999 !important;
  font-size: 15px;
  color: #555;
  font-family: FontAwesome, "Source Sans Pro", sans-serif; }
  @media (max-width: 767px) {
    input[type="text"] {
      min-height: 44px !important; } }
  input[type="text"]::placeholder {
    font-style: normal; }

.has-error {
  color: #ff0000; }

.optional-field {
  color: #999;
  font-weight: 400;
  font-size: 14px; }

.required-field {
  color: #ff0000;
  font-weight: 600;
  font-size: 15px; }

input[type="text"]::before {
  content: "sdff"; }

input[type="text"]:focus {
  outline: 0;
  border: 1px solid #999 !important;
  -webkit-box-shadow: inset 0 1px 1px #ccffff, 0 0 8px #ccffff !important;
  box-shadow: inset 0 1px 1px rgba(204, 255, 255, 0.8), 0 0 8px rgba(204, 255, 255, 0.8) !important; }

textarea:focus {
  outline: 0;
  border: 1px solid #999 !important;
  -webkit-box-shadow: inset 0 1px 1px #ccffff, 0 0 8px #ccffff !important;
  box-shadow: inset 0 1px 1px #ccffff, 0 0 8px #ccffff !important; }

input [type="radio"] {
  height: 0px; }

select:focus {
  box-shadow: none; }

input[type="radio"]:checked + span > .checked {
  background-color: #555; }

input[type="radio"] + span {
  background-color: #fff;
  border: 1px solid #999999;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 12px;
  height: 12px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  -moz-border-radius: 50%;
  border-radius: 50%; }
  input[type="radio"] + span > .checked {
    display: inline-block;
    width: 6px;
    height: 6px;
    position: relative;
    color: #fff;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 50%;
    background-color: #fff; }

input[type="checkbox"] {
  display: none; }

input[type="checkbox"] + label:before {
  font-family: FontAwesome, "Source Sans Pro", sans-serif;
  font-size: 15px;
  display: inline-block;
  padding-right: 20px; }
  @media (max-width: 767px) {
    input[type="checkbox"] + label:before {
      font-size: 18px; } }

input[type="checkbox"] + label:before {
  content: "\f096";
  color: #555;
  width: 12px; }

input[type="checkbox"]:checked + label:before {
  content: "\f14a";
  color: #009999;
  width: 12px; }

input[type="checkbox"] + label {
  cursor: pointer;
  font-weight: 400;
  margin-bottom: 0; }

input[type="checkbox"]:checked + label {
  font-weight: 400; }

@media (max-width: 767px) {
  .button-type-container {
    width: 100%; } }

.button-type-container .single-radio-button {
  display: inline; }

@media (max-width: 767px) {
  .button-type-container .single-radio-button {
    width: 50%;
    float: left; }
  .button-type-container > div:nth-of-type(odd) {
    padding-right: 5px; }
  .button-type-container > div:nth-of-type(even) {
    padding-left: 5px; } }

.nc-radio-button, .nc-radio-basic-long-button {
  border-radius: 3px;
  cursor: default;
  display: inline-table;
  height: 44px;
  margin-right: 10px;
  margin-bottom: 10px;
  vertical-align: middle;
  width: 125px; }
  @media (max-width: 767px) {
    .nc-radio-button, .nc-radio-basic-long-button {
      width: 100% !important; } }
  .nc-radio-button label, .nc-radio-basic-long-button label {
    font-weight: inherit;
    cursor: unset; }
  .nc-radio-button .nc-radio-button-row, .nc-radio-basic-long-button .nc-radio-button-row {
    display: table-row;
    height: inherit; }
    .nc-radio-button .nc-radio-button-row .input-radio, .nc-radio-basic-long-button .nc-radio-button-row .input-radio {
      display: table-cell;
      padding: 10px 10px 10px 10px;
      vertical-align: middle;
      width: 32px; }
      @media (max-width: 767px) {
        .nc-radio-button .nc-radio-button-row .input-radio, .nc-radio-basic-long-button .nc-radio-button-row .input-radio {
          display: none; } }
      .nc-radio-button .nc-radio-button-row .input-radio .radio, .nc-radio-basic-long-button .nc-radio-button-row .input-radio .radio {
        display: none;
        border: 1px solid #999;
        height: 0px;
        margin-top: 0px;
        width: 12px; }
    .nc-radio-button .nc-radio-button-row .radio-content, .nc-radio-basic-long-button .nc-radio-button-row .radio-content {
      color: #555;
      cursor: inherit;
      display: table-cell;
      font-size: 15px;
      text-align: left;
      padding: 10px 10px 10px 0px;
      vertical-align: middle; }
      @media (max-width: 767px) {
        .nc-radio-button .nc-radio-button-row .radio-content, .nc-radio-basic-long-button .nc-radio-button-row .radio-content {
          text-align: center; } }

.nc-radio-button-group {
  margin-bottom: 10px; }
  @media (max-width: 767px) {
    .nc-radio-button-group .nc-radio-button, .nc-radio-button-group .nc-radio-basic-long-button {
      width: 48%; } }
  .nc-radio-button-group .nc-radio-button:last-child, .nc-radio-button-group .nc-radio-basic-long-button:last-child {
    margin-right: 0px; }

.nc-radio-button:hover, .nc-radio-basic-long-button:hover {
  background-color: #ccffff;
  border: 1px solid #00cccc;
  cursor: pointer; }

@media (min-width: 768px) {
  .nc-radio-basic-long-button {
    min-width: 125px;
    width: inherit; } }

.nc-radio-basic-long-button .nc-radio-button-row .radio-content {
  padding: 10px; }
  @media (max-width: 767px) {
    .nc-radio-basic-long-button .nc-radio-button-row .radio-content {
      padding: 10px; } }

.nc-radio-description-button {
  height: 50px !important;
  width: auto; }
  @media (min-width: 768px) {
    .nc-radio-description-button {
      min-width: 185px !important;
      max-width: 300px; } }
  @media (max-width: 767px) {
    .nc-radio-description-button {
      width: 100% !important; } }
  .nc-radio-description-button .radio-content {
    text-align: center !important;
    padding-right: 10px !important; }
    .nc-radio-description-button .radio-content .title {
      text-transform: uppercase; }
      @media (max-width: 767px) {
        .nc-radio-description-button .radio-content .title {
          float: left;
          padding-left: 10px;
          text-align: left;
          padding-right: 25px; } }
    .nc-radio-description-button .radio-content .description {
      font-size: 14px;
      font-style: italic; }
      @media (max-width: 767px) {
        .nc-radio-description-button .radio-content .description {
          float: left;
          padding-left: 10px;
          text-align: left; } }

.nc-radio-description-title-button {
  height: 50px !important;
  width: auto; }
  @media (min-width: 768px) {
    .nc-radio-description-title-button {
      min-width: 185px !important;
      max-width: 300px; } }
  @media (max-width: 767px) {
    .nc-radio-description-title-button {
      width: 100% !important; } }
  .nc-radio-description-title-button .radio-content {
    text-align: center !important;
    padding-right: 10px !important; }
    @media (max-width: 767px) {
      .nc-radio-description-title-button .radio-content .title {
        float: left;
        padding-left: 10px;
        text-align: left;
        padding-right: 25px; } }
    .nc-radio-description-title-button .radio-content .description {
      font-size: 14px;
      font-style: italic; }
      @media (max-width: 767px) {
        .nc-radio-description-title-button .radio-content .description {
          float: left;
          padding-left: 10px;
          text-align: left; } }

.nc-radio-long-description-button {
  height: 70px !important; }
  @media (max-width: 767px) {
    .nc-radio-long-description-button {
      width: 100% !important; } }
  .nc-radio-long-description-button .radio-content {
    text-align: center !important;
    padding-right: 10px !important; }
    .nc-radio-long-description-button .radio-content .title {
      text-transform: uppercase; }
      @media (max-width: 767px) {
        .nc-radio-long-description-button .radio-content .title {
          padding-left: 10px;
          text-align: left; } }
    .nc-radio-long-description-button .radio-content .description {
      font-size: 14px;
      font-style: italic; }
      @media (max-width: 767px) {
        .nc-radio-long-description-button .radio-content .description {
          padding-left: 10px;
          text-align: left; } }

.nc-radio-description-long-title-button {
  width: 185px !important; }
  @media (max-width: 767px) {
    .nc-radio-description-long-title-button {
      width: 100% !important; } }
  .nc-radio-description-long-title-button .radio-content {
    text-align: center !important;
    padding: 10px 10px 10px 0px !important; }
    .nc-radio-description-long-title-button .radio-content .title {
      text-transform: uppercase; }
      @media (max-width: 767px) {
        .nc-radio-description-long-title-button .radio-content .title {
          padding-left: 10px;
          text-align: left; } }
    .nc-radio-description-long-title-button .radio-content .description {
      font-size: 14px;
      font-style: italic; }
      @media (max-width: 767px) {
        .nc-radio-description-long-title-button .radio-content .description {
          padding-left: 10px;
          text-align: left; } }

.nc-radio-two-title-button {
  min-height: 56px !important;
  max-height: 56px !important;
  padding: 0px 10px 0px 10px !important; }
  @media (max-width: 767px) {
    .nc-radio-two-title-button {
      width: 100% !important; } }
  .nc-radio-two-title-button .title {
    text-transform: capitalize; }

.nc-radio-three-title-button {
  min-height: 74px !important;
  max-height: 74px !important;
  padding: 0px 10px 0px 10px !important; }
  @media (max-width: 767px) {
    .nc-radio-three-title-button {
      width: 100% !important; } }
  .nc-radio-three-title-button .title {
    text-transform: capitalize; }

.nc-radio-title-button {
  height: 50px !important;
  padding: 0px 10px 0px 0px !important;
  width: 185px !important; }
  @media (max-width: 767px) {
    .nc-radio-title-button {
      width: 100% !important; } }

.nc-radio-heavy-button {
  width: 275px !important;
  min-height: 140px !important; }
  @media (max-width: 767px) {
    .nc-radio-heavy-button {
      width: 100% !important; } }
  .nc-radio-heavy-button .input-radio {
    width: 12px !important; }
  .nc-radio-heavy-button .radio-content {
    text-align: left !important; }
    @media (max-width: 767px) {
      .nc-radio-heavy-button .radio-content {
        padding-left: 10px !important; } }
    .nc-radio-heavy-button .radio-content .description {
      font-size: 14px; }
    .nc-radio-heavy-button .radio-content .fa-icon {
      margin-top: 10px; }

.rb-selected {
  background-color: #ccffff;
  border: 1px solid #00cccc; }
  .rb-selected .title {
    font-weight: 600; }

.rb-unselected {
  background-color: #ffffff;
  border: 1px solid #999; }

.readonly-radio-button {
  margin-bottom: 10px; }

.__disabled-radio-button {
  border: 1px solid #ccc;
  color: #999; }
  .__disabled-radio-button:hover {
    background-color: inherit;
    border: 1px solid #ccc; }
  .__disabled-radio-button.rb-selected {
    background-color: #e9e9e9;
    border: 1px solid #ccc;
    color: #999; }
    .__disabled-radio-button.rb-selected .radio-content {
      color: #999 !important; }
    .__disabled-radio-button.rb-selected span span.checked {
      background-color: #999; }
    .__disabled-radio-button.rb-selected .title {
      font-weight: 400; }
  .__disabled-radio-button.rb-unselected {
    border: 1px solid #ccc;
    color: #ccc; }
    .__disabled-radio-button.rb-unselected input[type="radio"] + span {
      border: 1px solid #ccc; }
    .__disabled-radio-button.rb-unselected .radio-content {
      color: #ccc !important; }

.disabled-cursor, .__disabled-dropdown, .__disabled-radio-button .nc-radio-button-input, .__disabled-radio-button .nc-radio-button-input span, .__disabled-radio-button .nc-radio-button-input span span.checked, .__disabled-radio-button:hover {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

.status-tag {
  font-style: normal !important;
  padding-top: 7px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 7px !important;
  border-radius: 6px;
  text-align: center; }

.inprogress, .transferred {
  background-color: #ffffcc;
  width: 100px; }

.progress {
  background-color: #ffffcc;
  width: 100px;
  box-shadow: none; }

.active {
  background-color: #ccffcc;
  width: 70px; }

.active-green-status {
  color: #ccffcc; }

.inactive-grey-status {
  color: #cccccc; }

.inprogress-yellow-status {
  color: #ffffcc; }

.terminated-red-status {
  color: #fdb5ad; }

.inactive, .onleave {
  background-color: #cccccc;
  width: 85px; }

.terminated, .retired, .deceased {
  background-color: #fdb5ad; }

.nc-page-container, .nc-list-page-container, .nc-entity-page-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container, .nc-activity-history-page-container {
  height: 100% !important;
  position: relative !important;
  padding: 30px 55px;
  color: #555 !important;
  font-family: "Source Sans Pro", sans-serif !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .nc-page-container, .nc-list-page-container, .nc-entity-page-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container, .nc-activity-history-page-container {
      padding: 10px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .nc-page-container, .nc-list-page-container, .nc-entity-page-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container, .nc-activity-history-page-container {
      padding: 20px !important; } }
  .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
    padding-bottom: 15px; }
    .nc-page-container .pageTitle-container .pageTitle, .nc-list-page-container .pageTitle-container .pageTitle, .nc-entity-page-container .pageTitle-container .pageTitle, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container .pageTitle, .nc-activity-history-page-container .pageTitle-container .pageTitle {
      float: left;
      font-size: 25px;
      font-weight: 400; }
      @media (max-width: 767px) {
        .nc-page-container .pageTitle-container .pageTitle, .nc-list-page-container .pageTitle-container .pageTitle, .nc-entity-page-container .pageTitle-container .pageTitle, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container .pageTitle, .nc-activity-history-page-container .pageTitle-container .pageTitle {
          float: initial;
          font-size: 25px;
          /* DEVIATION : This was added because visually it did not look good without this */ } }
    @media (min-width: 768px) and (max-width: 991px) {
      .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
        padding-bottom: 15px; } }
    @media (max-width: 767px) {
      .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
        padding-bottom: 10px; } }
  .nc-page-container p, .nc-list-page-container p, .nc-entity-page-container p, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container p, .nc-activity-history-page-container p {
    line-height: 1.3; }

.nc-page-container::before, .nc-list-page-container::before, .nc-entity-page-container::before, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container::before, .nc-activity-history-page-container::before {
  display: table;
  content: " "; }

.nc-page-container::after, .nc-list-page-container::after, .nc-entity-page-container::after, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container::after, .nc-activity-history-page-container::after {
  display: table;
  content: " ";
  clear: both; }

.nc-field-row {
  margin-bottom: 10px; }
  .nc-field-row .field-name {
    font-weight: 600; }
  .nc-field-row .edit-icon:hover {
    cursor: pointer; }

.open-deduction-based-popover-for-information-extra-small-type {
  background-color: #e9e9e9 !important;
  padding: 10px !important;
  margin-bottom: 30px !important; }
  @media (min-width: 320px) and (max-width: 479px) {
    .open-deduction-based-popover-for-information-extra-small-type {
      width: 98% !important; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .open-deduction-based-popover-for-information-extra-small-type {
      width: 98% !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .open-deduction-based-popover-for-information-extra-small-type {
      width: 98% !important; } }
  .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
    border-radius: 50px !important;
    width: 30px;
    height: 30px;
    background-color: #019999 !important;
    border: none !important;
    color: white;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center; }
    .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close i {
      color: #ffffff !important;
      font-size: 18px;
      margin-right: 0px;
      margin-left: 0px !important; }
    @media (min-width: 320px) and (max-width: 479px) {
      .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
    @media (min-width: 480px) and (max-width: 767px) {
      .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
    @media (min-width: 992px) {
      .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
  .open-deduction-based-popover-for-information-extra-small-type .deduction-popover-information-data {
    padding: 10px;
    font-size: 15px;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 400 !important;
    color: #555555 !important; }

.nc-entity-page-container .seperator, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .seperator {
  clear: both; }
  .nc-entity-page-container .seperator hr, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .seperator hr {
    border-color: #ccc;
    margin-top: 0px; }

@media (min-width: 481px) and (max-width: 767px) {
  .nc-entity-page-container .textinput-with-after-sign-set-width, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .textinput-with-after-sign-set-width {
    width: 70px;
    margin-top: 10px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .nc-entity-page-container .textinput-with-after-sign-set-width, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .textinput-with-after-sign-set-width {
    width: 100px;
    margin-top: 10px; } }

@media (min-width: 992px) and (max-width: 1198px) {
  .nc-entity-page-container .textinput-with-after-sign-set-width, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .textinput-with-after-sign-set-width {
    width: 70px;
    max-width: 550px !important;
    padding-top: 10px; } }

@media (min-width: 1199px) {
  .nc-entity-page-container .textinput-with-after-sign-set-width, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .textinput-with-after-sign-set-width {
    width: 100px;
    max-width: 550px !important; } }

.nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type {
  background-color: #e9e9e9 !important;
  padding: 10px !important;
  margin-bottom: 30px !important; }
  @media (min-width: 320px) and (max-width: 479px) {
    .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type {
      width: 98% !important; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type {
      width: 98% !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type {
      width: 98% !important; } }
  .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
    border-radius: 50px !important;
    width: 30px;
    height: 30px;
    background-color: #019999 !important;
    border: none !important;
    color: white;
    text-align: center;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center; }
    .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close i, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close i {
      color: #ffffff !important;
      font-size: 18px;
      margin-right: 0px;
      margin-left: 0px !important; }
    @media (min-width: 320px) and (max-width: 479px) {
      .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
    @media (min-width: 480px) and (max-width: 767px) {
      .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
    @media (min-width: 992px) {
      .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
  .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .deduction-popover-information-data, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .deduction-popover-information-data {
    padding: 10px;
    font-size: 15px;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 400 !important;
    color: #555555 !important; }

@media (min-width: 768px) {
  .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container {
    padding-bottom: 15px; } }

.nc-entity-page-container .page-description, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .page-description {
  font-size: 15px;
  line-height: 1.2;
  padding-bottom: 20px; }

.nc-entity-page-container .page-description-button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .page-description-button {
  float: right; }
  .nc-entity-page-container .page-description-button button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .page-description-button button {
    float: right; }
    @media (max-width: 991px) {
      .nc-entity-page-container .page-description-button button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .page-description-button button {
        float: left; } }
  @media (max-width: 991px) {
    .nc-entity-page-container .page-description-button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .page-description-button {
      float: left;
      margin-bottom: 20px; } }

.nc-entity-page-container .title-band-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .title-band-container {
  clear: both;
  margin-bottom: 20px; }
  @media (max-width: 991px) {
    .nc-entity-page-container .title-band-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .title-band-container {
      margin-bottom: 0px; } }
  .nc-entity-page-container .title-band-container .title-band, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .title-band-container .title-band {
    background-color: #396;
    padding: 12px 0 10px 20px;
    color: #fff;
    font-weight: 400;
    height: 45px;
    font-size: 18px;
    margin-left: 0; }

@media (max-width: 991px) {
  .nc-entity-page-container .title-band-container-sm, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .title-band-container-sm {
    margin-bottom: 15px; } }

.nc-entity-page-container .entity-page-content .step-title-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .step-title-container {
  font-size: 18px;
  margin-bottom: 20px; }
  .nc-entity-page-container .entity-page-content .step-title-container .step-number, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .step-title-container .step-number {
    background-color: #555;
    border-radius: 100px;
    font-size: 22px;
    font-weight: 700;
    float: left;
    height: 36px;
    margin-right: 15px;
    padding: 7px 10px 10px !important;
    width: 36px; }
  .nc-entity-page-container .entity-page-content .step-title-container .step-title, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .step-title-container .step-title {
    font-weight: 600;
    font-size: 18px;
    color: #555;
    float: left;
    margin-top: 5px; }

.nc-entity-page-container .entity-page-content .step-description, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .step-description {
  clear: both;
  color: #555;
  font-size: 15px;
  font-weight: 400; }

.nc-entity-page-container .entity-page-content .description-content .fa-calculator, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .fa-calculator {
  color: #fff !important; }

@media (max-width: 768px) {
  .nc-entity-page-container .entity-page-content .description-content .contains-single-link button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .contains-single-link button {
    width: 100%; } }

.nc-entity-page-container .entity-page-content .description-content .deduction-name-input, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .deduction-name-input {
  margin-bottom: 10px; }
  .nc-entity-page-container .entity-page-content .description-content .deduction-name-input i, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .deduction-name-input i {
    margin-left: 5px;
    color: #009999; }
  .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .edit-icon a, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .edit-icon a {
    float: right; }
    .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .edit-icon a i, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .edit-icon a i {
      color: #555; }
  .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .review-field, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .review-field {
    font-size: 15px;
    margin-bottom: 5px; }
  .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .deduction-name-description, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .deduction-name-description {
    margin-bottom: 15px;
    font-size: 15px; }
  .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .input-field, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .input-field {
    margin-right: 0px; }

.nc-entity-page-container .entity-page-content .seperator, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .seperator {
  clear: both; }
  .nc-entity-page-container .entity-page-content .seperator hr, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .seperator hr {
    border-color: #ccc; }

.nc-entity-page-container .progress-tracker, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker {
  background-color: #e9e9e9;
  border-radius: 7.5px;
  margin-top: 20px;
  float: right;
  padding-top: 30px;
  padding-right: 10px;
  padding-bottom: 17.5px;
  padding-left: 10px;
  width: 250px; }
  @media (min-width: 1220px) {
    .nc-entity-page-container .progress-tracker, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker {
      margin-left: 5px; } }
  .nc-entity-page-container .progress-tracker .title, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .title {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 20px;
    text-align: center; }
  .nc-entity-page-container .progress-tracker .step-menu, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .step-menu {
    list-style-type: none;
    padding-left: 0px; }
    .nc-entity-page-container .progress-tracker .step-menu .step-menu-item, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .step-menu .step-menu-item {
      border-radius: 3px;
      color: #555;
      font-size: 15px;
      font-weight: 400;
      padding-top: 12.5px;
      padding-bottom: 12.5px;
      padding-left: 8px; }
      .nc-entity-page-container .progress-tracker .step-menu .step-menu-item i, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .step-menu .step-menu-item i {
        color: #339966;
        font-size: 15px;
        margin-right: 14px; }
    .nc-entity-page-container .progress-tracker .step-menu .accessible-step:hover, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .step-menu .accessible-step:hover {
      background-color: #cccccb;
      padding-top: 12.5px;
      padding-bottom: 12.5px;
      cursor: pointer; }
    .nc-entity-page-container .progress-tracker .step-menu .inaccessible-step:hover, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .step-menu .inaccessible-step:hover {
      padding-top: 12.5px;
      padding-bottom: 12.5px; }
    .nc-entity-page-container .progress-tracker .step-menu .inaccessible-step i, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .step-menu .inaccessible-step i {
      visibility: hidden; }
    .nc-entity-page-container .progress-tracker .step-menu .active-step, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .step-menu .active-step {
      background-color: #9c9;
      color: #333;
      font-size: 15px;
      font-weight: 600;
      padding-top: 12.5px;
      padding-bottom: 12.5px;
      cursor: pointer; }
    .nc-entity-page-container .progress-tracker .step-menu .active-step:hover, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .step-menu .active-step:hover {
      background-color: #9c9; }

.nc-entity-page-container .taxability .taxability-title, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .taxability .taxability-title {
  color: #009966;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px; }

.nc-entity-page-container .taxability .taxability-content, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .taxability .taxability-content {
  padding-left: 10px; }
  .nc-entity-page-container .taxability .taxability-content .field .deduction-name-description, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .taxability .taxability-content .field .deduction-name-description {
    margin-bottom: 10px;
    font-size: 15px; }

@media (min-width: 992px) {
  .nc-entity-page-container .footer-button-container .nc-button-group button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group button {
    margin-right: 20px; } }

@media (max-width: 767px) {
  .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile {
    width: 205px;
    margin: auto;
    display: flex;
    justify-content: center; } }

@media (min-width: 768px) {
  .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile {
    display: inline; } }

.nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button {
  margin-right: 20px; }
  @media (min-width: 992px) {
    .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button {
      margin-right: 20px; } }

@media (max-width: 767px) {
  .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button {
    width: 100%; } }

@media (min-width: 768px) {
  .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button {
    display: inline; } }

.nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button button {
  margin-right: 20px; }
  @media (min-width: 992px) {
    .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button button {
      margin-right: 20px; } }
  @media (max-width: 767px) {
    .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button button {
      width: 100%; } }

.nc-entity-page-container::before, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container::before {
  display: table;
  content: " "; }

.nc-entity-page-container::after, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container::after {
  display: table;
  content: " ";
  clear: both; }

.row-simple-value {
  margin-bottom: 5px; }

.nc-enity-snapshot-summary .nc-field-group .edit-icon a {
  float: right; }
  .nc-enity-snapshot-summary .nc-field-group .edit-icon a .fa-pencil {
    color: #555; }

.nc-enity-snapshot-summary .nc-field-group .field-name {
  margin-bottom: 15px;
  padding-right: 15px;
  font-size: 15px;
  font-weight: 600; }
  .nc-enity-snapshot-summary .nc-field-group .field-name .field-description {
    color: #999;
    margin-top: 5px; }

.nc-enity-snapshot-summary .nc-field-group .field-description {
  color: #999; }

.nc-enity-snapshot-summary .nc-field-group .field-information {
  font-size: 14px !important;
  margin-bottom: 25px; }

@media (min-width: 992px) {
  .nc-enity-snapshot-summary .nc-field-group .inline-field-description {
    margin-top: 5px;
    font-size: 15px !important;
    color: #999;
    margin-left: 5px; } }

.nc-enity-snapshot-summary .nc-field-group .input-field {
  margin-bottom: 15px; }
  .nc-enity-snapshot-summary .nc-field-group .input-field .form-group {
    margin-bottom: 0px; }

.nc-enity-snapshot-summary .nc-field-group .radio-button-group .single-radio-button {
  display: inline; }
  @media (max-width: 767px) {
    .nc-enity-snapshot-summary .nc-field-group .radio-button-group .single-radio-button {
      width: 50%;
      float: left; } }

@media (max-width: 767px) {
  .nc-enity-snapshot-summary .nc-field-group .radio-button-group .single-radio-button:nth-of-type(odd) {
    padding-right: 5px; } }

@media (max-width: 767px) {
  .nc-enity-snapshot-summary .nc-field-group .radio-button-group .single-radio-button:nth-of-type(even) {
    padding-left: 5px; } }

.list-page-cancel.modal {
  padding-left: 0px !important; }
  .list-page-cancel.modal .modal-dialog {
    width: 600px; }
    @media (max-width: 767px) {
      .list-page-cancel.modal .modal-dialog {
        margin: 20px;
        width: auto !important; } }
    .list-page-cancel.modal .modal-dialog .modal-content {
      border-radius: 0px;
      box-shadow: none !important; }
      @media (min-width: 768px) {
        .list-page-cancel.modal .modal-dialog .modal-content {
          padding: 30px; } }
      @media (max-width: 767px) {
        .list-page-cancel.modal .modal-dialog .modal-content {
          padding: 20px; } }
      .list-page-cancel.modal .modal-dialog .modal-content .modal-header {
        border-bottom: none;
        padding: 0px; }
        .list-page-cancel.modal .modal-dialog .modal-content .modal-header .close-icon {
          text-align: right; }
          @media (min-width: 768px) {
            .list-page-cancel.modal .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: -15px;
              margin-left: 15px;
              margin-bottom: 5px; } }
          @media (max-width: 767px) {
            .list-page-cancel.modal .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: 0px;
              margin-left: 0px; } }
          .list-page-cancel.modal .modal-dialog .modal-content .modal-header .close-icon i {
            cursor: pointer;
            margin-top: 0px;
            margin-right: 0px;
            color: #555; }
      .list-page-cancel.modal .modal-dialog .modal-content .modal-body {
        color: #555;
        font-size: 18px;
        padding: 0px; }
        .list-page-cancel.modal .modal-dialog .modal-content .modal-body .modal-row {
          margin-bottom: 20px;
          text-align: center; }
      .list-page-cancel.modal .modal-dialog .modal-content .modal-footer {
        border: none;
        padding-bottom: 0px;
        text-align: center; }
        .list-page-cancel.modal .modal-dialog .modal-content .modal-footer .button-group {
          width: 275px;
          margin-left: auto;
          margin-right: auto; }
          .list-page-cancel.modal .modal-dialog .modal-content .modal-footer .button-group button {
            margin-right: 15px; }
            @media (max-width: 767px) {
              .list-page-cancel.modal .modal-dialog .modal-content .modal-footer .button-group button {
                margin-bottom: 15px;
                margin-right: 0px; } }
          .list-page-cancel.modal .modal-dialog .modal-content .modal-footer .button-group button:last-child {
            margin-bottom: 0px;
            margin-right: 0px; }
          @media (max-width: 767px) {
            .list-page-cancel.modal .modal-dialog .modal-content .modal-footer .button-group {
              width: 100%; } }
        @media (max-width: 767px) {
          .list-page-cancel.modal .modal-dialog .modal-content .modal-footer {
            padding: 0px; }
            .list-page-cancel.modal .modal-dialog .modal-content .modal-footer button {
              width: 100%; } }

.list-page-cancel.modal {
  padding-left: 0px !important; }
  .list-page-cancel.modal .modal-dialog {
    width: 600px; }
    @media (max-width: 767px) {
      .list-page-cancel.modal .modal-dialog {
        margin: 20px;
        width: auto !important; } }
    .list-page-cancel.modal .modal-dialog .modal-content {
      border-radius: 0px;
      box-shadow: none !important; }
      @media (min-width: 768px) {
        .list-page-cancel.modal .modal-dialog .modal-content {
          padding: 30px; } }
      @media (max-width: 767px) {
        .list-page-cancel.modal .modal-dialog .modal-content {
          padding: 20px; } }
      .list-page-cancel.modal .modal-dialog .modal-content .modal-header {
        border-bottom: none;
        padding: 0px; }
        .list-page-cancel.modal .modal-dialog .modal-content .modal-header .close-icon {
          text-align: right; }
          @media (min-width: 768px) {
            .list-page-cancel.modal .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: -15px;
              margin-left: 15px;
              margin-bottom: 5px; } }
          @media (max-width: 767px) {
            .list-page-cancel.modal .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: 0px;
              margin-left: 0px; } }
          .list-page-cancel.modal .modal-dialog .modal-content .modal-header .close-icon i {
            cursor: pointer;
            margin-top: 0px;
            margin-right: 0px;
            color: #555; }
      .list-page-cancel.modal .modal-dialog .modal-content .modal-body {
        color: #555;
        font-size: 18px;
        padding: 0px; }
        .list-page-cancel.modal .modal-dialog .modal-content .modal-body .modal-row {
          margin-bottom: 20px; }
      .list-page-cancel.modal .modal-dialog .modal-content .modal-footer {
        border: none;
        padding-bottom: 0px;
        text-align: center; }
        .list-page-cancel.modal .modal-dialog .modal-content .modal-footer .button-group {
          width: 300px;
          margin-left: auto;
          margin-right: auto; }
          .list-page-cancel.modal .modal-dialog .modal-content .modal-footer .button-group button {
            margin-right: 15px; }
            @media (max-width: 767px) {
              .list-page-cancel.modal .modal-dialog .modal-content .modal-footer .button-group button {
                margin-bottom: 15px;
                margin-right: 0px; } }
          .list-page-cancel.modal .modal-dialog .modal-content .modal-footer .button-group button:last-child {
            margin-bottom: 0px;
            margin-right: 0px; }
          @media (max-width: 767px) {
            .list-page-cancel.modal .modal-dialog .modal-content .modal-footer .button-group {
              width: 100%; } }
        @media (max-width: 767px) {
          .list-page-cancel.modal .modal-dialog .modal-content .modal-footer {
            padding: 0px; }
            .list-page-cancel.modal .modal-dialog .modal-content .modal-footer button {
              width: 100%; } }

.entity-page-add-modal.modal {
  padding-left: 0px !important; }
  .entity-page-add-modal.modal .modal-dialog {
    width: 750px; }
    @media (max-width: 767px) {
      .entity-page-add-modal.modal .modal-dialog {
        margin: 0px;
        width: auto !important; } }
    .entity-page-add-modal.modal .modal-dialog .modal-content {
      border-radius: 0px;
      box-shadow: none !important;
      border: 0px !important;
      outline: 0px !important; }
      @media (min-width: 768px) {
        .entity-page-add-modal.modal .modal-dialog .modal-content {
          padding: 30px; } }
      @media (max-width: 767px) {
        .entity-page-add-modal.modal .modal-dialog .modal-content {
          padding: 20px; } }
      .entity-page-add-modal.modal .modal-dialog .modal-content .modal-header {
        border-bottom: none;
        padding: 0px; }
        .entity-page-add-modal.modal .modal-dialog .modal-content .modal-header .close-icon {
          text-align: right; }
          @media (min-width: 768px) {
            .entity-page-add-modal.modal .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: -15px;
              margin-left: 15px;
              margin-bottom: 5px; } }
          @media (max-width: 767px) {
            .entity-page-add-modal.modal .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: 0px;
              margin-left: 0px; } }
          .entity-page-add-modal.modal .modal-dialog .modal-content .modal-header .close-icon i {
            cursor: pointer;
            margin-top: 0px;
            margin-right: 0px;
            font-size: 20px; }
      .entity-page-add-modal.modal .modal-dialog .modal-content .modal-body {
        color: #555;
        font-size: 18px;
        padding: 0px; }
        .entity-page-add-modal.modal .modal-dialog .modal-content .modal-body .modal-title {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px; }
        .entity-page-add-modal.modal .modal-dialog .modal-content .modal-body .modal-description {
          font-size: 14px; }
        .entity-page-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab {
          margin-top: 25px;
          font-size: 15px; }
          .entity-page-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li {
            min-height: 50px; }
          .entity-page-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li.active {
            width: inherit;
            background-color: transparent !important; }
            @media (min-width: 320px) and (max-width: 767px) {
              .entity-page-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li.active {
                width: 100%; } }
            @media (min-width: 768px) and (max-width: 991px) {
              .entity-page-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li.active {
                width: 50% !important; } }
            .entity-page-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li.active > a {
              border-top: 4px solid #339966; }
        .entity-page-add-modal.modal .modal-dialog .modal-content .modal-body .tab-content .tab-pane .nc-tab-content .modal-section {
          margin-bottom: 20px; }
          .entity-page-add-modal.modal .modal-dialog .modal-content .modal-body .tab-content .tab-pane .nc-tab-content .modal-section .field-desciption {
            color: #999; }
        .entity-page-add-modal.modal .modal-dialog .modal-content .modal-body .modal-row {
          margin-bottom: 20px; }
      .entity-page-add-modal.modal .modal-dialog .modal-content .modal-footer {
        border: none;
        clear: both;
        padding: 0px !important; }
        .entity-page-add-modal.modal .modal-dialog .modal-content .modal-footer .button-group {
          border-top: 1px solid #ccc;
          padding-top: 20px;
          text-align: left; }
          .entity-page-add-modal.modal .modal-dialog .modal-content .modal-footer .button-group button {
            margin-right: 15px; }
            @media (max-width: 767px) {
              .entity-page-add-modal.modal .modal-dialog .modal-content .modal-footer .button-group button {
                margin-bottom: 15px;
                margin-right: 0px; } }
          .entity-page-add-modal.modal .modal-dialog .modal-content .modal-footer .button-group button:last-child {
            margin-bottom: 0px;
            margin-right: 0px; }
        @media (max-width: 767px) {
          .entity-page-add-modal.modal .modal-dialog .modal-content .modal-footer {
            padding: 0px; }
            .entity-page-add-modal.modal .modal-dialog .modal-content .modal-footer button {
              width: 100%; } }

.entity-page-add-modal-lg.modal {
  padding-left: 0px !important; }
  @media (min-width: 768px) and (max-width: 991px) {
    .entity-page-add-modal-lg.modal .modal-dialog {
      width: auto !important;
      margin: 10px; } }
  @media (max-width: 767px) {
    .entity-page-add-modal-lg.modal .modal-dialog {
      margin: 0px;
      width: auto !important; } }
  @media (min-width: 992px) {
    .entity-page-add-modal-lg.modal .modal-dialog {
      width: 950px; } }
  .entity-page-add-modal-lg.modal .modal-dialog .modal-content {
    border-radius: 0px;
    box-shadow: none !important;
    border: 0px !important;
    outline: 0px !important; }
    @media (min-width: 768px) {
      .entity-page-add-modal-lg.modal .modal-dialog .modal-content {
        padding: 30px; } }
    @media (max-width: 767px) {
      .entity-page-add-modal-lg.modal .modal-dialog .modal-content {
        padding: 20px; } }
    .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-header {
      border-bottom: none;
      padding: 0px; }
      .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-header .close-icon {
        text-align: right; }
        @media (min-width: 768px) {
          .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-header .close-icon {
            margin-top: -15px;
            margin-left: 15px;
            margin-bottom: 5px; } }
        @media (max-width: 767px) {
          .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-header .close-icon {
            margin-top: 0px;
            margin-left: 0px; } }
        .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-header .close-icon i {
          cursor: pointer;
          margin-top: 0px;
          margin-right: 0px;
          font-size: 20px; }
    .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body {
      color: #555;
      padding: 0px; }
      .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container {
        padding: 0 !important; }
        .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .page-title {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 5px; }
      .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container {
        padding: 0px !important; }
      .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .modal-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px; }
      .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .modal-description {
        font-size: 14px; }
      .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab {
        margin-top: 25px;
        font-size: 15px; }
        .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li {
          min-height: 50px; }
        .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li.active {
          width: inherit;
          background-color: transparent !important; }
          @media (min-width: 320px) and (max-width: 767px) {
            .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li.active {
              width: 100%; } }
          @media (min-width: 768px) and (max-width: 991px) {
            .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li.active {
              width: 50% !important; } }
          .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li.active > a {
            border-top: 4px solid #339966; }
      .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .tab-content .tab-pane .nc-tab-content .modal-section {
        margin-bottom: 20px; }
        .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .tab-content .tab-pane .nc-tab-content .modal-section .field-desciption {
          color: #999; }
      .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .modal-row {
        margin-bottom: 20px; }
    .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-footer {
      border: none;
      clear: both;
      padding: 0px !important; }
      .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-footer .button-group {
        border-top: 1px solid #ccc;
        padding-top: 20px;
        text-align: left; }
        .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-footer .button-group button {
          margin-right: 15px; }
          @media (max-width: 767px) {
            .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-footer .button-group button {
              margin-bottom: 15px;
              margin-right: 0px; } }
        .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-footer .button-group button:last-child {
          margin-bottom: 0px;
          margin-right: 0px; }
      @media (max-width: 767px) {
        .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-footer {
          padding: 0px; }
          .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-footer button {
            width: 100%; } }

.entity-page-add-modal-sm.modal {
  padding-left: 0px !important; }
  @media (min-width: 768px) and (max-width: 991px) {
    .entity-page-add-modal-sm.modal .modal-dialog {
      width: auto !important;
      margin: 10px; } }
  @media (max-width: 767px) {
    .entity-page-add-modal-sm.modal .modal-dialog {
      margin: 20px;
      width: auto !important; } }
  @media (min-width: 992px) {
    .entity-page-add-modal-sm.modal .modal-dialog {
      width: 500px; } }
  .entity-page-add-modal-sm.modal .modal-dialog .modal-content {
    border-radius: 0px;
    box-shadow: none !important;
    border: 0px !important;
    outline: 0px !important; }
    @media (min-width: 768px) {
      .entity-page-add-modal-sm.modal .modal-dialog .modal-content {
        padding: 30px; } }
    @media (max-width: 767px) {
      .entity-page-add-modal-sm.modal .modal-dialog .modal-content {
        padding: 20px; } }
    .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-header {
      border-bottom: none;
      padding: 0px; }
      .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-header .close-icon {
        text-align: right; }
        @media (min-width: 768px) {
          .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-header .close-icon {
            margin-top: -15px;
            margin-left: 15px;
            margin-bottom: 5px; } }
        @media (max-width: 767px) {
          .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-header .close-icon {
            margin-top: 0px;
            margin-left: 0px; } }
        .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-header .close-icon i {
          cursor: pointer;
          margin-top: 0px;
          margin-right: 0px; }
    .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-body {
      color: #555;
      font-size: 18px;
      padding: 0px; }
      .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container {
        padding: 0px !important; }
      .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-body .modal-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px; }
      .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-body .modal-description {
        font-size: 14px; }
      .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab {
        margin-top: 25px;
        font-size: 15px; }
        .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li {
          min-height: 50px; }
        .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li.active {
          width: inherit;
          background-color: transparent !important; }
          @media (min-width: 320px) and (max-width: 767px) {
            .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li.active {
              width: 100%; } }
          @media (min-width: 768px) and (max-width: 991px) {
            .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li.active {
              width: 50% !important; } }
          .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li.active > a {
            border-top: 4px solid #339966; }
      .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-body .tab-content .tab-pane .nc-tab-content .modal-section {
        margin-bottom: 20px; }
        .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-body .tab-content .tab-pane .nc-tab-content .modal-section .field-desciption {
          color: #999; }
      .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-body .modal-row {
        margin-bottom: 20px; }
      @media (max-width: 991px) {
        .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-body .entity-form-section-secondary .content .nc-field-group .field-name {
          margin-bottom: 5px; }
        .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-body .entity-form-section-secondary .content .nc-field-group .field-description {
          margin-bottom: 25px; }
        .entity-page-add-modal-sm.modal .modal-dialog .modal-content .modal-body .entity-form-section-secondary .content .nc-field-group .input-field {
          margin-bottom: 5px; } }
  .entity-page-add-modal-sm.modal .modal-dialog .modal-footer {
    border: none;
    clear: both;
    padding: 0px !important; }
    .entity-page-add-modal-sm.modal .modal-dialog .modal-footer .button-group {
      border-top: 1px solid #ccc;
      padding-top: 20px;
      text-align: left; }
      .entity-page-add-modal-sm.modal .modal-dialog .modal-footer .button-group button {
        margin-right: 15px; }
        @media (max-width: 767px) {
          .entity-page-add-modal-sm.modal .modal-dialog .modal-footer .button-group button {
            margin-bottom: 15px;
            margin-right: 0px; } }
      .entity-page-add-modal-sm.modal .modal-dialog .modal-footer .button-group button:last-child {
        margin-bottom: 0px;
        margin-right: 0px; }
    @media (max-width: 767px) {
      .entity-page-add-modal-sm.modal .modal-dialog .modal-footer {
        padding: 0px; }
        .entity-page-add-modal-sm.modal .modal-dialog .modal-footer button {
          width: 100%; } }

.entity-page-cancel-modal.modal {
  padding-left: 0px !important; }
  .entity-page-cancel-modal.modal .modal-dialog {
    width: 675px; }
    @media (max-width: 767px) {
      .entity-page-cancel-modal.modal .modal-dialog {
        margin: 20px;
        width: auto !important; } }
    .entity-page-cancel-modal.modal .modal-dialog .modal-content {
      border-radius: 0px;
      box-shadow: none !important; }
      @media (min-width: 768px) {
        .entity-page-cancel-modal.modal .modal-dialog .modal-content {
          padding: 30px; } }
      @media (max-width: 767px) {
        .entity-page-cancel-modal.modal .modal-dialog .modal-content {
          padding: 20px; } }
      .entity-page-cancel-modal.modal .modal-dialog .modal-content .modal-header {
        border-bottom: none;
        padding: 0px; }
        .entity-page-cancel-modal.modal .modal-dialog .modal-content .modal-header .close-icon {
          text-align: right; }
          @media (min-width: 768px) {
            .entity-page-cancel-modal.modal .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: -15px;
              margin-left: 15px; } }
          @media (max-width: 767px) {
            .entity-page-cancel-modal.modal .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: 0px;
              margin-left: 0px; } }
          .entity-page-cancel-modal.modal .modal-dialog .modal-content .modal-header .close-icon i {
            cursor: pointer;
            margin-top: 0px;
            margin-right: 0px; }
      .entity-page-cancel-modal.modal .modal-dialog .modal-content .modal-body {
        color: #555;
        font-size: 18px;
        padding: 0px; }
        .entity-page-cancel-modal.modal .modal-dialog .modal-content .modal-body .modal-row {
          margin-bottom: 20px; }
      .entity-page-cancel-modal.modal .modal-dialog .modal-content .modal-footer {
        border: none;
        clear: both;
        padding: 0px; }
        .entity-page-cancel-modal.modal .modal-dialog .modal-content .modal-footer .button-group {
          margin-left: auto;
          margin-right: auto;
          text-align: center; }
          .entity-page-cancel-modal.modal .modal-dialog .modal-content .modal-footer .button-group button {
            margin-right: 15px; }
            @media (max-width: 767px) {
              .entity-page-cancel-modal.modal .modal-dialog .modal-content .modal-footer .button-group button {
                margin-bottom: 15px;
                margin-right: 0px; } }
          .entity-page-cancel-modal.modal .modal-dialog .modal-content .modal-footer .button-group button:last-child {
            margin-bottom: 0px;
            margin-right: 0px; }
        @media (max-width: 767px) {
          .entity-page-cancel-modal.modal .modal-dialog .modal-content .modal-footer {
            padding: 0px; }
            .entity-page-cancel-modal.modal .modal-dialog .modal-content .modal-footer button {
              width: 100%; } }

@media (max-width: 767px) {
  #gl-popup-modal .modal-dialog {
    margin: 20px;
    width: auto !important;
    height: 100%; } }

#gl-popup-modal .modal-header .close-icon .fa-times-circle-o {
  color: #555; }

#add-new-gl-account-modal .modal-header .close-icon .fa-times-circle-o {
  color: #555; }

.deduction-add-modal.modal {
  padding-left: 0px !important; }
  @media (min-width: 768px) and (max-width: 991px) {
    .deduction-add-modal.modal .modal-dialog {
      width: auto !important;
      margin: 10px; } }
  @media (max-width: 767px) {
    .deduction-add-modal.modal .modal-dialog {
      margin: 0px;
      width: auto !important; } }
  @media (min-width: 992px) {
    .deduction-add-modal.modal .modal-dialog {
      width: 950px; } }
  .deduction-add-modal.modal .modal-dialog .modal-content {
    border-radius: 0px;
    box-shadow: none !important;
    border: 0px !important;
    outline: 0px !important; }
    @media (min-width: 768px) {
      .deduction-add-modal.modal .modal-dialog .modal-content {
        padding: 30px; } }
    @media (max-width: 767px) {
      .deduction-add-modal.modal .modal-dialog .modal-content {
        padding: 20px; } }
    .deduction-add-modal.modal .modal-dialog .modal-content .modal-header {
      border-bottom: none;
      padding: 0px; }
      .deduction-add-modal.modal .modal-dialog .modal-content .modal-header .close-icon {
        text-align: right; }
        @media (min-width: 768px) {
          .deduction-add-modal.modal .modal-dialog .modal-content .modal-header .close-icon {
            margin-top: -15px;
            margin-left: 15px; } }
        @media (max-width: 767px) {
          .deduction-add-modal.modal .modal-dialog .modal-content .modal-header .close-icon {
            margin-top: 0px;
            margin-left: 0px; } }
        .deduction-add-modal.modal .modal-dialog .modal-content .modal-header .close-icon i {
          cursor: pointer;
          margin-top: 0px;
          margin-right: 0px;
          font-size: 20px; }
        @media (min-width: 992px) {
          .deduction-add-modal.modal .modal-dialog .modal-content .modal-header .close-icon {
            margin-bottom: 0; } }
    .deduction-add-modal.modal .modal-dialog .modal-content .modal-body {
      color: #555;
      font-size: 18px;
      padding: 0px; }
      .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container {
        padding: 0px !important; }
      .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .modal-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px; }
      .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .modal-description {
        font-size: 15px; }
      .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab {
        margin-top: 25px;
        font-size: 15px;
        border-bottom: none; }
        @media (max-width: 991px) {
          .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul {
            border-bottom: none; } }
        .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li {
          min-height: 40px;
          display: flex; }
          .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li > a {
            padding: 0px 15px;
            min-height: inherit;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center; }
            @media (max-width: 991px) {
              .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li > a {
                width: 100%; } }
            .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li > a:hover {
              background-color: #fff;
              border-top: 1px solid #cccccc;
              border-left: 1px solid #cccccc;
              border-right: 1px solid #cccccc; }
        @media (min-width: 768px) and (max-width: 991px) {
          .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li:nth-child(odd) a {
            margin-right: 10px; }
          .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li:nth-child(even) {
            margin-right: 0px; } }
        .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li.active {
          font-weight: 600;
          width: inherit;
          background-color: transparent !important; }
          @media (min-width: 320px) and (max-width: 767px) {
            .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li.active {
              width: 100%; } }
          @media (min-width: 768px) and (max-width: 991px) {
            .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li.active {
              width: 50% !important; } }
          .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .add_deduction_popup_tab ul li.active > a {
            border-top: 4px solid #339966; }
      .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .tab-content .add_deduction_popup_panel_header {
        padding: 10px; }
      .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .tab-content .tab-pane .nc-tab-content .modal-section {
        margin-bottom: 20px; }
        .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .tab-content .tab-pane .nc-tab-content .modal-section .field-desciption {
          color: #999; }
      .deduction-add-modal.modal .modal-dialog .modal-content .modal-body .modal-row {
        margin-bottom: 1 0px; }
    .deduction-add-modal.modal .modal-dialog .modal-content .modal-footer {
      border: none;
      clear: both;
      padding: 0px !important; }
      .deduction-add-modal.modal .modal-dialog .modal-content .modal-footer .button-group {
        border-top: 1px solid #ccc;
        padding-top: 20px;
        text-align: left; }
        .deduction-add-modal.modal .modal-dialog .modal-content .modal-footer .button-group button {
          margin-right: 15px; }
          @media (max-width: 767px) {
            .deduction-add-modal.modal .modal-dialog .modal-content .modal-footer .button-group button {
              margin-bottom: 15px;
              margin-right: 0px; } }
        .deduction-add-modal.modal .modal-dialog .modal-content .modal-footer .button-group button:last-child {
          margin-bottom: 0px;
          margin-right: 0px; }
      @media (max-width: 767px) {
        .deduction-add-modal.modal .modal-dialog .modal-content .modal-footer {
          padding: 0px; }
          .deduction-add-modal.modal .modal-dialog .modal-content .modal-footer button {
            width: 100%; } }

.netchex-filter-modal-mobile-body {
  height: 100vh;
  max-height: 100vh;
  padding: 15px !important; }
  .netchex-filter-modal-mobile-body .header-container {
    padding-bottom: 15px; }
    .netchex-filter-modal-mobile-body .header-container .close-icon {
      font-size: 18px;
      float: left; }
    .netchex-filter-modal-mobile-body .header-container .filter-label {
      font-size: 15px;
      font-weight: 400;
      text-align: center; }
    .netchex-filter-modal-mobile-body .header-container .clear-all-link {
      text-align: right; }
  .netchex-filter-modal-mobile-body .model {
    border: none; }
  .netchex-filter-modal-mobile-body .mobile-filter-panel-button-section {
    position: absolute;
    bottom: 15px;
    width: 100%;
    padding-right: 30px; }
    .netchex-filter-modal-mobile-body .mobile-filter-panel-button-section button {
      width: 100%; }

.netchex-sort-modal-mobile-body {
  padding: 15px !important; }
  .netchex-sort-modal-mobile-body .header-container {
    border-bottom: 1px solid #ccc;
    height: 40px;
    padding-bottom: 15px; }
    .netchex-sort-modal-mobile-body .header-container .close-icon {
      font-size: 18px !important;
      float: left; }
    .netchex-sort-modal-mobile-body .header-container .sort-label,
    .netchex-sort-modal-mobile-body .header-container .filter-label {
      font-size: 15px;
      font-weight: 400;
      text-align: center; }
    .netchex-sort-modal-mobile-body .header-container .clear-all-link {
      text-align: right; }
  .netchex-sort-modal-mobile-body .model {
    border: none; }

.nc-delete-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0; }

#entity-page-cancel-modal .modal-dialog {
  width: 700px; }
  @media (max-width: 767px) {
    #entity-page-cancel-modal .modal-dialog {
      width: auto !important; } }
  #entity-page-cancel-modal .modal-dialog i {
    color: #555; }
  @media (min-width: 768px) {
    #entity-page-cancel-modal .modal-dialog .message-footer .button-group {
      width: 537px; } }
  @media (max-width: 767px) {
    #entity-page-cancel-modal .modal-dialog .message-footer .button-group {
      width: 100%; } }

.rate-modal.modal {
  padding-left: 0px !important; }
  .rate-modal.modal .modal-dialog {
    width: 600px; }
    @media (max-width: 767px) {
      .rate-modal.modal .modal-dialog {
        margin: 20px;
        width: auto !important; } }
    .rate-modal.modal .modal-dialog .modal-content {
      border-radius: 0px;
      box-shadow: none !important; }
      @media (min-width: 768px) {
        .rate-modal.modal .modal-dialog .modal-content {
          padding: 30px; } }
      @media (max-width: 767px) {
        .rate-modal.modal .modal-dialog .modal-content {
          padding: 20px; } }
      .rate-modal.modal .modal-dialog .modal-content .modal-header {
        border-bottom: none;
        padding: 0px; }
        .rate-modal.modal .modal-dialog .modal-content .modal-header .close-icon {
          text-align: right; }
          @media (min-width: 768px) {
            .rate-modal.modal .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: -15px;
              margin-left: 15px;
              margin-bottom: 5px; } }
          @media (max-width: 767px) {
            .rate-modal.modal .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: 0px;
              margin-left: 0px; } }
          .rate-modal.modal .modal-dialog .modal-content .modal-header .close-icon i {
            cursor: pointer;
            margin-top: 0px;
            margin-right: 0px; }
      .rate-modal.modal .modal-dialog .modal-content .modal-body {
        color: #555;
        font-size: 18px;
        padding: 0px; }
        .rate-modal.modal .modal-dialog .modal-content .modal-body .modal-row .modal-title {
          margin-bottom: 10px;
          font-weight: 600; }
        .rate-modal.modal .modal-dialog .modal-content .modal-body .modal-row .modal-description {
          font-size: 14px;
          margin-bottom: 15px; }
      .rate-modal.modal .modal-dialog .modal-content .modal-footer {
        border: none;
        clear: both;
        padding: 0px; }
        .rate-modal.modal .modal-dialog .modal-content .modal-footer .button-group {
          text-align: left; }
          .rate-modal.modal .modal-dialog .modal-content .modal-footer .button-group button {
            margin-right: 15px; }
            @media (max-width: 767px) {
              .rate-modal.modal .modal-dialog .modal-content .modal-footer .button-group button {
                margin-bottom: 15px;
                margin-right: 0px; } }
          .rate-modal.modal .modal-dialog .modal-content .modal-footer .button-group button:last-child {
            margin-bottom: 0px;
            margin-right: 0px; }
        @media (max-width: 767px) {
          .rate-modal.modal .modal-dialog .modal-content .modal-footer {
            padding: 0px; }
            .rate-modal.modal .modal-dialog .modal-content .modal-footer button {
              width: 100%; } }

.toaster-container {
  clear: both;
  margin-bottom: 20px; }
  .toaster-container .toaster {
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    text-align: center; }
    .toaster-container .toaster .alert {
      padding-top: 7.5px;
      padding-bottom: 7.5px;
      padding-left: 5px;
      padding-right: 5px; }
    .toaster-container .toaster .alert-success {
      color: #339966;
      background-color: #ccffcc;
      border-color: #26a977; }
      .toaster-container .toaster .alert-success .close-icon:hover {
        cursor: pointer; }
      .toaster-container .toaster .alert-success .close-icon i {
        margin-right: 0px;
        color: #339966; }

.progress-tracker-container-mobile {
  background-color: #e9e9e9;
  padding-bottom: 20px;
  margin-bottom: 20px; }
  .progress-tracker-container-mobile .step-mobile-inner-container {
    overflow-x: scroll; }
    .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table {
      display: table;
      width: 100%;
      margin-top: 5px; }
      @media (max-width: 991px) {
        .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table {
          position: relative; } }
      .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table .add-deduction-step-sub-menu-mobile {
        display: table-row; }
        .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table .add-deduction-step-sub-menu-mobile .deduction-circle-icons {
          display: table-cell;
          width: 16.6667%; }
          .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table .add-deduction-step-sub-menu-mobile .deduction-circle-icons .steps-item-section .fa {
            margin-right: 0px; }
          .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table .add-deduction-step-sub-menu-mobile .deduction-circle-icons .steps-item-section .visible {
            visibility: visible; }
          .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table .add-deduction-step-sub-menu-mobile .deduction-circle-icons .steps-item-section .invisible {
            visibility: hidden; }
          .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table .add-deduction-step-sub-menu-mobile .deduction-circle-icons .steps-item-section .bg-green {
            background-color: #339966;
            min-height: 35px;
            max-height: 35px;
            max-width: 35px;
            padding-top: 4px;
            text-align: center;
            color: white;
            border: 4px solid white;
            border-radius: 50%;
            margin-left: auto;
            margin-right: auto; }
          .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table .add-deduction-step-sub-menu-mobile .deduction-circle-icons .steps-item-section .bg-grey {
            background-color: #e9e9e9;
            min-height: 35px;
            max-height: 35px;
            max-width: 35px;
            padding-top: 4px;
            text-align: center;
            color: white;
            border: 4px solid white;
            border-radius: 50%;
            margin-left: auto;
            margin-right: auto; }
          .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table .add-deduction-step-sub-menu-mobile .deduction-circle-icons .steps-item-section .inaccessible-step-sm {
            background-color: #e9e9e9;
            min-height: 35px;
            max-height: 35px;
            max-width: 35px;
            padding-top: 4px;
            text-align: center;
            color: white;
            border: 4px solid white;
            border-radius: 50%;
            margin-left: auto;
            margin-right: auto; }
            .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table .add-deduction-step-sub-menu-mobile .deduction-circle-icons .steps-item-section .inaccessible-step-sm i {
              visibility: hidden; }
          .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table .add-deduction-step-sub-menu-mobile .deduction-circle-icons .steps-item-section .active-step-sm {
            background-color: #339966;
            min-height: 35px;
            max-height: 35px;
            max-width: 35px;
            padding-top: 4px;
            text-align: center;
            color: white;
            border: 4px solid white;
            border-radius: 50%;
            margin-left: auto;
            margin-right: auto; }
            .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table .add-deduction-step-sub-menu-mobile .deduction-circle-icons .steps-item-section .active-step-sm i {
              visibility: hidden; }
          .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table .add-deduction-step-sub-menu-mobile .deduction-circle-icons .steps-item-section .accessible-step-sm {
            background-color: #339966;
            min-height: 35px;
            max-height: 35px;
            max-width: 35px;
            padding-top: 4px;
            text-align: center;
            color: white;
            border: 4px solid white;
            border-radius: 50%;
            margin-left: auto;
            margin-right: auto; }
          .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table .add-deduction-step-sub-menu-mobile .deduction-circle-icons .steps-item-section .step-name {
            margin-top: 5px;
            color: #555;
            font-size: 12px;
            text-align: center; }
            .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table .add-deduction-step-sub-menu-mobile .deduction-circle-icons .steps-item-section .step-name .add-deduction-step-sub-menu-link-name {
              color: #555555;
              font-weight: 400 !important;
              text-decoration: none !important; }
            .progress-tracker-container-mobile .step-mobile-inner-container .add-deduction-step-mobile-table .add-deduction-step-sub-menu-mobile .deduction-circle-icons .steps-item-section .step-name .add-deduction-step-sub-menu-link-name:hover {
              color: #555555; }

.hbar {
  position: relative;
  top: 24px;
  z-index: 0;
  height: 4px;
  min-width: 400px;
  background-color: #fff; }
  @media (min-width: 320px) and (max-width: 767px) {
    .hbar {
      margin-left: 50px;
      width: 81%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .hbar {
      margin-left: 70px;
      width: 81%; } }

.nc-page-container, .nc-list-page-container, .nc-entity-page-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container, .nc-activity-history-page-container {
  height: 100% !important;
  position: relative !important;
  padding: 30px 55px;
  color: #555 !important;
  font-family: "Source Sans Pro", sans-serif !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .nc-page-container, .nc-list-page-container, .nc-entity-page-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container, .nc-activity-history-page-container {
      padding: 10px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .nc-page-container, .nc-list-page-container, .nc-entity-page-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container, .nc-activity-history-page-container {
      padding: 20px !important; } }
  .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
    padding-bottom: 15px; }
    .nc-page-container .pageTitle-container .pageTitle, .nc-list-page-container .pageTitle-container .pageTitle, .nc-entity-page-container .pageTitle-container .pageTitle, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container .pageTitle, .nc-activity-history-page-container .pageTitle-container .pageTitle {
      float: left;
      font-size: 25px;
      font-weight: 400; }
      @media (max-width: 767px) {
        .nc-page-container .pageTitle-container .pageTitle, .nc-list-page-container .pageTitle-container .pageTitle, .nc-entity-page-container .pageTitle-container .pageTitle, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container .pageTitle, .nc-activity-history-page-container .pageTitle-container .pageTitle {
          float: initial;
          font-size: 25px;
          /* DEVIATION : This was added because visually it did not look good without this */ } }
    @media (min-width: 768px) and (max-width: 991px) {
      .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
        padding-bottom: 15px; } }
    @media (max-width: 767px) {
      .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
        padding-bottom: 10px; } }
  .nc-page-container p, .nc-list-page-container p, .nc-entity-page-container p, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container p, .nc-activity-history-page-container p {
    line-height: 1.3; }

.nc-page-container::before, .nc-list-page-container::before, .nc-entity-page-container::before, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container::before, .nc-activity-history-page-container::before {
  display: table;
  content: " "; }

.nc-page-container::after, .nc-list-page-container::after, .nc-entity-page-container::after, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container::after, .nc-activity-history-page-container::after {
  display: table;
  content: " ";
  clear: both; }

.nc-activity-history-page-container .export-excel-button {
  float: right; }

.nc-activity-history-page-container .navigation-button-container {
  clear: both;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px; }
  .nc-activity-history-page-container .navigation-button-container .inpage-back-list {
    min-width: unset;
    display: inline-flex; }

.nc-activity-history-page-container .table-row {
  clear: both;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #e9e9e9; }
  .nc-activity-history-page-container .table-row .status-name {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px; }
    @media (min-width: 320px) and (max-width: 767px) {
      .nc-activity-history-page-container .table-row .status-name {
        margin-bottom: 5px;
        /* DEVIATION : 5px bottom margin for visual aspect*/ } }
    @media (min-width: 768px) and (max-width: 991px) {
      .nc-activity-history-page-container .table-row .status-name {
        margin-bottom: 5px;
        /* DEVIATION : 5px bottom margin for visual aspect*/ } }
  .nc-activity-history-page-container .table-row .nc-activity-history-status-name {
    font-size: 15px;
    font-weight: 600; }
    @media (min-width: 992px) {
      .nc-activity-history-page-container .table-row .nc-activity-history-status-name {
        height: 38px;
        align-items: center;
        display: flex; } }
  .nc-activity-history-page-container .table-row .edit_note {
    margin-top: 5px; }
    @media (max-width: 991px) {
      .nc-activity-history-page-container .table-row .edit_note {
        margin-top: 10px; } }
  @media (min-width: 992px) {
    .nc-activity-history-page-container .table-row .nc-activity-history-table {
      margin-top: 10px; } }
  @media (max-width: 991px) {
    .nc-activity-history-page-container .table-row .nc-activity-history-table {
      margin-top: 15px; } }
  .nc-activity-history-page-container .table-row .nc-activity-history-table .header {
    font-weight: 600;
    padding: 5px 12px 5px 12px !important; }
  .nc-activity-history-page-container .table-row .nc-activity-history-table .header-sm {
    font-weight: 600; }
    @media (max-width: 991px) {
      .nc-activity-history-page-container .table-row .nc-activity-history-table .header-sm {
        float: left; } }
  @media (max-width: 991px) {
    .nc-activity-history-page-container .table-row .nc-activity-history-table .header-field-value-sm {
      padding-left: 50px; } }
  .nc-activity-history-page-container .table-row .nc-activity-history-table .content {
    clear: both;
    background-color: #e9e9e9;
    padding: 15px 12px 15px 12px !important; }
    @media (max-width: 991px) {
      .nc-activity-history-page-container .table-row .nc-activity-history-table .content {
        padding: 15px 15px 15px 15px !important; } }
    .nc-activity-history-page-container .table-row .nc-activity-history-table .content .row-sm {
      padding-bottom: 15px; }
    .nc-activity-history-page-container .table-row .nc-activity-history-table .content .row-sm:last-child {
      padding-bottom: 0px; }
    .nc-activity-history-page-container .table-row .nc-activity-history-table .content .seperator hr {
      height: 2px;
      background-color: #fff;
      margin: 0px -15px 15px -15px; }
    .nc-activity-history-page-container .table-row .nc-activity-history-table .content .field-sm {
      padding-right: 5px; }
      @media (max-width: 991px) {
        .nc-activity-history-page-container .table-row .nc-activity-history-table .content .field-sm {
          padding-bottom: 6px; } }

.nc-activity-history-page-container .timestamp-column-header {
  text-align: right;
  padding-right: 0px; }

.nc-activity-history-page-container .timestamp-column-value {
  padding-right: 0px;
  text-align: right;
  font-size: 15px; }
  @media (min-width: 992px) {
    .nc-activity-history-page-container .timestamp-column-value {
      height: 38px;
      align-items: center;
      display: flex;
      justify-content: flex-end; } }
  @media (min-width: 320px) and (max-width: 991px) {
    .nc-activity-history-page-container .timestamp-column-value {
      text-align: left;
      margin-top: 10px; } }

.nc-activity-history-page-container .field-description-row-mobile {
  background-color: #e9e9e9;
  padding: 6px 12px 6px 16px !important;
  margin-bottom: 5px; }

.nc-activity-history-page-container .field-header {
  color: #555;
  font-weight: 600;
  margin-right: 10px;
  min-width: 50px;
  /* DEVIATION : This was required to ensure that the label does not wrap in mobile view  */ }

.nc-activity-history-page-container .user-activity-information {
  font-weight: 600;
  margin-bottom: 10px; }

.nc-activity-history-page-container .table {
  display: table;
  padding: 0px;
  border-collapse: separate;
  border-spacing: 0px 2px;
  margin-bottom: 0px;
  width: 100%;
  max-width: 100%; }
  .nc-activity-history-page-container .table .result-rowcount {
    clear: both;
    margin-bottom: 15px; }
  .nc-activity-history-page-container .table .thead {
    display: table-header-group; }
    .nc-activity-history-page-container .table .thead .tr {
      display: table-row; }
      .nc-activity-history-page-container .table .thead .tr .th {
        display: table-cell;
        border: none !important;
        font-weight: 600;
        padding: 5px 12px 5px 12px !important; }
  .nc-activity-history-page-container .table .tbody {
    display: table-row-group;
    background-color: #e9e9e9; }
    .nc-activity-history-page-container .table .tbody .tr {
      display: table-row; }
      .nc-activity-history-page-container .table .tbody .tr .td {
        display: table-cell;
        padding: 2px 12px 2px 12px; }
        @media (min-width: 320px) and (max-width: 991px) {
          .nc-activity-history-page-container .table .tbody .tr .td {
            padding: 2px 12px 4px 12px; }
            .nc-activity-history-page-container .table .tbody .tr .td .field-header {
              display: table-cell; }
            .nc-activity-history-page-container .table .tbody .tr .td .field-value {
              display: table-cell; } }
  .nc-activity-history-page-container .table .table-header {
    font-weight: 700; }
  @media (max-width: 991px) {
    .nc-activity-history-page-container .table .table-header + .table-row {
      padding-top: 0px; } }
  .nc-activity-history-page-container .table .table-row {
    border-bottom: 1px solid #dbd6d6;
    clear: both;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px; }
    .nc-activity-history-page-container .table .table-row div {
      padding-left: 0px;
      padding-right: 0px; }
    .nc-activity-history-page-container .table .table-row .table .tbody .tr .td {
      padding-left: 15px;
      padding-right: 15px; }
    @media (min-width: 992px) {
      .nc-activity-history-page-container .table .table-row .table .tbody .tr .td {
        padding-left: 15px;
        padding-right: 15px; }
      .nc-activity-history-page-container .table .table-row .table .tbody .tr:first-child .td {
        padding-top: 15px; }
      .nc-activity-history-page-container .table .table-row .table .tbody .tr:last-child .td {
        padding-bottom: 15px; } }
    @media (min-width: 321px) and (max-width: 991px) {
      .nc-activity-history-page-container .table .table-row .table .tbody .tr .td {
        min-height: 26px; }
      .nc-activity-history-page-container .table .table-row .table .tbody .tr .td:last-child {
        padding-bottom: 15px; }
      .nc-activity-history-page-container .table .table-row .table .tbody .tr:first-child .td:first-child {
        padding-top: 15px;
        min-height: 39px; }
      .nc-activity-history-page-container .table .table-row .table .tbody .tr:last-child .td:last-child {
        padding-bottom: 15px; } }
    .nc-activity-history-page-container .table .table-row .edit_note {
      margin-top: 5px; }
  .nc-activity-history-page-container .table .table-row:last-child {
    border-bottom: none; }

@media (min-width: 320px) and (max-width: 767px) {
  .nc-activity-history-page-container .table:last-child {
    margin-bottom: 5px;
    /* DEVIATION : 5px bottom margin for visual aspect*/ } }

@media (min-width: 768px) and (max-width: 991px) {
  .nc-activity-history-page-container .table:last-child {
    margin-bottom: 5px;
    /* DEVIATION : 5px bottom margin for visual aspect*/ } }

.nc-activity-history-page-container::before {
  display: table;
  content: " "; }

.nc-activity-history-page-container::after {
  display: table;
  content: " ";
  clear: both; }

.modal#activity-history {
  padding-left: 0px !important; }
  .modal#activity-history .modal-dialog {
    width: 700px; }
    @media (max-width: 767px) {
      .modal#activity-history .modal-dialog {
        margin: 20px;
        width: auto !important; } }
    .modal#activity-history .modal-dialog .modal-content {
      border-radius: 0px;
      box-shadow: none; }
      .modal#activity-history .modal-dialog .modal-content hr {
        margin-top: 0px;
        margin-bottom: 20px !important; }
      @media (min-width: 768px) {
        .modal#activity-history .modal-dialog .modal-content {
          padding: 30px; } }
      @media (max-width: 767px) {
        .modal#activity-history .modal-dialog .modal-content {
          padding: 20px; } }
      .modal#activity-history .modal-dialog .modal-content .modal-header {
        border-bottom: none;
        padding: 0px; }
        .modal#activity-history .modal-dialog .modal-content .modal-header .modal-title-description {
          font-size: 12px;
          margin-bottom: 20px; }
        .modal#activity-history .modal-dialog .modal-content .modal-header .close-icon {
          cursor: pointer;
          text-align: right; }
          @media (min-width: 768px) {
            .modal#activity-history .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: -15px;
              margin-left: 15px; } }
          @media (max-width: 767px) {
            .modal#activity-history .modal-dialog .modal-content .modal-header .close-icon {
              margin-top: 0px;
              margin-left: 0px; } }
          .modal#activity-history .modal-dialog .modal-content .modal-header .close-icon i {
            margin-top: 0px;
            margin-right: 0px; }
        .modal#activity-history .modal-dialog .modal-content .modal-header .before-after-link {
          margin-top: 10px; }
          @media (max-width: 991px) {
            .modal#activity-history .modal-dialog .modal-content .modal-header .before-after-link .inpage-back-list {
              height: inherit;
              padding-top: 0;
              padding-bottom: 0; } }
      .modal#activity-history .modal-dialog .modal-content .modal-body {
        padding: 0px; }
        .modal#activity-history .modal-dialog .modal-content .modal-body .nc-activity-history-modal-row {
          margin-bottom: 20px; }
        @media (min-width: 992px) {
          .modal#activity-history .modal-dialog .modal-content .modal-body div:last-child .nc-activity-history-modal-row:last-child {
            margin-bottom: 0px; } }
      .modal#activity-history .modal-dialog .modal-content .modal-footer {
        padding: 20px 0px 0px 0px; }
        @media (max-width: 767px) {
          .modal#activity-history .modal-dialog .modal-content .modal-footer {
            text-align: center; } }
        @media (min-width: 768px) {
          .modal#activity-history .modal-dialog .modal-content .modal-footer {
            text-align: left; } }
        @media (min-width: 992px) {
          .modal#activity-history .modal-dialog .modal-content .modal-footer {
            display: none; } }

.nc-activity-history-modal-row {
  margin-bottom: 20px; }

.nc-page-container, .nc-list-page-container, .nc-entity-page-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container, .nc-activity-history-page-container {
  height: 100% !important;
  position: relative !important;
  padding: 30px 55px;
  color: #555 !important;
  font-family: "Source Sans Pro", sans-serif !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .nc-page-container, .nc-list-page-container, .nc-entity-page-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container, .nc-activity-history-page-container {
      padding: 10px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .nc-page-container, .nc-list-page-container, .nc-entity-page-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container, .nc-activity-history-page-container {
      padding: 20px !important; } }
  .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
    padding-bottom: 15px; }
    .nc-page-container .pageTitle-container .pageTitle, .nc-list-page-container .pageTitle-container .pageTitle, .nc-entity-page-container .pageTitle-container .pageTitle, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container .pageTitle, .nc-activity-history-page-container .pageTitle-container .pageTitle {
      float: left;
      font-size: 25px;
      font-weight: 400; }
      @media (max-width: 767px) {
        .nc-page-container .pageTitle-container .pageTitle, .nc-list-page-container .pageTitle-container .pageTitle, .nc-entity-page-container .pageTitle-container .pageTitle, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container .pageTitle, .nc-activity-history-page-container .pageTitle-container .pageTitle {
          float: initial;
          font-size: 25px;
          /* DEVIATION : This was added because visually it did not look good without this */ } }
    @media (min-width: 768px) and (max-width: 991px) {
      .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
        padding-bottom: 15px; } }
    @media (max-width: 767px) {
      .nc-page-container .pageTitle-container, .nc-list-page-container .pageTitle-container, .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container, .nc-activity-history-page-container .pageTitle-container {
        padding-bottom: 10px; } }
  .nc-page-container p, .nc-list-page-container p, .nc-entity-page-container p, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container p, .nc-activity-history-page-container p {
    line-height: 1.3; }

.nc-page-container::before, .nc-list-page-container::before, .nc-entity-page-container::before, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container::before, .nc-activity-history-page-container::before {
  display: table;
  content: " "; }

.nc-page-container::after, .nc-list-page-container::after, .nc-entity-page-container::after, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container::after, .nc-activity-history-page-container::after {
  display: table;
  content: " ";
  clear: both; }

.nc-field-row {
  margin-bottom: 10px; }
  .nc-field-row .field-name {
    font-weight: 600; }
  .nc-field-row .edit-icon:hover {
    cursor: pointer; }

.open-deduction-based-popover-for-information-extra-small-type {
  background-color: #e9e9e9 !important;
  padding: 10px !important;
  margin-bottom: 30px !important; }
  @media (min-width: 320px) and (max-width: 479px) {
    .open-deduction-based-popover-for-information-extra-small-type {
      width: 98% !important; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .open-deduction-based-popover-for-information-extra-small-type {
      width: 98% !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .open-deduction-based-popover-for-information-extra-small-type {
      width: 98% !important; } }
  .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
    border-radius: 50px !important;
    width: 30px;
    height: 30px;
    background-color: #019999 !important;
    border: none !important;
    color: white;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center; }
    .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close i {
      color: #ffffff !important;
      font-size: 18px;
      margin-right: 0px;
      margin-left: 0px !important; }
    @media (min-width: 320px) and (max-width: 479px) {
      .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
    @media (min-width: 480px) and (max-width: 767px) {
      .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
    @media (min-width: 992px) {
      .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
  .open-deduction-based-popover-for-information-extra-small-type .deduction-popover-information-data {
    padding: 10px;
    font-size: 15px;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 400 !important;
    color: #555555 !important; }

.nc-entity-page-container .seperator, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .seperator {
  clear: both; }
  .nc-entity-page-container .seperator hr, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .seperator hr {
    border-color: #ccc;
    margin-top: 0px; }

@media (min-width: 481px) and (max-width: 767px) {
  .nc-entity-page-container .textinput-with-after-sign-set-width, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .textinput-with-after-sign-set-width {
    width: 70px;
    margin-top: 10px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .nc-entity-page-container .textinput-with-after-sign-set-width, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .textinput-with-after-sign-set-width {
    width: 100px;
    margin-top: 10px; } }

@media (min-width: 992px) and (max-width: 1198px) {
  .nc-entity-page-container .textinput-with-after-sign-set-width, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .textinput-with-after-sign-set-width {
    width: 70px;
    max-width: 550px !important;
    padding-top: 10px; } }

@media (min-width: 1199px) {
  .nc-entity-page-container .textinput-with-after-sign-set-width, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .textinput-with-after-sign-set-width {
    width: 100px;
    max-width: 550px !important; } }

.nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type {
  background-color: #e9e9e9 !important;
  padding: 10px !important;
  margin-bottom: 30px !important; }
  @media (min-width: 320px) and (max-width: 479px) {
    .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type {
      width: 98% !important; } }
  @media (min-width: 480px) and (max-width: 767px) {
    .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type {
      width: 98% !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type {
      width: 98% !important; } }
  .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
    border-radius: 50px !important;
    width: 30px;
    height: 30px;
    background-color: #019999 !important;
    border: none !important;
    color: white;
    text-align: center;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center; }
    .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close i, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close i {
      color: #ffffff !important;
      font-size: 18px;
      margin-right: 0px;
      margin-left: 0px !important; }
    @media (min-width: 320px) and (max-width: 479px) {
      .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
    @media (min-width: 480px) and (max-width: 767px) {
      .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
    @media (min-width: 992px) {
      .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .open-deduction-based-popover-button-close {
        position: absolute !important;
        top: -15px !important;
        right: -15px; } }
  .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .deduction-popover-information-data, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .open-deduction-based-popover-for-information-extra-small-type .deduction-popover-information-data {
    padding: 10px;
    font-size: 15px;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 400 !important;
    color: #555555 !important; }

@media (min-width: 768px) {
  .nc-entity-page-container .pageTitle-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .pageTitle-container {
    padding-bottom: 15px; } }

.nc-entity-page-container .page-description, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .page-description {
  font-size: 15px;
  line-height: 1.2;
  padding-bottom: 20px; }

.nc-entity-page-container .page-description-button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .page-description-button {
  float: right; }
  .nc-entity-page-container .page-description-button button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .page-description-button button {
    float: right; }
    @media (max-width: 991px) {
      .nc-entity-page-container .page-description-button button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .page-description-button button {
        float: left; } }
  @media (max-width: 991px) {
    .nc-entity-page-container .page-description-button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .page-description-button {
      float: left;
      margin-bottom: 20px; } }

.nc-entity-page-container .title-band-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .title-band-container {
  clear: both;
  margin-bottom: 20px; }
  @media (max-width: 991px) {
    .nc-entity-page-container .title-band-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .title-band-container {
      margin-bottom: 0px; } }
  .nc-entity-page-container .title-band-container .title-band, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .title-band-container .title-band {
    background-color: #396;
    padding: 12px 0 10px 20px;
    color: #fff;
    font-weight: 400;
    height: 45px;
    font-size: 18px;
    margin-left: 0; }

@media (max-width: 991px) {
  .nc-entity-page-container .title-band-container-sm, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .title-band-container-sm {
    margin-bottom: 15px; } }

.nc-entity-page-container .entity-page-content .step-title-container, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .step-title-container {
  font-size: 18px;
  margin-bottom: 20px; }
  .nc-entity-page-container .entity-page-content .step-title-container .step-number, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .step-title-container .step-number {
    background-color: #555;
    border-radius: 100px;
    font-size: 22px;
    font-weight: 700;
    float: left;
    height: 36px;
    margin-right: 15px;
    padding: 7px 10px 10px !important;
    width: 36px; }
  .nc-entity-page-container .entity-page-content .step-title-container .step-title, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .step-title-container .step-title {
    font-weight: 600;
    font-size: 18px;
    color: #555;
    float: left;
    margin-top: 5px; }

.nc-entity-page-container .entity-page-content .step-description, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .step-description {
  clear: both;
  color: #555;
  font-size: 15px;
  font-weight: 400; }

.nc-entity-page-container .entity-page-content .description-content .fa-calculator, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .fa-calculator {
  color: #fff !important; }

@media (max-width: 768px) {
  .nc-entity-page-container .entity-page-content .description-content .contains-single-link button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .contains-single-link button {
    width: 100%; } }

.nc-entity-page-container .entity-page-content .description-content .deduction-name-input, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .deduction-name-input {
  margin-bottom: 10px; }
  .nc-entity-page-container .entity-page-content .description-content .deduction-name-input i, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .deduction-name-input i {
    margin-left: 5px;
    color: #009999; }
  .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .edit-icon a, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .edit-icon a {
    float: right; }
    .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .edit-icon a i, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .edit-icon a i {
      color: #555; }
  .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .review-field, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .review-field {
    font-size: 15px;
    margin-bottom: 5px; }
  .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .deduction-name-description, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .deduction-name-description {
    margin-bottom: 15px;
    font-size: 15px; }
  .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .input-field, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .description-content .deduction-name-input .input-field {
    margin-right: 0px; }

.nc-entity-page-container .entity-page-content .seperator, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .seperator {
  clear: both; }
  .nc-entity-page-container .entity-page-content .seperator hr, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .entity-page-content .seperator hr {
    border-color: #ccc; }

.nc-entity-page-container .progress-tracker, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker {
  background-color: #e9e9e9;
  border-radius: 7.5px;
  margin-top: 20px;
  float: right;
  padding-top: 30px;
  padding-right: 10px;
  padding-bottom: 17.5px;
  padding-left: 10px;
  width: 250px; }
  @media (min-width: 1220px) {
    .nc-entity-page-container .progress-tracker, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker {
      margin-left: 5px; } }
  .nc-entity-page-container .progress-tracker .title, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .title {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 20px;
    text-align: center; }
  .nc-entity-page-container .progress-tracker .step-menu, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .step-menu {
    list-style-type: none;
    padding-left: 0px; }
    .nc-entity-page-container .progress-tracker .step-menu .step-menu-item, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .step-menu .step-menu-item {
      border-radius: 3px;
      color: #555;
      font-size: 15px;
      font-weight: 400;
      padding-top: 12.5px;
      padding-bottom: 12.5px;
      padding-left: 8px; }
      .nc-entity-page-container .progress-tracker .step-menu .step-menu-item i, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .step-menu .step-menu-item i {
        color: #339966;
        font-size: 15px;
        margin-right: 14px; }
    .nc-entity-page-container .progress-tracker .step-menu .accessible-step:hover, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .step-menu .accessible-step:hover {
      background-color: #cccccb;
      padding-top: 12.5px;
      padding-bottom: 12.5px;
      cursor: pointer; }
    .nc-entity-page-container .progress-tracker .step-menu .inaccessible-step:hover, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .step-menu .inaccessible-step:hover {
      padding-top: 12.5px;
      padding-bottom: 12.5px; }
    .nc-entity-page-container .progress-tracker .step-menu .inaccessible-step i, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .step-menu .inaccessible-step i {
      visibility: hidden; }
    .nc-entity-page-container .progress-tracker .step-menu .active-step, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .step-menu .active-step {
      background-color: #9c9;
      color: #333;
      font-size: 15px;
      font-weight: 600;
      padding-top: 12.5px;
      padding-bottom: 12.5px;
      cursor: pointer; }
    .nc-entity-page-container .progress-tracker .step-menu .active-step:hover, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .progress-tracker .step-menu .active-step:hover {
      background-color: #9c9; }

.nc-entity-page-container .taxability .taxability-title, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .taxability .taxability-title {
  color: #009966;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px; }

.nc-entity-page-container .taxability .taxability-content, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .taxability .taxability-content {
  padding-left: 10px; }
  .nc-entity-page-container .taxability .taxability-content .field .deduction-name-description, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .taxability .taxability-content .field .deduction-name-description {
    margin-bottom: 10px;
    font-size: 15px; }

@media (min-width: 992px) {
  .nc-entity-page-container .footer-button-container .nc-button-group button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group button {
    margin-right: 20px; } }

@media (max-width: 767px) {
  .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile {
    width: 205px;
    margin: auto;
    display: flex;
    justify-content: center; } }

@media (min-width: 768px) {
  .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile {
    display: inline; } }

.nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button {
  margin-right: 20px; }
  @media (min-width: 992px) {
    .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile button {
      margin-right: 20px; } }

@media (max-width: 767px) {
  .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button {
    width: 100%; } }

@media (min-width: 768px) {
  .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button {
    display: inline; } }

.nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button button {
  margin-right: 20px; }
  @media (min-width: 992px) {
    .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button button {
      margin-right: 20px; } }
  @media (max-width: 767px) {
    .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button button, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container .footer-button-container .nc-button-group .nc-link-group-mobile-single-button button {
      width: 100%; } }

.nc-entity-page-container::before, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container::before {
  display: table;
  content: " "; }

.nc-entity-page-container::after, .entity-page-add-modal-lg.modal .modal-dialog .modal-content .modal-body .nc-entity-page-container::after {
  display: table;
  content: " ";
  clear: both; }

.row-simple-value {
  margin-bottom: 5px; }

.nc-enity-snapshot-summary .nc-field-group .edit-icon a {
  float: right; }
  .nc-enity-snapshot-summary .nc-field-group .edit-icon a .fa-pencil {
    color: #555; }

.nc-enity-snapshot-summary .nc-field-group .field-name {
  margin-bottom: 15px;
  padding-right: 15px;
  font-size: 15px;
  font-weight: 600; }
  .nc-enity-snapshot-summary .nc-field-group .field-name .field-description {
    color: #999;
    margin-top: 5px; }

.nc-enity-snapshot-summary .nc-field-group .field-description {
  color: #999; }

.nc-enity-snapshot-summary .nc-field-group .field-information {
  font-size: 14px !important;
  margin-bottom: 25px; }

@media (min-width: 992px) {
  .nc-enity-snapshot-summary .nc-field-group .inline-field-description {
    margin-top: 5px;
    font-size: 15px !important;
    color: #999;
    margin-left: 5px; } }

.nc-enity-snapshot-summary .nc-field-group .input-field {
  margin-bottom: 15px; }
  .nc-enity-snapshot-summary .nc-field-group .input-field .form-group {
    margin-bottom: 0px; }

.nc-enity-snapshot-summary .nc-field-group .radio-button-group .single-radio-button {
  display: inline; }
  @media (max-width: 767px) {
    .nc-enity-snapshot-summary .nc-field-group .radio-button-group .single-radio-button {
      width: 50%;
      float: left; } }

@media (max-width: 767px) {
  .nc-enity-snapshot-summary .nc-field-group .radio-button-group .single-radio-button:nth-of-type(odd) {
    padding-right: 5px; } }

@media (max-width: 767px) {
  .nc-enity-snapshot-summary .nc-field-group .radio-button-group .single-radio-button:nth-of-type(even) {
    padding-left: 5px; } }

.nc-payroll-dashboard-container {
  background-color: #e9e9e9;
  min-height: 100vh !important;
  position: relative !important;
  padding: 30px 55px !important;
  color: #555 !important;
  font-family: "Source Sans Pro", sans-serif !important; }
  @media (min-width: 320px) and (max-width: 767px) {
    .nc-payroll-dashboard-container {
      padding: 10px 0px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .nc-payroll-dashboard-container {
      padding: 20px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .nc-payroll-dashboard-container {
      padding: 30px !important; } }

.fa-info-circle {
  color: #009999; }
  @media (max-width: 767px) {
    .fa-info-circle {
      font-size: 15px; } }

.fa-times-circle-o {
  color: #555; }

.fa-ellipsis-h {
  border-radius: 50%;
  color: white; }

.w2-content {
  text-align: left; }
  @media (max-width: 767px) {
    .w2-content {
      margin-top: 15px; } }
  @media (min-width: 768px) {
    .w2-content {
      padding-left: 40px !important; } }
  .w2-content .section-name {
    margin-bottom: 10px; }
  .w2-content .list-item {
    margin-bottom: 5px; }
  .w2-content .list-item:first-child {
    margin-top: 5px; }
  .w2-content .list-item:last-child {
    margin-bottom: 0px; }

.misc-container {
  text-align: center;
  background-color: #e9e9e9;
  padding: 25px;
  margin-bottom: 20px;
  font-size: 15px; }
  .misc-container .title {
    color: #339966;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px; }
  .misc-container .description {
    margin-bottom: 20px;
    line-height: 1.3; }

.calculator {
  display: inline-flex;
  margin-left: auto;
  margin-right: auto;
  min-width: 250px;
  min-height: 50px;
  position: relative; }
  .calculator .icon {
    background-color: #339966;
    color: #ffffff;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
    left: 0; }
    .calculator .icon i {
      font-size: 20px;
      margin-right: 0px; }
  .calculator .content {
    height: inherit;
    min-height: inherit;
    background-color: #fff;
    padding: 10px 15px;
    padding-left: 65px;
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center; }

.nc-payroll-frequency {
  margin-bottom: 20px; }

.payroll-frequency {
  list-style-type: none;
  margin: 0px;
  border: 1px solid #cccccc;
  padding: 0px;
  display: inline-block; }
  .payroll-frequency.no-hover li:hover {
    background-color: #fff; }
  .payroll-frequency.no-hover input[type="checkbox"]:checked:hover + label {
    background-color: #009999;
    color: #fff; }
  .payroll-frequency li {
    text-align: center;
    float: left;
    border-right: 1px solid #cccccc; }
    .payroll-frequency li .hidden-checkbox {
      visibility: hidden; }
    .payroll-frequency li input[type="checkbox"] + label {
      width: 30px;
      height: 30px;
      max-width: 30px;
      max-height: 30px;
      margin: 0px;
      font-weight: 400;
      cursor: inherit;
      display: flex;
      justify-content: center;
      align-items: center; }
    .payroll-frequency li input[type="checkbox"]:hover + label {
      background-color: #ccffff; }
    .payroll-frequency li input[type="checkbox"]:checked + label::before {
      content: none; }
    .payroll-frequency li input[type="checkbox"] + label::before {
      content: none; }
    .payroll-frequency li input[type="checkbox"]:checked + label {
      background-color: #009999;
      color: #fff; }
    .payroll-frequency li input[type="checkbox"]:checked:hover + label {
      background-color: #009999;
      color: #fff; }
  .payroll-frequency li:hover {
    cursor: pointer;
    background-color: #ccffff; }
  .payroll-frequency li:last-child {
    border-right: none; }

.nc-navtab-container {
  float: left;
  position: relative;
  overflow-x: hidden; }
  .nc-navtab-container .nc-nav {
    width: max-content;
    list-style-type: none;
    margin-top: 20px;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
    .nc-navtab-container .nc-nav > li {
      position: relative;
      display: block;
      padding: 10px 15px; }
  .nc-navtab-container .nc-nav-tabs {
    border-bottom: 1px solid #ddd; }
    .nc-navtab-container .nc-nav-tabs > li {
      float: left;
      margin-bottom: -1px; }
    .nc-navtab-container .nc-nav-tabs li:hover {
      background-color: #eee;
      cursor: pointer; }
    .nc-navtab-container .nc-nav-tabs li.active {
      background-color: #fff;
      border-top: 4px solid #396;
      width: inherit; }

.scroll-buttons {
  float: left;
  margin-top: 20px; }
  .scroll-buttons .back,
  .scroll-buttons .next {
    float: left;
    width: 30px;
    height: 30px;
    padding: 8px 10.5px;
    border: 1px solid #eee; }
    .scroll-buttons .back i,
    .scroll-buttons .next i {
      margin-right: 0px; }
  .scroll-buttons .back:hover,
  .scroll-buttons .next:hover {
    cursor: pointer;
    background-color: #eee; }

.nc-accordian {
  margin-bottom: 15px;
  clear: both; }
  .nc-accordian .accordian-title {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 15px; }
  .nc-accordian .form-accordian-title {
    color: #339966;
    font-size: 18px;
    font-weight: 600; }
    .nc-accordian .form-accordian-title:hover {
      cursor: pointer; }
  .nc-accordian .collapse {
    margin-top: 10px; }
  .nc-accordian.form-accordian {
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc; }
    .nc-accordian.form-accordian:last-child {
      border-bottom: none; }
  .nc-accordian .arrow {
    transition: transform 0.5s; }
    .nc-accordian .arrow.arrow-active {
      transform: rotate(-180deg); }
    .nc-accordian .arrow.arrow-inactive {
      transform: rotate(0deg); }

.alert {
  border-radius: 0px;
  font-size: 15px;
  padding: 10px 20px;
  font-weight: 400;
  margin-bottom: 0; }

.alert-warning {
  background-color: #ffffcc;
  border: 1px solid #cccc00;
  color: #8a6d3b; }
  .alert-warning .fa-info-circle,
  .alert-warning .fa-times-circle-o {
    color: #8a6d3b; }
  .alert-warning .close-icon:hover {
    cursor: pointer; }
  .alert-warning .fa-times-circle-o:hover {
    cursor: pointer; }

.alert-danger {
  color: #fff;
  background-color: #fb6b5b;
  border: 1px solid #990000; }
  .alert-danger .fa-info-circle,
  .alert-danger .fa-times-circle-o {
    color: #fff; }
  .alert-danger .fa-times-circle-o:hover {
    cursor: pointer; }
  .alert-danger .fa {
    color: #fff; }

.alert-success {
  background-color: #ccffcc;
  border: 1px solid #26a977;
  color: #339966; }
  .alert-success .fa-info-circle,
  .alert-success .fa-times-circle-o {
    color: #339966; }
  .alert-success .fa-times-circle-o:hover {
    cursor: pointer; }
  .alert-success .fa {
    color: #396; }

.alert-generic {
  background-color: #d2ecec;
  color: #025471;
  border: 1px solid #0077a1; }
  .alert-generic .fa-info-circle,
  .alert-generic .fa-times-circle-o {
    color: #025471; }
  .alert-generic .fa-times-circle-o:hover {
    cursor: pointer; }
  .alert-generic .fa {
    color: #025471; }

.alert-notification {
  background-color: #d0eae5;
  border: 1px solid #26a977;
  color: #339966; }

.notification-header-message {
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  margin-bottom: 5px; }

.notification-message {
  font-size: 16px;
  text-align: left; }

@media (min-width: 992px) {
  .copy-entity-notification-container {
    position: absolute;
    z-index: 1; } }

@media (max-width: 767px) {
  .copy-entity-notification-container {
    right: 0px !important;
    left: 0px !important;
    padding: 0px 10px 0px 10px !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .copy-entity-notification-container {
    right: 0px !important;
    left: 0px !important;
    padding: 0px 20px 20px 20px !important; } }

.copy-entity-notification-container .toaster .toaster-message {
  display: flex; }
  .copy-entity-notification-container .toaster .toaster-message .notification-toaster-bell-icon {
    margin-right: 20px; }

.toaster-message {
  width: 95%; }

.toaster-close-icon {
  width: 5%; }

.payroll-dash-header-container {
  width: 100%;
  font-family: "Source Sans Pro", sans-serif !important; }
  @media (max-width: 767px) {
    .payroll-dash-header-container .payroll-dash-header-content {
      padding: 10px 10px 0 10px; } }
  .payroll-dash-header-container .payroll-dash-header-content .payroll-dash-header-title {
    font-family: "Source Sans Pro", sans-serif;
    color: #555;
    font-size: 25px;
    margin-bottom: 15px; }
  @media (min-width: 320px) and (max-width: 767px) {
    .payroll-dash-header-container .payroll-dash-header-content .payroll-dash-header-tab-section {
      margin-bottom: 15px; } }
  .payroll-dash-header-container .payroll-dash-header-content .payroll-dash-header-tab-section .payroll-dash-header-tab-container {
    float: right; }
    @media (max-width: 767px) {
      .payroll-dash-header-container .payroll-dash-header-content .payroll-dash-header-tab-section .payroll-dash-header-tab-container {
        float: left; } }
  .payroll-dash-header-container .payroll-dash-header-content .payroll-dash-header-tab-section .nc-tabs {
    float: left; }
  .payroll-dash-header-container .payroll-dash-header-content .payroll-dash-header-message {
    font-family: "Source Sans Pro", sans-serif;
    color: #555;
    font-size: 20px;
    margin-bottom: 30px; }
    @media (max-width: 767px) {
      .payroll-dash-header-container .payroll-dash-header-content .payroll-dash-header-message {
        margin-bottom: 20px; } }
    .payroll-dash-header-container .payroll-dash-header-content .payroll-dash-header-message .payroll-dash-header-stage-message {
      font-weight: 400; }

.payroll-dash-summary-container {
  padding: 30px;
  border: 1px solid #dadada;
  background-color: white;
  margin-bottom: 15px; }
  @media (max-width: 767px) {
    .payroll-dash-summary-container {
      padding: 20px 10px; } }
  .payroll-dash-summary-container .payroll-dash-summary-footer-section .payroll-dash-summary-footer-contents .table-header {
    display: flex;
    clear: both;
    align-items: flex-end; }
    .payroll-dash-summary-container .payroll-dash-summary-footer-section .payroll-dash-summary-footer-contents .table-header .unapproved-pto-row-header-first-column {
      padding-left: 25px; }
    .payroll-dash-summary-container .payroll-dash-summary-footer-section .payroll-dash-summary-footer-contents .table-header .timecard-row-header-first-column {
      padding-left: 25px; }
  .payroll-dash-summary-container .payroll-dash-summary-header-content {
    margin-bottom: 20px; }
    @media (max-width: 767px) {
      .payroll-dash-summary-container .payroll-dash-summary-header-content {
        margin-bottom: 10px; } }
    .payroll-dash-summary-container .payroll-dash-summary-header-content .payroll-dash-summary-header-cell {
      float: left; }
      @media (max-width: 767px) {
        .payroll-dash-summary-container .payroll-dash-summary-header-content .payroll-dash-summary-header-cell {
          width: 100%;
          margin-bottom: 10px; } }
    .payroll-dash-summary-container .payroll-dash-summary-header-content .payroll-dash-summary-header-title {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 18px;
      font-weight: 600;
      color: #555;
      padding-left: 20px;
      padding-top: 4px; }
      @media (max-width: 767px) {
        .payroll-dash-summary-container .payroll-dash-summary-header-content .payroll-dash-summary-header-title {
          padding-left: 0;
          padding-top: 10px; } }
  .payroll-dash-summary-container .payroll-dash-summary-header-message {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px; }
  .payroll-dash-summary-container .payroll-dash-summary-footer-buttons {
    padding-top: 20px;
    border-top: 1px solid #ccc; }
    @media (max-width: 991px) {
      .payroll-dash-summary-container .payroll-dash-summary-footer-buttons {
        border-top: none; } }
    @media (max-width: 767px) {
      .payroll-dash-summary-container .payroll-dash-summary-footer-buttons {
        padding: 0; }
        .payroll-dash-summary-container .payroll-dash-summary-footer-buttons button {
          margin-bottom: 10px; }
        .payroll-dash-summary-container .payroll-dash-summary-footer-buttons .secondary-link-button {
          width: 100%; } }
  @media (min-width: 768px) {
    .payroll-dash-summary-container .payroll-dash-summary-footer-buttons {
      text-align: right; } }
  .payroll-dash-summary-container .payroll-dash-summary-banner-section .payroll-dash-summary-banner-inprogress {
    background-color: #eef6e7;
    color: #555;
    display: flex;
    justify-content: center;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
    padding: 8.5px;
    text-align: center; }
  .payroll-dash-summary-container .payroll-dash-summary-content-section .payroll-dash-summary-item-container .payroll-dash-summary-item {
    font-family: "Source Sans Pro", sans-serif;
    width: 20%;
    float: left;
    margin-bottom: 15px;
    border-left: 1px solid #ccc;
    padding-left: 15px; }
    @media (max-width: 767px) {
      .payroll-dash-summary-container .payroll-dash-summary-content-section .payroll-dash-summary-item-container .payroll-dash-summary-item {
        width: 50%; } }
    .payroll-dash-summary-container .payroll-dash-summary-content-section .payroll-dash-summary-item-container .payroll-dash-summary-item .payroll-dash-summary-item-label {
      font-size: 12px;
      font-weight: 600;
      color: #555;
      text-transform: uppercase; }
    .payroll-dash-summary-container .payroll-dash-summary-content-section .payroll-dash-summary-item-container .payroll-dash-summary-item .payroll-dash-summary-item-value {
      font-size: 19px;
      color: #555;
      float: left; }
    .payroll-dash-summary-container .payroll-dash-summary-content-section .payroll-dash-summary-item-container .payroll-dash-summary-item .payroll-dash-summary-item-dollarIcon {
      font-size: 15px;
      margin-right: 2px; }
    .payroll-dash-summary-container .payroll-dash-summary-content-section .payroll-dash-summary-item-container .payroll-dash-summary-item .payroll-dash-summary-item-groupIcon {
      float: left;
      padding-top: 4px;
      padding-left: 5px; }
    .payroll-dash-summary-container .payroll-dash-summary-content-section .payroll-dash-summary-item-container .payroll-dash-summary-item .payroll-dash-summary-item-customIcon {
      float: left;
      padding-top: 1x;
      font-size: 17px;
      font-weight: 600; }
    .payroll-dash-summary-container .payroll-dash-summary-content-section .payroll-dash-summary-item-container .payroll-dash-summary-item .payroll-dash-summary-item-icon {
      float: left;
      padding-top: 3px;
      padding-left: 5px;
      cursor: pointer;
      font-size: 15px; }

.payroll-dash-summary-icon-list-bgcolor {
  background-color: #555; }
  .payroll-dash-summary-icon-list-bgcolor .arrow:after {
    border-bottom-color: #555 !important; }

.payroll-dash-summary-icon-list {
  font-family: inherit;
  color: white;
  min-width: 225px;
  border-radius: 0; }
  .payroll-dash-summary-icon-list .popover-content {
    padding: 10px !important; }
  .payroll-dash-summary-icon-list .payroll-dash-summary-icon-list-icon .icon {
    font-size: 15px;
    float: right;
    margin-right: 0;
    color: white;
    cursor: pointer; }

.nc-badge {
  border-radius: 15px;
  color: #fff;
  font-size: 15px;
  padding: 8px 16px;
  font-weight: 400; }

@media (min-width: 768px) {
  .start-payroll-button-container {
    margin-bottom: 20px; } }

.start-payroll-button-container #payroll-dash-start-payroll-button {
  padding: 0;
  border: none;
  width: 100%;
  height: 100%; }

.start-payroll-button-container .start-payroll-button-content {
  text-align: center;
  background-color: #ccc;
  border: none;
  border-radius: 2px;
  color: #ffffff !important;
  cursor: pointer;
  text-transform: uppercase;
  padding: 10px;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  @media (min-width: 768px) and (max-width: 991px) {
    .start-payroll-button-container .start-payroll-button-content {
      height: 100%; } }

.start-payroll-button-container .start-payroll-button-content.active {
  background-color: #006699; }

.start-payroll-button-container .start-payroll-button-content-icon > img {
  height: 23px; }

.start-payroll-button-container .start-payroll-button-context {
  font-size: 15px;
  font-weight: 400; }

@media (max-width: 768px) {
  .start-payroll-button-container .start-payroll-button-context {
    text-align: center !important;
    display: flex;
    align-items: center;
    justify-content: center; } }

.start-payroll-button-container .start-payroll-button-container-mobile .start-payroll-button-content-mobile {
  background-color: #ccc;
  border: none;
  border-radius: 2px;
  color: #ffffff !important;
  cursor: pointer;
  text-transform: uppercase;
  padding: 10px;
  width: 100%; }

.start-payroll-button-container .start-payroll-button-container-mobile .start-payroll-button-content-mobile.active {
  background-color: #006699; }

.start-payroll-button-container .start-payroll-button-container-mobile .start-payroll-button-content-icon {
  margin-right: 5px; }
  .start-payroll-button-container .start-payroll-button-container-mobile .start-payroll-button-content-icon > img {
    height: 23px; }

.payroll-dash-summary-checklist-section {
  background-color: rgba(233, 233, 233, 0.5);
  padding: 20px 20px 0px 20px;
  margin-left: 0px;
  margin-right: 0px;
  font-family: "Source Sans Pro", sans-serif;
  color: #555; }
  @media (max-width: 767px) {
    .payroll-dash-summary-checklist-section {
      padding: 20px 10px 0px 10px;
      margin-left: -10px;
      margin-right: -10px; } }
  .payroll-dash-summary-checklist-section .checklist-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; }
    .payroll-dash-summary-checklist-section .checklist-header .checklist-header-content .checklist-header-label {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px; }
      @media (max-width: 767px) {
        .payroll-dash-summary-checklist-section .checklist-header .checklist-header-content .checklist-header-label {
          margin-bottom: 0; } }
    @media (max-width: 767px) {
      .payroll-dash-summary-checklist-section .checklist-header .checklist-header-content {
        display: flex;
        align-items: center; } }
    @media (max-width: 991px) {
      .payroll-dash-summary-checklist-section .checklist-header {
        margin-bottom: 10px; } }
    .payroll-dash-summary-checklist-section .checklist-header .button-container button {
      width: inherit;
      white-space: nowrap; }
  .payroll-dash-summary-checklist-section .checklist-message {
    font-size: 15px; }
    @media (max-width: 991px) {
      .payroll-dash-summary-checklist-section .checklist-message {
        padding-right: 20px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .start-payroll-button-section {
    width: 24%; } }

@media (min-width: 768px) and (max-width: 991px) {
  .start-payroll-button-section {
    width: 23.5%; } }

@media (min-width: 1200px) {
  .start-payroll-button-section {
    width: 25%; } }

.column-header {
  font-size: 14px;
  font-weight: 600;
  padding-right: 10px;
  cursor: pointer; }

.column-item {
  font-size: 15px; }
  @media (max-width: 991px) {
    .column-item .field-name {
      float: left;
      font-weight: 600; }
    .column-item .field-value {
      float: left; } }

.column-action {
  font-size: 18px;
  padding-left: 20px;
  float: right;
  color: #777; }
  @media (max-width: 1199px) {
    .column-action {
      padding-left: 10px; }
      .column-action:first-child {
        padding-right: 0px; }
      .column-action:last-child {
        padding-left: 0px; } }
  .column-action i:hover {
    cursor: pointer; }

.user-icon-name {
  padding-right: 10px !important;
  padding-left: 0; }

.column-header-row {
  padding-left: 15px !important;
  padding-right: 15px !important;
  display: flex;
  align-items: flex-end; }

.popover-content {
  padding: 5px 10px; }

.payroll-popover {
  font-weight: 600; }

.payroll-table-header-actions {
  cursor: pointer;
  font-weight: 400 !important;
  font-size: 15px; }

.payroll-dashboard-checkbox {
  float: left;
  margin-right: 5px;
  padding-top: 10px; }
  @media (max-width: 991px) {
    .payroll-dashboard-checkbox {
      padding-top: 5px; } }

.payroll-dashboard-row {
  padding: 15px;
  margin-bottom: 8px; }
  .payroll-dashboard-row:last-child {
    margin-bottom: 0; }
  @media (max-width: 991px) {
    .payroll-dashboard-row {
      margin-bottom: 15px; }
      .payroll-dashboard-row:last-child {
        margin-bottom: 0; } }

.no-payroll-container {
  padding-top: 40px;
  padding-right: 15%;
  padding-left: 15%;
  padding-bottom: 40px;
  font-family: "Source Sans Pro", sans-serif;
  color: #555;
  text-align: center; }
  @media (max-width: 767px) {
    .no-payroll-container {
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 20px;
      padding-bottom: 20px; } }
  .no-payroll-container .no-payroll-container-header-img {
    margin-bottom: 15px; }
  .no-payroll-container .no-payroll-container-header {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 15px; }
  .no-payroll-container .no-payroll-container-body {
    line-height: 23px;
    font-size: 15px; }
  .no-payroll-container .no-payroll-container-footer {
    line-height: 23px;
    font-size: 15px; }
  .no-payroll-container .primary-link-button {
    text-transform: none;
    padding: 0px;
    cursor: pointer; }

.dashboard-toaster-container {
  margin-top: 15px; }
  .dashboard-toaster-container .alert {
    margin-bottom: 0px; }

.previewPayrollCalendarContainer {
  background-color: #e6e6e6;
  padding: 20px 20px 0px 20px;
  margin-bottom: 20px; }
  .previewPayrollCalendarContainer .title {
    color: #009966;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px; }
  .previewPayrollCalendarContainer .content-section {
    padding-bottom: 20px; }
    .previewPayrollCalendarContainer .content-section span {
      color: #555; }
  .previewPayrollCalendarContainer .previewButtonSection {
    background-color: #f2f2f2;
    padding: 5px 10px;
    width: 40%;
    margin-left: auto;
    margin-right: auto; }

body {
  cursor: default;
  font-family: "Source Sans Pro", sans-serif !important;
  line-height: 1.2;
  color: #555; }

div:focus {
  outline: none; }

a:focus,
a:hover {
  text-decoration: none;
  outline: none;
  cursor: pointer; }
  @media (min-width: 992px) {
    a:focus,
    a:hover {
      color: #0c4f48; } }
  @media (max-width: 991px) {
    a:focus,
    a:hover {
      color: #009999; } }

.display-table {
  display: table; }

i {
  margin-right: 5px; }

.row {
  margin-left: 0px;
  margin-right: 0px; }

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9 {
  padding-left: 0px;
  padding-right: 0px; }

.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9 {
  padding-left: 0px;
  padding-right: 0px; }

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  padding-left: 0px;
  padding-right: 0px; }

.tooltip.in {
  opacity: 1 !important; }

@media (max-width: 991px) {
  .tooltip {
    display: none; } }

body.modal-open {
  overflow: hidden;
  height: 100%;
  position: relative; }

.html-modal-open {
  overflow: hidden;
  height: 100%;
  position: relative; }

.round-grey-circle-icon {
  background-color: #c5c5c5;
  border-radius: 7px;
  color: white;
  font-size: 12px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 2px;
  padding-right: 2px; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.green {
  color: #396; }

.grey {
  color: #999; }

.border-collapse {
  border-collapse: collapse !important; }

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer;
  color: #999 !important;
  border: 1px solid #ccc !important; }
