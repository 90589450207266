.input-field .input-text-sign {
  display: flex;
  align-items: center;
  background: #eee;
  border: 1px solid #999;
  border-right: none;
  border-radius: 4px 0px 0px 4px;
  width: 34px;
  flex-shrink: 0; }
  @media (max-width: 767px) {
    .input-field .input-text-sign {
      width: 44px; } }
  .input-field .input-text-sign.__isSuffix {
    border-radius: 0px 4px 4px 0px;
    border-left: 0;
    border-right: 1px solid #999; }
  .input-field .input-text-sign span {
    width: 100%;
    text-align: center;
    color: #555;
    font-weight: 600; }
