@media (min-width: 992px) {
  .tab-content-header-wrapper {
    margin-bottom: 30px; } }

@media (max-width: 991px) {
  .tab-content-header-wrapper {
    margin-bottom: 20px; } }

@media (min-width: 768px) {
  .tab-content-header-wrapper .tab-content-header {
    display: flex;
    align-items: center;
    justify-content: space-between; } }

@media (min-width: 992px) {
  .tab-content-header-wrapper .tab-content-header {
    margin-bottom: 15px; } }

@media (min-width: 768px) {
  .tab-content-header-wrapper .tab-content-header .title {
    display: flex;
    font-size: 18px;
    font-weight: 600;
    align-items: center; } }

@media (max-width: 991px) {
  .tab-content-header-wrapper .tab-content-header .title {
    font-size: 20px; } }

@media (max-width: 767px) {
  .tab-content-header-wrapper .tab-content-header .title {
    font-size: 25px; } }

.tab-content-header-wrapper .tab-content-header .title svg {
  margin-right: 5px; }

.tab-content-header-wrapper .tab-content-header .customize-link {
  font-size: 15px; }
  @media (max-width: 767px) {
    .tab-content-header-wrapper .tab-content-header .customize-link {
      margin-top: 5px; } }
