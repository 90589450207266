.MultiSectionListItem {
  clear: both;
  padding-top: 20px;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 20px; }

@media (max-width: 991px) {
  .MultiSectionListItem:first-child {
    padding-top: 0px !important; } }

.MultiSectionListItem:last-child {
  border-bottom: none;
  padding-bottom: 0; }
