.legend-container {
  border-top: 1px solid #cccccc;
  padding: 15px 40px 0px 40px; }
  .legend-container .legends {
    list-style-type: none;
    padding-left: 0px; }
    .legend-container .legends .legend-item {
      margin-bottom: 15px; }
      .legend-container .legends .legend-item:last-child {
        margin-bottom: 0; }
      .legend-container .legends .legend-item .legend-box {
        border-radius: 2.5px;
        padding: 5px;
        margin-right: 10px;
        text-align: center;
        width: 32px;
        height: 32px;
        display: inline-block; }
      .legend-container .legends .legend-item .today-legend {
        background-color: #006699;
        color: #fff; }
      .legend-container .legends .legend-item .available-wire-legend {
        background-color: #009999;
        color: #fff; }
      .legend-container .legends .legend-item .available-legend {
        background-color: #fff;
        color: #555;
        border: 1px solid #555; }
      .legend-container .legends .legend-item .unavailable-legend {
        background-color: #ccc;
        color: #555; }
      .legend-container .legends .legend-item .holiday-legend {
        background-color: #00cccc;
        color: #fff; }
