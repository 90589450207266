.user-icon {
  display: flex; }
  .user-icon .user-pic {
    margin-right: 20px; }
    .user-icon .user-pic img {
      border-radius: 50%; }
  .user-icon .user-info .user-name {
    color: #009999;
    font-size: 18px; }
  .user-icon .user-info .user-description {
    color: #999;
    font-size: 14px; }
    .user-icon .user-info .user-description .user-verified {
      font-style: italic; }
      .user-icon .user-info .user-description .user-verified .fa-check-circle-o {
        color: #77e077; }
    .user-icon .user-info .user-description span {
      border-right: 1px solid #ccc;
      padding: 0px 5px; }
      .user-icon .user-info .user-description span:last-child {
        padding-right: 0px;
        border-right: none; }
      .user-icon .user-info .user-description span:first-child {
        padding-left: 0px; }
