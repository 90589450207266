.nc-tabs {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  border-bottom: 1px solid #999;
  cursor: pointer;
  padding-bottom: 10px;
  font-weight: 600;
  margin-left: 20px; }
  @media (min-width: 320px) and (max-width: 767px) {
    .nc-tabs {
      font-size: 14px;
      margin-left: 0px;
      padding-bottom: 10px; } }
  .nc-tabs .nc-tab-badge {
    float: left;
    position: relative; }
  .nc-tabs .nc-tab-label {
    padding-right: 5px;
    float: left;
    position: relative; }

@media (min-width: 320px) and (max-width: 767px) {
  .nc-tabs:last-child {
    margin-left: 10px; } }

@media (max-width: 322px) {
  .nc-tabs:last-child {
    margin-left: 3px; } }

.nc-tab-active {
  color: #333; }

.nc-tab-disable {
  color: #999; }

.badge-count {
  background-color: #fb6b5b;
  font-size: 14px !important;
  font-family: Source Sans Pro, sans-serif !important;
  font-weight: 400;
  min-width: 20px;
  height: 20px;
  padding: 3px 7px; }
