.report-dropdown-yes-mark {
  color: green !important; }

.report-dropdown-no-mark {
  color: #a3a3a3 !important; }

.employer-expence-liability-accounts-section {
  border-bottom: none !important; }
  .employer-expence-liability-accounts-section .employer-expence-found-result {
    padding-left: 0px !important; }

.general-ledger-next-button {
  margin-right: 30px !important;
  background-color: #e0dfdf !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 20px !important;
  border-radius: 0px !important;
  border: none !important;
  padding: 15px 40px 15px 40px !important; }

.general-ledger-cancel-button {
  color: #a3a3a3 !important;
  background-color: white !important;
  border-radius: 0px !important;
  border: none !important;
  padding: 15px 40px 15px 40px !important; }

.general-ledger-employer-view-tabs-section-with-button {
  padding-left: 0px !important; }

.general-ledger-employer-view-tabs-section {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.gl-deduction-page-description-button {
  margin-bottom: 30px !important;
  padding: 0px !important;
  float: right; }
  .gl-deduction-page-description-button button {
    float: right !important; }

.list-page-grid-table-cell .form-group {
  margin-bottom: 0px !important;
  padding-right: 15px !important; }

.unmapped-badge {
  background-color: #fb6b5b !important;
  padding: 6px 14px !important;
  font-size: 14px !important;
  font-family: "Source Sans Pro", sans-serif !important;
  border-radius: 20px !important;
  margin-top: -15px !important;
  margin-bottom: 10px !important; }

.unmapped-count-badge {
  background-color: #fb6b5b !important;
  font-size: 14px !important;
  font-family: "Source Sans Pro", sans-serif !important; }

.unmapped-count-badge-description {
  padding: 5px !important;
  color: #555 !important; }

.unmapped-count-badge-section {
  margin-top: 15px !important; }

.entity-page-footer {
  padding-left: 0px !important; }

.entity-page-footer-buttons {
  margin-top: 20px !important; }

.general-ledger-cancel-button-modal-content {
  min-height: 200px !important;
  min-width: 700px !important;
  top: 300px !important; }

.modal-footer-top-margin {
  margin-top: 30px !important;
  margin-left: 15px;
  padding-right: 0px !important; }

.cancel-changes-button {
  margin-left: 1px !important; }

.modal-footer-buttons-section {
  margin-top: 10px !important; }

.save-changes-button {
  margin-right: 5px !important;
  width: 30% !important; }

@media (min-width: 992px) {
  .continue-editing-button {
    margin-right: 5px !important;
    width: 35% !important; } }

.top-padding {
  padding-top: 15px !important;
  padding-bottom: 10px !important; }
  @media (min-width: 320px) and (max-width: 991px) {
    .top-padding {
      padding-bottom: 5px !important; } }

.add-general-ledge-popup-tab {
  border: none !important; }
  .add-general-ledge-popup-tab ul {
    border-bottom: none; }
    .add-general-ledge-popup-tab ul .active {
      border-bottom: 1px solid white; }
    .add-general-ledge-popup-tab ul li.active > a {
      color: #555555;
      border-radius: 0px;
      border-bottom: 4px solid #71d769 !important;
      font-weight: 600; }
    .add-general-ledge-popup-tab ul li {
      font-size: 16px !important; }
      .add-general-ledge-popup-tab ul li a {
        color: #555555;
        border-radius: 0px;
        margin-right: 15px;
        border: none !important;
        padding: 0px !important;
        border-bottom: 1px solid grey !important; }
      .add-general-ledge-popup-tab ul li a:hover {
        background-color: transparent !important; }

.add-general-ledge-popup-tab .tab-content .active {
  background-color: transparent !important;
  width: 100% !important; }

.add-general-ledge-popup-tab ul li.active {
  background-color: transparent !important; }
  @media (min-width: 992px) {
    .add-general-ledge-popup-tab ul li.active {
      width: inherit !important; } }

@media screen and (min-width: 320px) and (max-width: 991px) {
  .list-page-grid-table .name-column {
    font-size: 16px;
    padding-right: 0px !important; }
  .add-general-ledge-popup-tab {
    display: block;
    font-weight: 700; }
    .add-general-ledge-popup-tab ul {
      display: inline-flex;
      width: 100%; }
      .add-general-ledge-popup-tab ul li.active {
        margin-right: 2%; }
      .add-general-ledge-popup-tab ul li {
        width: 50%; }
        .add-general-ledge-popup-tab ul li a {
          margin-left: 5px;
          margin-right: 10px; }
  .react-toggle {
    float: right !important; }
  .react-toggle-track {
    width: 55px !important;
    height: 28px !important; }
  .react-toggle-thumb {
    width: 26px !important;
    height: 26px !important; }
  .react-toggle--checked {
    left: 28px !important;
    margin-right: 30px; }
    .react-toggle--checked .react-toggle-thumb {
      left: 28px !important; }
  .unmapped-badge {
    font-size: 12px !important;
    margin-bottom: 0px !important; }
  .add-general-ledge-popup-tab .tab-content .active {
    background-color: transparent !important;
    width: 100% !important; }
  .add-general-ledge-popup-tab ul li.active {
    background-color: transparent !important; } }
  @media screen and (min-width: 320px) and (max-width: 991px) and (min-width: 992px) {
    .add-general-ledge-popup-tab ul li.active {
      width: inherit !important; } }

@media (min-width: 992px) {
  .temporary-class-for-dropdown {
    width: 30%;
    margin-left: 2%; } }

@media screen and (min-width: 320px) and (max-width: 991px) {
  .temporary-class-for-dropdown {
    padding-bottom: 10px;
    padding-top: 10px; } }
