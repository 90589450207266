.dependent {
  background-color: #ccffcc;
  width: fit-content; }

.beneficiary {
  background-color: #339966;
  width: fit-content;
  color: #ffffff; }

.type-tag {
  padding: 3px 20px;
  border-radius: 30px;
  text-align: center;
  font-style: normal !important; }
