.custom-fields-container .No-Holiday-Found-Page {
  border: dashed 1px #ccc;
  border-radius: 5px;
  padding: 50px 50px 60px 50px;
  background-color: #e9e9e9; }
  .custom-fields-container .No-Holiday-Found-Page .No-Holiday-Found-Image {
    display: flex;
    justify-content: center;
    align-items: center; }
  .custom-fields-container .No-Holiday-Found-Page .No-Holiday-Found-Head {
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px; }
  .custom-fields-container .No-Holiday-Found-Page .No-Holiday-Found-content {
    text-align: center;
    font-size: 15px; }

.custom-fields-container .custom-field .custom-field-accordian {
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 600; }
  .custom-fields-container .custom-field .custom-field-accordian .accordian-arrow {
    font-size: 20px;
    color: #555; }
  .custom-fields-container .custom-field .custom-field-accordian .accordian-name {
    padding-left: 20px;
    color: #555; }

.custom-fields-container .custom-field .collapse-section {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-radius: 3px;
  padding: 20px 40px;
  margin-bottom: 20px; }
  .custom-fields-container .custom-field .collapse-section .collapsable-section {
    background-color: white !important; }
  .custom-fields-container .custom-field .collapse-section .section-table {
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 20px; }
    .custom-fields-container .custom-field .collapse-section .section-table .field-item {
      margin-top: 5px;
      margin-bottom: 5px;
      background-color: #e9e9e9;
      display: flex;
      align-items: center; }
      .custom-fields-container .custom-field .collapse-section .section-table .field-item .field-name-section {
        display: flex;
        align-items: center; }
        .custom-fields-container .custom-field .collapse-section .section-table .field-item .field-name-section .field-image {
          padding: 10px 0px 10px 10px; }
        .custom-fields-container .custom-field .collapse-section .section-table .field-item .field-name-section .field-name {
          padding-left: 20px; }
      .custom-fields-container .custom-field .collapse-section .section-table .field-item .edit-and-delete-icons {
        display: flex;
        justify-content: flex-end;
        font-size: 22px; }
        .custom-fields-container .custom-field .collapse-section .section-table .field-item .edit-and-delete-icons .icon {
          padding-left: 20px;
          padding-right: 20px; }
  .custom-fields-container .custom-field .collapse-section .custom-field-section {
    display: flex;
    align-items: center; }
    .custom-fields-container .custom-field .collapse-section .custom-field-section .button-group {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
  .custom-fields-container .custom-field .collapse-section .details-page-header {
    cursor: pointer; }
    .custom-fields-container .custom-field .collapse-section .details-page-header .disabled {
      color: #e9e9e9; }
    .custom-fields-container .custom-field .collapse-section .details-page-header #popover-close-icon {
      font-size: 20px; }
    .custom-fields-container .custom-field .collapse-section .details-page-header .edit-field {
      font-size: 18px;
      position: relative;
      padding-left: 10px; }

.custom-fields-container .custom-field .grey-color-background {
  background-color: #efefef !important; }

.custom-fields-container .custom-field .white-color-background {
  background-color: white !important;
  border: 1px solid #cccccc; }
