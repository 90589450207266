.modal-body-container {
  font-family: "Source Sans Pro", sans-serif;
  color: #555; }
  .modal-body-container .modal-body-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px; }
  .modal-body-container .modal-body-content {
    font-size: 15px;
    line-height: 1.4; }
    .modal-body-container .modal-body-content .primary-link-button {
      text-transform: none;
      cursor: pointer; }
    .modal-body-container .modal-body-content .content-section {
      margin-bottom: 10px; }
    .modal-body-container .modal-body-content .content-section-item {
      margin-bottom: 5px !important; }
    .modal-body-container .modal-body-content .list-section > ul {
      padding-top: 5px;
      padding-left: 17px; }
      .modal-body-container .modal-body-content .list-section > ul li {
        margin-bottom: 5px; }
        .modal-body-container .modal-body-content .list-section > ul li:last-child {
          margin-bottom: 0px; }
    .modal-body-container .modal-body-content .modal-body-gray-section {
      background-color: rgba(233, 233, 233, 0.5);
      border: 1px solid #ccc;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 15px;
      display: flex;
      align-items: center; }
      .modal-body-container .modal-body-content .modal-body-gray-section .primary-link-button {
        font-weight: 400;
        padding: 0px !important;
        cursor: pointer; }
      .modal-body-container .modal-body-content .modal-body-gray-section:last-child {
        margin-bottom: 10px; }
      .modal-body-container .modal-body-content .modal-body-gray-section.inside-accordian:last-child {
        margin-bottom: 0px; }
    .modal-body-container .modal-body-content .alert-infomation-section {
      padding-top: 20px;
      border-top: 1px solid #ccc; }
    .modal-body-container .modal-body-content .accordian-title {
      font-size: 15px;
      font-weight: normal !important; }
  .modal-body-container .secondary-header {
    font-size: 15px;
    font-weight: 600; }
  .modal-body-container .warning-icon {
    background-color: #fb6b5b !important; }
  .modal-body-container .outline-button {
    background-color: #fff; }
    @media (max-width: 767px) {
      .modal-body-container .outline-button {
        width: unset !important; } }
  .modal-body-container .fa-exclamation-circle {
    color: #fb6b5b; }
  .modal-body-container .fa-angle-right {
    font-size: 16px; }
  .modal-body-container .fa-angle-down {
    font-size: 16px; }

.two-line-modal-content {
  font-size: 18px; }

.modal-content-button {
  padding: 8px 20px 10px 25px; }
  .modal-content-button i {
    margin-right: 0px; }

@media (min-width: 992px) {
  .outline-button:hover {
    background-color: #f0eeee !important; } }

.modal-footer .float-left {
  float: none; }
  @media (min-width: 768px) {
    .modal-footer .float-left {
      float: left; } }
