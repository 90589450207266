#payroll-calendar .modal-content {
  background: #e6e6e6; }
  #payroll-calendar .modal-content #payroll-modal-cancel-button-close-icon {
    float: right;
    cursor: pointer; }
  #payroll-calendar .modal-content .preview-payroll-calendar-container .title {
    color: #009966;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 10px; }
  #payroll-calendar .modal-content .preview-payroll-calendar-container .content-section {
    padding-bottom: 10px; }
    #payroll-calendar .modal-content .preview-payroll-calendar-container .content-section span {
      color: #555; }
  #payroll-calendar .modal-content .preview-payroll-calendar-content {
    padding: 0px 50px; }
    #payroll-calendar .modal-content .preview-payroll-calendar-content .payroll-calendar-summary-container {
      padding: 15px 20px 0px 20px;
      background-color: #e6e6e6;
      margin-bottom: 15px; }
      #payroll-calendar .modal-content .preview-payroll-calendar-content .payroll-calendar-summary-container .payroll-calendar-summary-item-container {
        background: #ffffff; }
